import React, {Component} from "react";

class SaturationExam extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {observations} = this.props
        // let heartRate = observations.find(observation => observation.type === 'Heart_Rate');
        let saturation = observations.find(observation => observation.type === 'Saturation');
        return (
            <div className={'exam-result-container saturation-exam-container'}>
                <div className={'exam-results saturation-results-container centered'}>
                    <div className={'exam-saturation saturation-result'}>
                        {saturation ? saturation['value'] : ''}%
                    </div>
                    <div className={'exam-saturation bpm-result'}>
                        <div className={'bpm-result-number'}>
                            {saturation ? saturation['secondValue'] : ''}
                            {/*{heartRate ? heartRate['value'] : ''}*/}
                        </div>
                        <div className={'bpm-result-unit'}>
                            bpm
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SaturationExam
