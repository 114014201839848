import React, {Component} from "react";

class MC_Abdomen extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#c10096'

        let A_color = grey
        let B_color = grey

        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){ return (
            <svg viewBox={"0 0 333 385"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF"
                          d="M115.168 213.509l4.503 1.184c4.794-12.456 1.846-24.91-1.263-38.163-1.026-4.395-2.291-8.729-3.789-12.981-.412-1.165.651-4.857-.67-7.619l-16.127 5.625-10.566 18.379-5.468 26.771-8.34 42.251-1.66 10.157-8.33 12.199-7.76 25.676-4.488 16.04-4.92 17.224.052.157-5.202 12.78 6.9 8.49 9.73-5.134 19.817-39.265 11.005-19.066 5.365-21.114 15.108-35.524 6.103-18.067z"/>
                    <path fill="#FBDFCF"
                          d="M58.126 338.572l1.257 5.893-3.521 17.679-4.273 9.736-12.324 17.936-4.024.512-4.275 3.075-6.287 1.281-2.26-2.05-.253-3.848-1.006-2.563 4.778-18.704-5.281 2.306-5.03-1.025-.754-3.838 4.275-2.554 3.262-4.1 3.018-9.736 12.574-9.736 5.28-3.848 9.807-1.793 5.037 5.377zM209.456 246.665l-2.151 31.945 4.146 23.264.051.13 5.305 26.619c-4.649.775-9.296 1.483-13.971 1.876-15.162 1.037-30.377.971-45.53-.197-14.382-.875-30.851-2.662-46.882-2.006l5.255-26.292.05-.13 4.134-22.389-1.932-31.931s-7.585-32.391-7.84-46.192c-.206-10.889 6.475-34.634 1.373-44.552l27.927-9.681 8.68-7.805.476-10.981.091-1.98.606-13.369 13.029-8.632.012-.014.402.092.899.196.091-.026 1.211-.262.101.066 12.929 8.571.567 12.974.142 3.124.462 10.232 8.678 7.805 25.122 8.501 2.512 1.338c-.515 1.114 8.756 7.649 8.433 8.435-2.163 5.301-2.125 8.62-3.489 14.208-3.811 15.652-4.789 9.761-7.382 40.394l-3.507 26.669zM271.975 299.556l-.936-3.013-.033-.113.969 3.126z"/>
                    <path fill="#FBDFCF"
                          d="M211.632 213.213l-2.032-.887c-4.794-12.456-4.38-22.984-1.271-36.237 1.029-4.395 2.296-8.728 3.796-12.979.733-2.22 1.593-4.395 2.575-6.512l14.662 5.863 10.129 17.033 5.47 26.771 8.336 42.251 1.661 10.156 8.333 12.201 7.758 25.672 4.488 16.04 4.917 17.226-.052.159 5.209 12.779-6.9 8.491-9.731-5.134-19.82-39.265-11.007-19.077-4.731-20.671-15.401-34.933-6.389-18.947z"/>
                    <path fill="#FBDFCF"
                          d="M268.617 338.132l-1.257 5.893 3.521 17.677 4.275 9.736 12.323 17.934 4.024.513 4.275 3.075 6.285 1.28 2.263-2.05.251-3.848 1.006-2.562-4.778-18.704 5.281 2.305 5.03-1.025.755-3.848-4.274-2.562-3.262-4.099-3.018-9.736-12.573-9.736-5.281-3.848-9.808-1.793-5.038 5.398z"/>
                    <path fill="#ECD2C2"
                          d="M178.63 129.097l-7.585 6.244H157.06l-8.51-6.997.091-1.98.606-13.369 13.03-8.632.412.079.991.175.115.026 1.202-.222 12.927 8.58.567 12.973.139 3.123z"/>
                    <path fill="#FBDFCF"
                          d="M195.687 46.393l-10.679-15.58-18.541-3.762-3.399.234-3.4-.227-18.541 3.796-10.644 15.599-2.318 17.951 1.866 13.226 6.049 25.504 5.418 9.6 11.981 11.819 19.34-.011 11.956-11.847 5.401-9.61 6.009-25.513 1.837-13.224-2.335-17.955z"/>
                    <path fill="#FBDFCF"
                          d="M132.348 75.974l-5.102-1.177-1.852 2.844 3.486 10.388 3.484 7.32 3.251 6.847 4.636.233-7.903-26.455zM193.781 75.92l5.1-1.186 1.856 2.832-3.468 10.397-3.471 7.326-3.24 6.851-4.635.24 7.858-26.46z"/>
                    <path fill="#82543A"
                          d="M193.974 29.907l-13.146-9.517-14.876-3.594-7.408-.296-13.726 3.923-13.128 9.542-7.405 16.811 2.493 22.696 2.477 6.755 4.957 11.95-1.252-18.54 3.544-14.909 17.535-7.3 12.785 5.327 2.035-4.737 4.357 5.034 4.649-7.696 11.913 11.249 2.994 12.972-1.221 18.541 4.934-11.96 2.469-6.758 2.453-22.699-7.433-16.794z"/>
                    <path fill="#E1C7B8" d="M162.022 302.235l2.826-3.456-2.448-3.974-2.663 3.232 2.285 4.198z"/>
                    <path fill="#E1C7B8"
                          d="M220.939 347.363l-1.049-15.487-8.91-32.377-1.079-8.249-5.045 6.93-25.581 7.032-37.093-.282-19.841-7.628-5.358-5.463-1.008 8.328-8.689 31.711-1.217 16.052-5.202 38.163 19.704 7.758 17.741 4.548 17.855.558 5.688-.71 1.911-8.456 1.94 8.543 5.691.7 17.855-.614 17.727-4.608 19.977-6.821-6.017-39.628z"/>
                    <path fill="#82543A"
                          d="M162.82 378.102l-6.537-3.775-3.705-17.761-.876 17.761 10.897 8.881.221-5.106z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M163.5 132.43L163.5 344.675M163.5 132.43L163.5 344.675"/>
                    </g>
                    <path fill="#2E2E2E"
                          d="M221.624 143.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.094.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.13.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404zM98.624 143.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.094.028-.168.084-.224.056-.066.13-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.18.854 2.114 0 .737-.182 1.334-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .074-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.066-.135-.164-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.13.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.164 1.806-.49.401-.336.602-.836.602-1.498 0-.663-.201-1.158-.602-1.484-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M75 217.5L251 217.5M75 217.5L251 217.5"/>
                    </g>
                    <g opacity=".5">
                        <path stroke="#fff" d="M93 156.5L233 156.5M93 156.5L233 156.5"/>
                    </g>
                    <path fill={B_color}
                          d="M169 283c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M177.401 288c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM181 293.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM181 272.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={A_color}
                          d="M133 283c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M140.409 288c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM145 293.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM145 272.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H293V350H0z" transform="translate(17 11)"/>
                    </clipPath>
                    <filter id="filter0_d" width="333" height="390" x="0" y="-5" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );
    }else {
            return (
                <svg viewBox={"0 0 333 385"}
                     width={width}
                     height={height}
                     style={style}
                     xmlns={xmlns}
                     name={name}
                     className={className}>
                    <g clip-path="url(#clip0)" filter="url(#filter0_d)">                    <path d="m100.84 197.227 4.503 1.184c4.794-12.456 1.847-24.91-1.262-38.163a123.692 123.692 0 0 0-3.789-12.981c-.413-1.165.65-4.857-.67-7.619l-16.127 5.625-10.567 18.379-5.468 26.771-8.34 42.251-1.66 10.157-8.33 12.199-7.76 25.676-4.487 16.04-4.92 17.224.051.157-5.202 12.78 6.9 8.49 9.73-5.134 19.818-39.265 11.005-19.066 5.364-21.114 15.108-35.524 6.103-18.067z" fill="#FBDFCF"/>
                    <path d="m43.8 322.29 1.257 5.893-3.521 17.679-4.273 9.736-12.324 17.936-4.024.512-4.275 3.075-6.287 1.281-2.26-2.05-.253-3.848-1.006-2.563 4.778-18.704-5.281 2.306-5.03-1.025-.754-3.838 4.275-2.554 3.262-4.1 3.018-9.736 12.574-9.736 5.28-3.848 9.807-1.793 5.037 5.377zm151.33-91.907-2.151 31.945 4.146 23.264.051.13 5.305 26.619c-4.649.775-9.296 1.483-13.971 1.876a313.793 313.793 0 0 1-45.53-.197c-14.382-.875-30.851-2.662-46.882-2.006l5.255-26.292.05-.13 4.134-22.389-1.932-31.931s-7.585-32.391-7.84-46.192c-.206-10.889 6.475-34.634 1.373-44.552l27.927-9.681 8.68-7.805.476-10.981.091-1.98.606-13.369 13.029-8.632.012-.014.402.092.899.196.091-.026 1.211-.262.101.066 12.929 8.571.567 12.974.142 3.124.462 10.232 8.678 7.805 25.122 8.501 2.512 1.338c-.515 1.114 8.756 7.649 8.433 8.435-2.163 5.301-2.125 8.62-3.489 14.208-3.811 15.652-4.789 9.761-7.382 40.394l-3.507 26.669zm62.519 52.891-.936-3.013-.033-.113.969 3.126z" fill="#FBDFCF"/>
                    <path d="m197.304 196.931-2.032-.887c-4.794-12.456-4.38-22.984-1.271-36.237a123.862 123.862 0 0 1 3.796-12.979 60.718 60.718 0 0 1 2.575-6.512l14.662 5.863 10.129 17.033 5.47 26.771 8.336 42.251 1.661 10.156 8.333 12.201 7.758 25.672 4.488 16.04 4.917 17.226-.052.159 5.209 12.779-6.9 8.491-9.731-5.134-19.82-39.265-11.007-19.077-4.731-20.671-15.401-34.933-6.389-18.947z" fill="#FBDFCF"/>
                    <path d="m254.292 321.851-1.257 5.893 3.521 17.677 4.275 9.736 12.323 17.934 4.024.513 4.275 3.075 6.285 1.28 2.263-2.05.251-3.848 1.006-2.562-4.778-18.704 5.281 2.305 5.03-1.025.755-3.848-4.274-2.562-3.262-4.099-3.018-9.736-12.573-9.736-5.281-3.848-9.808-1.793-5.038 5.398z" fill="#FBDFCF"/>
                    <path d="m164.303 112.816-7.585 6.244h-13.985l-8.51-6.997.091-1.98.606-13.369 13.03-8.632.412.079.991.175.115.026 1.202-.222 12.927 8.58.567 12.973.139 3.123z" fill="#ECD2C2"/>
                    <path d="m181.362 30.111-10.679-15.58-18.541-3.761-3.399.234-3.4-.227-18.541 3.796-10.644 15.599-2.318 17.95 1.866 13.227 6.049 25.504 5.418 9.6 11.981 11.819 19.34-.011 11.956-11.847 5.401-9.61 6.009-25.514 1.837-13.224-2.335-17.955z" fill="#FBDFCF"/>
                    <path d="m118.02 59.693-5.102-1.177-1.852 2.844 3.486 10.388 3.484 7.32 3.251 6.847 4.636.233-7.903-26.455zm61.433-.054 5.1-1.186 1.856 2.832-3.468 10.397-3.471 7.326-3.24 6.851-4.635.24 7.858-26.46z" fill="#FBDFCF"/>
                    <path d="M179.646 13.626 166.5 4.109 151.624.515l-7.408-.296-13.726 3.923-13.128 9.542-7.405 16.81 2.493 22.697 2.477 6.755 4.957 11.95-1.252-18.54 3.544-14.91 17.535-7.3 12.785 5.328 2.035-4.737 4.357 5.034 4.649-7.696 11.913 11.249 2.994 12.972-1.221 18.54 4.934-11.96 2.469-6.757 2.453-22.7-7.433-16.793z" fill="#82543A"/>
                    <path d="m147.695 285.953 2.826-3.456-2.448-3.974-2.663 3.232 2.285 4.198z" fill="#E1C7B8"/>
                    <path d="m206.611 331.082-1.049-15.487-8.91-32.377-1.079-8.249-5.045 6.93-25.581 7.032-37.093-.282-19.841-7.628-5.358-5.463-1.008 8.328-8.689 31.711-1.217 16.052-5.202 38.163 19.704 7.758 17.741 4.548 17.855.558 5.688-.71 1.911-8.456 1.94 8.543 5.691.7 17.855-.614 17.727-4.608 19.977-6.821-6.017-39.628z" fill="#E1C7B8"/>
                    <path d="m148.493 361.821-6.537-3.775-3.705-17.761-.876 17.761 10.897 8.881.221-5.106z" fill="#82543A"/>
                    <g opacity=".5">
                        <path d="M149.176 116.148v212.245-212.245zm0 0v212.245z" fill="#000"/>
                        <path d="M149.176 116.148v212.245m0-212.245v212.245-212.245z" stroke="#fff"/>
                    </g>
                    <path d="M207.624 127a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.302.302 0 0 1-.224.084h-.266zM84.624 127a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.37 1.246.378 2.058.01.42.014.794.014 1.12 0 .318-.004.686-.014 1.106-.018.859-.144 1.568-.378 2.128a2.42 2.42 0 0 1-1.176 1.26c-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.44-.205.752-.527.938-.966.196-.448.3-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                    <g opacity=".5">
                        <path d="M60.676 201.219h176-176zm0 0h176z" fill="#000"/>
                        <path d="M60.676 201.219h176m-176 0h176-176z" stroke="#fff"/>
                    </g>
                    <g opacity=".5">
                        <path d="M78.676 140.219h140-140zm0 0h140z" fill="#000"/>
                        <path d="M78.676 140.219h140m-140 0h140-140z" stroke="#fff"/>
                    </g>
                    <path fill={B_color} d="M154.676 266.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M163.077 271.719a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zm-.517 7.32c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={A_color} d="M118.676 266.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M126.085 271.719a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zm-1.303 9.238c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0H293V350H0z" transform="translate(0 0)"/>
                        </clipPath>
                        <filter id="filter0_d" width="333" height="390" x="0" y="-5" color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="10"/>
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                    </defs>
                </svg>            )
        }
    }

}

export default MC_Abdomen

