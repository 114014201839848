import React, {createRef, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from 'react-confirm'
import '../../style/component/modal/Media.css'
import SVGraphics from "../../assets/SVGraphics";
import moment from "moment";
import sessionsApi from "../../services/ApiServices/doctorApi/sessionsApi";
import MakeError from "../Error/ErrorModal";
import MakeMessage from "./MessageModal";
import MakeErrorConfirm from "../Error/ErrorModalWithCancel";



let snapshots = [];
let scaleFactor = 0.25;


class SnapModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            captures:this.props.captures?this.props.captures:[],
        }

    }
    async checkQueryParam(query) {
        return Object.keys(query).length > 0 && !!query.pid && !!query.tenant
    }

    async deleteSnapShot(fileID){
        let { proceed } = this.props
        let check = await MakeErrorConfirm({title:"Delete Image", message:"Are you sure you want to remove this snapshot?"})
        if(check){
            let {captures} = this.state
            let deleteSnap = await sessionsApi.deleteScreenshot(fileID)
            if(deleteSnap){
                let newSnaps = captures.filter(x=>x.fileID!==fileID)
                proceed(newSnaps)
            }
        }
    }

    render() {
        let {
            show,
            proceed,
            file,
        } = this.props;

        let time = moment(file.createdAt).format('HH:mm');
        let date = moment(file.createdAt).format('MMM DD YYYY');
        let language = 'en'
        let title =''
        if(language === 'en'){
            title = file['type'] === 'MouthCapture' ?'Mouth' : 'Throat'
        }else{
            title = file['type'] === 'MouthCapture'? 'Boca' : 'Garganta'
        }

        return (
            <Modal
                size="lg"
                show={show}
                centered={true}
                onHide={() => proceed()}
                dialogClassName={ 'video-media-dialog-filtering'  + ' snap-dialog'}
                contentClassName={'video-media-content media-content media-content-filtering'}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                    {/*<span className={'media-close-btn-container modal-close-video modal-close-btn-snap pointer'} onClick={() => proceed(this.state.captures)}>*/}

                {/*</span>*/}
                <div className={'video-modal-title'}><span
                    style={{fontWeight: 'bold'}}>{title + ' capture'} </span> {time} | {date} </div>

                <SVGraphics svgname={'close-white'} className={'modal-close-btn image-trash-btn-image modal-close-btn-snap'} onClick={() => proceed(this.state.captures)}/>
                <Modal.Body
                    className={ 'video-media-content-filtering image-body'}>
                    <SVGraphics svgname={'trashIcon'}
                                className={'modal-close-btn image-trash-btn-image'}
                                onClick={() => this.deleteSnapShot(file.fileID)} />
                    <img alt={'img'}
                                 className={'modal-video-media modal-media modal-snap-media-filtering'}
                                 src={file.downloadUrl}/>

                </Modal.Body>
            </Modal>
        );
    }
}

SnapModal.propTypes = {

    file: PropTypes.object,

};

export default function MakeSnap({ file: file,captures:captures}) {
    return createConfirmation(confirmable(SnapModal))({
         file,captures
    });
}
