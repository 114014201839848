import React, {Component} from "react";
import utils from "../../../../utils/utils";
import FinishedSessionExams from "./patient_control/FinishedSessionExams";
import Status from "../../../../config/Status";
const offlineCurrentSessionStatuses = [Status.read, Status.ended];

class OnlineSession extends Component {

    render() {
        let {session, patient,t} = this.props
        return (
            <div className={'patient-card-content-container session-data-container'}>
                <div className={'session-header-container'}>
                    <div className={'session-header'}>
                        <div className={'session-header-text'}>{t('physician.patient.exams.liveSession')}</div>
                        <div className={'session-header-time-container'}>
                            {
                                offlineCurrentSessionStatuses.includes(session['status']) &&
                                <div className={'session-header-time-container'}>
                                    <div className={'session-header-time-container'}>
                                        <div className={'session-header-text session-header-date'}>
                                            {t('physician.patient.profile.start')}: {utils.getDateStringWithYear(new Date(session['examStartTime']))}
                                        </div>
                                        <div
                                            className={'session-header-text session-header-time'}>{utils.format24(new Date(session['examStartTime']))}</div>
                                    </div>
                                    <div className={'session-header-time-container'}>
                                        <div className={'session-header-text session-header-date'}>
                                            {t('physician.patient.profile.end')}: {utils.getDateStringWithYear(new Date(session['examEndTime']))}
                                        </div>
                                        <div
                                            className={'session-header-text session-header-time'}>{utils.format24(new Date(session['examEndTime']))}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                    <FinishedSessionExams session={session} patient={patient}
                                          t={this.props.t}
                                          setStatusRead={(sessionID) => this.props.setStatusRead(sessionID)}
                                          saveSummary={(session) => this.props.saveSummary(session)}  online={true}/>
            </div>
        );
    }

}

export default OnlineSession
