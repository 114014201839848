import React, {Component} from "react";
import '../../../style/View/physician/patient/Session.css'
import '../../../style/View/physician/patient/Card.css'
import queryString from "query-string";
import {doctorApi} from "../../../services/ApiService";
import Status from "../../../config/Status";
import OfflineSession from "../../../component/card/session/offline/OffineSession";
import PatientPersonalInfo from "../../../component/card/info/PatientPersonalInfo";
import SessionHistory from "../../../component/card/session-history/SessionHistory";
import Globals from "../../../utils/Globals";
import {history} from "../../../utils/history";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import OnlineSession from "../../../component/card/session/online/OnlineSession";
import MakeMessage from "../../../component/modal/MessageModal";
import MakeModal from "../../../component/modal/PatientSendExamModal";
import PatientSendExamModal from "../../../component/modal/PatientSendExamModal";
import SVGraphics from "../../../assets/SVGraphics";

const offlineCurrentSessionStatuses = [Status.read, Status.ended];
const onlineShowingSessionStatuses = [Status.initiated, Status.inProgress];


class PatientCardComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pid: null,
            patient: null,
            tenant: null,
            sessions: [],
            history: [],
            showedSession: null,
            hasOfflineSession: false,
            hasOnlineSession: false,
            isLoading: true,
            urlLinkStatus:'Share Link'

        }
        this.historyRef = React.createRef()
    }

    async componentDidMount() {
        let search = !history.location.search || history.location.search === {} ? window.location.search : history.location.search
        let query = queryString.parse(search);
        let allow = await this.checkQueryParam(query);
        let pid = query.pid;
        let sid = query.sid;
        let tenant = query.tenant;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth

        await this.initializeData(pid, tenant, accessToken,asrAuth);
        if(sid) {
            await this.expandRowBySid(parseInt(sid))
            await MakeModal({tenant:tenant})
        }
    }
    async expandRowBySid(sid) {
        await this.historyRef.expandRowBySid(sid)
        let sessionElem = document.getElementById(sid);
        if (sessionElem)
            sessionElem.scrollIntoView();
    }

    async getData(pid,tenant, accessToken, asrAuth) {
        let patient = await doctorApi.getPatientById(pid,tenant, accessToken, asrAuth);
        // console.log({patient})
        let sessions = await doctorApi.getPatientSessionsById(pid,tenant,true, accessToken, asrAuth);
        // console.log(sessions)
        let ignoredSessions = [Status.missed, Status.denied];
        await this.setState({tenant, patient, sessions: sessions.filter(ses => !ignoredSessions.includes(ses.status))});

    }

    async initializeData(pid, tenant, accessToken, asrAuth) {
        let state = Object.assign({}, this.state)
        let history = [], lastSession = null, patientSessions = [];
        let {sessions,patient} = this.state;
        if (!patient || !sessions) {
            await this.getData(pid, tenant, accessToken, asrAuth)
            await this.initializeData(pid, tenant, accessToken, asrAuth)
            return;
        }
        if (patient) {
            history = history.concat(sessions)
            patientSessions = patientSessions.concat(sessions)
            if (!sessions) {
                await this.getData(pid, tenant, accessToken, asrAuth)
                await this.initializeData(pid, tenant, accessToken, asrAuth)
                return;
            } else {
                if (patientSessions.length > 0) {
                    lastSession = patientSessions[0]
                    let videoSessionStatus = lastSession['videoSessionStatus']
                    if (videoSessionStatus === 'Offline' &&
                        offlineCurrentSessionStatuses.includes(lastSession['status'])) {
                        state = ({...state, showedSession: lastSession, hasOfflineSession: true, hasOnlineSession: false})
                        history.splice(0, 1)
                    } else if (videoSessionStatus === 'Online') {
                        if (lastSession['status'] === Status.calling) {
                            patient['onlineSession'] = Object.assign({}, lastSession)
                            if (patientSessions.length > 1) {
                                lastSession = patientSessions[1]
                                videoSessionStatus = lastSession['videoSessionStatus']
                                if (offlineCurrentSessionStatuses.includes(lastSession['status'])) {
                                    if (videoSessionStatus === 'Offline')
                                        state = ({...state, showedSession: lastSession, hasOfflineSession: true, hasOnlineSession: false})
                                    else if (videoSessionStatus === 'Online')
                                        state = ({...state, showedSession: lastSession, hasOnlineSession: true, hasOfflineSession: false})
                                    history.splice(0, 1)
                                }
                            }
                        } else if (onlineShowingSessionStatuses.includes(lastSession['status']) ||
                            offlineCurrentSessionStatuses.includes(lastSession['status'])) {
                            state = ({...state, showedSession: lastSession, hasOnlineSession: true, hasOfflineSession: false})
                            history.splice(0, 1);
                        }
                    }
                }

            }
        } else {

            await this.getData(pid, tenant, accessToken, asrAuth)
            await this.initializeData(pid, tenant, accessToken, asrAuth)
            return;
        }
        state = ({...state, pid, patient, isLoading: false, history})
        this.setState({...state})
    }


    async checkQueryParam(query) {
        return Object.keys(query).length > 0 && !!query.pid && !!query.tenant
    }

    async saveSummary(session, currentSession) {

    }

    async setStatusRead(sessionID) {

    }
    // async createOnlineSession(pid) {
    //     let onlineSession = await doctorApi.createOnlineSession(pid);
    //     this.setState({disableCall: true})
    //     this.props.setCurrentOnlineSession(onlineSession);
    //
    // }
    // callButtonCLicked = async (onlineSession, patient, pid) => {
    //     let isCameraAllowed = false
    //     let isMicrophoneAllowed = false
    //
    //     let doesQueryExists = navigator && navigator.hasOwnProperty('permissions') && (navigator.permissions).hasOwnProperty('query')
    //     if (doesQueryExists) {
    //         try {
    //             isCameraAllowed =  (await navigator.permissions.query({name: 'camera'})).state === 'granted'
    //             isMicrophoneAllowed =  (await navigator.permissions.query({name: 'microphone'})).state === 'granted'
    //         } catch (err) {
    //             console.error(err)
    //         }
    //
    //         if (isCameraAllowed && isMicrophoneAllowed){
    //             !onlineSession ? await this.createOnlineSession(pid) :
    //                 this.answerIncomingCall(patient['onlineSession'], pid)
    //             this.setState({disableCall: true})
    //         } else {
    //             this.props.setAllowedPermission(true)
    //         }
    //     } else {
    //         !onlineSession ? await this.createOnlineSession(pid) :
    //             this.answerIncomingCall(patient['onlineSession'], pid)
    //         this.setState({disableCall: false})
    //     }
    // }

    render() {
        let {isLoading, tenant, patient, history, showedSession, hasOfflineSession, sessions, hasOnlineSession} = this.state;

        let currentShowedSession = JSON.parse(JSON.stringify(showedSession))
        let {t} = this.props
        let firstName, lastName = "";

        if (!!patient) {
            firstName = patient['firstName'];
            lastName = patient['lastName'];
        }

        Globals['hasOfflineSession'] = hasOfflineSession && currentShowedSession;
        Globals['sentToPatient'] = !!currentShowedSession && !!currentShowedSession['summary'] && (currentShowedSession['summary']).length > 0 && ![Status.sent, Status.done].includes(currentShowedSession['status'])

        //style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}

        let ignoredSessions = [Status.missed, Status.denied, Status.inProgress, Status.initiated, Status.calling];
        if (!isLoading)
            return (
                <div className={'app-content offline-session'}>
                    <div className={'app-content-header'}>{`${firstName} ${lastName}`}
                    </div>
                    <div className={'app-content-body'}>
                        <div className={'patient-card-content-container patient-personal-data-container'}>
                            <PatientPersonalInfo patient={patient} tenant={tenant} t={this.props.t}/>
                            {/*<button disabled={!this.props.currentOnlineSession && disableCall} className={!onlineSession ? 'patient-call-btn mhd-btn': 'patient-call-btn patient-call-btn-flicker mhd-btn'} onClick={async () => await this.callButtonCLicked(onlineSession, patient, pid)}>*/}
                            {/*    <SVGraphics svgname={'videocam'} className={'patient-call-btn-videocam'}/>*/}
                            {/*    {*/}
                            {/*        !onlineSession ?*/}
                            {/*            <div className={'patient-call-btn-text'}>{t('physician.patient.profile.callPatient')}</div> :*/}
                            {/*            <div className={'patient-call-btn-text'}>{t('physician.patient.profile.startVideo')}</div>*/}
                            {/*    }*/}

                            {/*</button>*/}
                        </div>
                        <div className={'patient-card-content-container sessions-data-container'}>
                            {hasOnlineSession && currentShowedSession && offlineCurrentSessionStatuses.includes(currentShowedSession['status']) &&
                                <OnlineSession session={currentShowedSession} patient={patient} t={this.props.t}
                                               tenant={tenant}
                                               setStatusRead={(sessionID) => this.setStatusRead(sessionID)}
                                               saveSummary={(session) => this.saveSummary(session, currentShowedSession)}/>
                            }
                            {hasOfflineSession && currentShowedSession &&
                                <OfflineSession session={currentShowedSession} patient={patient} t={this.props.t}
                                                setStatusRead={(sessionID) => this.setStatusRead(sessionID)}
                                                tenant={tenant}
                                                saveSummary={(session) => this.saveSummary(session, currentShowedSession)}/>
                            }
                            {/*{*/}
                            {/*    !!patient &&*/}
                                <SessionHistory setRef={historyRef => this.historyRef = historyRef}
                                                sessions={history.filter(ses => !ignoredSessions.includes(ses.status))}
                                                patient={patient}
                                                tenant={tenant}
                                                t={this.props.t}
                                                setStatusRead={(sessionID) => this.setStatusRead(sessionID)}/>
                            {/*}*/}
                        </div>

                    </div>
                    {/*<PatientSendExamModal/>*/}
                </div>
            );
        else return (
            <div className={"loader spin"} style={{display: isLoading ? "block" : "none"}}/>
        );
    }

}

const PatientCard = compose(
    withTranslation()
)(PatientCardComposed);
export default PatientCard

// https://apple.eu.nonagon-care.com/login?redirectToCard=PatientID02
