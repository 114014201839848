import React, {Component} from 'react'
import ReactPlayer from "react-player";
import $ from 'jquery'
// import screenfull from 'screenfull'
// import { findDOMNode } from 'react-dom'
import SVGraphics from "../../assets/SVGraphics";
import Duration from "../../utils/Duration";
import '../../style/component/media/VideoPlayer.css'
import RangeInputVideo from "../input/RangeInputVideo";

class VideoPlayerSnap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: 'Video Player',
            url: null,
            muted: false,
            playing: false,
            isFullscreen: false,
            played: 0,
            loaded: 0,
            duration: 0,
            currentPosition:0.0
        }
        this.player = null
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0
        })
    }

    handlePlayPause = () => {
        this.setState({playing: !this.state.playing})

        if(!this.state.playing && navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)
        {
            document.getElementById(`videoPlayer+${this.props.title}`).play()
        }
        else if(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1){
            document.getElementById(`videoPlayer+${this.props.title}`).pause()
        }

    }

    handlePlay = () => {
        this.setState({playing: true})
    }

    handlePause = () => {
        this.setState({playing: false})
    }

    handleToggleMuted = () => {
        this.setState({muted: !this.state.muted})
    }

    ref = player => {
        this.player = player
    }

    handleSeekMouseDown = e => {
        this.setState({seeking: true})
    }
     seekToTime() {
        // try and avoid pauses after seeking
         let video_element = document.getElementById(`videoId`);
        video_element.pause();
        video_element.currentTime =  video_element.currentTime +0.033376 ; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:
        let timer = setInterval(function() {
            if (video_element.paused && video_element.readyState ==4 || !video_element.paused) {
                clearInterval(timer);
            }
        }, 50);
    }
    seekToTimeBack() {
        // try and avoid pauses after seeking
         let video_element = document.getElementById(`videoId`);
        video_element.pause();
        video_element.currentTime =  video_element.currentTime -0.033376 ; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:
        let timer = setInterval(function() {
            if (video_element.paused && video_element.readyState ==4 || !video_element.paused) {
                clearInterval(timer);
            }
        }, 50);
    }
    handleSeekChange = e => {
        if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {

            this.setState({played: parseFloat(e.target.value)})}
        else {

            let currentSeconds =  Math.floor(document.getElementById(`videoPlayer+${this.props.title}`).currentTime % 60);
            let played = 0+"."+currentSeconds ;


            this.setState({played})
        }
    }

    handleSeekMouseUp = e => {

        if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {

            this.setState({seeking: false})
            this.player.seekTo(parseFloat(e.target.value))
        } else {
            let currentSeconds =  Math.floor(document.getElementById(`videoPlayer+${this.props.title}`).onmouseup % 60);
        }

    }

    handleProgress = state => {
        if (!this.state.seeking) {
            this.setState(state)
        }
        if (this.state.played >= 0.99999) {
            this.setState({played: 0})
            this.player.seekTo(0)
        }
    }

    handleDuration = (duration) => {
        this.setState({duration})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.fileId !== nextProps.fileId)
            this.setState({title: nextProps.title, url: nextProps.url})
    }

    async componentDidMount() {
        this.setState({title: this.props.title, url: this.props.url})
        // await document.addEventListener('fullscreenchange', event => {this.handleFullScreenChange(event)}, false);
        // await document.addEventListener('mozfullscreenchange', event => {this.handleFullScreenChange(event)}, false);
        // await document.addEventListener('MSFullscreenChange', event => {this.handleFullScreenChange(event)}, false);
        // await document.addEventListener('webkitfullscreenchange', event => {this.handleFullScreenChange(event)}, false);
    }

    componentWillUnmount() {
        // document.removeEventListener('fullscreenchange',console.log('remove fullscreenchange'))
        // document.removeEventListener('mozfullscreenchange',console.log('remove mozfullscreenchange'))
        // document.removeEventListener('MSFullscreenChange',console.log('remove MSFullscreenChange'))
        // document.removeEventListener('webkitfullscreenchange',console.log('remove webkitfullscreenchange'))
    }

    handleClickFullscreen = async () => {
        if (this.props.openFullScreen) {
            this.setState({
                played: 0,
                playing: false
            })
            this.props.openFullScreen()
        }

    }

    handleFullScreenChange = async (event) => {
        this.setState({isFullscreen: !this.state.isFullscreen})
    }
    onEnded =  () => {
        this.setState({playing: false})
        this.setState({played: 0})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.url!==this.props.url){
            let video = document.getElementById('videoId');
            let isPaused = video.paused
            let time = video.currentTime
            video.src = this.props.url;
            video.currentTime = time;
            if(!isPaused){
                video.play()
            }
        }
    }


    render() {
        const {title, url, playing, muted, isFullscreen, played, duration} = this.state
        let {className, videoFiltering,id , frame} = this.props
        let contClass = 'video-player-cont vp-cont-' + className
        let labelClass = 'video-player-label h-centered vp-lbl-' + className
        let playerClass = 'react-player rp-' + className
        let frameClass = 'video-player-frame h-centered vp-frame-' + className
        let videoPlayerControlClass = 'video-player-control  h-centered vp-cont-' + className
        let videoPlayerControllersClass = 'video-player-controllers vpc-cont-' + className
        let videoPlayerPlayControllersClass = 'video-player-play-controllers vpp-cont-' + className
        let videoPlaying = 'video-player-playing-control vp-' + className
        let videoPlayerRange = 'video-player-range custom-range vp-range-' + className
        let fullscreenClass = 'video-player-screen-control video-fullscreen vp-fsc-' + className
        let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1


        return (
            <div className={contClass}>
                {!!title && <label className={labelClass}>{title}</label>}
                <div className={frameClass}>
                    {isSafari ? <video id={id} onTimeUpdate={(e)=>this.handleSeekChange(e)} onEnded={()=>this.onEnded()}  style={{width: "100%", borderTopLeftRadius: "20px",borderTopRightRadius: '20px', height: '100%'}}
                        onSeeking={(e) => this.handleSeekChange(e)}
                        onSeeked={(e) => this.handleSeekChange(e)}
                                      controls
                                       playsinline
                                       // crossOrigin="anonymous"
                                        >
                            {url === this.props.url && <source src={url} type="video/mp4"/>}
                            {url !== this.props.url && <source src={url} type="video/mp4"/>}
                    </video>
                        :
                        // <ReactPlayer
                        // ref={this.ref}
                        // className={playerClass}
                        // width='100%'
                        // height='100%'
                        // url={url}
                        // playing={playing}
                        // muted={muted}
                        // controls={isFullscreen}
                        // onPlay={this.handlePlay}
                        // onPause={this.handlePause}
                        // onReady={() => console.log('onReady')}
                        // onStart={() => console.log('onStart')}
                        // onBuffer={() => console.log('onBuffer')}
                        // onSeek={e => console.log('onSeek', e)}
                        // onError={e => console.log('onError', e)}
                        // onProgress={this.handleProgress}
                        // onDuration={this.handleDuration}
                        // videoStyle={{borderTopRightRadius: '20px',
                        // borderTopLeftRadius:'20px'}}
                        // />

                        <video id={id}
                               // onTimeUpdate={(e)=>this.handleSeekChange(e)}
                               // onEnded={()=>this.onEnded()}
                               style={{width: "100%", height: '100%'}}
                               // onSeeking={(e) => this.handleSeekChange(e)}
                               // onSeeked={(e) => this.handleSeekChange(e)}
                               controls
                               // onLoadedMetadata={this.props.onLoadedMetadata}
                               // onPlaying={this.props.onPlaying}
                               // onPause={this.props.onPause}
                               disablePictureInPicture={true}
                               preload
                                disableDownload={true}
                               controlsList="nodownload noplaybackrate nofullscreen "
                               autoplay={false}
                               // onMouseMove={this.props.onMouseMove}
                               // onMouseOut={this.props.onMouseOut}
                               // crossOrigin="anonymous"

                        >
                            {url === this.props.url && <source src={url} type="video/mp4"/>}
                            {url !== this.props.url && <source src={url} type="video/mp4"/>}
                        </video>
                    }
                  {/*  {videoFiltering &&*/}
                  {/*<RangeInputVideo/>*/}
                  {/*  }*/}

                </div>
                {/*{*/}
                {/*    frame*/}
                {/*}*/}
                {/*<div style={{display:"flex",width:"100%",justifyContent:"center"}}>*/}
                {/*    <div onClick={()=>this.seekToTimeBack()} style={{border:"1px solid black",width:"100px",display:"flex",justifyContent:"space-around",cursor:"pointer",alignItems:"center"}}><i className="arrow left"/> frame -1</div>*/}
                {/*    <div onClick={()=>this.seekToTime()} style={{border:"1px solid black",width:"100px",display:"flex",justifyContent:"space-around",cursor:"pointer",alignItems:"center"}}>*/}
                {/*        frame +1<i className="arrow right"/> </div>*/}
                {/*</div>*/}

                {/*<div className={videoPlayerControlClass}>*/}
                {/*    <div className={videoPlayerControllersClass}>*/}
                {/*        <div className={videoPlayerPlayControllersClass}>*/}
                {/*            <SVGraphics svgname={playing ? 'pause' : 'play'} onClick={this.handlePlayPause}*/}
                {/*                        className={videoPlaying}/>*/}
                {/*            /!*<div className={'duration-container video-player-timing'}>*!/*/}
                {/*            /!*    <Duration className={'video-player-timing'} seconds={duration * played} />*!/*/}
                {/*            /!*    /*!/*/}
                {/*            /!*    <Duration className={'video-player-timing'} seconds={duration} />*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </div>*/}
                {/*        <input type={'range'}*/}
                {/*               min={0} max={0.999999} step='any'*/}
                {/*               id={'video-player-range'}*/}
                {/*               className={videoPlayerRange}*/}
                {/*               value={played}*/}
                {/*               onMouseDown={this.handleSeekMouseDown}*/}
                {/*               onChange={this.handleSeekChange}*/}
                {/*               onMouseUp={this.handleSeekMouseUp}*/}
                {/*        />*/}
                {/*        {this.props.openFullScreen &&*/}
                {/*        <SVGraphics svgname={'fullscreen'} onClick={this.handleClickFullscreen}*/}
                {/*                    className={fullscreenClass}/>}*/}

                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default VideoPlayerSnap
