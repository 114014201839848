//SkinExam
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../../../utils/useMediaQuery";
import VideoPlayerOtoscopeNew from "../../../../component/media/VideoPlayerOtoscopeNew";
import SVGraphics from "../../../../assets/SVGraphics";
import moment from "moment";
import utils from "../../../../utils/utils";
import NewVideoModalWithFilter from "../NewVideoModalWithFilter";
import SnapShootExam from "./SnapShootExam";
import MakeErrorConfirm from "../../../../component/Error/ErrorModalWithCancel";
import sessionsApi from "../../../../services/ApiServices/doctorApi/sessionsApi";

export default function EarsExamNew(props) {
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');
    const mobileTablet = isSmallScreen || isMobilScreen

    const [LeftFiles, setIsLeftFiles] = useState([]);
    const [RightFiles, setIsRightFiles] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [enlargeImage, setEnlargeImage] = useState(null);
    const [leftVideo, setLeftVideo] = useState(null);
    const [rightVideo, setRightVideo] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState({});
    const [allFiles, setAllFiles] = useState({});
    const [snaps, setSnaps] = useState({});
    const [earSide, setEarSide] = useState(null);
    const [url, setUrl] = useState(null);
    const [deviceModel, setDeviceModel] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [type, setType] = useState(props.examName)
    const Ears = ['Left', 'Right']


    useEffect(async () => {
        // sessionStorage.setItem('userLoggedIn','true');

            setIsUserLoggedIn(props.isUserLoggedIn)

        if(props.exam.SessionTechInfo){
            setDeviceModel(props.exam.SessionTechInfo.ng_device_model)
        }
        // console.log("props.exam",props.exam)

        // let sessionRes = await doctorApi.getSessionById(sid, tenant, accessToken,asrAuth);
        // if(sessionRes){
            let files = props.files

        let RightCaptures
        let LeftCaptures
        if(props.examName==='Ears'){
            setLeftVideo(props.files.find(x=>(x.filter === "Filter1" ||  x.filter === "None") && x.subType === 'Left'))
            setRightVideo(props.files.find(x=>(x.filter === "Filter1" ||  x.filter === "None") && x.subType === 'Right'))
             RightCaptures = files.filter(x => x.type === "RightEarCapture" )
             LeftCaptures = files.filter(x => x.type === "LeftEarCapture")
            setType('Ears')

        }else{
            setLeftVideo(props.files.find(x=>(x.filter === "Filter1" ||  x.filter === "None") && x.type === 'Mouth'))
            // if(deviceModel){
                setRightVideo(props.files.find(x=>(x.filter === "Filter1" ||  x.filter === "None") && x.type === 'Throat'))
                RightCaptures = files.filter(x => x.type === "ThroatCapture" )
            // }
             LeftCaptures = files.filter(x => x.type === "MouthCapture")
            setType('Mouth')
        }
        setIsLeftFiles(LeftCaptures)
        setIsRightFiles(RightCaptures)
    }, [props.exam,props.isUserLoggedIn])

    const getFileTimestamp = (file) => {
        let time = moment(file.createdAt).format('HH:mm');
        let date = utils.getDateStringWithYear(new Date(file.createdAt));
        return `${time} | ${date}`
    }
    const openFullScreen = (video,ear,title,snaps) =>{
        setSnaps(snaps)
        //otoscopeVideo
        let vid = document.getElementById(`videoPlayer+${title}`);
        vid?.pause();

        let files
        if(type==='Ears'){
            files = props.files.filter(x=>(x.subType === ear))
        }else{
            files = props.files.filter(x=>(x.type === ear))
        }

        setEarSide(ear)
        setSelectedVideo(video)
        setUrl(video.downloadUrl)
        setAllFiles(files)
        setOpenModal(true)
    }
    const closeModal = () => {
        setSelectedVideo(null)
        setOpenModal(false)
    }
    const deleteSnapShot =   async (fileID)=>{
        let check = await MakeErrorConfirm({title:"Delete Image", message:"Are you sure you want to remove this snapshot?"})
        if(check){
            let deleteSnap = await sessionsApi.deleteScreenshot(fileID)
            if(deleteSnap){
                // get the updated exam
                props.addScreenshot()
            }
        }
    }
return (
    // <div className={isSmallScreen || mediumScreen  ?'exam-heart-container-tablet':isMobilScreen?'exam-heart-container-mobile':'exam-heart-container'}>
    <div className={ mediumScreen?'exam-ears-container-medium': isSmallScreen ?'exam-heart-container-tablet':isMobilScreen?'exam-heart-container-mobile':'exam-heart-container'}>
        {Ears.map((ear,index)=>{
            let snaps = ear === 'Left'?LeftFiles:RightFiles
            let video = ear === 'Left'?leftVideo:rightVideo
            return <div className={'ears-exam-video-image-container'} style={{width:mobileTablet || !deviceModel || deviceModel==='N9+'?'100%':'45%',paddingTop:index===0 && isMobilScreen?'1.6rem':'0.5rem'}}>
                <div className={'ears-exam-video-container'}>
                    <div style={{color:!deviceModel &&type!=='Ears'&& ear === 'Right'?'transparent':'' }} className={'ears-exam-subTitle'}>{`${props.examName==='Ears'?'Ear ' +ear:ear === 'Left'?!deviceModel?'Mouth & Throat':'Mouth':deviceModel?'Throat':'---'}`}</div>
                    { video ?
                        <VideoPlayerOtoscopeNew fileId={video?.fileID}
                                                file={video}
                                                title={type+ear}
                            openFullScreen={() => openFullScreen(video,`${props.examName==='Ears'?'Ear ' +ear:ear === 'Left'?!deviceModel || deviceModel==='N9+' ?'Mouth':'Mouth':deviceModel!=='N9+'?'Throat':''}`,type+ear,snaps)}
                                                url={video?.downloadUrl}
                                                fullscreenClass={`video-player-screen-control video-fullscreen-throat ${isMobilScreen?'ears-exam-fullScreen-button-mobile':'ears-exam-fullScreen-button'}` }/>
                        :
                        (!deviceModel || deviceModel==='N9+') && type ==='Ears' ?
                        <div className={'ears-exam-noVideo'}>No exams</div>:
                            null
                    }
                </div>
                <div className={'ear-exam-capture-container'}>
                    {snaps && snaps.map(file => {
                        return <div className={'ear-exam-capture'}>
                            <div className={'exam_title_throat'}> {`${props.examName==='Ears'?"Ear " +ear:props.examName} Snapshot`}</div>
                            <div className={'ear-exam-snapShotImage'}>
                                <img src={file['downloadUrl']} alt={'capture'} className={'ear-exam-snapShotImage'}
                                     onClick={() => this.openImage(file)}/>
                                {isUserLoggedIn &&
                                <SVGraphics svgname={'circle-trash'} className={'modal-close-btn image-trash-btn-snap'}
                                            onClick={() =>deleteSnapShot(file.fileID, file.type)}
                                />
                                }
                                <SVGraphics className={'exam-ear-image-enlarge'} svgname={'enlarge'} style={{left:isUserLoggedIn?'51%':'75%'}} onClick={()=>{
                                    setEnlargeImage(file)
                                    setEarSide(ear)}}/>

                            </div>

                            <div className={'exam_footer_throat'}>
                                { `${moment(file.createdAt).format('HH:mm')}, ${utils.getDateStringWithYear(new Date(file.createdAt))}`}
                                                      </div>
                        </div>

                    })
                    }
                </div>
                {isMobilScreen && index === 0 && <div className={'ears-exam-path'}></div>}
            </div>

        })}
        {
            openModal &&
            <NewVideoModalWithFilter
                file={selectedVideo}
                files={allFiles}
                show={openModal}
                exam={props.exam}
                url={url}
                addScreenshot={props.addScreenshot}
                type={type}
                title={`${earSide} video`}
                proceed={()=>closeModal()}
                propsCaptures={snaps}
            />
        }
        {enlargeImage &&
        <SnapShootExam file={enlargeImage} title={props.examName==='Ears'?"Ear " +earSide:props.examName} closeWindow={()=>setEnlargeImage(null)} show={enlargeImage}/>
        }


    </div>
)
}