import React, {Component} from "react";
import '../../style/component/input/RangeInputVideo.css'

class RangeInputVideo extends Component {

    constructor(props) {
        super(props);
        this.inputRef = null
    }


    render() {

        return (
            <div className={'custom-video-range'}>
            <input type={'range'} className={'amount-progress'} defaultValue={0} step={"any"} min={0} max={100} />
            </div>
        )

    }
}


export default RangeInputVideo
