import React, {Component} from "react";

class FA_LungsBack extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1
        let isC_Enabled = (files.findIndex(file => file['dotName'] === 'C')) > -1
        let isD_Enabled = (files.findIndex(file => file['dotName'] === 'D')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'
        let isC_Active = activeDot === 'C'
        let isD_Active = activeDot === 'D'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#03b3fe'

        let A_color = grey
        let B_color = grey
        let C_color = grey
        let D_color = grey
        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }
        if (isC_Enabled) {
            if (isC_Active) {
                C_color = active
            } else {
                C_color = sleep
            }
        }
        if (isD_Enabled) {
            if (isD_Active) {
                D_color = active
            } else {
                D_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){
        return (
            <svg viewBox={"0 0 340 418"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF"
                          d="M316.446 429.829l-2.571 4.62-5.647 4.617-9.671-3.263-20.7-35.866-21.214-52.013-3.388-18.917-20.866-50.456-8.088-21.851-6.696 20.52-1.746 18.446-4.723 36.994 12.034 25.875.062.144 16.202 46.535 1.296 26.424 1.027 9.234.04 1.477.308 9.685-.575 13.747-1.15 26.879-14.868 77.784-8.519 35.555-4.538 30.366 1.131 55.379-3.471 31.885-8.871 44.299-3.868 39.176 2.694 9.135-1.375 9.747 12.856 11.468 1.704 7.182-15.403 11.449-10.781 4.864-11.561-1.273-6.059-5.478 1.705-21.441.352-6.341-1.889-11.366 2.691-13.647-2.485-39.457-2.526-44.606 1.107-39.527 3.779-28.767-.821-28.212-.391-36.401.041-43.109-2.49-39.942.144-33.874.123-.527-.077-5.335-1.027-2.757-1.54.128-2.439 2.372-.176 5.323.206.801.144 33.869-2.505 39.97.061 43.109-.411 36.401-.821 28.212 3.799 28.767 1.11 39.527-2.548 44.606-2.484 39.458 2.853 14.159-1.273 9.745-.527 6.149 2.834 23.084-6.407 4.883-12.857.246-14.251-3.877-11.077-10.999 1.171-7.672 16.598-10.702-1.54-8.392 1.98-10.054-6.421-40.054-8.867-44.317-3.471-31.885 1.109-55.379-4.517-30.366-8.545-35.56-14.868-77.785-1.212-28.93-.492-11.695.352-9.829.061-1.436-.37-8.885 2.63-26.674 16.202-46.535.062-.144 12.033-25.875-4.726-36.989-2.648-17.666-6.154-20.765-7.722 21.318-20.88 50.451-3.388 18.917-21.205 52.015-20.7 35.866-11.583 5.99-8.214-9.911 6.201-14.915-.061-.185 5.85-20.101 5.38-18.855 6.961-26.099 7.455-23.514 9.919-14.239 4.332-20.477 9.263-46.577 6.506-31.249 12.054-19.882 18.571-8.483 30.929-9.42 10.328-9.111.719-15.593.679-15.143 15.505-10.095 1.561.352 1.54-.352 15.526 10.095.719 15.616.677 15.12 10.33 9.111 30.905 9.418 18.586 8.473 12.054 19.881 6.506 31.25 9.242 46.577 4.353 20.477 9.921 14.239 7.455 23.514 6.961 26.099 5.38 18.855 5.854 20.108 4.232 13.055z"/>
                    <path fill="#E1C7B8"
                          d="M222.145 218.837v18.797l5.739 20.098-4.877 21.288-7.146 16.64-.287 2.256-17.467-.943-29.776-2.565-29.265 2.819-22.005 2.39-.514-3.96-6.231-14.845-5.648-24.365 3.165-27.024-3.08-17.768-5.483-15.059-4.374-4.002 11.581-5.293 3.942-1.191 3.902 8.104 5.692 21.545 13.154 65.397 35.246 5.622 36.089-5.622 6.316-68.31 1.913-18.973 7.445-8.721 5.718 2.156 9.222 4.207-7.148 8.166-5.823 19.156z"/>
                    <path fill="#82543A"
                          d="M143.184 54.6l-5.905-8.72 2.567-16.671 11.809-10.003 16.941-2.565 20.024 6.675 5.391 20.774-7.188 12.568-43.639-2.059z"/>
                    <path fill="#FBDFCF"
                          d="M132.402 101.094l-5.648-1.098-2.054 2.635 3.851 9.663 3.851 6.814 3.593 6.37 5.135.22-8.728-24.604zM200.428 101.094l5.648-1.098 2.054 2.635-3.851 9.662-3.851 6.81-3.594 6.37-5.134.219 8.728-24.598z"/>
                    <path fill="#82543A"
                          d="M199.316 57.082l-13.972-9.406-16.614-1.309.014-.402-2.547.2-2.548-.2.014.402-16.614 1.309-13.97 9.406-7.888 16.584 2.638 22.398 2.637 6.676 3.869 14.713 7.184 18.553 13.011 15.46 12.484 4.789 12.162-5.815 10.564-14.075 7.636-14.968 4.572-18.664 2.637-6.676 2.638-22.398-7.907-16.577z"/>
                    <path fill="#E1C7B8"
                          d="M89.34 452.1l11.56-6.189 18.585-5.27 17.015.739 14.794 4.619 10.198 5.492c1.316 1.869 4.143 7.278 4.349 7.278.677.595 1.385.852 2.31-.255l4.505-7.617 14.609-8.131 22.285-2.218 20.25 3.689 9.711 6.198 3.693 2.772-1.208-53.332-9.671-18.926-5.29 4.712-29.411 7.695-55.589.256-39.751-7.695-2.321-2.608-9.58 19.974-1.042 48.817z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M169.5 166L169.5 390M169.5 166L169.5 390"/>
                    </g>
                    <path fill="#2E2E2E"
                          d="M220.624 168c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.093.028-.168.084-.224.056-.065.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.181.854 2.114 0 .737-.182 1.335-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .075-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.065-.135-.163-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.163 1.806-.49.401-.336.602-.835.602-1.498s-.201-1.157-.602-1.484c-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618zM99.624 168c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.093.028-.168.084-.224.056-.056.13-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.131.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404z"/>
                    <path stroke="#fff" fill={'none'}
                          d="M150.826 217.412c-1.804 10.593-5.526 23.694-9.449 34.143-1.961 5.226-3.967 9.771-5.799 13.002-.918 1.618-1.781 2.886-2.561 3.743-.805.885-1.424 1.222-1.848 1.222-.21 0-.491-.077-.849-.278-.354-.199-.757-.504-1.203-.918-.892-.829-1.912-2.054-3.017-3.619-2.207-3.126-4.708-7.544-7.152-12.681-4.888-10.275-9.513-23.356-11.091-34.599-.338-2.407.703-4.977 2.696-7.569 1.987-2.584 4.869-5.123 8.07-7.441 6.402-4.637 13.982-8.324 17.934-9.69 3.911-1.352 8.472-1.308 11.547 1.986 3.101 3.322 4.866 10.115 2.722 22.699zM186.983 217.412c1.805 10.593 5.527 23.694 9.449 34.143 1.962 5.226 3.967 9.771 5.8 13.002.917 1.618 1.78 2.886 2.561 3.743.805.885 1.424 1.222 1.847 1.222.211 0 .491-.077.849-.278.355-.199.758-.504 1.204-.918.892-.829 1.912-2.054 3.016-3.619 2.207-3.126 4.709-7.544 7.152-12.681 4.889-10.275 9.514-23.356 11.092-34.599.338-2.407-.703-4.977-2.697-7.569-1.987-2.584-4.869-5.123-8.07-7.441-6.402-4.637-13.981-8.324-17.934-9.69-3.911-1.352-8.471-1.308-11.547 1.986-3.101 3.322-4.866 10.115-2.722 22.699z"/>
                    <path fill={A_color}
                          d="M132 233c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M139.409 238c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM144 243.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM144 222.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={B_color}
                          d="M182 233c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M190.401 238c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM194 243.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM194 222.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={C_color}
                          d="M132 288c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M144.083 293.14c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828-.009-.252-.014-.663-.014-1.232 0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196-.056.047-.121.07-.196.07h-1.89c-.121 0-.215-.023-.28-.07-.065-.056-.121-.154-.168-.294-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084.065.056.093.126.084.21-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zM144 298.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM144 277.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={D_color}
                          d="M182 288c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M190.319 293c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zM194 298.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM194 277.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H300V380.392H0z" transform="translate(17 13)"/>
                    </clipPath>
                    <filter id="filter0_d" width="340" height="420.392" x="0" y="-3" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );}
        else {
            return (
                <svg viewBox={"0 0 340 418"}
                     width={width}
                     height={height}
                     style={style}
                     xmlns={xmlns}
                     name={name}
                     className={className}>
                    <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path d="m302.446 413.828-2.571 4.62-5.647 4.617-9.671-3.263-20.7-35.866-21.214-52.013-3.388-18.917-20.866-50.456-8.088-21.851-6.696 20.52-1.746 18.446-4.723 36.994 12.034 25.875.062.144 16.202 46.535 1.296 26.424 1.027 9.234.04 1.477.308 9.685-.575 13.747-1.15 26.879-14.868 77.784-8.519 35.555-4.538 30.366 1.131 55.379-3.471 31.885-8.871 44.299-3.868 39.176 2.694 9.135-1.375 9.747 12.856 11.468 1.704 7.182-15.403 11.449-10.781 4.864-11.561-1.273-6.059-5.478 1.705-21.441.352-6.341-1.889-11.366 2.691-13.647-2.485-39.457-2.526-44.606 1.107-39.527 3.779-28.767-.821-28.212-.391-36.401.041-43.109-2.49-39.942.144-33.874.123-.527-.077-5.335-1.027-2.757-1.54.128-2.439 2.372-.176 5.323.206.801.144 33.869-2.505 39.97.061 43.109-.411 36.401-.821 28.212 3.799 28.767 1.11 39.527-2.548 44.606-2.484 39.458 2.853 14.159-1.273 9.745-.527 6.149 2.834 23.084-6.407 4.883-12.857.246-14.251-3.877-11.077-10.999 1.171-7.672 16.598-10.702-1.54-8.392 1.98-10.054-6.421-40.054-8.867-44.317-3.471-31.885 1.109-55.379L101.42 590l-8.545-35.56-14.868-77.785-1.212-28.93-.492-11.695.352-9.829.06-1.436-.37-8.885 2.63-26.674 16.203-46.535.062-.144 12.033-25.875-4.726-36.989-2.648-17.666-6.154-20.765-7.722 21.318-20.88 50.451-3.388 18.917-21.205 52.015-20.7 35.866-11.583 5.99-8.214-9.911 6.2-14.915-.06-.185 5.85-20.101 5.38-18.855 6.96-26.099 7.456-23.514 9.919-14.239 4.332-20.477 9.263-46.577 6.506-31.249 12.054-19.882 18.57-8.483 30.93-9.42 10.328-9.111.719-15.593.679-15.143 15.505-10.095 1.561.352 1.54-.352 15.526 10.095.719 15.616.677 15.12 10.33 9.111 30.905 9.418 18.586 8.473 12.054 19.881 6.506 31.25 9.242 46.577 4.353 20.477 9.921 14.239 7.455 23.514 6.961 26.099 5.38 18.855 5.854 20.108 4.232 13.055z" fill="#FBDFCF"/>
                    <path d="M208.145 202.836v18.797l5.739 20.098-4.877 21.288-7.146 16.64-.287 2.256-17.467-.943-29.776-2.565-29.265 2.819-22.005 2.39-.514-3.96-6.23-14.845-5.648-24.365 3.165-27.024-3.08-17.768-5.484-15.059-4.373-4.002 11.58-5.293 3.942-1.191 3.902 8.104 5.692 21.545 13.154 65.397 35.246 5.622 36.089-5.622 6.316-68.31 1.913-18.973 7.445-8.721 5.718 2.156 9.222 4.207-7.148 8.166-5.823 19.156z" fill="#E1C7B8"/>
                    <path d="m129.184 38.6-5.905-8.72 2.567-16.671 11.809-10.003L154.596.64l20.024 6.675 5.391 20.774-7.188 12.568-43.639-2.06v.002z" fill="#82543A"/>
                    <path d="m118.401 85.094-5.648-1.098-2.054 2.635 3.851 9.663 3.851 6.814 3.593 6.37 5.135.22-8.728-24.604zm68.026 0 5.648-1.098 2.054 2.635-3.851 9.662-3.851 6.81-3.594 6.37-5.134.219 8.728-24.598z" fill="#FBDFCF"/>
                    <path d="m185.316 41.082-13.972-9.406-16.614-1.31.014-.401-2.547.2-2.548-.2.014.402-16.614 1.309-13.97 9.406-7.888 16.584 2.638 22.398 2.637 6.676 3.869 14.713 7.184 18.553 13.011 15.46 12.484 4.789 12.162-5.815 10.564-14.075 7.636-14.968 4.572-18.664 2.637-6.676 2.638-22.398-7.907-16.577z" fill="#82543A"/>
                    <path d="m75.34 436.099 11.56-6.189 18.585-5.27 17.015.739 14.794 4.619 10.198 5.492c1.316 1.869 4.143 7.278 4.349 7.278.677.595 1.385.852 2.31-.255l4.505-7.617 14.609-8.131 22.285-2.218 20.25 3.689 9.711 6.198 3.693 2.772-1.208-53.332-9.671-18.926-5.29 4.712-29.411 7.695-55.589.256-39.751-7.695-2.321-2.608-9.58 19.974-1.043 48.817z" fill="#E1C7B8"/>
                    <g opacity=".5">
                        <path d="M155.5 150v224-224zm0 0v224z" fill="#000"/>
                        <path d="M155.5 150v224m0-224v224-224z" stroke="#fff"/>
                    </g>
                    <path fill="#2E2E2E" d="M85.624 152a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.303.303 0 0 1-.224.084h-.266zM206.624 152a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.369 1.246.378 2.058a47.205 47.205 0 0 1 0 2.226c-.018.859-.144 1.568-.378 2.128-.233.56-.625.98-1.176 1.26-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.439-.205.752-.527.938-.966.196-.448.299-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z"/>
                    <path fill={'none'} d="M136.825 201.412c-1.804 10.593-5.526 23.694-9.449 34.143-1.961 5.226-3.967 9.771-5.799 13.002-.918 1.618-1.781 2.886-2.561 3.743-.805.885-1.424 1.222-1.848 1.222-.21 0-.491-.077-.849-.278-.354-.199-.757-.504-1.203-.918-.892-.829-1.912-2.054-3.017-3.619-2.207-3.126-4.708-7.544-7.152-12.681-4.888-10.275-9.513-23.356-11.091-34.599-.338-2.407.703-4.977 2.696-7.569 1.987-2.584 4.869-5.123 8.07-7.441 6.402-4.637 13.982-8.324 17.934-9.69 3.911-1.352 8.472-1.308 11.547 1.986 3.101 3.322 4.866 10.115 2.722 22.699zm36.157 0c1.805 10.593 5.527 23.694 9.449 34.143 1.962 5.226 3.967 9.771 5.8 13.002.917 1.618 1.78 2.886 2.561 3.743.805.885 1.424 1.222 1.847 1.222.211 0 .491-.077.849-.278.355-.199.758-.504 1.204-.918.892-.829 1.912-2.054 3.016-3.619 2.207-3.126 4.709-7.544 7.152-12.681 4.889-10.275 9.514-23.356 11.092-34.599.338-2.407-.703-4.977-2.697-7.569-1.987-2.584-4.869-5.123-8.07-7.441-6.402-4.637-13.981-8.324-17.934-9.69-3.911-1.352-8.471-1.308-11.547 1.986-3.101 3.322-4.866 10.115-2.722 22.699z" stroke="#fff"/>
                    <path fill={A_color} d="M118 217c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M125.409 222a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zM130 227.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM130 206.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={B_color} d="M168 217c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M176.401 222a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM180 227.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM180 206.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={C_color} d="M118 272c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M130.083 277.14c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828a38.663 38.663 0 0 1-.014-1.232c0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196a.295.295 0 0 1-.196.07h-1.89a.475.475 0 0 1-.28-.07.665.665 0 0 1-.168-.294c-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084a.23.23 0 0 1 .084.21c-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zM130 282.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM130 261.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={D_color} d="M168 272c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff" d="M176.319 277a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zM180 282.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM180 261.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0H300V380.392H0z" transform="translate(0 0)"/>
                        </clipPath>
                        <filter id="filter0_d" width="340" height="420.392" x="0" y="-3" color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="10"/>
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                    </defs>

                    </svg>

            )

        }
    }

}

export default FA_LungsBack
