import React, {Component} from "react";
import "./ToolTip.css"

class ToolTip extends Component {

    constructor(props) {
        super(props);
    }
    render(){
        let {text, placement, tooltipText,style} = this.props
        return(
            <div className={`tooltip-custom tooltip-custom-${placement}`} >{text}
                <span style={style} className="tooltiptext">{tooltipText}</span>
            </div>
        )
    }
}

export default ToolTip