import React from 'react';
import MetaTags from 'react-meta-tags';
import PatientsApi from "../../../services/ApiServices/doctorApi/patientsApi";
class PatientCardRedirect extends React.Component {

    constructor() {
        super();
    }

    async componentDidMount() {
        const ttuid = this.props.match.params.ttuid
        const ttuidData = await PatientsApi.getPatientCardIframePathDetailsFromTTUID(ttuid)
        if (!!ttuidData) {
            if (ttuidData.status === 'success') {
                const params = new URLSearchParams({
                    tenant: ttuidData.tenant,
                    pid: ttuidData.pid,
                    sid:ttuidData.sid,
                    accessToken: ttuidData.accessToken,
                    asrAuth: ttuidData.asrAuth,
                    isSharedByPatient:true,
                });
                this.props.history.push({
                    tenant: ttuidData.tenant,

                    pathname: '/physician/patient/card',
                    search: params.toString()
                })
                // this.props.history.push({
                //     tenant: ttuidData.tenant,
                //     pathname: '/PatientNew',
                //     search: params.toString()
                // })

            } else if (ttuidData.status === 'expired') {
                this.props.history.push({
                    tenant: ttuidData.tenant,
                    pathname: `/rdct/expired/pcrd/${ttuid}`,
                })
            }
        }else this.props.history.push({
            tenant: ttuidData.tenant,
            pathname: `/rdct/404/pcrd`,
        })
    }

    render() {
        return (
                <div/>
        )
    }
}


export default PatientCardRedirect
