import Config from "../../config/Config";
import React from "react";
import PatientsCard from "../../views/physician/patient/PatientsCard";
import {PatientNew} from "../../views/physician/patient_new/Patient";


const PhysicianRoutes = {
    PatientComponent: ({props}) => <PatientNew {...props}/>,
}
const PhysicianPatientComponents = {
    PatientComponent: {
        component: 'PatientComponent',
        url: '/physician/patient/card',
        access: Config.roles.Physician,
        signal: true,
        isAllowPermissionsControl: true
    }
};
const PhysicianRoutesConfig = {
    routes: [
        ...Object.values(PhysicianPatientComponents)
    ]
};


export {PhysicianRoutesConfig, PhysicianRoutes};
