import React, {Component} from 'react'
import ReactPlayer from "react-player";
import SVGraphics from "../../assets/SVGraphics";
import '../../style/component/media/VideoPlayer.css'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class AudioPlayerComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: 'audio Player',
            url: null,
            currentUrl: null,
            muted: false,
            playing: false,
            isFullscreen: false,
            played: 0,
            loaded: 0,
            duration: 0,
            filters: {
                Diaphragm: null,
                Bell: null,
                Extended: null,
                Midband: null
            }
        }
        this.popupRef = null
        this.player = null
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0
        })
    }

    handlePlayPause = () => {
        this.props.play(!this.props.playing)
        if(!this.props.playing &&(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1))
        {
            document.getElementById(`player+${this.props.title}`).play()
        }
        else if((navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)){
            document.getElementById(`player+${this.props.title}`).pause()
        }
    }

    // handlePlay = () => {
    //     this.props.play(true)
    // }
    //
    // handlePause = () => {
    //     this.props.play(false)
    // }

    ref = player => {
        this.player = player
    }

    handleSeekMouseDown = e => {
        this.setState({seeking: true})
    }

    handleSeekChange = e => {
        if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {
            this.setState({played: parseFloat(e.target.value)})
        }
        else {



            let currentSeconds =  Math.floor(document.getElementById(`player+${this.props.title}`).currentTime % 60);
            let played = 0+"."+currentSeconds ;


            this.setState({played})
        }

    }

    handleSeekMouseUp = e => {
        this.setState({seeking: false})
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        if (!this.state.seeking) {
            this.setState(state)
        }
        if (this.state.played >= 0.99999) {
            this.setState({played: 0})
            this.player.seekTo(0)
            this.props.play(false)
        }
    }

    handleDuration = (duration) => {
        this.setState({duration})
    }

    async componentDidMount() {
        let {title, files} = this.props
        // let BellIndex = files.findIndex(file => file['dotName'] === title && file['filter'] === 'Filter1')
        // let DiaphragmIndex = files.findIndex(file => file['dotName'] === title && file['filter'] === 'Filter2')
        // let ExtendedIndex = files.findIndex(file => file['dotName'] === title && file['filter'] === 'Filter3')
        // let MidbandIndex = files.findIndex(file => file['dotName'] === title && file['filter'] === 'Filter4')

        let filters = {
            Diaphragm: files.find(file => file['dotName'] === title && file['filter'] === 'Filter1'),
            Bell: files.find(file => file['dotName'] === title && file['filter'] === 'Filter2'),
            Extended: files.find(file => file['dotName'] === title && file['filter'] === 'Filter3'),
            Midband: files.find(file => file['dotName'] === title && file['filter'] === 'Filter4')
        }

        let url
        let currentUrl;
        let entries = Object.entries(filters)
        for (const i in entries) {
            let [filter,filterUrl] = entries[i]
            if (!!filterUrl) {
                url = filterUrl['downloadUrl'];
                currentUrl = url
                break;
            }
        }
        this.setState({title, url, currentUrl, filters})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {title, files} = this.props
        let {url, currentUrl} = this.state
        // let DiaphragmIndex = files.find(file => file['dotName'] === title && file['filter'] === 'Filter2')
        // let BellIndex = files.find(file => file['dotName'] === title && file['filter'] === 'Filter1')
        // let ExtendedIndex = files.find(file => file['dotName'] === title && file['filter'] === 'Filter3')
        // let MidbandIndex = files.find(file => file['dotName'] === title && file['filter'] === 'Filter4')

        let filters = {
            Diaphragm: files.find(file => file['dotName'] === title && file['filter'] === 'Filter1'),
            Bell: files.find(file => file['dotName'] === title && file['filter'] === 'Filter2'),
            Extended: files.find(file => file['dotName'] === title && file['filter'] === 'Filter3'),
            Midband: files.find(file => file['dotName'] === title && file['filter'] === 'Filter4')
        }

        if (!url){
            let entries = Object.entries(filters)
            for (const i in entries) {
                let [filter,filterUrl] = entries[i]
                if (!!filterUrl) {
                    if (currentUrl === null)
                        currentUrl = filterUrl['downloadUrl']
                    url = filterUrl['downloadUrl'];
                    break;
                }
            }
        }
        this.setState({url, currentUrl, filters})
    }

    showFilter = async () => {

    }
    onEnded = async () => {
        this.props.play(!this.props.playing)
        this.setState({played: 0})
    }


    render() {
        const {title, url, currentUrl, muted, isFullscreen, played, filters} = this.state
        let {className, isRemote,t} = this.props
        let contClass = 'audio-player-cont ap-cont-' + className
        let labelClass = 'audio-player-label ap-lbl-' + className
        let audioPlayerControlClass = 'audio-player-control  h-centered ap-contContainer-' + className
        let audioPlayerControllersClass = 'audio-player-controllers apc-cont-' + className
        let audioPlayerPlayControllersClass = 'audio-player-play-controllers app-cont-' + className
        let audioPlaying = 'audio-player-playing-control ap-' + className
        let audioPlayerRange = 'audio-player-range custom-range ap-range-' + className
        let moreClass = 'audio-player-screen-control audio-more ap-fsc-' + className
        let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
        return (
            <div className={contClass}>
                {!isRemote && <label className={labelClass}> {t('physician.patient.exams.point')}</label>}
                {!isRemote && <label className={labelClass + ' audio-dotName-label'}>{title}</label>}
                <div>

                    {isSafari ?
                        <audio id={`player+${title}`} onTimeUpdate={(e)=>this.handleSeekChange(e)} onEnded={()=>this.onEnded()}>
                            <source id={`playersource+${title}`} src={url} type="audio/wav"/>
                        </audio>
                        :
                    //     <audio controls>
                    //     <source src={url} type={"audio/wav"}/>
                    // </audio>


                    <ReactPlayer
                        ref={this.ref}
                        // className={playerClass}
                        width='100%'
                        height='100%'
                        url={url}
                        playing={this.props.playing}
                        muted={muted}
                        controls={isFullscreen}
                        onPlay={this.handlePlay}
                        config={{
                            file: {
                                forceAudio: true
                            }
                        }}
                        onPause={this.handlePause}
                        onReady={() => console.log('onReady')}
                        onStart={() => console.log('onStart')}
                        onBuffer={() => console.log('onBuffer')}
                        onSeek={e => console.log('onSeek', e)}
                        onError={e => console.log('onError', e)}
                        onProgress={this.handleProgress}
                        onDuration={this.handleDuration}
                    />}
                </div>
                <div className={audioPlayerControlClass}>
                    <div className={audioPlayerControllersClass}>
                        <SVGraphics svgname={this.props.playing ? 'pause' : 'play'} onClick={this.handlePlayPause}
                                    className={audioPlaying}/>

                        <input type={'range'}
                               min={0} max={0.999999} step='any'
                               id={'audio-player-range'}
                               className={audioPlayerRange}
                               value={played}
                               onMouseDown={this.handleSeekMouseDown}
                               onChange={this.handleSeekChange}
                               onMouseUp={this.handleSeekMouseUp}
                        />
                        <OverlayTrigger rootClose={true} ref={ref => this.popupRef = ref} trigger="click"
                                        placement="left" overlay={
                            <Popover id="popover-basic" className={'audio-popup'}>
                                <Popover.Content>

                                    {
                                        Object.entries(filters).map(([name, entry]) => {
                                            let className = 'audio-popup-filter'
                                            let link = !!entry ? entry['downloadUrl'] : null
                                            if (link === url) className = className + ' audio-popup-filter-active'
                                            if (!!link) return <div className={className}><div onClick={() => {
                                                this.setState({currentUrl: url, url: link})
                                                if(isSafari) {
                                                    let audio = document.getElementById(`player+${title}`)
                                                    let source = document.getElementById(`playersource+${title}`)
                                                    source.src = link
                                                    audio.load()
                                                    this.handlePlayPause()
                                                }
                                                document.body.click()
                                            }} className={'audio-popup-filter-name'}>{name}</div><a href={link}  onClick={() => {
                                                document.body.click()
                                            }}>{t('physician.patient.exams.download')}</a></div>
                                        })
                                    }
                                </Popover.Content>
                            </Popover>
                        }>
                            <SVGraphics svgname={'3dotsPlayer'} onClick={this.showFilter} className={moreClass}/>
                        </OverlayTrigger>


                    </div>
                </div>
            </div>
        );
    }
}
const AudioPlayer = compose(
    withTranslation(),

)(AudioPlayerComposed);

export default AudioPlayer


