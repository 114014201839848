import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../../style/View/NotFound.css'
import PatientsApi from "../../services/ApiServices/doctorApi/patientsApi";
import logo from "../../assets/nonagon-icon.svg"
import fluentLogo from "../../assets/Fluent_logo.png"

class NotFound extends Component {

    async askLinkRenew() {
        const ttuid = this.props.match.params.ttuid
        await PatientsApi.renewPatientCardIframePathFromTTUID(ttuid)

    }

    render() {

        let {title} = this.props;
        const tenant = this.props.match.params.tenant
        title = title ? title : 'Link Has expired';
        //fixme: fex return to homepage
        return (
            <div className={"expired-link-container"}>
                <img style={{width:'160px'}} src={tenant ==='fluentsmartexam'?fluentLogo:logo}/>
                <div className={"expired-link-title"}>Not Found</div>
                <div className={"expired-link-Message"}>The link to your patient’s exams was not found .</div>
                <div className={"expired-link-Message"} style={{textDecoration:'underline'}} onClick={e => this.askLinkRenew()}>Ask your patient for new link</div>
            </div>
        );
    }
}

NotFound.propTypes = {
    title: PropTypes.string
};

export default NotFound;
