import React, {useEffect, useState} from "react";
import '../patientStyle/patientProfile.css'
import useMediaQuery from "../../../../utils/useMediaQuery";
import {Skeleton, Stack} from "@mui/material";
import Setting_Builder from "../../../../component/forms_builder/Setting_Builder";
import SVGraphics from "../../../../assets/SVGraphics";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import medicalData from "./patientMedicalData.json"
import moment from "moment";


export function PatientProfile(props) {

    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 550px)');
    const isExtraSmall = useMediaQuery('(min-width:551px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mobileTablet = isSmallScreen || isMobilScreen
    const [entry, setEntry] = useState({});
    const [errorsOnSave, setErrorsOnSave] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isDTC, setIsDTC] = useState(false);
    const [medicalInfoQuestion, setMedicalInfoQuestion] = useState([]);
    const [medicalInfoVac, setMedicalInfoVac] = useState([]);
    const [medicalIntex, setMedicalIntex] = useState([]);
    const [medicalCondition, setMedicalCondition] = useState([]);
    const [key, setKey] = useState('patientDetails');

    useEffect(() => {
        let _patient = props.patient
        console.log("_patient",_patient)
        _patient.height = _patient['height'] ? _patient['height'] + " " + _patient["heightUnit"] : " "
        _patient.weight = _patient['weight'] ? _patient['weight'] + " "+ _patient['weightUnit'] : " "
        setEntry(_patient)
        let medicalCondition = props.age < 12 ? medicalData.medicalCondition.child : medicalData.medicalCondition.adult
        setIsLoading(false)
        setMedicalInfoQuestion(medicalData.medicalInfo.questions)
        setMedicalInfoVac(medicalData.medicalInfo.vaccinations)
        setMedicalCondition(medicalCondition)
        if(_patient && _patient.users && _patient.users.length>0){
            let dtc = _patient.users[0]?.PatientSubscription?.category==="dtc"?true:false
            setIsDTC(dtc)
        }
        setMedicalIntex(_patient.MedicalIntakes)




    }, [])
    // const validationSchema = Yup.object().shape({
    //     firstName: Yup.string().required('First Name is required'),
    //     lastName: Yup.string().required('Last Name is required'),
    //     email: Yup.string().matches(Config.emailRegExp, 'Email is not valid').required('email  is required'),
    //     phoneNumber: Yup.string().required('phone number  is required'),
    //     idNumber: Yup.string().required('ID  is required'),
    //     birthday: Yup.string().required('birthday  is required'),
    //     practiceID: Yup.string().required('practice  is required'),
    //     gender: Yup.string().required('gender  is required'),
    //     physicians:Yup.array().min(1).required('physicians  is required'),
    // });

    const patientProfileForm = () => {
        let options = [{label: "Male", value: "Male"}, {label: "Female", value: "Female"}]
        let className = `input-patients-design ${isMobilScreen && 'input-patients-design-mobile'}`
        return [
            {
                type: 'text',
                name: 'firstName',
                label: 'First Name*',
                className: className,
                disabled: true
            },
            {type: 'date', name: 'birthday', label: 'DOB*', className: className, disabled: true},
            {type: 'text', name: 'height', label: 'Height', className: className, disabled: true},
            {
                type: 'text',
                name: 'state',
                label: 'State*',
                className: className,
                disabled: true
            },
            {
                type: isDTC?'text':'phoneNumber',
                name: isDTC?'email':'phoneNumber',
                label: isDTC?'Email*':'Phone',
                className: isDTC?className:`input-patients-design-phone ${isMobilScreen && 'input-patients-design-mobile'}`,
                disabled: true
            },
            {
                type: 'text',
                name: 'lastName',
                label: 'Last Name*',
                className: className,
                disabled: true
            },
            {
                type: 'select',
                name: 'gender',
                options: options,
                // selected:this.state.entry.gender,
                // defaultValue:this.state.entry.gender,
                disabled: true,
                label: 'Sex at Birth*',
                className: className,
            },
            {type: 'text', name: 'weight', label: 'Weight', className: className, disabled: true},
            {type: 'text', name: 'zipCode', label: 'Zip Code*', className: className, disabled: true},
            {
                type: 'text',
                name: isDTC?'PreferredPharmacy':'email',
                label: isDTC?'Preferred Pharmacy':`Email*`,
                className: className,
                disabled: true
            },
        ]

    }
    //
    // const onChange = async (name, value) => {
    //
    //     utils.set(entry, name, value);
    //     let errors = await validationHelper.validate(entry, validationSchema);
    //     if (utils.get(errorsOnSave, name)) {
    //         if (utils.get(errors, name) && utils.get(errors, name).length > 0) {
    //             utils.set(errorsOnSave, name, utils.get(errors, name));
    //         } else delete errorsOnSave[name];
    //     }
    //     if (entry.practiceID){
    //         let physiciansData = await practiceApi.getPhysiciansPractice(entry.practiceID);
    //         let physicians = await this.reorderPhysicianDataByName(physiciansData);
    //         this.setState({physicians})
    //     }
    //
    //     this.setState({
    //         entry,
    //         errors,
    //         errorsOnSave,
    //         isErr: false, errMessage: ''
    //     })
    // }

    const patientDetails = () => {
        return <Setting_Builder entry={entry}
                                matrix={isMobilScreen ? [1, 10] : [5, 5]}
                                errors={errorsOnSave}
                                isErr={!!errorsOnSave}
                                fields={patientProfileForm()}
                                imageHandler={''}/>
    }
    const returnRadio = (ques) => {
        let vaccines = entry.Vaccinations
        return <div style={{display: 'flex'}}>
            {
                ques.options.map(opt => {
                    return <label className={'container-radio'}>
                        <input key={opt} type={'radio'} name={ques.name}
                               checked={ques.name !=='Allergies' && ques.name !=='Medication' &&ques.name !=='MedicalHistory'? vaccines.find(x=>x.vaccinationType===ques.name)?.status===opt.toLowerCase():medicalIntex.find(x=>x.intakeType===ques.name)?.status===opt.value} disabled/>
                        <span className={'checkmark'}></span>
                        {ques.name !=='Allergies' && ques.name !=='Medication' &&ques.name !=='MedicalHistory'?opt:opt.label}</label>
                })
            }
        </div>
    }
    const returnCheckBox = (data) => {
            return <label className={'container-radio'}>
            <input type={'checkbox'} name={data.name} checked={medicalIntex.find(x=>x.intakeType===data.name)?.status} disabled/>
            <span className={'checkmark-checkBox'}></span>
            {data.label}</label>
    }

    return (
        <div className={'past-exams-container'} style={{
            overflowY: mobileTablet ? 'auto' : 'hidden',
            height: isMobilScreen ? '60vh' : isSmallScreen ? '80vh' : '95vh',
            marginTop: mobileTablet ? '2rem' : '3rem',
            marginLeft:props.isOnLineCall&&!mobileTablet&&'3rem'
        }}
             onClick={() => props.closeHeaderMenu()}
        >
            {isLoading ?
                <div style={{width: '100%', height: '100%'}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" sx={{fontSize: '4rem'}}/>
                        <Skeleton variant="circular" width={80} height={80}/>
                        <Skeleton variant="rectangular" height={200}/>
                        <Skeleton variant="rounded" height={200}/>
                    </Stack>

                </div> :
                <div className={'past-exams-sub-container'}
                     style={{width: mobileTablet && '90%'}}>
                    <div className={'title-container'}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <SVGraphics svgname={'back-circle'} className={'back-circle-icon'}
                                        onClick={props.backToSession}/>
                            <div className={'past-exam-label'}>Patient Profile</div>
                        </div>
                        {!isDTC || mobileTablet &&

                        <div className={'lastUpdated'}
                             style={{top: '0rem'}}>{`Last update: ${moment(entry?.updatedAt).format('MMM DD,YYYY')}`}</div>
                        }
                    </div>
                    {isDTC ?
                        <div className={'dtc-patient-profile '}>
                            {!mobileTablet && <div
                                className={'lastUpdated'}>{`Last update: ${moment(entry?.updatedAt).format('MMM DD,YYYY')}`}</div>}

                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-4"
                                variant={'underline'}
                            >
                                <Tab eventKey="patientDetails" title="Patient  Details"
                                     tabClassName={key === 'patientDetails' ? 'tabClassName tabClassName-selected' : 'tabClassName'}
                                >
                                    {patientDetails()}
                                </Tab>
                                <Tab eventKey="medicalInfo" title="Medical Info"
                                     tabClassName={key === 'medicalInfo' ? 'tabClassName tabClassName-selected' : 'tabClassName'}>
                                    <div>
                                        {medicalInfoQuestion.map(ques => {
                                            return <div key={ques.name} className={'questionCon'}>
                                                <div className={'questionClass'}>{ques.question}</div>
                                                {returnRadio(ques)}
                                                {medicalIntex.find(x=>x.intakeType===ques.name)?.status===true &&
                                                    <div className={'questionNote'}>
                                                        {medicalIntex?.find(x => x.intakeType === ques.name)?.description}
                                                    </div>
                                                }
                                                <div className={'questionBorder'}></div>
                                            </div>
                                        })
                                        }
                                        <div className={'questionClass'}>Patient vaccinations up-to-date?</div>
                                        <div
                                            className={`vaccination-container ${mobileTablet && 'vaccination-container-mobile'}`}>
                                            {medicalInfoVac.map(vac => {
                                                return <div className={'vaccination-radio-container'} key={vac.name}>
                                                    <div className={'vaccination-radio-label'}>{vac.label}</div>
                                                    {returnRadio(vac)}
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="medicalCondition" title="Medical Condition"
                                     tabClassName={key === 'medicalCondition' ? 'tabClassName tabClassName-selected' : 'tabClassName'}
                                >
                                    <div>
                                        <div className={'questionCon'}>
                                            <div className={'questionClass'}>Medical conditions that apply to patient
                                            </div>
                                            {returnCheckBox({name: 'None', label: 'None'})}

                                            <div className={'questionBorder'}></div>
                                            <div className={'vaccination-container'}
                                                 style={{width: mobileTablet ? '100%' : '65%'}}>
                                                {medicalCondition.map(condition => {
                                                    return <div
                                                        className={'vaccination-radio-container condition-radio-container'}
                                                        key={condition.name}>
                                                        {/*<div className={'vaccination-radio-label'}>{condition.label}</div>*/}
                                                        {returnCheckBox(condition)}
                                                    </div>
                                                })
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </Tab>
                            </Tabs>
                        </div>
                        :
                        <div
                            className={isMobilScreen ? 'patient-profile-container-mobile' : 'patient-profile-container'}>
                            {patientDetails()}
                        </div>
                    }


                </div>
            }

        </div>
    )
}