import React, {Component} from "react";

class MA_LungsBack extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1
        let isC_Enabled = (files.findIndex(file => file['dotName'] === 'C')) > -1
        let isD_Enabled = (files.findIndex(file => file['dotName'] === 'D')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'
        let isC_Active = activeDot === 'C'
        let isD_Active = activeDot === 'D'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#03b3fe'

        let A_color = grey
        let B_color = grey
        let C_color = grey
        let D_color = grey
        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }
        if (isC_Enabled) {
            if (isC_Active) {
                C_color = active
            } else {
                C_color = sleep
            }
        }
        if (isD_Enabled) {
            if (isD_Active) {
                D_color = active
            } else {
                D_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){  return (
            <svg viewBox={"0 0 381 416"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF"
                          d="M337.929 406.624l-6.777 7.378-12.216-1.036-29.739-60.509-9.857-27.712-.638-16.027-13.574-27.094-4.76-13.802-2.634 11.956-5.566 27.712-5.546 17.257 1.848 24.614.162.997 1.678 10.093 5.567 38.182 11.791 45.39.381 13.313 1.381 47.785-6.777 41.87-3.696 30.168 1.848 25.866 4.929 23.999-1.848 52.958-6.777 35.702-10.196 52.344-.914 10.156.298 8.926 2.464 7.696 11.042 13.612 15.19 3.18v7.378l-3.248 5.089-15.721 6.762-15.742 6.021-13.871-1.399-9.73-6.783 2.315-13.378.936-20.776 1.848-14.311-.616-13.865-1.38-34.176-4.242-41.13-3.173-12.421.936-40.175 1.848-17.087-4.158-21.247-1.848-35.087-8.327-40.175-7.86-31.398-.956-13.908-1.169-17.362-.361-5.364-.404 5.532-1.299 17.15-1.062 13.95-7.86 31.398-8.327 40.175-1.843 35.089-4.164 21.242 1.848 17.087.914 40.175-2.252 11.611-5.141 41.94-1.402 34.176 1.699 12.932.615 11.534.085 26.332 1.148 11.533-13.894 6.7-16.338-2.227-9.709-2.311-19.078-6.32-3.209-5.066.674-4.615 20.883-5.301 9.206-13.173 3.378-4.94 1.551-7.696-.936-13.527-12.016-54.505-6.785-35.698-1.848-52.958 4.929-23.999 1.848-25.865-3.696-30.168-6.798-41.871 1.869-46.809.596-15.054 11.132-44.626 5.545-38.182 1.722-10.283v-.021l.128-.784 1.848-24.615-5.546-17.257-5.545-27.711-2.655-11.957-4.76 13.803-13.576 27.098-.615 16.028-9.857 27.711-28.404 59.695-13.563 1.844-6.797-7.378 5.544-24.02 8.647-54.167 6.161-27.712 10.494-16.006 4.957-34.496v-25.865l3.697-28.302 9.256-17.872 14.17-11.067 17.895-2.46 11.726 1.229 28.998-16.614.914-19.547.936-20.014 48.585.467.765 17.448.935 21.646 28.998 16.62 11.728-1.228 17.887 2.458 14.191 11.067 9.257 17.871 3.696 28.303v25.86l4.928 34.493 10.494 16.006 6.182 27.712 8.626 54.167 5.549 24.023z"/>
                    <path fill="#E1C7B8"
                          d="M270.988 455.257l-2.931 2.078-6.947 4.918-18.972 6.933-22.455.191-4.76.042-16.971-4.15-7.118-3.095-.276-.128-.487.296-5.521 3.159-16.465 4.813-.892.254-29.614-1.845-23.284-8.841-1.232-1.568-3.102-3.966 2.463-12.021 7.202-42.134 7.17-34.452 2.07-5.056 3.186-10.515v-.022l3.825 2.593 15.427 7.4 23.454 4.622h26.83l26.216-4.303 17.291-7.399 3.548-2.694 3.06 10.325 2.028 4.262 6.903 34.715 7.595 42.666 2.759 12.922z"/>
                    <path fill="#FBDFCF"
                          d="M147.411 73.69l2.555 8.386 1.148 3.748.259.832 9.754 25.17 1.813 4.674 15.085 23.937 11.734 2.512 12.623-2.679 14.178-24.247 1.461-3.75 9.367-24.196.74-1.903 1.184-3.88 2.777-9.105-.926-29.515-14.918-20.445-25.283-6.521v-.13l-.222.075-.223-.075v.13l-27.262 7.018-14.939 20.43-.905 29.534z"/>
                    <path fill="#FBDFCF"
                          d="M215.339 113.032l2.683-.757 4.165-1.201 4.571-6.816 4.794-9.734 2.739-7.779-.907-5.116-2.722.444-1.351.222-1.869.313-3.591 9.015-8.512 21.409zM146.357 86.748l2.74 7.779 4.794 9.734 4.571 6.816 2.665.757 4.183 1.201-7.533-18.935-4.572-11.49-3.239-.534-1.649-.277-1.053-.162-.907 5.11z"/>
                    <path fill="#82543A"
                          d="M232.089 73.19l-.925-29.515-14.919-20.446-25.282-6.521v-.13l-.223.075-.222-.075v.13l-27.262 7.018-14.94 20.43-.908 29.534 2.555 8.386 3.394 5.787 7.512 16.842 11.157 22.78 18.06 4.156 18.28-3.849 19.188-41.961 1.759-3.533 2.776-9.108z"/>
                    <path fill="#CFC5C0"
                          d="M120.027 267.851l-1.677 4.876-2.613-15.03-4.248-18.721-2.848-10.956 5.672 8.841 4.95 20.841.764 10.149zM260.537 267.852l1.678 4.876 2.612-15.03 4.25-18.721 2.847-10.961-5.672 8.841-4.95 20.841-.765 10.154z"/>
                    <path fill="#2E2E2E"
                          d="M250.624 152c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.093.028-.168.084-.224.056-.065.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.181.854 2.114 0 .737-.182 1.335-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .075-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.065-.135-.163-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.163 1.806-.49.401-.336.602-.835.602-1.498s-.201-1.157-.602-1.484c-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618zM121.624 152c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.093.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.131.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M190.5 113L190.5 339M190.5 113L190.5 339"/>
                    </g>
                    <path fill="none" stroke="#fff"
                          d="M168.525 203.978c-2.028 11.908-6.212 26.634-10.621 38.38-2.205 5.874-4.46 10.986-6.522 14.621-1.032 1.819-2.004 3.249-2.886 4.217-.906.996-1.618 1.393-2.122 1.393-.251 0-.577-.091-.984-.32-.405-.227-.861-.572-1.364-1.04-1.007-.936-2.156-2.317-3.399-4.077-2.483-3.517-5.296-8.485-8.043-14.259-5.495-11.551-10.695-26.258-12.47-38.901-.383-2.729.799-5.633 3.043-8.552 2.238-2.911 5.482-5.768 9.082-8.374 7.199-5.215 15.722-9.361 20.17-10.899 4.406-1.522 9.562-1.478 13.042 2.249 3.506 3.755 5.484 11.414 3.074 25.562zM211.284 203.978c2.029 11.908 6.213 26.634 10.622 38.38 2.205 5.874 4.46 10.986 6.521 14.621 1.033 1.819 2.005 3.249 2.886 4.217.907.996 1.618 1.393 2.122 1.393.251 0 .577-.091.985-.32.404-.227.861-.572 1.364-1.04 1.007-.936 2.156-2.317 3.399-4.077 2.483-3.517 5.295-8.485 8.042-14.259 5.496-11.551 10.696-26.258 12.47-38.901.383-2.729-.798-5.633-3.042-8.552-2.238-2.911-5.483-5.768-9.082-8.374-7.2-5.215-15.723-9.361-20.17-10.899-4.407-1.522-9.562-1.478-13.042 2.249-3.506 3.755-5.485 11.414-3.075 25.562z"/>
                    <path fill={A_color}
                          d="M153 210c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M160.409 215c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM165 220.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM165 199.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={B_color}
                          d="M203 210c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M211.401 215c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM215 220.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM215 199.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={C_color}
                          d="M153 265c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M165.083 270.14c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828-.009-.252-.014-.663-.014-1.232 0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196-.056.047-.121.07-.196.07h-1.89c-.121 0-.215-.023-.28-.07-.065-.056-.121-.154-.168-.294-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084.065.056.093.126.084.21-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zM165 275.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM165 254.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={D_color}
                          d="M203 265c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M211.319 270c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zM215 275.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM215 254.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H341V380H0z" transform="translate(17 12)"/>
                    </clipPath>
                    <filter id="filter0_d" width="381" height="420" x="0" y="-4" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );}
        else {
            return (
                <svg viewBox={"0 0 381 416"}
                     width={width}
                     height={height}
                     style={style}
                     xmlns={xmlns}
                     name={name}
                     className={className}>
                    <g clip-path="url(#clip0)" filter="url(#filter0_d)">                    <path d="m295.603 390.343-6.777 7.378-12.216-1.036-29.739-60.509-9.857-27.712-.638-16.027-13.574-27.094-4.76-13.802-2.634 11.956-5.566 27.712-5.546 17.257 1.848 24.614.162.997 1.678 10.093 5.567 38.182 11.791 45.39.381 13.313 1.381 47.785-6.777 41.87-3.696 30.168 1.848 25.866 4.929 23.999-1.848 52.958-6.777 35.702-10.196 52.344-.914 10.156.298 8.926 2.464 7.696 11.042 13.612 15.19 3.18v7.378l-3.248 5.089-15.721 6.762-15.742 6.021-13.871-1.399-9.73-6.783 2.315-13.378.936-20.776 1.848-14.311-.616-13.865-1.38-34.176-4.242-41.13-3.173-12.421.936-40.175 1.848-17.087-4.158-21.247-1.848-35.087-8.327-40.175-7.86-31.398-.956-13.908-1.169-17.362-.361-5.364-.404 5.532-1.299 17.15-1.062 13.95-7.86 31.398-8.327 40.175-1.843 35.089-4.164 21.242 1.848 17.087.914 40.175-2.252 11.611-5.141 41.94-1.402 34.176 1.699 12.932.615 11.534.085 26.332 1.148 11.533-13.894 6.7-16.338-2.227-9.71-2.311-19.077-6.32-3.21-5.066.675-4.615 20.883-5.301 9.206-13.173 3.378-4.94 1.55-7.696-.935-13.527L81.215 699.4l-6.785-35.698-1.848-52.958 4.929-23.999 1.848-25.865-3.696-30.168-6.798-41.871 1.869-46.809.596-15.054 11.132-44.626 5.545-38.182 1.722-10.283v-.021l.128-.784 1.848-24.615-5.546-17.257-5.545-27.711-2.655-11.957-4.76 13.803-13.576 27.098-.615 16.028-9.857 27.711-28.404 59.695-13.563 1.844-6.797-7.378 5.544-24.02 8.647-54.167 6.16-27.712 10.495-16.006 4.957-34.496v-25.865l3.697-28.302 9.256-17.872 14.17-11.067 17.895-2.46 11.726 1.229 28.998-16.614.914-19.547.936-20.014 48.585.467.765 17.448.935 21.646 28.998 16.62 11.728-1.228 17.887 2.458 14.191 11.067 9.257 17.871 3.696 28.303v25.86l4.928 34.493 10.494 16.006 6.182 27.712 8.626 54.167 5.549 24.023z" fill="#FBDFCF"/>
                    <path d="m228.662 438.976-2.931 2.078-6.947 4.918-18.972 6.933-22.455.191-4.76.042-16.971-4.15-7.118-3.095-.276-.128-.487.296-5.521 3.159-16.465 4.813-.892.254-29.614-1.845-23.284-8.841-1.232-1.568-3.102-3.966 2.463-12.021 7.202-42.134 7.17-34.452 2.07-5.056 3.186-10.515v-.022l3.825 2.593 15.427 7.4 23.454 4.622h26.83l26.216-4.303 17.291-7.399 3.548-2.694 3.06 10.325 2.028 4.262 6.903 34.715 7.595 42.666 2.759 12.922z" fill="#E1C7B8"/>
                    <path d="m105.084 57.409 2.555 8.386 1.148 3.748.259.832 9.754 25.17 1.813 4.674 15.085 23.937 11.734 2.512 12.623-2.679 14.178-24.247 1.461-3.75 9.367-24.196.74-1.903 1.184-3.88 2.777-9.105-.926-29.515-14.918-20.445L148.635.427v-.13l-.222.075-.223-.075v.13l-27.262 7.018-14.939 20.43-.905 29.534z" fill="#FBDFCF"/>
                    <path d="m173.013 96.75 2.683-.756 4.165-1.201 4.571-6.816 4.794-9.734 2.739-7.78-.907-5.115-2.722.444-1.351.222-1.869.313-3.591 9.015-8.512 21.409zm-68.982-26.283 2.74 7.779 4.794 9.734 4.571 6.816 2.665.757 4.183 1.2-7.533-18.934-4.572-11.49-3.239-.534-1.649-.277-1.053-.162-.907 5.11z" fill="#FBDFCF"/>
                    <path d="m189.763 56.909-.925-29.515-14.919-20.446L148.637.427v-.13l-.223.075-.222-.075v.13L120.93 7.445l-14.94 20.43-.908 29.534 2.555 8.386 3.394 5.787 7.512 16.842 11.157 22.78 18.06 4.156 18.28-3.849 19.188-41.961 1.759-3.533 2.776-9.108z" fill="#82543A"/>
                    <path d="m77.7 251.57-1.677 4.876-2.612-15.03-4.249-18.721-2.848-10.956 5.673 8.841 4.95 20.841.764 10.149zm140.51.001 1.678 4.876 2.612-15.03 4.25-18.721 2.847-10.961-5.672 8.841-4.95 20.841-.765 10.154z" fill="#CFC5C0"/>
                    <path d="M79.624 136a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.303.303 0 0 1-.224.084h-.266zM208.624 136a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.369 1.246.378 2.058a47.205 47.205 0 0 1 0 2.226c-.018.859-.144 1.568-.378 2.128-.233.56-.625.98-1.176 1.26-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.439-.205.752-.527.938-.966.196-.448.299-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                    <g opacity=".5">
                        <path d="M148.174 96.719v226-226zm0 0v226z" fill="#000"/>
                        <path d="M148.174 96.719v226m0-226v226-226z" stroke="#fff"/>
                    </g>
                    <path fill="none" clip-rule="evenodd" d="M126.199 187.697c-2.028 11.908-6.212 26.634-10.621 38.38-2.205 5.874-4.46 10.986-6.522 14.621-1.032 1.819-2.004 3.249-2.886 4.217-.906.996-1.618 1.393-2.122 1.393-.251 0-.577-.091-.984-.32-.405-.227-.861-.572-1.364-1.04-1.007-.936-2.156-2.317-3.399-4.077-2.483-3.517-5.296-8.485-8.043-14.259-5.495-11.551-10.695-26.258-12.47-38.901-.383-2.729.799-5.633 3.043-8.552 2.238-2.911 5.482-5.768 9.082-8.374 7.199-5.215 15.722-9.361 20.17-10.899 4.406-1.522 9.562-1.478 13.042 2.249 3.506 3.755 5.484 11.414 3.074 25.562zm42.759 0c2.029 11.908 6.213 26.634 10.622 38.38 2.205 5.874 4.46 10.986 6.521 14.621 1.033 1.819 2.005 3.249 2.886 4.217.907.996 1.618 1.393 2.122 1.393.251 0 .577-.091.985-.32.404-.227.861-.572 1.364-1.04 1.007-.936 2.156-2.317 3.399-4.077 2.483-3.517 5.295-8.485 8.042-14.259 5.496-11.551 10.696-26.258 12.47-38.901.383-2.729-.798-5.633-3.042-8.552-2.238-2.911-5.483-5.768-9.082-8.374-7.2-5.215-15.723-9.361-20.17-10.899-4.407-1.522-9.562-1.478-13.042 2.249-3.506 3.755-5.485 11.414-3.075 25.562z" stroke="#fff"/>
                    <path fill={A_color} d="M110.674 193.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M118.083 198.719a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zm-1.303 9.238c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={B_color} d="M160.674 193.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M169.075 198.719a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zm-.517 7.32c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={C_color} d="M110.674 248.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M122.757 253.859c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828a38.663 38.663 0 0 1-.014-1.232c0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196a.295.295 0 0 1-.196.07h-1.89a.475.475 0 0 1-.28-.07.665.665 0 0 1-.168-.294c-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084a.23.23 0 0 1 .084.21c-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zm-.083 5.36c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={D_color} d="M160.674 248.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M168.993 253.719a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zm.041 7.516c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0H341V380H0z" transform="translate(0 0)"/>
                        </clipPath>
                        <filter id="filter0_d" width="381" height="420" x="0" y="-4" color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="10"/>
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                    </defs>
                </svg>

            )
        }
    }

}

export default MA_LungsBack
