import {BehaviorSubject} from 'rxjs';
import {AllUsersApi, doctorApi} from "../ApiService";
import auth from "../../config/AuthConst";
import utils from "../../utils/utils";


let userLoggedIn = false;
let pwdUserLoggedIn = false;
let mfaUserLoggedIn = false;
let fpUserLoggedIn = false;

const currentUserSubject = new BehaviorSubject();
let pwdUserSubject = new BehaviorSubject();
let mfaUserSubject = new BehaviorSubject();
let fpUserSubject = new BehaviorSubject();

export const authenticationService = {
    login,
    setSTSUser,
    completeNewPassword,
    checkPassword,
    resendCode,
    logout,
    reauthenticate,
    setUserData,
    isLoggedin,
    forgotPassword,
    mfaSubmit,
    forgotPasswordSubmit,
    refreshToken,
    confirmNewPassword,
    refreshUserWithToken,
    currentSessionToken,
    setUserProperties,
    userLoggedIn,
    pwdUserLoggedIn,
    mfaUserLoggedIn,
    fpUserLoggedIn,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    pwdUser: pwdUserSubject.asObservable(),
    get pwdUserValue() {
        return pwdUserSubject.value
    },
    mfaUser: mfaUserSubject.asObservable(),
    get mfaUserValue() {
        return mfaUserSubject.value
    },
    fpUser: mfaUserSubject.asObservable(),
    get fpUserValue() {
        if (fpUserSubject.value)
            return fpUserSubject.value
        else return JSON.parse(sessionStorage.getItem('fploggedInUser'))
    }
};

async function setSTSUser(user){
    await currentUserSubject.next(user);
    sessionStorage.setItem('loggedInUser', JSON.stringify(user))
    sessionStorage.setItem('userLoggedIn', String(true))
}

async function login(username, password,tenant, role) {

    return new Promise(async (resolve, reject) => {
        await AllUsersApi.signIn(username, password,tenant , role)
            .then(async user => {

                let challenge = user.challengeName;
                if (challenge === auth.PWD_RST) {
                    pwdUserSubject.next(user);
                    pwdUserLoggedIn = true;
                    userLoggedIn=true;
                    resolve(user);
                } else if(challenge === auth.MFA) {
                    mfaUserSubject.next(user);
                    mfaUserLoggedIn = true;
                    userLoggedIn=true;
                    resolve(user);
                } else {
                    try {
                        await currentUserSubject.next(user);
                        sessionStorage.setItem('loggedInUser', JSON.stringify(user))
                        sessionStorage.setItem('userLoggedIn', String(true))
                        userLoggedIn=true;
                        await resolve(user);
                    } catch (error) {
                        console.error("error getting ", error);
                        await reject(error);
                    }
                }
            })
            .catch(error => {
                console.error("error signing in", error);
                reject(error);
            });
    });
}

async function completeNewPassword(newPassword) {
    return new Promise(async (resolve, reject) => {
        AllUsersApi.completeNewPassword(newPassword).then(async user => {
            if (user.hasOwnProperty('challengeName') && user.challengeName === auth.PWD_RST) {
                pwdUserSubject.next(user);
                pwdUserLoggedIn = true;
            } else if(user.hasOwnProperty('challengeName') && user.challengeName === auth.MFA) {
                mfaUserSubject.next(user);
                mfaUserLoggedIn = true;
            } else {
                if (user.hasOwnProperty('token'))
                    user['accessToken'] = user['token']
                await currentUserSubject.next(user);
                sessionStorage.setItem('loggedInUser', JSON.stringify(user))
                sessionStorage.setItem('userLoggedIn', String(true))
            }
            resolve(user);
        }).catch((err) => {
            reject(err)
        });
    });
}
async function confirmNewPassword(newPassword,code,tenant,token) {
    return new Promise(async (resolve, reject) => {
        AllUsersApi.confirmNewPassword(newPassword,code,tenant,token).then(async user => {
            if (user.hasOwnProperty('challengeName') && user.challengeName === auth.PWD_RST) {
                pwdUserSubject.next(user);
                pwdUserLoggedIn = true;
            } else if(user.hasOwnProperty('challengeName') && user.challengeName === auth.MFA) {
                mfaUserSubject.next(user);
                mfaUserLoggedIn = true;
            } else {
                if (user.hasOwnProperty('token'))
                    user['accessToken'] = user['token']
                await currentUserSubject.next(user);
                sessionStorage.setItem('loggedInUser', JSON.stringify(user))
                sessionStorage.setItem('userLoggedIn', String(true))
            }
            resolve(user);
        }).catch((err) => {
            reject(err)
        });
    });
}



async function refreshToken(refreshToken) {
    return new Promise(async (resolve, reject) => {
        let user = JSON.parse(JSON.stringify(currentUserSubject['value']));
        if (!refreshToken) {
            refreshToken = user['refreshToken']
        }
        await AllUsersApi.refreshToken(refreshToken).then(async token => {
            resolve(token['accessToken'])
        }).catch(e => {
            console.log(e.message)
            reject(e);
        })
    })
}

async function currentSessionToken() {
    let user = JSON.parse(JSON.stringify(currentUserSubject['value']));
    let currentToken = user['accessToken']
    let refreshToken = user['refreshToken']
    let decodedToken = utils.parseJwt(currentToken)
    let exp = (decodedToken['exp']) * 1000
    let curTime = (new Date()).getTime()
    let tokenExpired = curTime - exp > 0
    return new Promise(async (resolve, reject) => {
        if (tokenExpired) {
            await this.refreshToken(refreshToken).then(async token => {
                //set new token to user
                user['accessToken'] = token
                await currentUserSubject.next(user);
                sessionStorage.setItem('loggedInUser', JSON.stringify(user))
                resolve(token)
            }).catch(e => {
                console.error(e);
                reject(e);
            })
        } else {
            resolve(currentToken)
        }
    });
}

async function forgotPassword(username,tenant, role) {
    return new Promise(async (resolve, reject) => {
        await AllUsersApi.forgotPassword(username,tenant, role)
            .then(async userData => {
                // await resolve()
                // await fpUserSubject.next(userData);
                // sessionStorage.setItem('fploggedInUser', JSON.stringify(userData))
                // sessionStorage.setItem('fpUserLoggedIn', String(true))
                resolve(userData)
            })
            .catch(err => {
                console.error('Error', err);
                reject(err)
            });
    });
}
async function checkPassword(newPassword,token,tenant) {
    return new Promise(async (resolve, reject) => {
       await AllUsersApi.checkPassword(newPassword,token,tenant).then(async data => {
             await resolve(data)
        }).catch((err) => {
            console.error('Error', err);
            reject(err)
        });
    });
}
async function resendCode(password,token,tenant) {
    return new Promise(async (resolve, reject) => {
       await AllUsersApi.resendCode(password,token,tenant).then(async data => {
             await resolve(data)
        }).catch((err) => {
            console.error('Error', err);
            reject(err)
        });
    });
}
async function mfaSubmit(code) {
    return new Promise(async (resolve, reject) => {
        await AllUsersApi.mfaSubmit(code)
            .then(async user => {
                await currentUserSubject.next(user);
                sessionStorage.setItem('loggedInUser', JSON.stringify(user))
                sessionStorage.setItem('userLoggedIn', String(true))
                await resolve(user);
            })
            .catch(err => {
                console.error('Error', err);
                reject(err)
            });
    });
}
async function forgotPasswordSubmit(code, new_password) {
    return new Promise(async (resolve, reject) => {
        await AllUsersApi.forgotPasswordSubmit(code, new_password)
            .then(async (data) => {
                await resolve()
            })
            .catch(err => {
                console.error('Error', err);
                reject(err)
            });
    });
}

async function setUserData(vals) {
    currentUserSubject.next(vals);
    sessionStorage.setItem('loggedInUser', JSON.stringify(vals))
}

function reauthenticate() {
    let data = JSON.parse(sessionStorage.getItem('loggedInUser'))
    sessionStorage.setItem('userLoggedIn', String(true))
    currentUserSubject.next(data);
}

function setUserProperties(data) {
    let user = JSON.parse(sessionStorage.getItem('loggedInUser'))
    let {firstName, lastName,photoUrl,isForClinicalUse,speciality,sessionType,familySize,allowLiveSession,allowPatientControl,offlineSessionProvider,onlineSessionProvider,patientsInvitationUrlTenant,allowPatientSelfShareTenant,allowPatientSelfShare} = data
    let {profile} = user

    profile['firstName'] = firstName!== undefined ? firstName : user.profile.firstName
    profile['photoUrl'] = photoUrl ?photoUrl :""
    profile['lastName'] = lastName!== undefined ? lastName :user.profile.lastName
    profile['isForClinicalUse'] = isForClinicalUse
    profile['familySize'] = familySize
    profile['speciality'] = speciality
    profile['sessionType'] = sessionType
    profile['allowLiveSession'] = allowLiveSession
    profile['allowPatientControl'] = allowPatientControl
    profile['offlineSessionProvider'] = offlineSessionProvider
    profile['onlineSessionProvider'] = onlineSessionProvider
    profile['patientsInvitationUrlTenant'] = patientsInvitationUrlTenant
    profile['allowPatientSelfShare'] = allowPatientSelfShare
    profile['allowPatientSelfShareTenant'] = allowPatientSelfShareTenant
    this.setUserData(user)
}

async function refreshUserWithToken(token) {
    let userData = await doctorApi.getUser(token);
    if (!userData) {
        console.error("error getting userData");
    }
    await currentUserSubject.next(userData);
    sessionStorage.setItem('loggedInUser', JSON.stringify(userData))
    sessionStorage.setItem('userLoggedIn', String(true))
}


function isLoggedin() {
    return Boolean(sessionStorage.getItem('userLoggedIn') === "true" && sessionStorage.getItem('loggedInUser') !== "undefined")
}

async function logout() {
    try {

        // document.getElementById('root').click();
        userLoggedIn = false;
        sessionStorage.clear()
        currentUserSubject.next(null);
        sessionStorage.setItem('loggedInUser', JSON.stringify(null))
        sessionStorage.setItem('userLoggedIn', String(false))
    } catch (error) {
        console.error('error signing out: ', error);
    }

}

