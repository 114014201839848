import axios from 'axios'
import Config from "../../config/Config";
import {authenticationService} from "../auth/AuthenticationService";
import restApi from "./RestApi";

const AllUsersApi = {
    signIn: async (email, password,tenant, role) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        if (tenant) (requestconfig.headers)['tenant'] = tenant
        if (email.toLowerCase() === 'mhd@myhomedoc.com') (requestconfig.headers)['tenant'] = 'mhd'
        let body = {
            "email": email,
            "password": password,

        };
        let url = `${Config.loggedGlobalUrl}/api/v1/users/login?role=${role}`;
        return await restApi.post(
            url,
            body,
            requestconfig
        );
    },

    refreshToken: async (refreshToken) => {

        if (!authenticationService.currentUserValue)
            return;
        let user = authenticationService.currentUserValue;

        let body = {
            email: (user['profile'])['email'],
            refreshToken
        };

        let role = sessionStorage.role || user.profile.role
        let url = `${Config.globalUrl}/api/v1/users/refreshToken?role=${role}`;
        return await restApi.post(
            url,
            body,
            {headers: {'Content-Type': 'application/json',"tenant":user.tenant}}
        );
    },

    // forgotPassword: async (email,tenant, role) => {
    //     let body = {
    //         email
    //     };
    //     let requestconfig = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     };
    //     if (tenant) (requestconfig.headers)['tenant'] = tenant
    //     let url = `${Config.globalUrl}/api/v1/users/forgot?role=${role}`;
    //     let response =  await axios.post(
    //         url,
    //         body,
    //         requestconfig
    //     );
    //     return response
    // },
    forgotPassword: async (email,tenant, role) => {
        let body = {
            email
        };
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        if (tenant) (requestconfig.headers)['tenant'] = tenant
        let url = `${Config.globalUrl}/api/v1/users/password/reset?role=${role}`;
        let response =  await axios.post(
            url,
            body,
            requestconfig
        );
        return response
    },
    mfaSubmit: async (code) => {
        if (!authenticationService.mfaUserValue)
            return;
        let user = authenticationService.mfaUserValue;
        let body = {email: user.profile.email,code,session: user.session};
        let config = {
            headers: {
                'Content-Type': 'application/json',
                "tenant":user.tenant
            }
        }

        let url = `${Config.globalUrl}/api/v1/users/mfa?role=${user.role}`;
        return await restApi.post(
        url,
        body,
        config
    );
},
    forgotPasswordSubmit: async (code, password) => {
        if (!authenticationService.fpUserValue)
            return;
        let user = authenticationService.fpUserValue;

        let body = {email: user.Destination, password, code};
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        (requestconfig.headers)['tenant'] = user.tenant
        let url = `${Config.globalUrl}/api/v1/users/resetConfirm?role=${user.role}`;
        return await axios.post(
            url,
            body,
            requestconfig
        );
    },
    beVersion: async () => {
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        let url = `${Config.globalUrl}/open-api/v1/be/service/version`;
        return await axios.get(
            url,
            requestconfig
        );
    }
};

export default AllUsersApi
