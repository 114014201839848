//REACT_APP_API_ENDPOINT
const Config = {
//REGION BACKEND
    globalUrl: process.env.REACT_APP_API_ENDPOINT,
    loggedGlobalUrl: process.env.REACT_APP_API_ENDPOINT_LOGGED,
    region: process.env.REACT_APP_REGION,
//Version
    version: 'V' + require('../../package.json')['version'],
    MHDVersion: require('../../package.json')['MHDVersion'],
//ENDREGION BACKEND
    openTokKey: "46034322",

    userRoles: ['Physician', 'MHDAdmin', 'TenantAdmin', 'PracticeAdmin'],
    loginRoles: ['Physician', 'TenantAdmin', 'PracticeAdmin'],
    loginRolesSpanish: [{label:'Médico',value:'Physician'},{label:'Administrador de usuarios',value:'TenantAdmin'},{label:'Administrador del consultorio',value:'PracticeAdmin'}],
    roles: {
        Physician: 'Physician',
        MHDAdmin: 'MHDAdmin',
        TenantAdmin: 'TenantAdmin',
        PracticeAdmin: 'PracticeAdmin'
    },


    userSignIn: '/api/v1/webapp/signin',
    emailRegExp: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneRegExp: /^[+][0-9_\- \(\)]*$/,
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    passwordRegExp: /^(?=^.{8,20}$)(?=.*\d)(?=.*[!@#%^&*]+)(?![.\n])(?!.*[${}\/<>\[\]\"\'\`])(?=.*[A-Z])(?=.*[a-z]).*$/,
    numbersOnlyPatter: /^[0-9]*$/
};

Object.freeze(Config);
export default Config;
