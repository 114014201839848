import React, {Component} from 'react'
import ReactPlayer from "react-player";
import $ from 'jquery'
// import screenfull from 'screenfull'
// import { findDOMNode } from 'react-dom'
import SVGraphics from "../../assets/SVGraphics";
import Duration from "../../utils/Duration";
import '../../style/component/media/VideoPlayer.css'
import RangeInputVideo from "../input/RangeInputVideo";

class VideoPlayerThroat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: 'Video Player',
            url: null,
            muted: false,
            playing: false,
            isFullscreen: false,
            played: 0,
            loaded: 0,
            duration: 0,
            currentPosition:0.0
        }
        this.player = null
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0
        })
    }

    handlePlayPause = () => {
        this.setState({playing: !this.state.playing})

        if(!this.state.playing && navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)
        {
            document.getElementById(`videoPlayer+${this.props.title}`).play()
        }
        else if(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1){
            document.getElementById(`videoPlayer+${this.props.title}`).pause()
        }

    }

    handlePlay = () => {
        this.setState({playing: true})
    }

    handlePause = () => {
        this.setState({playing: false})
    }

    handleToggleMuted = () => {
        this.setState({muted: !this.state.muted})
    }

    ref = player => {
        this.player = player
    }

    handleSeekMouseDown = e => {
        this.setState({seeking: true})
    }


    handleSeekChange = e => {
        if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {

            this.setState({played: parseFloat(e.target.value)})}
        else {

            let currentSeconds =  Math.floor(document.getElementById(`videoPlayer+${this.props.title}`).currentTime % 60);
            let played = 0+"."+currentSeconds ;


            this.setState({played})
        }
    }

    handleSeekMouseUp = e => {

        if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {

            this.setState({seeking: false})
            this.player.seekTo(parseFloat(e.target.value))
        } else {
            let currentSeconds =  Math.floor(document.getElementById(`videoPlayer+${this.props.title}`).onmouseup % 60);
        }

    }

    handleProgress = state => {
        if (!this.state.seeking) {
            this.setState(state)
        }
        if (this.state.played >= 0.99999) {
            this.setState({played: 0})
            this.player.seekTo(0)
        }
    }

    handleDuration = (duration) => {
        this.setState({duration})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.fileId !== nextProps.fileId)
            this.setState({title: nextProps.title, url: nextProps.url})
    }

    async componentDidMount() {
        this.setState({title: this.props.title, url: this.props.url})
        // await document.addEventListener('fullscreenchange', event => {this.handleFullScreenChange(event)}, false);
        // await document.addEventListener('mozfullscreenchange', event => {this.handleFullScreenChange(event)}, false);
        // await document.addEventListener('MSFullscreenChange', event => {this.handleFullScreenChange(event)}, false);
        // await document.addEventListener('webkitfullscreenchange', event => {this.handleFullScreenChange(event)}, false);
    }

    componentWillUnmount() {
        // document.removeEventListener('fullscreenchange',console.log('remove fullscreenchange'))
        // document.removeEventListener('mozfullscreenchange',console.log('remove mozfullscreenchange'))
        // document.removeEventListener('MSFullscreenChange',console.log('remove MSFullscreenChange'))
        // document.removeEventListener('webkitfullscreenchange',console.log('remove webkitfullscreenchange'))
    }

    handleClickFullscreen = async () => {
        if (this.props.openFullScreen) {
            this.setState({
                played: 0,
                playing: false
            })
            this.props.openFullScreen()
        }

    }

    handleFullScreenChange = async (event) => {
        this.setState({isFullscreen: !this.state.isFullscreen})
    }
    onEnded =  () => {
        this.setState({playing: false})
        this.setState({played: 0})
    }




    render() {
        const {title, url, playing, muted, isFullscreen, played, duration} = this.state
        let {className, videoFiltering, fullscreenClass} = this.props
        let isMouthThroat = title==="Throat" || title==="Mouth"
        let contClass = 'mouthThroat-video'
        let labelClass = 'video-player-label h-centered vp-lbl-' + className
        let playerClass = 'react-player rp-' + className
        let frameClass =  'h-centered frame-throatMouth'
        let videoPlayerControlClass = 'video-player-control  h-centered vp-cont-' + className
        let videoPlayerControllersClass = 'video-player-controllers vpc-cont-' + className
        let videoPlayerPlayControllersClass = 'video-player-play-controllers vpp-cont-' + className
        let videoPlaying = 'video-player-playing-control vp-' + className
        let videoPlayerRange = 'video-player-range custom-range vp-range-' + className
        // let fullscreenClass = 'video-player-screen-control video-fullscreen-throat vp-fsc-' + className
        let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
        let video = document.getElementById(`videoPlayer+${title}`)
        let fullScreenStyle= isSafari?{
            bottom:"80px"
        }:null
        return (
            <div className={contClass} >
                {/*{!!title && !isMouthThroat && <label className={labelClass}>{title}</label>}*/}
                <div className={frameClass}
                     onClick={this.handleClickFullscreen}>
                    {isSafari ? <video id={`videoPlayer+${title}`} onTimeUpdate={(e)=>this.handleSeekChange(e)} onEnded={()=>this.onEnded()}  style={{width: "100%", height: '100%',  padding:'5%'}}
                                       onSeeking={(e) => this.handleSeekChange(e)}
                                       onSeeked={(e) => this.handleSeekChange(e)}
                                       onLoadedMetadata={this.props.onLoadedMetadata}
                                       onPlaying={this.props.onPlaying}
                                       onPause={this.props.onPause}
                                       onMouseMove={this.props.onMouseMove}
                                       onMouseOut={this.props.onMouseOut}
                                       disablePictureInPicture
                                       muted
                                       controls
                                       webkit-playsinline
                                       playsinline
                                       controlsList="nodownload noplaybackrate nofullscreen"
                        >
                            {url === this.props.url && <source src={url} type="video/mp4"/>}
                            {url !== this.props.url && <source src={url} type="video/mp4"/>}
                        </video>
                        :

                        <video id={`videoPlayer+${title}`} onTimeUpdate={(e)=>this.handleSeekChange(e)} onEnded={()=>this.onEnded()}  style={{width: "100%",height:"100%", padding:'5%'}}
                               onSeeking={(e) => this.handleSeekChange(e)}
                               onSeeked={(e) => this.handleSeekChange(e)} controls
                               onLoadedMetadata={this.props.onLoadedMetadata}
                               onPlaying={this.props.onPlaying}
                               onPause={this.props.onPause}
                               onMouseMove={this.props.onMouseMove}
                               onMouseOut={this.props.onMouseOut}
                               disablePictureInPicture
                               muted
                               controls
                               webkit-playsinline
                               playsinline
                               controlsList="nodownload noplaybackrate nofullscreen"
                        >
                            {url === this.props.url && <source src={url} type="video/mp4"/>}
                            {url !== this.props.url && <source src={url} type="video/mp4"/>}
                        </video>
                    }
                  {/*  {videoFiltering &&*/}
                  {/*<RangeInputVideo/>*/}
                  {/*  }*/}

                </div>


                {/*<div className={videoPlayerControlClass}>*/}
                {/*    <div className={videoPlayerControllersClass}>*/}
                {/*        <div className={videoPlayerPlayControllersClass}>*/}
                {/*            <SVGraphics svgname={playing ? 'pause' : 'play'} onClick={this.handlePlayPause}*/}
                {/*                        className={videoPlaying}/>*/}
                {/*            /!*<div className={'duration-container video-player-timing'}>*!/*/}
                {/*            /!*    <Duration className={'video-player-timing'} seconds={duration * played} />*!/*/}
                {/*            /!*    /*!/*/}
                {/*            /!*    <Duration className={'video-player-timing'} seconds={duration} />*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </div>*/}
                {/*        <input type={'range'}*/}
                {/*               min={0} max={0.999999} step='any'*/}
                {/*               id={'video-player-range'}*/}
                {/*               className={videoPlayerRange}*/}
                {/*               value={played}*/}
                {/*               onMouseDown={this.handleSeekMouseDown}*/}
                {/*               onChange={this.handleSeekChange}*/}
                {/*               onMouseUp={this.handleSeekMouseUp}*/}
                {/*        />*/}
                        {this.props.openFullScreen &&
                        <SVGraphics svgname={'fullscreen-square-white'} onClick={this.handleClickFullscreen}
                                    style={fullScreenStyle}
                                    className={fullscreenClass}/>

                        }

                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default VideoPlayerThroat
