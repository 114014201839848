import React, {Component} from "react";
import {OTPublisher, OTSubscriber, createSession} from "opentok-react";
import SVGraphics from "../../../assets/SVGraphics";
import Config from "../../../config/Config";
import '../../../style/component/TokboxVideo.css'
import {doctorApi} from "../../../services/ApiService";
import Status from "../../../config/Status";
import Globals from "../../../utils/Globals";
import Protocol from "../../../services/siganling/ComProtocol";
import {actionsReceiver} from "../../../config/constants";


const OT = require('@opentok/client');

class TokboxVideo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            publishVideo: true,
            publishAudio: true,
            subscribeToAudio: true,
            connection: 'Connecting',
            isVideoHovered: false,
            isAudioHovered: false,
            isSpeakerHovered: false,
            isScreenOn: false,
            streams: [],
            disableEndCall: false,
            patient: {},
            totalSeconds: 0
        };
        this.otSubscriber = React.createRef()
        this.otSession = React.createRef();
        this._isMounted = false;
        this.sessionTimer = null

        this.sessionEventHandlers = {
            connectionCreated: (event) => {
                console.info('Opentok', 'session', 'connectionCreated', event)
            },
            sessionConnected: (event) => {
                console.info('Opentok', 'session', 'sessionConnected', event)
            },
            streamCreated: (event) => {
                console.info('Opentok', 'session', 'streamCreated', event)
            },
            streamDestroyed: (event) => {
                console.info('Opentok', 'session', 'streamDestroyed', event)
            },
            sessionDisconnected: (event) => {
                console.info('Opentok', 'session', 'sessionDisconnected', event)
            },
            connectionDestroyed: (event) => {
                console.info('Opentok', 'session', 'connectionDestroyed', event)
                this.endCall()
            }
        };

        this.publisherEventHandlers = {
            accessAllowed: (event) => {
                console.info('Opentok', 'publisher', 'accessAllowed', event)
            },
            accessDialogClosed: (event) => {
                console.info('Opentok', 'publisher', 'accessDialogClosed', event)
            },
            destroyed: (event) => {
                console.info('Opentok', 'publisher', 'destroyed', event)
            },
            mediaStopped: (event) => {
                console.info('Opentok', 'publisher', 'mediaStopped', event)
            },
            videoDimensionsChanged: (event) => {
                console.info('Opentok', 'publisher', 'videoDimensionsChanged', event)
            },
            videoElementCreated: (event) => {
                console.info('Opentok', 'publisher', 'videoElementCreated', event)
            },
            accessDenied: (event) => {
                console.info('Opentok', 'publisher', 'accessDenied', event)
            },
            streamCreated: (event) => {
                console.info('Opentok', 'publisher', 'streamCreated', event)
            },
            streamDestroyed: ({reason}) => {
                this.endCall()
                console.info('Opentok', 'publisher', 'streamDestroyed', reason)
            },
        };

        this.subscriberEventHandlers = {
            connected: (event) => {
                let that = this
                //setDoctorOnline
                this.sessionTimer = setInterval(() => {
                    let {totalSeconds} = this.state
                    ++totalSeconds;
                    this.setState({totalSeconds})
                }, 1000);
                console.info('Opentok', 'subscriber', 'connected', event)
                this.setState({disableEndCall: false});
            },
            // de

            videoElementCreated: (event) => {
                if (event.target.stream.videoType === 'screen') {
                    this.setState({isScreenOn: true})
                } else if (event.target.stream.videoType === 'camera') {
                    this.setState({isScreenOn: false})
                }
            }
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.props.setSH(this.sessionHelper);
        this.sessionHelper?.session.on("signal", (event) => {
            this.props.receiveSignal(JSON.parse(event.data))
        });
        //
        // let disableEndCall = (this.props.session.initiator).replaceAll(/\s/g, '') === 'Patient' && this.props.session.sta
        // this.setState({disableEndCall})
    }
    async componentDidUpdate(prevProps, prevState) {
        if(this.props.mobileEndCall === true){
            await this.endCall()
        }
    }

    pad(val) {
        let valString = val + "";
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    acceptCall() {

    }

    onSessionError = error => {
        console.error('Opentok', 'session', 'onSessionError', error)
        this.setState({error});
    };

    onPublish = () => {
        console.info('Opentok', 'publisher', 'onPublish')
    };

    onPublishError = error => {
        console.error('Opentok', 'publisher', 'onPublishError', error)
        this.setState({error});
    };

    onSubscribe = () => {
        console.info('Opentok', 'subscriber', 'onSubscribe')
    };

    onSubscribeError = error => {
        console.error('Opentok', 'subscriber', 'onSubscribeError', error)
        this.setState({error});
    };

    async endCall() {
        await this.props.sendSignal(Protocol.General(actionsReceiver.Disconnect))
        clearInterval(this.sessionTimer)
        let tenant = this.props.getSessionData?.tenant
        let accessToken = this.props.getSessionData?.accessToken
        let asrAuth = this.props.getSessionData?.asrAuth
        Globals['remote'] = false
        if ((this.props.exam?.initiator)?.replaceAll(/\s/g, '') === 'Physician' && this.props.exam.status === Status.calling)
            await doctorApi.updateSessionById(this.props.exam['sessionID'], {status: Status.missed});
        if ([Status.initiated, Status.inProgress].includes(this.props.exam?.status)) {
            let sessionRes = await doctorApi.updateSessionById(this.props.exam['sessionID'], {
                status: Status.read,
                examEndTime: (new Date()).toISOString()
            });
            if (sessionRes) {
                sessionRes = await doctorApi.getSessionById(this.props.exam['sessionID'], tenant, accessToken, asrAuth);
                await this.props.updateSession(sessionRes['patientID'], sessionRes);
            }
        } else if ([Status.calling].includes(this.props.exam?.status)) {
            let sessionRes = await doctorApi.updateSessionById(this.props.exam['sessionID'], {
                status: Status.missed,
                examEndTime: (new Date()).toISOString()
            });
            if (sessionRes) {
                sessionRes = await doctorApi.getSessionById(this.props.exam['sessionID'], tenant, accessToken, asrAuth);
                await this.props.updateSession(sessionRes['patientID'], sessionRes);
            }
        }
        if (this.sessionHelper) {
            this.sessionHelper.disconnect();
        }

        await this.props.endCall();
    }


     componentWillMount() {

        let session = this.props.session
        let apiKey = Config.openTokKey
        let sessionId = !!session ? session?.videoSessionID : undefined
        let token = !!session ? session?.videoSessionToken : undefined
        if(sessionId){
             this.sessionHelper = createSession({
                apiKey: apiKey,
                sessionId: sessionId,
                token: token,
                onStreamsUpdated: streams => {
                     this.setState({streams});
                }
            });
            Object.entries(this.sessionEventHandlers).forEach(([key, value]) => {
                this.sessionHelper.session.on(key, event => {
                    this.sessionEventHandlers[key](event)
                })
            })
        }
    }

    async componentWillUnmount() {
        this._isMounted = false;
        await this.props.setSH(null);
    }

    getImgData = () => {
        if (this.otSubscriber) {
            const subscriber = this.otSubscriber.current.getSubscriber();
            const imageData = subscriber.getImgData();
            var image = new Image();
            image.src = 'data:image/png;base64,' + imageData;
            document.body.appendChild(image);
        }
    }
    getStream = (stream,subscribeToAudio) =>{
        return <OTSubscriber
            onSubscribe={this.onSubscribe}
            properties={{subscribeToAudio}}
            onError={this.onSubscribeError}
            key={stream.id}
            ref={this.otSubscriber}
            session={this.sessionHelper.session}
            stream={stream}
            eventHandlers={this.subscriberEventHandlers}
        />
    }

    render() {
        let {
            streams,
            publishVideo,
            publishAudio,
            isScreenOn,
            subscribeToAudio,
            disableEndCall,
            totalSeconds
        } = this.state
        let stream = streams[0]
        let hours = this.pad(parseInt(totalSeconds / (60 * 60)))
        let minutes = this.pad(Math.abs(hours * 60 - parseInt(totalSeconds / (60))))
        let containerClass = this.props.isMobile?'tokbox-mobile':'tokbox'

        return (
            <div className={containerClass} >
                {
                    this.props.isMobile ?
                        this.props.fullScreenCall?
                        <div className={'tokbox-mobile'}>
                            {/*<div className={'chat-timer'}>{hours}:{minutes}:{this.pad(totalSeconds % 60)}</div>*/}
                            <div
                                className={isScreenOn ? "video-OTStreams video-OTStreams-screen" : "video-OTStreams-mobile"}>
                                <div className={"video-OTSubscriber-mobile"} id={'video-OTSubscriber'}
                                     style={{borderRadius: '0'}}>
                                    {stream && this.getStream(stream,subscribeToAudio)
                                    // <OTSubscriber
                                    //     onSubscribe={this.onSubscribe}
                                    //     properties={{subscribeToAudio}}
                                    //     onError={this.onSubscribeError}
                                    //     key={stream.id}
                                    //     ref={this.otSubscriber}
                                    //     session={this.sessionHelper.session}
                                    //     stream={stream}
                                    //     eventHandlers={this.subscriberEventHandlers}
                                    // />
                                    }
                                    {
                                        !stream &&
                                        <div className={'online-session-placeholder-mobile'}
                                             style={{borderRadius: "0px"}}>
                                            <SVGraphics svgname={'mhd-logo'}
                                                        className={'online-session-placeholder-logo centered'} style={{top:'43%'}}/>
                                            <div className="dot-typing centered"/>
                                            <div className={'connecting-call'}>Connecting..</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{visibility: publishVideo ? 'visible' : 'hidden'}}
                                 className={'video-OTPublisher-container'} style={{border: "20px"}}>
                                <div
                                    className={isScreenOn ? "video-OTPublisher video-OTPublisher-screen" : "video-OTPublisher"}>
                                    <OTPublisher
                                        properties={{publishVideo, publishAudio}}
                                        onPublish={this.onPublish}
                                        onError={this.onPublishError}
                                        session={this.sessionHelper?.session}
                                        eventHandlers={this.publisherEventHandlers}
                                    />
                                </div>
                            </div>
                            <div className={'opentok-controls-container-mobile'}>

                                <div className={'opentok-control-holder'} onClick={() => this.setState({publishVideo: !publishVideo})}>

                                <SVGraphics   svgname={publishVideo ? 'online-video-on-black' : 'online-video-off'}
                                              style={{left:'12%',top:'3%', width:'40px'}}
                                             className={'online-video-controller doctor-camera-btn pointer'} stroke={'white'}/>
                                </div>
                                <div className={'opentok-control-holder'}
                                     onClick={() => this.setState({publishAudio: !publishAudio})}>

                                <SVGraphics    svgname={publishAudio ? 'online-mic-on-black' : 'online-mic-off'}
                                               style={{left:'9%',top:'7%', width:'40px'}}
                                              className={'online-video-controller doctor-mic-btn pointer'} stroke={'white'}/>
                                </div>
                                <div className={'opentok-control-holder'}
                                     onClick={() => this.setState({subscribeToAudio: !subscribeToAudio})}
                                >

                                <SVGraphics  svgname={subscribeToAudio ? 'online-speaker-on-black' : 'online-speaker-off'}
                                             style={{left:'9%',top:'3%', width:'40px'}}
                                             className={'online-video-controller doctor-speaker-btn pointer'} stroke={'white'}/>
                                </div>

                                <button className={'btn mhd-btn end-call-btn end-call-btn-mobile'} disabled={disableEndCall} onClick={() => this.endCall()}>
                                    <SVGraphics svgname={'end-call'} className={'end-call-btn-svg-mobile'} disabled={disableEndCall}/>
                                </button>
                            </div>

                        </div>
                            :
                            <div className={'tokbox-mobile-small'} onClick={this.props.setFullScreen}>
                                {/*<div className={'chat-timer'}>{hours}:{minutes}:{this.pad(totalSeconds % 60)}</div>*/}
                                <div
                                    className={isScreenOn ? "video-OTStreams video-OTStreams-screen" : "video-OTStreams-mobile"}>
                                    <div className={"video-OTSubscriber-mobile"} id={'video-OTSubscriber'}
                                         style={{borderRadius: '0'}}>
                                        {stream && this.getStream(stream,subscribeToAudio)
                                        // <OTSubscriber
                                        //     style={{borderRadius:'0'}}
                                        //     onSubscribe={this.onSubscribe}
                                        //     properties={{subscribeToAudio}}
                                        //     onError={this.onSubscribeError}
                                        //     key={stream.id}
                                        //     ref={this.otSubscriber}
                                        //     session={this.sessionHelper.session}
                                        //     stream={stream}
                                        //     eventHandlers={this.subscriberEventHandlers}
                                        // />
                                        }
                                        {
                                            !stream &&
                                            <div className={'online-session-placeholder-mobile'}
                                                 style={{borderRadius: "0px"}}>
                                                <SVGraphics svgname={'mhd-logo'}
                                                            className={'online-session-placeholder-logo centered'} style={{top:'43%'}}/>
                                                <div className="dot-typing centered" />
                                                <div className={'connecting-call-small'}>Connecting..</div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                    <div style={{visibility: publishVideo  && this.props.fullScreenCall ? 'visible' : 'hidden',border: "20px"}}
                                         className={'video-OTPublisher-container'} >
                                        <div
                                            className={isScreenOn ? "video-OTPublisher video-OTPublisher-screen" : "video-OTPublisher"}>
                                            <OTPublisher
                                                properties={{publishVideo, publishAudio}}
                                                onPublish={this.onPublish}
                                                onError={this.onPublishError}
                                                session={this.sessionHelper?.session}
                                                eventHandlers={this.publisherEventHandlers}
                                            />
                                        </div>
                                    </div>


                            </div>
                        :
                        <div className={'tokbox-web'}>
                            <div className={'chat-timer'}>{hours}:{minutes}:{this.pad(totalSeconds % 60)}</div>
                            <div className={isScreenOn ? "video-OTStreams video-OTStreams-screen" : "video-OTStreams"}>
                                <div className={"video-OTSubscriber"} id={'video-OTSubscriber'}>
                                    {stream && this.getStream(stream,subscribeToAudio)
                                    // <OTSubscriber
                                    //     onSubscribe={this.onSubscribe}
                                    //     properties={{subscribeToAudio}}
                                    //     onError={this.onSubscribeError}
                                    //     key={stream.id}
                                    //     ref={this.otSubscriber}
                                    //     session={this.sessionHelper.session}
                                    //     stream={stream}
                                    //     eventHandlers={this.subscriberEventHandlers}
                                    // />
                                    }
                                    {
                                        !stream &&
                                        <div className={'online-session-placeholder'} style={{borderRadius: "20px"}}>
                                            <SVGraphics svgname={'mhd-logo-white'}
                                                        className={'online-session-placeholder-logo centered'}/>
                                            <div className="dot-typing centered" style={{left:'-9872px',top:'72%'}}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{visibility: publishVideo ? 'visible' : 'hidden'}}
                                 className={'video-OTPublisher-container'} style={{border: "20px"}}>
                                <div
                                    className={isScreenOn ? "video-OTPublisher video-OTPublisher-screen" : "video-OTPublisher"}>
                                    <OTPublisher
                                        properties={{publishVideo, publishAudio}}
                                        onPublish={this.onPublish}
                                        onError={this.onPublishError}
                                        session={this.sessionHelper.session}
                                        eventHandlers={this.publisherEventHandlers}
                                    />
                                </div>
                            </div>
                            <div className={'opentok-controls-container'}>
                                <div className={'opentok-controls'}>
                                    {/*<div className={'opentok-control-holder'}*/}

                                    <SVGraphics onClick={() => this.setState({publishVideo: !publishVideo})}
                                                svgname={publishVideo ? 'online-video-on-black' : 'online-video-off'}
                                                className={'online-video-controller doctor-camera-btn pointer'}
                                                stroke={'#2E2E2E'}/>
                                    {/*</div>*/}
                                    {/*<div className={'opentok-control-holder'}*/}

                                    <SVGraphics onClick={() => this.setState({publishAudio: !publishAudio})}
                                                svgname={publishAudio ? 'online-mic-on-black' : 'online-mic-off'}
                                                className={'online-video-controller doctor-mic-btn pointer'}
                                                stroke={'#2E2E2E'}/>
                                    {/*</div>*/}
                                    {/*<div className={'opentok-control-holder'}*/}

                                    <SVGraphics onClick={() => this.setState({subscribeToAudio: !subscribeToAudio})}
                                                svgname={subscribeToAudio ? 'online-speaker-on-black' : 'online-speaker-off'}
                                                className={'online-video-controller doctor-speaker-btn pointer'}
                                                stroke={'#2E2E2E'}/>
                                    {/*</div>*/}

                                </div>
                                <button className={'btn mhd-btn end-call-btn'} style={{cursor:'pointer',zIndex:'55'}} disabled={disableEndCall}
                                        onClick={() => this.endCall()}>
                                    <SVGraphics svgname={'end-call'} className={'end-call-btn-svg'}
                                                disabled={disableEndCall}/>
                                    <label className={'end-call-btn-label'} style={{cursor:'pointer'}}>End call</label>
                                </button>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

//actions
// function mapDispatchToProps(dispatch) {
//     return {};
// }
//
// //state
// const mapStateToProps = state => {
//     return {};
// };

// const TokboxVideo = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(TokboxVideoConnected);

export default TokboxVideo
