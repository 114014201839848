import Config from "../../config/Config";
import React from "react";
import PatientCardRedirect from "../../views/redirect/PatientCardRedirect/index";
import ExpiredLink from "../../views/redirect/ExpiredLink";
import NotFound from "../../views/redirect/NotFound";


const RedirectRoutes = {
    PatientCardRedirectComponent: ({props}) => <PatientCardRedirect {...props}/>,
    ExpiredLinkComponent: ({props}) => <ExpiredLink {...props}/>,
    NotFoundComponent: ({props}) => <NotFound {...props}/>,
}

const RedirectRoutesComponents = {
    PatientCardRedirectComponent: {
        component: 'PatientCardRedirectComponent',
        url: '/rdct/pcrd/:ttuid',
    },

    ExpiredLinkComponent: {
        component: 'ExpiredLinkComponent',
        url: '/rdct/expired/pcrd/:ttuid',
    },

    NotFoundComponent: {
        component: 'NotFoundComponent',
        url: '/rdct/404/pcrd',
    }
};
const RedirectRoutesConfig = {
    routes: [
        ...Object.values(RedirectRoutesComponents)
    ]
};


export {RedirectRoutesConfig, RedirectRoutes};
