import React, {Component} from "react";
import SkinBodyIndicatorFront from "./SkinBodyIndicatorFront";
import SkinBodyIndicatorBack from "./SkinBodyIndicatorBack";
import { bodyParts} from "../../config/constants";

class SkinBody extends Component {
0
    render() {
        let {hoveredSkin} = this.props
        let language= 'en'
        let html = <SkinBodyIndicatorFront className={this.props.className} part={''} side={''}/>
        let frontParts = [bodyParts.face, bodyParts.chest, bodyParts.abdomen, bodyParts.genitals]
        let backParts =  [bodyParts.head, bodyParts.back, bodyParts.buttocks]
        if (!!hoveredSkin) {
            if (frontParts.includes(hoveredSkin.part.toLowerCase())) hoveredSkin.facing = 'front'
            else if (backParts.includes(hoveredSkin.part.toLowerCase())) hoveredSkin.facing = 'back'
            if ((hoveredSkin.facing).toLowerCase() === 'front')
                html = <SkinBodyIndicatorFront className={this.props.className} part={hoveredSkin.part}
                                               side={hoveredSkin.side}/>
            else if ((hoveredSkin.facing).toLowerCase() === 'back')
                html = <SkinBodyIndicatorBack className={this.props.className} part={hoveredSkin.part}
                                              side={hoveredSkin.side}/>
        }

        return (html);
    }

}

export default SkinBody
