import ReactTable from "react-table-v6";
import React from "react";
import "react-table-v6/react-table.css"
import Pagination from "./Pagination";
import '../../style/component/table/MHDTable.css'
import PropTypes from "prop-types";
import TheadComponent from "./TheadComponent";
import utils from "../../utils/utils";
import SVGraphics from "../../assets/SVGraphics";

class NoDataConst extends React.Component {
    render() {
        let {show,title} = this.props;
        //TODO...
        return (
            <div className={'h-centered'}
                 style={{display: show ? "block" : "none", position: 'absolute', top: '130px'}}>
                <SVGraphics style={{display: 'block'}} svgname={'no-search'} height={'150px'}/>
                <div style={{display: 'block', fontSize: '40px', textAlign: "center"}}>{title}</div>
            </div>
        );
    }
}

class LoadingComponent extends React.Component {
    render() {
        return (
            <div className={"loader spin"} style={{display: this.props.loading ? "block" : "none"}}/>
        );
    }
}

class MHDTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selected: {}, selectAll: 0, expanded: []};
        this.reactTable = React.createRef()
    }

    componentDidMount() {
        let {setTableRef} = this.props
        if (!!setTableRef)
            this.props.setTableRef(this)
    }

    getTrProps = (state, rowInfo, instance) => {
        let {original} = rowInfo;

        let deleted = true;
        if (original.hasOwnProperty('accountStatus') && original['accountStatus'] !== 1) {
            deleted = false;
        }
        if (original.hasOwnProperty('enabled') && original['enabled'] !== 1) {
            deleted = false;
        }
        if (rowInfo) {
            let style = {
                color: deleted ? 'black' : '#b4b4b4',
            };
            if (this.props.onRowClick || this.props.SubComponent)
                style['cursor'] = 'pointer';
            let className = deleted ? 'unlocked' : 'locked'
            if (rowInfo.className) className = className + ' ' + rowInfo.className
            let propsObj = {
                className: className,
                style
            };
            if (this.props.onRowClick)
                propsObj['onClick'] = () => this.props.onRowClick(original);
            else if (this.props.SubComponent)
                propsObj['onClick'] = () => this.expandRow(rowInfo);
            return propsObj
        }
        return {};
    };

    expandRow(row) {
        let {expanded} = this.state;
        if (expanded[row.viewIndex]) {
            expanded[row.viewIndex] = !expanded[row.viewIndex];
        } else {
            expanded[row.viewIndex] = true;
        }
        this.setState({
            expanded: expanded
        });
    }

    expandRowBySid(sid) {
        if (this.reactTable) {
            let rows = this.reactTable.state.resolvedData
            let row = rows.filter(row =>
                row._original.sessionID === sid
            )
            if (row.length>0) {
                let index = (row[0])._index
                let {expanded} = this.state;
                if (expanded[index]) {
                    expanded[index] = !expanded[index];
                } else {
                    expanded[index] = true;
                }
                this.setState({
                    expanded: expanded
                });
            }
        }

    }

    getTheadThProps = (state, column, instance) => {

        let field = instance.id;
        let data = [];
        let filterData = [];
        data.forEach(obj => {
            filterData.push(obj[field])
        });
        let uniqFilterData = [...new Set(filterData)];
        let props = {
            data: uniqFilterData,
            onFilterDataSelected: filter => this.props.onFilterDataSelected(filter),
            onSort: () => this.setState({expanded: []})
        };
        if (instance.id) {
            utils.set(props, 'field', instance.id)
        }
        if (instance.makeFilter) {
            utils.set(props, 'makeFilter', instance.makeFilter)
        }
        if (instance.thClass) {
            utils.set(props, 'thClass', instance.thClass)
        }
        if (instance.filterConverter) {
            utils.set(props, 'filterConverter', instance.filterConverter)
        }
        return props
    };

    //editable

    getTableColumns = () => {
        let {columns, edit} = this.props;
        let allowEdit = false;
        if (!!edit) {
            allowEdit = columns.findIndex(col => (col['accessor'] === "edit")) === -1;
            if (allowEdit) {
                columns.push({
                    accessor: "edit",
                    Header: "Edit",
                    makeFilter: false,
                    sortable: false,
                    resizable: false,
                    width: 55,
                    maxWidth: 55,
                    Cell: ({original}) => {
                        return (
                            <SVGraphics onClick={() => edit(original)} svgname={'edit'}
                                        className={'list-table-edit inline pointer v-centered'}/>
                        );
                    },
                })
                // cols = cols.concat(editColumn)
            }
        }
        return columns
    };

    getNoDataProps = () => {
        return {
            show: !this.props.isLoading && (!this.props.data || this.props.data.length === 0),
            title : this.props.title
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let {data} = nextProps
        let rows = this.reactTable.state.resolvedData
        if (data && data.length > rows.length) {
            this.setState({expanded: []})
        }
    }

    render() {

        return (
            <ReactTable
                className={this.props.className}
                data={this.props.data}
                columns={this.getTableColumns()}
                defaultPageSize={10000000000000000000}
                showPagination={/*this.props.data.length>8*/ false}
                //pageSize={pgSize}
                height
                minRows={0}
                maxRows={10000000000000000000}
                sortable={true}
                multiSort={false}
                expanded={this.state.expanded}
                ref={instance => {
                    this.reactTable = instance
                }}
                loading={this.props.isLoading}
                LoadingComponent={LoadingComponent}
                getLoadingProps={this.getLoadingProps}
                getTrProps={this.getTrProps}
                getTheadThProps={this.getTheadThProps}
                ThComponent={TheadComponent}
                //filterable={true}
                showPaginationBottom={false}
                PaginationComponent={Pagination}
                showPageSizeOptions={false}
                getNoDataProps={this.getNoDataProps}
                NoDataComponent={NoDataConst}
                SubComponent={!!this.props.SubComponent ? (v) => this.props.SubComponent(v) : false}
                defaultSorted={
                    this.props.hasOwnProperty('defaultSorted') ?
                    this.props.defaultSorted : []
                }
            />
        );
    }
}

MHDTable.propTypes = {
    onFilterDataSelected: PropTypes.func,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
};

export default MHDTable

