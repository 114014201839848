import React, {Component} from "react";
import PropTypes from 'prop-types'
import '../../style/component/input/CheckBox.css'
import '../../style/component/input/Switch.css'


class Switch extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    onChange = event => {
        this.props.onChange(event);
    };


    render() {
        let checked = this.props.checked;
        let {name, disabled, onLabel, offLabel, className} = this.props;

        let containerClassName = "mhd-switch-input-container";
        let switchCoverClassName = "mhd-switch-cover";
        let inputClassName = "mhd-switch-input";
        let switchSliderClassName = "mhd-switch-slider round"
        let onLabelClassName = checked ? "mhd-switch-label mhd-switch-on-label active" : "mhd-switch-label mhd-switch-on-label";
        let offLabelClassName = !checked ? "mhd-switch-label mhd-switch-off-label  active" : "mhd-switch-label mhd-switch-off-label";

        if (!!className) {
            containerClassName = containerClassName + ' switch-container-' + className
            switchCoverClassName = onLabelClassName + ' switch-cover-' + className
            inputClassName = inputClassName + ' switch-input-' + className
            switchSliderClassName = onLabelClassName + ' switch-slider-' + className
            onLabelClassName = onLabelClassName + ' switch-on-label-' + className
            offLabelClassName = offLabelClassName + ' switch-off-label-' + className
        }

        return (

            <div className={containerClassName}>
                <div className={offLabelClassName}>
                    {offLabel}
                </div>
                <label htmlFor={name} className={switchCoverClassName}>
                    <input type={"checkbox"}
                           checked={checked}
                           value={!checked}
                           disabled={disabled}
                           className={inputClassName}
                           name={name}
                           id={name}
                           onChange={e => this.onChange(e)}
                    />
                    <span className={switchSliderClassName}/>
                </label>
                <div className={onLabelClassName}>
                    {onLabel}
                </div>
            </div>


        );
    }
}

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
    className: PropTypes.string,
};

export default Switch
