import React, {Component} from "react";
import SVGraphics from "../../../../../assets/SVGraphics";
import ExamTabs from "./examTabs/ExamTabs";
import Exam from "./exams/Exam";
import Status from "../../../../../config/Status";
import {doctorApi} from "../../../../../services/ApiService";
import MakeVideo from "../../../../modal/VideoModal";
import * as Yup from "yup";
import validationHelper from "../../../../../utils/validationHelper";
import utils from "../../../../../utils/utils";
import MakeMessage from "../../../../modal/MessageModal";
import Globals from "../../../../../utils/Globals";
const {suggestion} = Globals

class FinishedSessionExams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: null,
        }
    }


    async onSummaryChange(event) {
        let value = event.target.value
        let {validationSchema} = this.state
        let summaryCont = {summary: value}
        let errors = await validationHelper.validate(summaryCont, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errors, 'summary')) {
            utils.set(errorsOnSave, 'summary', utils.get(errors, 'summary'))
        } else {
            delete errorsOnSave['summary']
        }
        this.setState({
            summaryCont,
            errors,
            errorsOnSave
        })
        // this.summaryRef = React.createRef()
    }

    async openFullScreen(url) {
        await MakeVideo({url})
    }

    async saveSummary() {
        let {t} = this.props
        if (!utils.isEmpty(this.state.errors)) {
            this.setState({errorsOnSave: this.state.errors});
            return;
        }
        let {summaryCont,notesCont} = this.state
        let {summary} = summaryCont
        let {notes} = notesCont;

        let body = {status: Status.sent, summary,notes}
        let sessionResp = await doctorApi.updateSessionById(this.props.session['sessionID'], body)
        if (!!sessionResp) {
            let {session} = this.props
            session['status'] = sessionResp['status']
            session['summary'] = sessionResp['summary']
            session['notes'] = sessionResp['notes'];

            session['updatedAt'] = sessionResp['updatedAt']
            await MakeMessage({title:t('physician.patient.exams.sessionChanged'), message: t('physician.patient.exams.summarySaved')});
            this.props.saveSummary(session)
        }
    }

    async setSuggestion(suggestion, sessionID) {
        let ta = document.getElementById('session-notes-id-' + sessionID)
        let {notesCont} = this.state
        let {notes} = notesCont
        let suggestionValue = suggestion + ' '
        if (!!notes) suggestionValue = ' ' + suggestionValue
        notes = notes + suggestionValue
        ta.value = notes
        await this.onNotesChange({target: {name: "", value: notes}})
    }
    async onNotesChange(event) {
        let value = event.target.value;
        let {validationSchema} = this.state;
        let notesCont = {notes: value};
        let errors = await validationHelper.validate(notesCont, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;

        if (utils.get(errors, 'notes')) {
            utils.set(errorsOnSave, 'notes', utils.get(errors, 'notes'))
        } else {
            delete errorsOnSave['notes']
        }
        this.setState({
            notesCont
        });
        // this.summaryRef = React.createRef()
    }

    render() {
        let {session, patient,t} = this.props
        let {type} = this.state


        return (
            <div className={'session-exams-container'}>
                <div className={'session-exams-container'}>
                    <ExamTabs t={this.props.t} exams={session['exams']} observations={session['Observations']} type={type}
                              onChange={type => this.setState({type})}/>
                    <Exam t={this.props.t} observations={session['Observations']} type={type} files={session['Files']}
                          patient={patient}/>
                </div>
            </div>
        );
    }
}

export default FinishedSessionExams
