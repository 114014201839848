import React, {Component} from "react";
import PropTypes from 'prop-types'
import '../../style/component/input/TextInput.css'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import TextField from "@material-ui/core/TextField";
import {css} from '@emotion/css'
import {IconButton, InputAdornment} from "@material-ui/core";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

class TextInput extends Component {

    constructor(props) {
        super(props);
        this.inputRef = null
    }

    componentDidMount() {
        // this.inputRef.click()
    }



    render() {
        const styles = {
            textFieldLabel: css({
                color: "red"
            }),
            textField: 'mhd-text-input'
        };

        let {
            type,
            name,
            onChange,
            defaultValue,
            placeholder,
            required,
            label,
            isErr,
            errMessage,
            className,
            endAdornment,
            isNewPassword,
        } = this.props;
        let containerClassName = "mhd-text-input-container";
        let inputClassName = isErr ? "mhd-text-input err-mhd-text-input" : "mhd-text-input";
        let labelClassName = "mhd-text-input-label";
        let errClassName = "mhd-text-input-error-message";

        if (!!className) {
            containerClassName = containerClassName + ' container-' + className;
            inputClassName = inputClassName + ' text-input-' + className;
            labelClassName = labelClassName + ' label-' + className;
            errClassName = errClassName + ' err-' + className;
        }

        return (
            <div onKeyPress={event => {
                if (event.key === 'Enter') {
                    this.props.onSubmit();
                }
            }} className={containerClassName} autoComplete={"new-password"}>
                {
                    isNewPassword ?
                        <OverlayTrigger
                            placement="bottom"
                            delay={{show: 250, hide: 400}}
                            overlay={<Tooltip className="password-tooltip">
                                Password must contain at least one of: small letters, big letters, numbers and
                                [!,@,#,$,%,^,*,] with length of 8-20
                            </Tooltip>}>
                            <TextField
                                id={`input-${name}`}
                                className={styles.textField}
                                type="password"
                                error={isErr}
                                helperText={isErr ? errMessage : ''}
                                label={label}
                                onChange={event => onChange(event)}
                                defaultValue={defaultValue}
                                // required={required}
                                name={name}
                                InputLabelProps={{
                                    FormLabelClasses: {
                                        focused: styles.textFieldLabel
                                    }
                                }}
                            />

                        </OverlayTrigger>
                        :

                        <TextField
                            id={`input-${name}`}
                            className={styles.textField}
                            type={type}
                            error={isErr}
                            onChange={event => onChange(event)}
                            helperText={isErr ? errMessage : ''}
                            label={placeholder}
                            defaultValue={defaultValue}
                            // required={required}
                            name={name}
                            InputProps={{
                                endAdornment:endAdornment
                            }}
                        />

                }

            </div>
        );
    }
}

TextInput.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    isErr: PropTypes.bool,
    errMessage: PropTypes.string,
    defaultValue: PropTypes.string,
    isNewPassword: PropTypes.bool
};

export default TextInput
