import {Modal} from "react-bootstrap";
import SVGraphics from "../../../../assets/SVGraphics";
import React from "react";
import moment from "moment";
import useMediaQuery from "../../../../utils/useMediaQuery";

export default function SnapShootExam(props) {
    const time = moment(props.file.createdAt).format('HH:mm');
    const date = moment(props.file.createdAt).format('MMM DD YYYY');
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    return(
        <Modal
            size="lg"
            show={props.show}
            centered={true}
            onHide={() => props.closeWindow()}
            dialogClassName={ `media-dialog-snap ${isSmallScreen?'media-dialog-snap-small':isMobilScreen?'media-dialog-snap-mobile':''}`}
            contentClassName={`media-dialog-snap-content ${isSmallScreen?'media-dialog-snap-small':isMobilScreen?'media-dialog-snap-mobile':''}`}
            backdrop={false}
            keyboard={true}
            // aria-labelledby="example-modal-sizes-title-sm"
        >
            {/*<span className={'media-close-btn-container modal-close-video modal-close-btn-snap pointer'} onClick={() => proceed(this.state.captures)}>*/}

            {/*</span>*/}
            <div className={'media-dialog-snap-title'}

            ><span
                style={{fontWeight: 'bold'}}>{props.title + `${props.title!=='Skin'?' Snapshoot ':''}  `} </span> {time} | {date}

            <SVGraphics svgname={'close-white'} className={' media-dialog-snap-modal-close-btn'} onClick={() => props.closeWindow()}/>
            </div>
            <Modal.Body
                className={ ' media-dialog-snap-image'}
            style={{height:isMobilScreen?'21.75rem':'45.75rem'}}
            >
                {/*<SVGraphics svgname={'trashIcon'}*/}
                {/*            className={'modal-close-btn image-trash-btn-image'}*/}
                {/*            onClick={() => this.deleteSnapShot(props.file.fileID)} />*/}
                <img alt={'img'}
                     style={{width:'100%'}}
                     className={'media-dialog-snap-image'}
                     src={props.file.downloadUrl}/>

            </Modal.Body>
        </Modal>
    )

}
