import React, {Component} from "react";
import MakeVideoFilter from "../../../../modal/VideoModalWithFilter";
import moment from "moment";
import VideoPlayerThroat from "../../../../media/VideoPlayerThroat";
import SVGraphics from "../../../../../assets/SVGraphics";
import sessionsApi from "../../../../../services/ApiServices/doctorApi/sessionsApi";
import MakeSnap from "../../../../modal/SnapModal";
import {doctorApi} from "../../../../../services/ApiService";
import MakeErrorConfirm from "../../../../Error/ErrorModalWithCancel";
import queryString from "query-string";
import {history} from "../../../../../utils/history";
import utils from "../../../../../utils/utils";

class ThroatExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            MouthCaptures: [],
            ThroatCaptures: [],
            delete:false
        }

    }

    async componentDidMount() {
        let search = !history.location.search || history.location.search === {} ? window.location.search : history.location.search
        let query = queryString.parse(search);
        let allow = await this.checkQueryParam(query);
        let tenant = query.tenant;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth
        let sid = query.sid;
        let sessionRes = await doctorApi.getSessionById(sid, tenant, accessToken,asrAuth);
        if(sessionRes) {
            let files = sessionRes.Files
            let MouthCaptures = files.filter(x => x.type === "MouthCapture")
            let ThroatCaptures = files.filter(x => x.type === "ThroatCapture")
            this.setState({MouthCaptures, ThroatCaptures})
        }
    }
    async checkQueryParam(query) {
        return Object.keys(query).length > 0 && !!query.pid && !!query.tenant
    }

    async openFullScreen(url, file, files) {
        let {MouthCaptures, ThroatCaptures} = this.state
        let {id} = this.props
        let language = 'en'
        let type = language === 'en' ? file['type'] : file['type'] === 'Mouth' ? 'Boca' : 'Garganta'
        let filters = ['Original','Brighter'].map((label,id) => {return{id: String(id+1), label}})
        let captures = file['type'] === "Mouth" ? MouthCaptures : ThroatCaptures
        let newCaptures = await MakeVideoFilter({url, file, type, files, filters, id, captures})
        if (!!newCaptures && newCaptures.length > 0) {
            if (file['type'] === "Mouth") {
                this.setState({MouthCaptures: newCaptures})
            } else {
                this.setState({ThroatCaptures: newCaptures})
            }
        }
    }
    async deleteSnapShot(fileID,type){
        let { MouthCaptures, ThroatCaptures} = this.state
        let query = queryString.parse(history.location.search);
        let allow = await this.checkQueryParam(query);
        let tenant = query.tenant;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth
        let check = await MakeErrorConfirm({title:"Delete Image", message:"Are you sure you want to remove this snapshot?"})
        if(check) {
            if (!this.state.delete) {
                this.setState({delete: true})
                let deleteSnap = await sessionsApi.deleteScreenshot(fileID, tenant, accessToken,asrAuth)
                if (deleteSnap) {
                    if (type === "MouthCapture") {
                        let newSnaps = MouthCaptures.filter(x => x.fileID !== fileID)
                        this.setState({MouthCaptures: newSnaps})
                    } else if (type === "ThroatCapture") {
                        let newSnaps = ThroatCaptures.filter(x => x.fileID !== fileID)
                        this.setState({ThroatCaptures: newSnaps})
                    }
                    this.setState({delete: false})
                }
            }
        }
    }

    getFileTimestamp(file) {
        let time = moment(file.createdAt?file.createdAt:file.updatedAt).format('HH:mm');
        let date = utils.getDateStringWithYear(new Date(file.createdAt?file.createdAt:file.updatedAt));
        return `${time} | ${date}`
    }
    async openImage(file){
        let {MouthCaptures, ThroatCaptures} = this.state
        let captures = file['type'] === "MouthCapture" ? MouthCaptures : ThroatCaptures
        let newCaptures = await MakeSnap({file, captures})
        if (file['type'] === "MouthCapture") {
            this.setState({MouthCaptures: newCaptures})
        } else {
            this.setState({ThroatCaptures: newCaptures})
        }
    }


    render() {
        let language = 'en'
        let exams = ['Mouth', 'Throat']
        let {files, t,onlineCall,tenant} = this.props
        let {MouthCaptures, ThroatCaptures} = this.state
        let isCaptures = MouthCaptures?.length > 0 || ThroatCaptures?.length > 0
        let gridItem = isCaptures && onlineCall ?'throat-grid-item-capture-online': isCaptures && !onlineCall ? 'throat-grid-item-capture' : 'throat-grid-item'
        let newThroatMouth = false
        if(files.length > 0){
            if(files.find(x=>x.type==="Mouth") && (files.find(x=>x.type==="Throat"))){
                newThroatMouth=true
            }
        }
        let containerStyle;
        if(newThroatMouth){
            containerStyle={left:'-4%',justifyContent: 'start'}
        }else{
            containerStyle={justifyContent: 'start'}
        }
        if (!files || files.length === 0)
            return  <div className={'exam-result-container throat-exam-container'}/>
        else {

            return (
                <>

                    <div className={'exam-result-container throat-exam-container'}>
                        {!newThroatMouth &&
                            <label style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}
                                className={'newthroatMouth-title'}>{`${t('physician.patient.exams.mouth')} & ${t('physician.patient.exams.throat')}`}</label>
                        }
                        { exams?.map(exam => {
                            let video = (files.find(x => {
                                let isExam = x.type === exam, hasFilters = !!files.filter, isCorrectFilter = x.filter === "Filter1"
                                if (hasFilters)
                                    if (isExam && isCorrectFilter) return exam
                                    else if (isExam) return exam
                            }))
                            let captures = exam === 'Mouth' ? MouthCaptures : ThroatCaptures

                            return (
                                <>
                                    {newThroatMouth &&
                                        <label style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}} className={'throatMouth-title'}>{t(`physician.patient.exams.${exam.toLowerCase()}`)}</label>
                                    }
                                    <div style={containerStyle} className={isCaptures && onlineCall ?'throat-exam-results-container-throat-online': isCaptures && !onlineCall? 'throat-exam-results-container-throat' : 'throat-exam-results-container '} >
                                        { !!video &&
                                            <div className={gridItem}>
                                                {/*<div className={'exam_title_throat'}>{language === 'en' ? video['type'] : video['type'] === 'Mouth' ? 'Boca' : 'Garganta'}</div>*/}
                                                <VideoPlayerThroat fileId={video['fileID']}
                                                                   openFullScreen={() => this.openFullScreen(video['downloadUrl'], video, files.filter(x => x.type === exam))}
                                                                   className={'throat-video'}
                                                                   url={video['downloadUrl']}
                                                                   fullscreenClass={'video-player-screen-control video-fullscreen-throat vp-fsc-throat-video'}/>
                                                <div className={'exam_footer_throat'}>{this.getFileTimestamp(video)}</div>
                                            </div>
                                        }
                                        {
                                            captures?.map((capture,index) => {
                                                return (
                                                    <div className={gridItem+"-"+index}>
                                                        <div
                                                            className={'exam_title_throat'}>{t(`physician.patient.exams.${exam.toLowerCase()}`) + " snapshot "}</div>
                                                        <img src={capture['downloadUrl']} alt={'capture'} className={'snapShotImage'} onClick={()=>this.openImage(capture)}/>
                                                        <div className={'exam_footer_throat'}>{this.getFileTimestamp(capture)}</div>
                                                        <SVGraphics svgname={'trashIcon'} className={!onlineCall ?'modal-close-btn image-trash-btn-video':'modal-close-btn image-trash-btn-video-online'} onClick={()=>this.deleteSnapShot(capture.fileID,capture.type)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </>
            )
        }
    }

}

export default ThroatExam
