import React, {useEffect, useState} from "react";
import './patientStyle/patientNew.css'
import {PatientHeader} from "./PatientHeader";
import useMediaQuery from "../../../utils/useMediaQuery";
import {PatientNewMain} from "./PatientNewMain";
import {PatientSidebar} from "./PatientSidebar";
import {PatientsNotes} from "./PatientsNotes";
import SVGraphics from "../../../assets/SVGraphics";
import moment from "moment";
import {history} from "../../../utils/history";
import queryString from "query-string";
import {doctorApi} from "../../../services/ApiService";
import MakeModal from "../../../component/modal/PatientSendExamModal";
import {PastExams} from "./PastExams";
import {LoadingSkelaton} from "../../../component/LoadingSkelaton";
import NewModal from "./NewModal";
import {ShareSessionModal} from "../../../component/modal/ShareSessionModal";
import Config from "../../../config/Config";
import {PatientProfile} from "./patientProfile/PatientProfile";
import {useGesture} from "react-use-gesture";
import {TechInfoModal} from "./TechInfoModal";
import {authenticationService} from "../../../services/auth/AuthenticationService";
import {Login} from "../../Login";
import utils from "../../../utils/utils";
import TokboxVideo from "./TokboxVideo";


export function PatientNew(props) {

    const [isNotes, setIsNotes] = useState(false);
    const [pid, setPid] = useState(null);
    const [patient, setPatient] = useState(null);
    const [patientFullData, setPatientFullData] = useState(null);
    const [tenant, setTenant] = useState(null);
    const [lastSessionID, setLastSessionId] = useState(null);
    const [exam, setExam] = useState(null);
    const [age, setAge] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [patientVideo, setPatientVideo] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrolled, setScrolled] = useState(false);
    const [prescriptionPopUp, setPrescriptionPopUp] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [pastExams, setPastExams] = useState(false);
    const [isPatientProfile, setIsPatientProfile] = useState(false);
    const [shareSession, setShareSession] = useState(false);
    const [scrollElement, setScrollElement] = useState(null);
    const [sessionUrl, setSessionUrl] = useState(null);
    const [screenHeight, setScreenHeight] = useState(null);
    const [AsrAuth, setAsrAuth] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [examPosition, setExamPosition] = useState('');
    const [sessions, setSessions] = useState([]);
    const [headerHeight, setHeaderHeight] = useState('4.5rem');
    const [menuPosition, setMenuPosition] = useState('220px');
    const [isTechInfo, setIsTechInfo] = useState(false);
    const [sharedByPatient, setSharedByPatient] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isOpenLogin, setIsOpenLogin] = useState(false);
    const [isOnlineCall, setIsOnlineCall] = useState(false);
    const [userData, setUserData] = useState("");
    const [currentOnlineSession, setCurrentOnlineSession] = useState(null);
    const [disableCall, setDisableCall] = useState(false);
    const [isPermissionsNotificationAllowed, setIsPermissionsNotificationAllowed] = useState(false);
    const [isCameraAllowed, setIsCameraAllowed] = useState(false);
    const [isMicrophoneAllowed, setIsMicrophoneAllowed] = useState(false);
    const [updateIncomingCall, setUpdateIncomingCall] = useState(false);
    const [fullScreenCall, setFullScreenCall] = useState(false);
    const [ mobileEndCall, setMobileEndCall] = useState(false);
    let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');
    const largeScreen = useMediaQuery('(min-width: 1440px) and (max-width:1919px)');
    // const menu = [{label: 'Latest Exam', name: 'latestExam'}, {label: 'Past Exams', name: 'pastExams'}]
    const menu = [{label: 'Latest Exam', name: 'latestExam'}, {label: 'Past Exams', name: 'pastExams'}, {label: 'Patient Profile', name: 'patientProfile'},isUserLoggedIn ? {label: 'Log out', name: 'logOut'} : {label: 'Login as physician', name: 'login'}]
    let opentokRef = null
    let onlineSessionRef = {}
    const bind = useGesture({
        onScroll: ({ xy: [_, y] }) => {
            if(isMobilScreen) {
                let _header = document.getElementById('patient-header')
                if (_header) {

                    const elementRect = _header.getBoundingClientRect(); // Get the element's position and size
                    const elementEndY = elementRect.top + elementRect.height; // Calculate the end Y position
                    if(y===0){
                        setMenuPosition('220px')
                        setHeaderHeight('14.5rem')
                    }
                       else if(elementRect.top<0 && elementRect.top>-199 && elementEndY>32){
                     setMenuPosition(elementEndY + 'px')

                 }else if(elementRect.top<-199){
                            setMenuPosition('4.5rem')
                            setHeaderHeight('4.5rem')
                        }
                }else {
                    setMenuPosition('220px')
                    setHeaderHeight('14.5rem')
                }
            }
        },
    });

    // const xlargeScreen = useMediaQuery('(min-width: 1920px)');
    const mobileTablet = isSmallScreen || isMobilScreen
    // const touchPosition = useRef({ x: 0, y: 0 });


    useEffect(async () => {
        let isAuthenticated = Boolean(sessionStorage.getItem('userLoggedIn') === 'true');
      // sessionStorage.setItem('userLoggedIn','true');
        let user = authenticationService.currentUserValue
        if(isAuthenticated){
            setIsUserLoggedIn(true)
            setUserData(authenticationService.currentUserValue?.profile)
        }

        let search = !history.location.search || history.location.search === {} ? window.location.search : history.location.search
        let query = queryString.parse(search);
        let pid = query?.pid;
        let sid = query?.sid;
        let isSharedByPatient = query?.isSharedByPatient
        let tenant = query?.tenant;
        let _accessToken = (query?.accessToken)?.split(" ").join("+")
        let asrAuth = query?.asrAuth
        setAsrAuth(asrAuth)
        setAccessToken(_accessToken)
        // if(!isSharedByPatient){
        //     props.history.push({
        //         tenant: tenant,
        //         pathname: `/Login`,
        //     })
        // }
        // window.addEventListener("scroll", handleScroll);
        if (window.innerHeight < 600) {
            setScreenHeight(55)
        } else if (window.innerHeight > 600 && window.innerHeight < 630) {
            setScreenHeight(56)
        } else if (window.innerHeight < 700) {
            setScreenHeight(56)
        } else if (window.innerHeight > 700 && window.innerHeight < 750) {
            setScreenHeight(60)
        } else if (window.innerHeight >= 750) {
            setScreenHeight(59)
        } else {
            setScreenHeight(70)
        }

        localStorage.setItem('unit', Config.region === 'eu' ? 'c' : 'f')
        let currentPage = localStorage.getItem('currentPage')

        if(isSmallScreen){
            setHeaderHeight("8.25rem")
        }else if(isMobilScreen){
            setHeaderHeight("12.5rem")
        }

        // console.log("currentPage",currentPage)
        // let hasTouchScreen = false;
        // if ("maxTouchPoints" in navigator) {
        //     hasTouchScreen = navigator.maxTouchPoints > 0;
        // } else if ("msMaxTouchPoints" in navigator) {
        //     hasTouchScreen = navigator.msMaxTouchPoints > 0;
        // } else {
        //     const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        //     if (mQ && mQ.media === "(pointer:coarse)") {
        //         hasTouchScreen = !!mQ.matches;
        //     } else if ("orientation" in window) {
        //         hasTouchScreen = true; // deprecated, but good fallback
        //     } else {
        //         // Only as a last resort, fall back to user agent sniffing
        //         let UA = navigator.userAgent;
        //         hasTouchScreen =
        //             /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        //             /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        //     }
        // }
        //
        // if (hasTouchScreen) {
        //     setDeviceType("Mobile");
        // } else {
        //     setDeviceType("Desktop");
        // }

        const getPatientData = async (pid) => {
            let patient = await doctorApi.getPatientById(pid, tenant, _accessToken, asrAuth);

            if (patient && !patient.error) {
                if(isSharedByPatient){
                    openModal(tenant).then()
                    setSharedByPatient(true)
                }
                setPatientFullData(patient)
                let _age = moment.duration(moment(new Date())?.diff(new Date(patient?.birthday)));
                setAge(_age)
                setProfileImage(patient?.photoUrl)
                let _patient = {
                    fullName: patient['firstName'] + ' ' + patient['lastName'],
                    idNumber: patient['idNumber'] ? patient['idNumber'] : "_",
                    tenantName: tenant,
                    age: `${_age.years()} y, ${_age.months()} m`,
                    birthday: moment(patient['birthday']).format('MMM DD, YYYY'),
                    height: patient['height'] && patient["heightUnit"] ? patient['height'] + " "+ patient["heightUnit"] : "_",
                    weight: patient['weight'] && patient['weightUnit'] ? patient['weight'] + " "+ patient['weightUnit'] : "_",
                    gender: patient['gender'].toUpperCase()
                }
                setPatient(_patient)
                getData(pid, tenant, _accessToken, asrAuth, sid).then();

            } else if (patient && patient.error && patient.error === 'Unauthorized') {
                props.history.push({
                    tenant: tenant,
                    pathname: `/expiredLink`,
                })
            }
        }
        getPatientData(pid).then()
        // if(localStorage.getItem('sessionId')){
        //     sid=localStorage.getItem('sessionId')
        // }

        // return ()=>{
        //     localStorage.setItem('sessionId',null)
        // }
        return () => {
            localStorage.setItem('currentPage', null)
        }
        if(authenticationService.currentUserValue){
            if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)){
                setIsPermissionsNotificationAllowed(!authenticationService.currentUserValue.profile.lastLoginAt)

                navigator.permissions.query({name: 'camera'}).then(function (permissionStatus) {
                    setIsCameraAllowed( permissionStatus.state === "granted")

                    permissionStatus.onchange = () => {
                        setIsPermissionsNotificationAllowed(false)
                    }
                })
                navigator.permissions.query({name: 'microphone'}).then(function (permissionStatus) {
                    setIsMicrophoneAllowed(permissionStatus.state === "granted")

                    permissionStatus.onchange = () => {
                        setIsPermissionsNotificationAllowed(false)
                    }
                })
            }
        }




    }, [])
    const sendVisitSummary = () => {
        getData(pid, tenant, accessToken, AsrAuth, exam.sessionID).then();
    }
    const addScreenshot = () => {
        getData(pid, tenant, accessToken, AsrAuth, exam.sessionID).then();
    }



    // const handleTouchStart = (event) => {
    //     console.log("window.pageYOffset",window)
    //     const touch = event.touches[0];
    //     console.log("touchPosition.current",touch)
    //     touchPosition.current = { x: touch.clientX, y: touch.clientY };
    // };

    // useEffect(() => {
    //     console.log("deviceType",deviceType)
    //     if(deviceType==="Mobile") {
    //         console.log("************************")
    //
    //         // let element = document.getElementById('patientCard')
    //         function handleTouchMove(e) {
    //
    //             setTimeout(() => {
    //                 const touch = e.changedTouches[0];
    //                 touchPosition.current = { x: touch?.pageY, y: touch?.pageY };
    //
    //                 console.log("Scrolling...", window.pageYOffset);
    //                 console.log("eeeeee...",e);
    //                 // setScrollPosition(touchPosition.current.y);
    //                 setScrollPosition(window.pageYOffset);
    //             }, 0);
    //         }
    //
    //         window.addEventListener("touchend", handleTouchMove, {
    //             passive: true, // improves performance on mobile devices
    //         });
    //
    //         return () => {
    //             window.removeEventListener("touchend", handleTouchMove);
    //         };
    //     }
    //     //     const onScroll = (e) => {
    //     //
    //     //         let touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
    //     //         console.log("Scrolling...",touch.pageY);
    //     //         setScrollPosition(touch.pageY);
    //     //     };
    //     //
    //     //     window.addEventListener('touchstart', onScroll);
    //     //
    //     //     return () => window.removeEventListener('touchstart', onScroll);
    //     // }
    //
    // }, []);


    const openModal = async (tenant) => {
        await MakeModal({tenant: tenant})
    }

    const getData = async (pid, tenant, accessToken, asrAuth, sid) => {
        let Exam
        if (sid) {
            Exam = await doctorApi.getSessionById(sid, tenant, accessToken, asrAuth)
        } else {
            let Exams = await doctorApi.getPatientSessionsById(pid, tenant, true, accessToken, asrAuth)
            if (Exams && Exams.length > 0) {
                setLastSessionId(Exams[0]?.sessionID)
                Exam = await doctorApi.getSessionById(Exams[0]?.sessionID, tenant, accessToken, asrAuth)
                setExamPosition('last')
            }
        }
        let examsArray = []
        if (Exam) {
            examsArray = Exam['SessionExams']
            const examsArr = ["Body_Temperature", "Saturation", "BloodPressure", "Heart", "Lungs", "Abdomen", "Ears", "Throat", "Skin", "Covid19", "Strep"]
            examsArray.sort((a, b) => examsArr.indexOf(a.exam) - examsArr.indexOf(b.exam));
            Exam['SessionExams'] = examsArray
            let _videoPatient = Exam?.Files.find(x => x.type === "PatientVideo")
            setPatientVideo(_videoPatient)
        }
        setExam(Exam ? Exam : {})
        setPid(pid)
        // setPatient(patient)
        setTenant(tenant)


    }


    const scrollToView = (e) => {
        setScrollElement(e)
    }
    const onRowClick = async (data) => {
        const scrollableElement = document.getElementById('patientCard');
        // console.log()
        // scrollableElement?.scrollIntoView({ behavior: "smooth", block: 'start' });
        // scrollableElement?.scrollIntoView({ behavior: "smooth", block: 'start' });
        scrollableElement.scrollTo({
            top: 0,
            behavior: 'instant'
        });
        await setExam(null)

        await getData(data.patientID, tenant, accessToken, AsrAuth, data.sessionID).then();
        setPastExams(false)
        localStorage.setItem('currentPage', 'session')

        // localStorage.setItem('sessionId',data.sessionID)

    }
    const getShareLink = async () => {
        let link = await doctorApi.getSessionLink(pid, tenant, accessToken, AsrAuth)
        if (link) {
            setSessionUrl(link.iframeUrl)
        }
        setShareSession(true)
        // let url = await patientsApi.sharePatient(patient.patientID)
        // if (url.hasOwnProperty('iframeUrl'))
        //     await MakeSharePatient({url: url.iframeUrl, t: t})
    };
    const headerMenuOnClick = async (type) => {
        setOpenMenu(false)
        switch (type) {
            case 'pastExams': {
                setPastExams(true)
                setIsPatientProfile(false)
                localStorage.setItem('currentPage', 'pastExams')
                break;
            }
            case 'patientProfile': {
                setPastExams(false)
                setIsPatientProfile(true)
                localStorage.setItem('currentPage', 'patientProfile')
                break;
            }
            case 'latestExam': {
                setPastExams(false)
                setIsPatientProfile(false)
                localStorage.setItem('currentPage', 'latestExam')
                setExamPosition('last')
                if (lastSessionID) {
                    getData(pid, tenant, accessToken, AsrAuth, lastSessionID).then()
                } else {
                    let Exams = await doctorApi.getPatientSessionsById(pid, tenant, true, accessToken, AsrAuth)
                    if (Exams && Exams.length > 0) {
                        getData(pid, tenant, accessToken, AsrAuth, Exams[0]?.sessionID).then()
                    }
                }
                break;
            }
            case 'logOut': {
                    setIsUserLoggedIn(false)
                await authenticationService.logout()
                break;
            }
            case 'login': {
                await login()
                break;
            }
            default : {
                setPastExams(false)
                setIsPatientProfile(false)
            }
        }
    }
    const backToSession = () => {
        headerMenuOnClick('latestExam').then()
    }
    const returnHeader = () => {
        return <PatientHeader
            isUserLoggedIn={isUserLoggedIn}
            endCallFromMobile={() => endCall()}
            isPermissionsNotificationAllowed={isPermissionsNotificationAllowed}
            patient={patient}
            profileImage={profileImage}
            mainScrollPosition={scrollPosition}
            scrolled={scrolled}
            isMenuOpen={openMenu}
            openMenu={() => setOpenMenu(!openMenu)}
            isOnlineCall={isOnlineCall}
            sharedByPatient={sharedByPatient}
            headerMenuOnClick={headerMenuOnClick}
            callButtonCLicked={mobileTablet ? ()=>callButtonCLicked():null}
            shareRecords={() => getShareLink()}
            // endCallFromMobile={mobileTablet? () => endCall():null}

        />
    }
    const navigate = (type) => {
        let _sessions =[...sessions]
        if (_sessions.length === 0) {

            const getSessionsData = async () => {
                let exams = await doctorApi.getPatientSessionsById(pid, tenant, true, accessToken, AsrAuth)
                if (exams) {
                    setSessions(exams)
                    _sessions=exams
                }
            }
            getSessionsData().then()
        }
        let current = _sessions.findIndex(x => x.sessionID === exam.sessionID)
        if (type === 'next') {
            if (current > 0) {
                setExamPosition('')
                let nextSession = _sessions[current - 1]
                if (nextSession) {
                    getData(pid, tenant, accessToken, AsrAuth, nextSession.sessionID).then()
                }
            } else {
                setExamPosition('last')
            }
        } else if (type === 'prev') {
            if (current >= 0 && current !==_sessions.length-1) {
                setExamPosition('')
                let prevSession = _sessions[current + 1]
                if (prevSession) {
                    getData(pid, tenant, accessToken, AsrAuth, prevSession.sessionID).then()
                }
            } else if(current ===_sessions.length-1){
                setExamPosition('first')
            }
        }
    }
    const openTechInfo = () => {
            setIsTechInfo(!isTechInfo)
    }
    const closeTechInfo = () => {
        setIsTechInfo(false)
    }
    const login = async () => {
        setIsOpenLogin(true)
    }
    const loginFromModal = () => {
        setIsUserLoggedIn(true)
        // setUserProperties
        let userData = authenticationService.currentUserValue
        setUserData(userData['profile'])
        authenticationService.setUserProperties(userData['profile'])
        closeLogin()
    }
    const closeLogin = () => {
        setIsOpenLogin(false)
    }
    const createOnlineSession = async(pid) => {
        let onlineSession = await doctorApi.createOnlineSession(pid);
        setDisableCall(true)
        await setCurrentOnlineSession(onlineSession);
        await setIsOnlineCall(true)

    }
    const callButtonCLicked = async () => {
        setFullScreenCall(mobileTablet?true:false)
        let onlineSession = currentOnlineSession
        let isCameraAllowed = false
        let isMicrophoneAllowed = false
        let doesQueryExists = navigator && navigator.hasOwnProperty('permissions') && (navigator.permissions).hasOwnProperty('query')
        if (doesQueryExists) {
            try {
                isCameraAllowed =  (await navigator.permissions.query({name: 'camera'})).state === 'granted'
                isMicrophoneAllowed =  (await navigator.permissions.query({name: 'microphone'})).state === 'granted'
            } catch (err) {
                console.error(err)
            }
            if (isCameraAllowed && isMicrophoneAllowed){
                !onlineSession ? await createOnlineSession(pid) :
                    answerIncomingCall(patient['onlineSession'], pid)
                setDisableCall(true)
            } else {
               setIsPermissionsNotificationAllowed(true)
            }
        } else {
            !onlineSession ? await createOnlineSession(pid) :
              answerIncomingCall(onlineSession, pid)
            setDisableCall(false)
        }
    }
    const answerIncomingCall = (onlineSession, pid) => {
            setCurrentOnlineSession(onlineSession);
        setDisableCall(false)
       IncomingCallUpdate(pid, null);

    }
    const IncomingCallUpdate = (pid,type) => {
        let session = exam;
        // let pid = action.pid;
        let patientsData = patientFullData;
            if (!!session && session !== null) {
                patientsData['sessions'] = [session]
        }
        setUpdateIncomingCall(patientsData)
    }

    const receiveSignal = (signal) => {
        console.log("signal",signal)
        onlineSessionRef.receiveSignal(signal)
    }
    const updateSession = (pid,session) => {
        let patientsData = patientFullData;
        setPatientFullData(patientsData)
    }

    const sendSignal = async(obj) => {
        let signal = utils.flatten(obj)
        if (!opentokRef) return;
        let stream = opentokRef?.streams[0]
        if (stream){
            await opentokRef.session.signal({
                    to: stream.connection,
                    data: JSON.stringify(signal),
                    // type: "textMessage"
                }, err => {
                    if (err) {
                        console.error(err);
                    }
                }
            );
        }
    }
    const endCall = () => {
       setCurrentOnlineSession(null)
        setIsOnlineCall(false)
        if(mobileTablet){
            setMobileEndCall(true)
            setMobileEndCall(false)
        }
    }
    const getTokBox = () => {
        return   <TokboxVideo setSH={sessionHelper => opentokRef = sessionHelper}
                              sendSignal={signal => sendSignal(signal)}
                              mobileEndCall={mobileEndCall}
                              isPermissionsNotificationAllowed={isPermissionsNotificationAllowed}
            // updateSession={(pid, session) => props.updateSession(pid, session)}
                              updateSession={(pid, session) => updateSession(pid, session)}
                              getSessionData={{tenant: tenant, accessToken: accessToken, asrAuth: AsrAuth}}
                              endCall={() => endCall()}
                              setFullScreen={()=>setFullScreenCall(true)}
            // receiveSignal={signal => this.props.receiveSignal(signal)}
                              receiveSignal={signal => props.receiveSignal(signal)}
                              isMobile={mobileTablet}
                              fullScreenCall={fullScreenCall}
                              session={currentOnlineSession}
                              exam={exam}

        />
    }

    const displayNotes = !sharedByPatient || isUserLoggedIn


    return (
        <div className={'patient-Container-app'}
             id={'patient-container'}
             style={{backgroundColor: pastExams || isPatientProfile ? '#F5F5F7' : isNotes ? '#FFFFFF' : '#E4E4E6'}}>
            {mobileTablet && isOnlineCall &&
            <div className={mobileTablet ? fullScreenCall?'tokbox-chat-container-mobile': 'tokbox-chat-container-mobile-small': 'tokbox-chat-container'} >
                {fullScreenCall &&
                    <div className={'call-back-to-exams'}   onClick={() => setFullScreenCall(false)}>
                        <SVGraphics svgname={'back-left-white'} className={'back-circle-icon'}
                                  />
                        <div className={'back-to-exams-label'}>BACK TO EXAMS</div>
                    </div>
                }
                {isOnlineCall && mobileTablet &&
                    getTokBox()
                }

            </div>
            }
                    <>
                        {isOnlineCall && !mobileTablet &&

                        <div className={'tokbox-chat-container-web'} style={{left:isPatientProfile || pastExams?'1%':'14.2%'}}>
                            {getTokBox()}

                        </div>
                        }
                        {mobileTablet && openMenu &&
                        <div className={`header-menu header-menu-mobile `}>
                            <div className={'menu-title-mobile'}>
                                <div style={{marginLeft: '2rem'}}>Menu</div>
                                <SVGraphics svgname={'close-black'} className={' modal-close-btn-menu'}
                                            onClick={() => setOpenMenu(false)}/>
                            </div>
                            {menu.map((m, index) => {
                                return <div className={'header-menu-label header-menu-label-mobile'}
                                            key={m.name}
                                            onClick={() => headerMenuOnClick(m.name)}
                                            style={{
                                                borderBottom: '1px solid #F0EFF2',
                                                color: m.name === 'logOut' ? '#A51414' : '#2E2E2E'
                                            }}
                                >
                                    <div style={{paddingLeft: '2rem'}}>{m.label}</div>
                                </div>
                            })
                            }
                            <div style={{paddingTop: '80%', marginBottom: '1rem'}}>
                                {isUserLoggedIn &&
                                <div style={{paddingLeft: '2rem'}}
                                     className={'header-menu-physician'}>{`${userData?.firstName ? userData?.firstName : ""} ${userData?.lastName ? userData?.lastName + ',' : ""} ${userData?.jobTitle ? userData?.jobTitle : ""}`}</div>
                                }
                                <div className={'menuVersion'}
                                     style={{display: 'flex'}}>Beta {Config.version} ({Config.MHDVersion})
                                </div>
                            </div>
                        </div>
                        }

                        {
                            pastExams ?
                                <>
                                    <div className={'patientContainer'} id={'patientCard'}>
                                        <div
                                            className={`header ${isSmallScreen ? "headerSmall" : isMobilScreen ? 'mobileHeader' : ""}`}
                                            style={{height: headerHeight}}
                                        >
                                            {returnHeader()}
                                        </div>
                                        <PastExams
                                            onRowClick={onRowClick}
                                            closeHeaderMenu={() => setOpenMenu(false)}
                                            backToSession={() => backToSession()}
                                            isOnLineCall={isOnlineCall}
                                        />
                                    </div>
                                </>
                                :
                                isPatientProfile ?
                                    <>
                                        <div className={`patientContainer`} id={'patientCard'}>
                                            <div
                                                className={`header ${isSmallScreen ? "headerSmall" : isMobilScreen ? 'mobileHeader' : ""}`}
                                                style={{height: headerHeight}}
                                            >
                                                {returnHeader()}
                                            </div>
                                            <PatientProfile
                                                closeHeaderMenu={() => setOpenMenu(false)}
                                                patient={patientFullData}
                                                backToSession={() => backToSession()}
                                                age={age.years()}
                                                isOnLineCall={isOnlineCall}
                                            />
                                        </div>
                                    </>
                                    :
                                    exam ? (
                                            <>
                                                {!isNotes ? (
                                                    <div className={'patientContainer'} id={'patientCard'}  {...bind()}>
                                                        <div
                                                            id={'patient-header'}
                                                            className={`header ${isSmallScreen ? "headerSmall" : isMobilScreen ? 'mobileHeader' : ""}`}
                                                            style={{height: headerHeight}}
                                                        >
                                                            {returnHeader()}
                                                        </div>
                                                        {mobileTablet &&
                                                        <div className={`menuTablet ${isMobilScreen ? 'menuMobile' : ""}`}
                                                             style={{top: isMobilScreen ? menuPosition : '10rem'}}
                                                        >
                                                            <PatientSidebar exam={exam}
                                                                            isUserLoggedIn={isUserLoggedIn}
                                                                            getShareLink={() => getShareLink()}
                                                                            scrollToView={(e) => scrollToView(e)}
                                                                            isOnlineCall={isOnlineCall}
                                                                            sessionId={exam.sessionID}
                                                                            prescriptionPopup={() => setPrescriptionPopUp(true)}/>
                                                        </div>
                                                        }
                                                        <div className={"grid-container"}
                                                             style={{height: isMobilScreen ? screenHeight + '%' : isSmallScreen ? '79%' : '92%'}}
                                                             onClick={() => setOpenMenu(false)}
                                                        >
                                                            {!mobileTablet &&
                                                            <div className={`menu `}>
                                                                <PatientSidebar exam={exam}
                                                                                callButtonCLicked={() => callButtonCLicked()}
                                                                                isUserLoggedIn={isUserLoggedIn}
                                                                                getShareLink={() => getShareLink()}
                                                                                scrollToView={(e) => scrollToView(e)}
                                                                                sharedByPatient={sharedByPatient}
                                                                                sessionId={exam.sessionID}
                                                                                sendSignal={signal => sendSignal(signal)}
                                                                                isOnlineCall={isOnlineCall}
                                                                                updateSession={(pid, session) => updateSession(pid, session)}
                                                                                setSH={sessionHelper => opentokRef = sessionHelper}
                                                                                receiveSignal={receiveSignal}
                                                                                session={currentOnlineSession}
                                                                                endCall={() => endCall()}
                                                                                isPermissionsNotificationAllowed={isPermissionsNotificationAllowed}
                                                                                getSessionData={{
                                                                                    tenant: tenant,
                                                                                    accessToken: accessToken,
                                                                                    asrAuth: AsrAuth
                                                                                }}
                                                            />

                                                            </div>
                                                            }
                                                            <div
                                                                className={`${isMobilScreen ? 'main-mobile' : isSmallScreen ? 'main-tablet' : 'main'}`}
                                                            >
                                                                <PatientNewMain age={age.years()} exam={exam}
                                                                                gender={patient?.gender}
                                                                                patientVideo={patientVideo}
                                                                                scrollElement={scrollElement}
                                                                                isUserLoggedIn={isUserLoggedIn}
                                                                                setScrollNone={() => setScrollElement(null)}
                                                                                pastExams={() => setPastExams(true)}
                                                                                navigate={navigate}
                                                                                tenant={tenant}
                                                                                login={() => login()}
                                                                                examPosition={examPosition}
                                                                                sharedByPatient={sharedByPatient}
                                                                                openTechInfo={() => openTechInfo()}
                                                                                addScreenshot={() => addScreenshot()}
                                                                />
                                                            </div>

                                                            {!mobileTablet && displayNotes &&
                                                            <div className={`note`}>
                                                                <PatientsNotes exam={exam} patient={patient}
                                                                               isUserLoggedIn={isUserLoggedIn}
                                                                               sendVisitSummary={() => sendVisitSummary()}/>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`${isMobilScreen ? 'notes-mobile' : isSmallScreen ? 'notes-tablet' : ''}`}>
                                                        <PatientsNotes exam={exam} isMobile={true}
                                                                       isUserLoggedIn={isUserLoggedIn}
                                                                       closeNotes={() => setIsNotes(false)}
                                                                       patient={patient}
                                                                       sendVisitSummary={() => sendVisitSummary()}/>
                                                    </div>)}
                                                {mobileTablet && !isNotes && displayNotes &&
                                                <div className={'notes-mobile-button'} onClick={() => setIsNotes(true)}>
                                                    <SVGraphics svgname={'notes'} className={'notes-mobile-button-icon'}/>
                                                </div>
                                                }
                                            </>) :
                                        <LoadingSkelaton/>
                        }
                        {isTechInfo && mobileTablet &&
                        < TechInfoModal
                            exam={exam}
                            closeTechInfo={() => closeTechInfo()}
                        />
                        }
                        {prescriptionPopUp &&
                        <NewModal
                            title={'Prescription Required'}
                            message={'Oximeter prescription required to perform Heart rate & saturation exam'}
                            closeModal={() => setPrescriptionPopUp(false)}
                            show={prescriptionPopUp}
                            dialogClassName={'prescription-modal'}

                        />
                        }
                        {shareSession &&
                        <ShareSessionModal
                            url={sessionUrl}
                            show={shareSession}
                            closeModal={() => setShareSession(false)}
                        />}
                        {isOpenLogin &&
                        <Login
                            tenant={tenant}
                            show={isOpenLogin}
                            closeModal={() => closeLogin()}
                            login={() => loginFromModal()}
                        />
                        }
                    </>
        </div>
    )
}