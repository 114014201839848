import React from "react";
import "../patientStyle/notes.css"

export function TemplateCard(props) {

    return (
        <div className={'notes-template-card-container'}>
            <div className={'notes-template-card-container-title'}>
                <div className={'notes-template-card-title'}>{props.title}</div>
            </div>
            {props.body}

        </div>
    )
}
