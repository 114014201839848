import './patientStyle/techInfo.css'
import React, {useEffect, useState} from "react";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SVGraphics from "../../../assets/SVGraphics";
import useMediaQuery from "../../../utils/useMediaQuery";



export function TechInfoModal(props) {
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mobileTablet = isSmallScreen || isMobilScreen
    const [techInfo, setTechInfo] = useState([]);


    useEffect(()=>{
        let techInfoData = props.exam.SessionTechInfo
        if(techInfoData){
            let _techInfo = [
                {label:'exam start date', value:moment(props.exam.examStartTime).format('MMM DD, YYYY')},
                {label:'Exam start time', value:moment(props.exam.examStartTime).format('hh:mm A')},
                {label:'Otoscope FW', value:getValue(techInfoData.ng_otoscope_device_sn)},
                {label:'OS', value:getValue(techInfoData.os)},
                {label:'Mobile Device', value:getValue(techInfoData.device)},
                {label:'NNG device FW', value:getValue(techInfoData.ng_device_fw)},
                {label:'NNG Device SN', value:getValue(techInfoData.ng_device_sn)},
                {label:'Device Type', value:getValue(techInfoData.ng_device_model)},
            ]
            setTechInfo(_techInfo)
        }

    },[])
    const getValue = (value) =>{
        return value==='none'||!value?'-':value
    }
    return (
        <div className={mobileTablet?'tech-info-container-mobileTablet':'tech-info-container'}>


                <div className={'tech-info-title'}>
                    {mobileTablet &&   <SVGraphics svgname={'back-arrow'} className={'tech-info-back-btn'} onClick={props.closeTechInfo}/>}
                    Tech Info
                    {!mobileTablet &&
                    <SVGraphics svgname={'close-black'} className={'tech-info-close-btn'} onClick={props.closeTechInfo}/>
                    }
                </div>
            {
                mobileTablet &&
                <div className={'tech-info-path'}></div>
            }

            <div className={mobileTablet?'tech-info-content-mobileTablet':'tech-info-content'}>
                {
                    techInfo?.map(info=>{
                        return <div className={'tech-info-data'} key={info.label}>
                                <div className={'tech-info-data-label'}>{info.label}</div>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{show: 250, hide: 300}}
                                overlay={<Tooltip id="prev" {...props}>
                                    {info.value}
                                </Tooltip>}>
                                <div className={'tech-info-data-value'}>{info.value}</div>
                            </OverlayTrigger>

                            </div>
                    })
                }



            </div>
            <div className={'tech-info-data-label'} style={{marginBottom:'0.5rem'}}>EXAMS</div>
            <div className={'tech-info-exam-icons'}>
                {
                    props.exam.exams.map(exam => {
                        if (exam !== 'Heart_Rate') {
                            return <SVGraphics key={exam} svgname={exam} className={'session-history-exam-icon'}/>
                        }
                    })
                }
            </div>
        </div>
    )
}