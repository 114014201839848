import front_men_head from './men/front/Head.svg'
import front_men_neck from './men/front/Neck.svg'
import front_men_chest from './men/front/Chest.svg'
import front_men_abdomen from './men/front/Abdomen.svg'
import front_men_genitals from './men/front/Genitals.svg'
import front_men_arm_right from './men/front/Arm_right.svg'
import front_men_arm_left from './men/front/Arm_left.svg'
import front_men_hand_left from './men/front/Hand_left.svg'
import front_men_hand_right from './men/front/Hand_right.svg'
import front_men_leg_right from './men/front/Leg_right.svg'
import front_men_leg_left from './men/front/Leg_left.svg'
import front_men_foot_right from './men/front/Foot_right.svg'
import front_men_foot_left from './men/front/Foot_left.svg'
import front_women_head from './women/front/Head.svg'
import front_women_neck from './women/front/Neck.svg'
import front_women_chest from './women/front/Chest.svg'
import front_women_abdomen from './women/front/Abdomen.svg'
import front_women_genitals from './women/front/Genitals.svg'
import front_women_arm_right from './women/front/Arm_right.svg'
import front_women_arm_left from './women/front/Arm_left.svg'
import front_women_hand_left from './women/front/Hand_left.svg'
import front_women_hand_right from './women/front/Hand_right.svg'
import front_women_leg_right from './women/front/Leg_right.svg'
import front_women_leg_left from './women/front/Leg_left.svg'
import front_women_foot_right from './women/front/Foot_right.svg'
import front_women_foot_left from './women/front/Foot_left.svg'
import front_child_head from './child/front/Head.svg'
import front_child_neck from './child/front/Neck.svg'
import front_child_chest from './child/front/Chest.svg'
import front_child_abdomen from './child/front/Abdomen.svg'
import front_child_genitals from './child/front/Genitals.svg'
import front_child_arm_right from './child/front/Arm_right.svg'
import front_child_arm_left from './child/front/Arm_left.svg'
import front_child_hand_left from './child/front/Hand_left.svg'
import front_child_hand_right from './child/front/Hand_right.svg'
import front_child_leg_right from './child/front/Leg_right.svg'
import front_child_leg_left from './child/front/Leg_left.svg'
import front_child_foot_right from './child/front/Foot_right.svg'
import front_child_foot_left from './child/front/Foot_left.svg'
import back_men_head from './men/back/Head.svg'
import back_men_neck from './men/back/Neck.svg'
import back_men_back from './men/back/Back.svg'
import back_men_buttock from './men/back/Buttock.svg'
import back_men_arm_right from './men/back/Arm_right.svg'
import back_men_arm_left from './men/back/Arm_left.svg'
import back_men_hand_left from './men/back/Hand_left.svg'
import back_men_hand_right from './men/back/Hand_right.svg'
import back_men_leg_right from './men/back/Leg_right.svg'
import back_men_leg_left from './men/back/Leg_left.svg'
import back_men_foot_right from './men/back/Foot_right.svg'
import back_men_foot_left from './men/back/Foot_left.svg'
import back_women_head from './women/back/Head.svg'
import back_women_neck from './women/back/Neck.svg'
import back_women_back from './women/back/Back.svg'
import back_women_buttock from './women/back/Buttock.svg'
import back_women_arm_right from './women/back/Arm_right.svg'
import back_women_arm_left from './women/back/Arm_left.svg'
import back_women_hand_left from './women/back/Hand_left.svg'
import back_women_hand_right from './women/back/Hand_right.svg'
import back_women_leg_right from './women/back/Leg_right.svg'
import back_women_leg_left from './women/back/Leg_left.svg'
import back_women_foot_right from './women/back/Foot_right.svg'
import back_women_foot_left from './women/back/Foot_left.svg'
import back_child_head from './child/back/Head.svg'
import back_child_neck from './child/back/Neck.svg'
import back_child_back from './child/back/Back.svg'
import back_child_buttock from './child/back/Buttock.svg'
import back_child_arm_right from './child/back/Arm_right.svg'
import back_child_arm_left from './child/back/Arm_left.svg'
import back_child_hand_left from './child/back/Hand_left.svg'
import back_child_hand_right from './child/back/Hand_right.svg'
import back_child_leg_right from './child/back/Leg_right.svg'
import back_child_leg_left from './child/back/Leg_left.svg'
import back_child_foot_right from './child/back/Foot_right.svg'
import back_child_foot_left from './child/back/Foot_left.svg'

let SkinBodyImages = {
    Skin:{
        'MALE':{
            Front:{
                Face:front_men_head,
                Neck:front_men_neck,
                Chest:front_men_chest,
                Abdomen:front_men_abdomen,
                Genitals:front_men_genitals,
                Arm_right:front_men_arm_right,
                Arm_left:front_men_arm_left,
                Hand_right:front_men_hand_right,
                Hand_left:front_men_hand_left,
                Leg_right:front_men_leg_right,
                Leg_left:front_men_leg_left,
                Foot_right:front_men_foot_right,
                Foot_left:front_men_foot_left,
            },
            Back:{
                Head:back_men_head,
                Neck:back_men_neck,
                Back:back_men_back,
                Buttocks:back_men_buttock,
                Arm_right:back_men_arm_right,
                Arm_left:back_men_arm_left,
                Hand_right:back_men_hand_right,
                Hand_left:back_men_hand_left,
                Leg_right:back_men_leg_right,
                Leg_left:back_men_leg_left,
                Foot_right:back_men_foot_right,
                Foot_left:back_men_foot_left,
            }
        },
        'FEMALE':{
            Front:{
                Face:front_women_head,
                Neck:front_women_neck,
                Chest:front_women_chest,
                Abdomen:front_women_abdomen,
                Genitals:front_women_genitals,
                Arm_right:front_women_arm_right,
                Arm_left:front_women_arm_left,
                Hand_right:front_women_hand_right,
                Hand_left:front_women_hand_left,
                Leg_right:front_women_leg_right,
                Leg_left:front_women_leg_left,
                Foot_right:front_women_foot_right,
                Foot_left:front_women_foot_left,
            },
            Back:{
                Head:back_women_head,
                Neck:back_women_neck,
                Back:back_women_back,
                buttocks:back_women_buttock,
                Arm_right:back_women_arm_right,
                Arm_left:back_women_arm_left,
                Hand_right:back_women_hand_right,
                Hand_left:back_women_hand_left,
                Leg_right:back_women_leg_right,
                Leg_left:back_women_leg_left,
                Foot_right:back_women_foot_right,
                Foot_left:back_women_foot_left,
            }
        },
        'CHILD':{
            Front:{
                Face:front_child_head,
                Neck:front_child_neck,
                Chest:front_child_chest,
                Abdomen:front_child_abdomen,
                Genitals:front_child_genitals,
                Arm_right:front_child_arm_right,
                Arm_left:front_child_arm_left,
                Hand_right:front_child_hand_right,
                Hand_left:front_child_hand_left,
                Leg_right:front_child_leg_right,
                Leg_left:front_child_leg_left,
                Foot_right:front_child_foot_right,
                Foot_left:front_child_foot_left,
            },
            Back:{
                Head:back_child_head,
                Neck:back_child_neck,
                Back:back_child_back,
                Buttocks:back_child_buttock,
                Arm_right:back_child_arm_right,
                Arm_left:back_child_arm_left,
                Hand_right:back_child_hand_right,
                Hand_left:back_child_hand_left,
                Leg_right:back_child_leg_right,
                Leg_left:back_child_leg_left,
                Foot_right:back_child_foot_right,
                Foot_left:back_child_foot_left,
            }
        }

    }

}
export default SkinBodyImages