import {OverlayTrigger, Popover} from "react-bootstrap";
import SVGraphics from "../../../assets/SVGraphics";
import React from "react";

class TableMoreOptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ref: null
        }
        this.ref = null;
    }

    render() {
        let {options} = this.props;
        return (
            <OverlayTrigger rootClose={true} ref={ref => this.ref = ref} trigger="click" placement="left" onClick={e => e.stopPropagation()} overlay={
                <Popover id="popover-basic" className={'waiting-room-row-options-popup'}>
                    <Popover.Content>
                        {
                            options.map((action, index) => {
                                let {actionName, call} = action;
                                let className = 'audio-popup-filter pointer';
                                return <div key={index} className={className} onClick={e => {
                                    call();
                                    e.stopPropagation()
                                    document.getElementById('root').click();
                                }}>{actionName}</div>
                            })
                        }
                    </Popover.Content>
                </Popover>
            }>
                <SVGraphics svgname={'3dots'} onClick={e => e.stopPropagation()}
                            className={!!this.ref ? 'waiting-room-row-options active' : 'waiting-room-row-options'}/>
            </OverlayTrigger>
        );
    }
}

export default TableMoreOptions
