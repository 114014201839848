import React, {Component} from "react";
import Body from "../../../../../assets/body/Body";
import utils from "../../../../../utils/utils";
import AudioPlayer from "../../../../media/AudioPlayer";

class AbdomenExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            playingDot: null,
            isA: false,
            isB: false
        }
    }

    render() {
        let {files, patient} = this.props
        let body = <div/>
        if (!!files && !!patient)
            body = <Body activeDot={this.state.playingDot} gender={patient.gender}
                         age={utils._calculateAge(new Date(patient['birthday']))} part={'abdomen'}
                         className={'exam-body'} name={'exam-body'} files={files}/>

        let A_files = files.filter(file => file['dotName'] === 'A')
        let B_files = files.filter(file => file['dotName'] === 'B')

        return (
            <div className={'exam-result-container abdomen-exam-container'}>
                <div className={'exam-res-container h-centered'}>
                    {body}
                    <div className={'stethoscope-audios'}>
                        {A_files.length > 0 && <AudioPlayer playing={this.state.isA} play={bool => this.setState({
                            isA: bool,
                            isB: false,
                            playingDot: bool ? 'A' : null
                        })} title={'A'} className={'abdomen-exam-player stethoscope-audio'} files={A_files}/>}
                        {B_files.length > 0 && <AudioPlayer playing={this.state.isB} play={bool => this.setState({
                            isA: false,
                            isB: bool,
                            playingDot: bool ? 'B' : null
                        })} title={'B'} className={'abdomen-exam-player stethoscope-audio'} files={B_files}/>}
                    </div>
                </div>
            </div>
        );
    }

}

export default AbdomenExam
