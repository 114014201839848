import React, {Component} from "react";
import '../../style/View/physician/Physician.css'
import utils from "../../utils/utils";
import Middleware from "../../routes/Middleware";
import {Route, Switch, Router, Redirect} from 'react-router'
import NotFound from "../NotFound";
import {PhysicianRoutesConfig, PhysicianRoutes} from "../../routes/components_access/Physician";
import IdleTimer from "react-idle-timer";
import {authenticationService} from "../../services/auth/AuthenticationService";



class Physician extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingPatient: false,
            idleTime: 10,
            isCameraAllowed: true,
            isMicrophoneAllowed: true,
        };

        this.idleTimer = null;
        this.cardRef = React.createRef()
    }

    async componentDidMount() {
        this.setState({isLoading: false});

    }


    setCardRef(ref) {
        this.cardRef = ref
    }

    buildRoutes() {

        let that = this
        let routes = [];
        let middlewares = ['userAccessRoute'];
        PhysicianRoutesConfig.routes.forEach((obj, index) => {
            if (!utils.isEmpty(PhysicianRoutes[obj.component])) {
                const component = Middleware.routeToDisplay(
                    middlewares,
                    <Route
                        key={index}

                        path={obj.url}
                        render={(props) => {
                            if (obj.isAllowPermissionsControl){
                                props['setAllowedPermission'] = (isPermissionsNotificationAllowed) => this.setAllowedPermission(isPermissionsNotificationAllowed);
                            }
                            if (obj.signal) {
                                props['setCardRef'] = (ref) => this.setCardRef(ref);
                            }
                            return PhysicianRoutes[obj.component]({props})
                        }}/>,
                    {role: obj.access}
                );
                if (!utils.isEmpty(component)) {
                    routes.push(component);
                }
            }
        });
        routes = routes.filter((x) => {
            return !utils.isEmpty(x);
        });
        return routes;
    };
    async handleOnIdle(event) {
        // let isAuthenticated = Boolean(sessionStorage.getItem('userLoggedIn') === 'true');
        // if(isAuthenticated)
            await authenticationService.logout();
        // await this.setState({isLoading: false});

    }


    render() {

        let {isLoading,loadingPatient,idleTime} = this.state;

        const routes = this.buildRoutes();


        return (
            // <div style={{height: '100%'}}>

                <div className={`app-container physician-app`}>

                        <IdleTimer
                            ref={ref => {
                                this.idleTimer = ref
                            }}
                            timeout={1000 * 60 * 1}
                            onIdle={this.handleOnIdle}
                            events={['keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove']}
                            // debounce={250}
                        />


                    <div className={"loader spin"} style={{display: isLoading || loadingPatient ? "block" : "none"}}/>
                    {/*<div className={'app-container'}>*/}
                        <Switch>
                            {routes}
                            <Route exact component={NotFound}/>
                        </Switch>
                    {/*</div>*/}
                </div>
            // </div>

        );
    }
}

export default Physician
