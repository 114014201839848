import doctorApi from "./ApiServices/doctorApi/doctorApi";
import AllUsersApi from "./ApiServices/AllUsersApi";
import axios from "axios";
import MakeError from "../component/Error/ErrorModal";
import {history} from "../utils/history";
import {authenticationService} from "./auth/AuthenticationService";

export {
    doctorApi,
    AllUsersApi,
};

axios.interceptors.request.use(async (config) => {
    let headers = config.headers
    headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains'
    headers['rawResponse'] = true

    // if (!!authenticationService.currentUserValue)
    //     headers['asrAuth'] = !!authenticationService.currentUserValue.asrAuth

    config.headers = headers
    return config;


}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// declare a response interceptor
axios.interceptors.response.use(
    async (response) => {
        return Promise.resolve(response['data']);
    },
    async (error) => {

        if (error.response) { // client received an error response (5xx, 4xx)

            // keys to identify request
            let response = error.response;

            let method = String(response.config.method).toLowerCase();
            let endpoint = String(response.config.url);
            let headers = response.config.headers
            let status = response.status
            // Error building tools
            let title = 'Error';
            let message = "Error, please refresh the page";

            if (response.hasOwnProperty('data')) {
                if (response.data.hasOwnProperty('message')) {
                    message = response.data.message.replace(/"/g, "");
                } else if (response.data.hasOwnProperty('messages')) {
                    message = (response.data.messages.map(msg => {
                        return msg.replace(/"/g, "");
                    })).join('\n')
                }
            } else message = String(response).replace(/"/g, "");

            // find request by endpoint and modify err popup accordingly
            if (endpoint.includes('users')) {
                if (endpoint.includes('register')) {
                    if (endpoint.includes('physician')) {
                        title = 'Could not create physician';
                    } else if (endpoint.includes('tenant')) {
                        title = 'Could not create tenants';
                    }
                } else if (endpoint.includes('profile'))
                    title = 'Could not edit user';
                else if (response.hasOwnProperty('data'))
                    return Promise.reject(response['data']);
                else return Promise.reject(response);
            } else if (endpoint.includes('physicians')) {
                if (endpoint.includes('patients')) {
                    let routeDir = endpoint.split('patients');
                    if (routeDir.length > 2) {
                        if (method.includes('get') && (endpoint.toLowerCase()).includes('patientid')) {
                            title = 'Could not get patient';
                        } else if (method.includes('get') && (endpoint.toLowerCase()).includes('sessions')) {
                            title = 'Could not get patient\'s sessions';
                        } else if (method.includes('get')) {
                            title = 'Could not get patients';
                        }
                    } else if (method.includes('get')) {
                        title = 'Could not get patients';
                    }
                } else if (endpoint.includes('sessions')) {
                    if (method.includes('get') && ((endpoint.toLowerCase()).includes('sessionid'))) {
                        title = 'Could not get patient\'s session';
                    } else if (method.includes('put') && (endpoint.toLowerCase()).includes('sessionid')) {
                        title = 'Could not update patient\'s session';
                    }
                } else if (endpoint.includes('meeting')) {
                    if (method.includes('post')) {
                        title = 'Could not save patient\'s meeting';
                    } else if (method.includes('get')) {
                        title = 'Could not get meetings';
                    } else if (method.includes('put')) {
                        title = 'Could not edit patient\'s meeting';
                    } else if (method.includes('delete')) {
                        title = 'Could not delete patient\'s meetings';
                    }
                }
            }

            if(error.response.data.message === 'Unauthorized - token expired'){
            return error.response.data
        }else{
                await MakeError({title, message})
            }


        } else if (error.request) {// client never received a response, or request never left

            let endpoint = String(error.request.responseURL);
            if (endpoint.includes('users')) {
                return Promise.reject({data: {message: 'Connection Error Or Timed out'}});
            } else {
                await MakeError({message: 'Connection Error Or Timed out'});
            }
        } else {

            await MakeError({message: 'Connection Error Or Timed out'});
            return Promise.reject({error, data: {message: 'Connection Eerror Or Timed out'}});
        }

    }

    );


