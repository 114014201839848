import React from 'react';
import MetaTags from 'react-meta-tags';
import PatientsApi from "../../../services/ApiServices/doctorApi/patientsApi";
class PatientCardRedirectMetas extends React.Component {

    constructor() {
        super();
        this.state = {
            tenant: null
        }
    }

    async componentWillMount() {
        let pathParams = window.location.pathname.split('/')
        let ttuid = pathParams[pathParams.length-1]
        const ttuidData = await PatientsApi.getPatientCardIframePathDetailsFromTTUID(ttuid)
        this.setState({tenant: ttuidData.tenant})
    }

    //http://localhost:3218/rdct/pcrd/U16735-3290-9d25-17454

    render() {
        let {image, url} = this.props
        let {tenant} = this.state

        // if (tenant === 'fluentsmartexam')
        //     return (
        //         <MetaTags>
        //             <title>Fluent Care</title>
        //             <meta name="title" content="Fluent Clinic" />
        //             <meta name="og:title" content="Fluent Clinic" />
        //             <meta name="description" content="I’ve shared my Fluent exams for your review. You can access my exams at the following link for the next 24 hours:" />
        //             <meta name="og:description" content="I’ve shared my Fluent exams for your review. You can access my exams at the following link for the next 24 hours:" />
        //
        //             <meta property="og:image" content={image}/>
        //             <meta property="og:image:secure_url" content={image}/>
        //             <meta property="og:url" content={url} />
        //             <meta property="og:site_name" content={"Fluent Clinic"} />
        //             <meta property="og:isPay" content={"0"} />
        //         </MetaTags>
        //     )
        // else return (
        //     <MetaTags>
        //         <title>Nonagon Care</title>
        //         <meta name="title" content="NONAGON Clinic" />
        //         <meta name="og:title" content="NONAGON Clinic" />
        //         <meta name="description" content="I’ve shared my Nonagon exams for your review. You can access my exams at the following link for the next 24 hours:" />
        //         <meta name="og:description" content="I’ve shared my Nonagon exams for your review. You can access my exams at the following link for the next 24 hours:" />
        //
        //         <meta property="og:image" content={image}/>
        //         <meta property="og:image:secure_url" content={image}/>
        //         <meta property="og:url" content={url} />
        //         <meta property="og:site_name" content={"Nonagon Clinic"} />
        //         <meta property="og:isPay" content={"0"} />
        //     </MetaTags>
        // )
        return (
            <div></div>
        )
    }
}


export default PatientCardRedirectMetas
