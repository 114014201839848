import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './style/ScrollBar.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

import './config/i18n';
import {authenticationService} from "./services/auth/AuthenticationService";

let isAuthenticated = authenticationService.isLoggedin();
if (isAuthenticated) authenticationService.reauthenticate();


ReactDOM.render(
    // <React.StrictMode>
        <App/>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

Number.prototype.toFixedUR = function (fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(this * fixed) / fixed;
}
