import React, {Component} from "react";
import Select, {components} from 'react-select';
import '../../style/component/input/outlinedSelect.css'
import SVGraphics from "../../assets/SVGraphics";

// const options = [
//     {label: "fsgtest", value: "fsgtest"},
//     {label: "445test2", value: "445test2"},
//     {label: "t34est3", value: "t34est3"},
//     {label: "test43", value: "test43"},
//     {label: "3test2", value: "3test2"},
//     {label: "fsgtest", value: "fsgtest"},
//     {label: "445test2", value: "445test2"},
//     {label: "t34est3", value: "t34est3"},
//     {label: "test43", value: "test43"},
//     {label: "3test2", value: "3test2"},
//     {label: "dtest3", value: "dtest3"}
// ]
//
// const options2 = [
//     {label: {name:"test",id:"123"}, value: "test"},
//     {label: {name:"test2",id:"456"}, value: "test2"},
//     {label: {name:"test2",id:"789"}, value: "test2"},
// ]

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SVGraphics svgname={'glass'}
                        className={'SearchInput-DropdownIndicator v-centered'}/>
        </components.DropdownIndicator>
    );
};

class OutlinedSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            text: null,
            options: [],
            isFocused: false,
            isErr: false
        };
    }

    handleInputChange = text => {
        this.setState({text})
        // this.setState({selectedOption: null})
    };

    handleChange = async selectedOption => {
        if (!!selectedOption)
            this.props.onSelect(selectedOption.value)
        // this.setState({selectedOption/*,isFocused: false*/})
    };

    filterOption = (option, inputValue) => {
        let names = String(option.data.label).toLowerCase().match(inputValue ? inputValue.toLowerCase() : "")
        return (names || []).length > 0
    }

    CustomOption = (props) => {
        let {label, value, isSelected, innerProps} = props
        return <div className={'select-option'}>
            <div className={'select-option-container pointer'} key={value} onClick={() => innerProps.onClick()}>
                <div className={'select-option-label-name'}>{label.name}</div>
                <div className={'select-option-label-id'}>{label.id}</div>
            </div>
        </div>
    }

    render() {
        let {text, isFocused,} = this.state;
        let {options, disabled, defaultValue, label, isErr, errMessage,selected,className,styles} = this.props
        let value = null
        // = (!!selected && !!options ? options.find(option => option.value === selected) : null) || defaultValue
        if (selected && options)
            value = options.find(option => option.value === selected)
        else if (defaultValue && options)
            this.handleChange(options.find(option => option.value === defaultValue))

        let isActive = !!value
        let labelClass = 'outlined-select-label outlined-select-label-default'
        let selectClass = 'outlined-select outlined-select-default'
        let selectInputClass = 'OutLinedSelect'
        if (!!className) {
            selectClass = selectClass + 'outlined-select outlined-select-default' + className
            labelClass = labelClass + 'outlined-select-label outlined-select-label-default' + className
            selectInputClass = selectInputClass + 'OutLinedSelect' + className
        }
        if (disabled) {
            labelClass = labelClass + ' outlined-select-label-focused outlined-select-label-disabled'
            selectClass = selectClass + ' outlined-select-disabled'
            selectInputClass = 'OutLinedSelect-disabled ' + selectInputClass
        } else {
            if (isFocused) {
                labelClass = 'outlined-select-label outlined-select-label-focused'
                selectClass = 'outlined-select outlined-select-focused'

            }
            else if (isActive) {
                labelClass = 'outlined-select-label outlined-select-label-active'
                selectClass = 'outlined-select outlined-select-active'
            }
        }

        if (isErr) {
            labelClass = labelClass + ' outlined-select-label-err'
            selectClass = selectClass + ' outlined-select-err'
            selectInputClass = 'OutLinedSelect-err ' + selectInputClass
        }

        if (!styles)
            styles = {}
        let defaultValueObj = {label: defaultValue, value: defaultValue}
        return (
            <div className={'outlined-select-container'}>
                <div className={labelClass}>{label}</div>
                <div className={selectClass}>
                    <Select
                        // menuIsOpen={true}
                        isSearchable={true}

                        value={value}
                        filterOption={(option, inputValue) => this.filterOption(option, inputValue)}
                        inputValue={text}
                        onFocus={async () => {
                            this.setState({isFocused: true})
                            await this.handleChange(null)
                        }}
                        onBlur={() => this.setState({isFocused: false})}
                        components={{/*DropdownIndicator,*/ /*Option: this.CustomOption*/}}
                        placeholder={null}
                        onChange={val => {
                            this.handleChange(val)
                        }}
                        isDisabled={this.props.disabled}
                        onInputChange={val => this.handleInputChange(val)}
                        classNamePrefix={selectInputClass}
                        options={options}
                        styles={{
                            ...styles,
                            control: (base, state) => ({
                                ...base,
                                border: "unset",
                                backgroundColor: "#ffffff",
                                width: "100%",
                                height: "50px",
                                color: "#BABABA",
                                textTransform: "capitalize",
                                boxShadow: 'unset',
                                fontSize: "18px",
                                outline: "unset",
                                fontFamily: "Rubik-Regular"
                            }),
                            menu: (base, state) => ({
                                ...base,
                                zIndex:'10000',

                            }),
                        }}
                    />
                </div>
                {
                    isErr &&
                    <div className={'outlined-error'}>{errMessage}</div>
                }
            </div>

        );
    }

}

export default OutlinedSelect
