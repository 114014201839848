import React, {useEffect, useState} from "react";
import SVGraphics from "../../../assets/SVGraphics";
import "./patientStyle/sideBar.css"
import utils from "../../../utils/utils";
import useMediaQuery from "../../../utils/useMediaQuery";
import {getExamValue} from "./exams/getExamsValue";
import TokboxVideo from "./TokboxVideo";

export function PatientSidebar(props) {

    const examsArr = ["Body_Temperature", "Saturation", "Heart", "Lungs", "Abdomen", "Ears", "Throat", "Skin"]
    const examsArrMobile = ["Body_Temperature", "Saturation", "Heart", "Abdomen", "Lungs", "Ears", "Throat", "Skin"]
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 550px)');
    const isExtraSmall = useMediaQuery('(min-width:551px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');

    const largeScreen = useMediaQuery('(min-width: 1440px) and (max-width:1920px)');
    const [isPrescriptionRequired, setIsPrescriptionRequired] = useState(false);
    const [prescription, setPrescription] = useState(null);
    const mobileTablet = isSmallScreen || isMobilScreen || isExtraSmall


    useEffect(() => {
        if(props.exam.oximRxRequired){
            setIsPrescriptionRequired(true)
        }
        setPrescription(props.exam['UserOximPrescription'])


    }, [])



    return (
        <div className={isMobilScreen ? 'sidebar-Container-small' : 'sidebar-Container'}>
            {!mobileTablet && props.isUserLoggedIn && !props.isOnlineCall &&
            <div className={'call-patient-button-container'}>
                <button className={'call-patient-button'} onClick={props.callButtonCLicked}>
                    <SVGraphics svgname={'phone'} width={24} heigth={24} style={{marginRight:'0.75rem'}}/>
                    <div>Call Patient</div>
                </button>

            </div>
            }
            {props.isOnlineCall &&
            <div className={'tokbox-chat-container'}>

            </div>
            }
            {mobileTablet &&
            <div className={`sidebar-icons-tablet ${isMobilScreen ? 'sidebar-icons-mobile' : isExtraSmall?'sidebar-icons-extraSmall':''}`}>
                <div style={{width:'2.5rem',height:'2.5rem',marginRight:isMobilScreen?'0.5rem':'1.125rem'}}
                     onClick={() => props.getShareLink()}
                >
                <SVGraphics svgname={'share-circle'}
                            style={{width:'2.5rem'}}/>
                </div>
                {
                    examsArrMobile.map(ex => {
                        if (ex !== 'Heart_Rate') {
                            let isExist = props.exam?.SessionExams.find(x => x.exam === ex)
                            return <div key={ex} className={`sidebar-icons ${ex === 'Saturation' && isPrescriptionRequired && 'sidebar-icons-container-saturation'}`}>
                            <div  className={`sidebar-icons-container `} onClick={ex==='Saturation' && !prescription && isPrescriptionRequired?()=>props.prescriptionPopup():(e)=>props.scrollToView(ex)}>
                                <SVGraphics svgname={isExist ? ex : ex + "-disabled"}
                                            className={isSmallScreen ? 'sidebar-exam-icon' : 'sidebar-exam-icon-mobile'}/>
                            </div>
                            </div>
                        }
                    })
                }
            </div>
            }
            {!mobileTablet &&
            <div className={'sidebar-medium-large-screen'}>


                <div className={'notes-title-section'} style={{marginLeft:'3%'}}>

                    <div className={'notes-title'}>
                        {props.isMobile && <div onClick={props.closeNotes} className={'notes-arrow'}>&#8592;</div>}
                        Exams
                    </div>
                    {
                        !props.sharedByPatient &&
                        <button className={'notes-copyNotes-button share-records-button'}
                                onClick={() => props.getShareLink()}
                        >
                            <SVGraphics width={"0.9688rem"} height={"0.9688rem"} svgname={'share'}
                                        style={{position: 'relative', bottom: '6%'}}
                            />
                            <div >Share Records</div>
                        </button>
                    }


                </div>
                {/*<div className={'sidebar-text sidebar-examDate'}>*/}
                {/*    {utils.getDateStringWithYear(new Date(exam.examStartTime))}*/}
                {/*</div>*/}
                {
                    // exam.exams
                    examsArr.map(ex => {
                        let isExist = props.exam?.SessionExams?.find(x => x.exam === ex)
                        if (ex !== 'Heart_Rate') {
                            return <div key={ex} className={'sidebar-examCard'} onClick={(e)=>props.scrollToView(ex)}>
                                <div className={'sidebar-text sidebar-title-container'}>
                                    <SVGraphics svgname={isExist ? ex : ex + "-disabled"}
                                                className={'sidebar-exam-icon'}/>
                                    <div className={'sidebar-text sidebar-examTitle'}
                                         style={{color: !isExist ? "#CBCBCB" : "#2E2E2E"}}>{utils.getExamLabel(ex)}</div>
                                </div>
                                <div className={'sidebar-text sidebar-examValue'}>
                                    {isExist && props.exam && getExamValue(ex, props.exam)}
                                </div>
                                { ex === 'Saturation' && isPrescriptionRequired  &&
                                <div className="row" style={{position:'relative', bottom:'1rem', width:'100%', display:'flex',justifyContent:'center'}}>
                                    <div className="col patient-personal-data-holder"
                                         style={{width: '80?%', maxWidth: 'fit-content'}}>
                                        {/*<div*/}
                                        {/*    className={'patient-personal-label col1'}>''</div>*/}
                                        <div className={'patient-personal-value'}>{prescription ?
                                            <span className={'prescription-required'}> <a style={{textDecoration: 'none',color:'#7B5EC6'}}
                                                href={prescription['fileUrl']} target="_blank" rel="noopener noreferrer">  <SVGraphics
                                                width={"0.9688rem"} height={"0.9688rem"}
                                                svgname={'eye-purple'}/> Prescription</a></span>
                                            : <span className={'prescription-required'}>
                                                <SVGraphics
                                                    width={"1.5rem"} height={"1.5rem"}
                                                    svgname={'error-purple'}/>
                                                Prescription Required</span>}</div>
                                    </div>

                                </div>
                                }
                            </div>
                        }
                    })
                }
            </div>
            }


            {/*</div>*/}
        </div>
    )
}