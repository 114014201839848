import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../style/View/NotFound.css'
import PatientsApi from "../services/ApiServices/doctorApi/patientsApi";
import logo from "../assets/nonagon-icon.svg"

class NotFound extends Component {

    async askLinkRenew() {
        const ttuid = this.props.match.params.ttuid
        await PatientsApi.renewPatientCardIframePathFromTTUID(ttuid)

    }

    render() {

        let {title} = this.props;
        title = title ? title : 'Link Has expired';
        //fixme: fex return to homepage
        return (
            <div className={"expired-link-container"}>
                <img style={{width:'160px'}} src={logo}/>
                <div className={"expired-link-title"}>Link Expired</div>
                <div className={"expired-link-Message"}>The link to your patient’s exams has expired.</div>
                <div className={"expired-link-Message"} style={{textDecoration:'underline'}} onClick={e => this.askLinkRenew()}>Ask your patient for new link</div>
            </div>
        );
    }
}

NotFound.propTypes = {
    title: PropTypes.string
};

export default NotFound;
