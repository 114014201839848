import Config from "../../../config/Config";
import restApi from "../RestApi";
import {authenticationService} from "../../auth/AuthenticationService";

const sessionsApi = {
    getPatientSessionsById: async (patientId,tenant,oncall = false, accessToken, asrAuth) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'tenant': tenant,
                'Authorization': accessToken,
                'x-asrAuth': asrAuth
            },
        };

        let api = `${Config.globalUrl}/api/rest/v1.0/sts/patients/${patientId}/sessions?oncall=true`;
        return (await restApi.get(
            api,
            requestconfig
        ))
    },
    // getSessionLink: async (patientId,tenant,oncall = false, accessToken, asrAuth) => {
    getSessionLink: async (patientId,tenant, accessToken, asrAuth) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'tenant': tenant,
                'Authorization': accessToken,
                'x-asrAuth': asrAuth
            },
        };

        let api = `${Config.globalUrl}/api/rest/v1.0/sts/physicians/services/iframe/patient/${patientId}`;
        return (await restApi.get(
            api,
            requestconfig
        ))
    },
    getSessionById: async (sessionId,tenant,accessToken,asrAuth) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'tenant': tenant,
                'Authorization': accessToken,
                'x-asrAuth': asrAuth
            },
        };
        return (await restApi.get(
            `${Config.globalUrl}/api/rest/v1.0/sts/sessions/${sessionId}`,
            requestconfig
        ))
    },
    addScreenshot: async (screenshot,pid) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'tenant' : doctor.tenant,
                'Content-Type': 'application/json',
                'Authorization': doctor.accessToken,
            },
        };
        return (await restApi.post(
            `${Config.loggedGlobalUrl}/api/iframe/v1/physician/files/${pid}/screenshot`,
            screenshot,
            requestconfig
        ))
    },
    deleteScreenshot: async (id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'tenant': doctor.tenant,
                'Authorization': doctor.accessToken,
            },
        };
        return (await restApi.delete(
            `${Config.loggedGlobalUrl}/api/iframe/v1/physician/files/${id}`,
            requestconfig
        ))
    },
    saveNotes: async (notes,pid,sessionId) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'tenant': doctor.tenant,
                'Authorization': doctor.accessToken,
            },
        };
        return (await restApi.put(
            `${Config.loggedGlobalUrl}/api/iframe/v1/physician/sessions/${pid}/sessions/${sessionId}`,
            notes,
            requestconfig

        ))
    },
    createOnlineSession: async (patientId) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'tenant' : doctor.tenant,
                'Content-Type': 'application/json',
                'Authorization': doctor.accessToken,
                'asrAuth': true,
            },
        };
        let api = `${Config.loggedGlobalUrl}/api/v1/webapp/physicians/patients/${patientId}/sessions`;

        return (await restApi.post(
            api,
            {},
            requestconfig
        ))
    },
    updateSessionById: async (sessionId, body) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'tenant' : doctor.tenant,
                'Content-Type': 'application/json',
                'Authorization': doctor.accessToken,
                'asrAuth': true
            },
        };
        return (await restApi.put(
            `${Config.loggedGlobalUrl}/api/v1/webapp/physicians/sessions/${sessionId}`,
            body,
            requestconfig
        ))
    },


};

export default sessionsApi;
