//SkinExam
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../../../utils/useMediaQuery";
import SkinBodyImages from "./assetsExams/Skin/SkinBodyImages";
import SVGraphics from "../../../../assets/SVGraphics";
import SnapShootExam from "./SnapShootExam";

export default function SkinExamNew(props) {

    const [gender, setGender] = useState("MALE");
    const [enlargeImage, setEnlargeImage] = useState(null);
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');
    useEffect(() => {
        let gen = props.age < 12 ? 'CHILD' : props.gender
        setGender(gen)


    })

    return (
        <div
            className={mediumScreen ? 'exam-skin-container-tablet' : isMobilScreen ? 'exam-heart-container-mobile' : 'exam-skin-container'}>
            {
                props.files.map(file => {
                    let position = file.filter.includes('Front') || file.subType === 'Chest' || file.subType === 'Abdomen' || file.subType === 'Genitals' || file.subType === 'Face' ? 'Front' :
                        file.filter.includes('Back') || file.subType === 'Back' || file.subType === 'Buttocks' || file.subType === 'Head' ? 'Back' : 'Front'
                    let side = file.fileName.includes('Left') ? 'left' : file.fileName.includes('Right') ? 'right' : ''
                    return <div style={{marginBottom: '1.5rem'}}>
                        <div>{file.subType === 'Head' || file.subType === 'Chest' || file.subType === 'Abdomen' || file.subType === 'Genitals' || file.subType === 'Face' || file.subType === 'Back' || file.subType === 'Buttocks' ? file.subType : position + ' ' + side + ' ' + file.subType}</div>
                        <div key={file.fileID} className={'exam-skin-image-con'}>
                            <img className={'exam-skin-image'} src={file.downloadUrl} style={{
                                width: isSmallScreen || isMobilScreen ? '15.8rem' : '20.5rem',
                                height: isSmallScreen || isMobilScreen ? '15.8rem' : '21.5rem'
                            }}/>
                            <SVGraphics height={'20%'} className={'exam-skin-image-enlarge'}
                                        style={{right: isMobilScreen ? '2.2rem' : mediumScreen ? '3rem' : '3rem'}}
                                        svgname={'enlarge'} onClick={() => setEnlargeImage(file)}/>
                            <img className={'exam-skin-image-body'} src={SkinBodyImages['Skin'][gender][position]
                                [file.subType === 'Head' || file.subType === 'Neck' || file.subType === 'Abdomen' || file.subType === 'Chest' || file.subType === 'Genitals' || file.subType === 'Face' || file.subType === 'Back' || file.subType === 'Buttocks' ? file.subType : file.subType + '_' + side]}/>
                        </div>
                    </div>

                })
            }
            {enlargeImage &&
            <SnapShootExam file={enlargeImage} title={'Skin'} closeWindow={() => setEnlargeImage(null)}
                           show={enlargeImage}/>
            }
        </div>
    )
}