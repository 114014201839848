import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from 'react-confirm'
import '../../style/component/modal/modal.css'
import SVGraphics from "../../assets/SVGraphics";
import nonagonLogo from "../../assets/nonagonLogo.png"
import nonagonDevice from "../../assets/nonagonDevice.svg"
import fluentDevice from "../../assets/fluentsmartexam.png"
import fluentLogo from "../../assets/Fluent_logo.png"

class PatientSendExamModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            isErr: false
        }
    }


    render() {
        let {
            show,
            proceed,
            tenant
        } = this.props;
       let className = tenant === 'fluentsmartexam' ? 'patient-send-fluentexam' : 'patient-send-exam'
        return (
            <Modal
                size="lg"
                show={show}
                centered={true}
                onHide={() => proceed()}
                dialogClassName={'exit-session-dialog'}
                contentClassName={'exit-session-content '+className}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className={'exit-session-media-body'}>
                    <div className={"patient-send-exam-body"}>
                    <div className={'title-send-exam'}>
                        <a  href={"https://nonagon-care.com/"} target="_blank">
                            <img src={tenant === 'fluentsmartexam' ? fluentLogo :nonagonLogo} />
                        </a>
                    </div>
                    <div className={'modal-message-header title-send-exam'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>
                        Your patient sent you exams via  {tenant === 'fluentsmartexam' ?'Fluent SmartExam':'Nonagon'}
                    </div>
                    <div className={'modal-message-body'}>
                        <div>
                            Welcome to {tenant === 'fluentsmartexam' ?'Fluent SmartExam':'Nonagon'}, a system that allows your patients to take and send remote physical exams for your review.
                        </div>
                        <div className={"image-container-send-exam"}>
                            <img src={tenant === 'fluentsmartexam' ? fluentDevice:nonagonDevice} />
                        </div>
                    </div>
                    <div className={'modal-message-footer send-exam-footer'}>
                        {tenant === 'fluentsmartexam' ?
                        <div className={"send-exam-about"}>

                                <div>
                                    Learn more about the <a href={"https://getfluenthealth.com"} target="_blank">Fluent SmartExam</a><br/>
                                </div>
                        </div>
                                :
                            <div className={"send-exam-about"}>
                                Want to learn more about Nonagon?
                                <div>
                                Visit or contact us at <a href={"https://nonagon-care.com/#contact"} target="_blank">Nonagon-Care
                                at Hand</a>
                            </div>

                        </div>}

                        <button className={'btn mhd-btn'} style={{backgroundColor:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}} onClick={() => proceed(true)}>View Exams</button>
                    </div>


                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

PatientSendExamModal.propTypes = {
    show: PropTypes.bool,
    tenant: PropTypes.bool
};

export default function MakeModal({tenant}) {
    return createConfirmation(confirmable(PatientSendExamModal))({
        tenant
    });
}
