import React, {Component} from "react";

class FC_Abdomen extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#c10096'

        let A_color = grey
        let B_color = grey

        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){
        return (
            <svg viewBox={"0 0 340 418"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#82543A"
                          d="M150.247 71.113l10.204 10.347.628 12.365-7.112 14.518-12.74 10.914-10.148 5.927-.933 9.984 4.814 3.909 5.492-1.013-5.327 4.809-10.306-2.897-5.698-11.214 7.097-17.325 11.349-13.13-2.168-9.121 1.489-11.674 13.359-6.399z"/>
                    <path fill="#FBDFCF"
                          d="M220.261 208.264l-.141 5.869c-4.937-12.55-8.099-26.052-4.897-39.407 1.058-4.429 2.363-8.796 3.909-13.08.78-2.542 1.714-5.034 2.795-7.462l14.949 6.809 10.428 17.163 5.632 26.979 8.587 42.577 1.71 10.236 8.582 12.293 7.988 25.872 4.63 16.162 5.064 17.359-.053.161 5.365 12.876-7.107 8.556-10.022-5.172-20.412-39.568-11.334-19.225-2.932-16.332-14.713-42.221-8.028-20.445z"/>
                    <path fill="#FBDFCF"
                          d="M277.305 338.02l-1.295 5.947 3.625 17.815 4.403 9.811 12.689 18.074 4.142.516 4.404 3.098 6.474 1.289 2.337-2.076.258-3.873 1.037-2.583-4.913-18.847 5.439 2.324 5.18-1.034.777-3.872-4.404-2.582-3.367-4.132-3.107-9.811-12.948-9.809-5.439-3.874-10.101-1.807-5.191 5.426zM229.829 341.353l-11.792-40.166-.05-.124-4.26-22.564-2.067-31.711 7.516-12.151-.065-6.647c-4.693-13.442-6.873-27.627-6.432-41.853.211-10.983 1.579-23.16 6.825-33.152l-25.896-7.871-8.936-7.871-.586-13.056-.625-13.484-13.43-8.715-1.334.301-1.349-.301-13.415 8.715-.587 13.075-.622 13.468-8.937 7.872-26.228 7.957c.526 1.133.947 2.231 1.274 3.02 2.217 5.374 4.027 10.907 5.414 16.551 3.925 15.781-.235 31.354-6.407 45.891l.384 16.093 7.514 12.151-2.059 30.819-4.266 23.454-.052.124-11.792 40.175-2.281 23.028-.035 17.396 1.775 20.267 15.465 72.297 10.104 37.925 30.686-17.332 1.246-36.049 2.168-34.507-.124-29.246-1.658-18.087c2.426.123 4.858.123 7.284 0l-1.363 18.087-.123 29.246 2.134 34.507 4.912 37.975 30.909 10.67 6.539-34.963 14.861-70.518 2.065-20.267-.072-17.784-2.232-22.645zM57.074 298.703l.963-3.035.034-.113-.997 3.148z"/>
                    <path fill="#FBDFCF"
                          d="M117.58 207.817l.139 5.871c4.937-12.551 8.1-26.053 4.898-39.406-1.059-4.429-2.364-8.797-3.909-13.082-.78-2.541-1.714-5.033-2.795-7.462l-14.945 6.813-10.432 17.16-5.632 26.979-8.586 42.576-1.71 10.235-8.58 12.293-7.99 25.87-4.622 16.164-5.065 17.361.054.159-5.365 12.877 7.108 8.557 10.02-5.172 20.413-39.568 11.332-19.225 2.932-16.33 14.712-42.224 8.023-20.446z"/>
                    <path fill="#FBDFCF"
                          d="M60.54 337.568l1.294 5.947-3.626 17.812-4.402 9.811-12.689 18.072-4.144.525-4.402 3.098-6.474 1.291-2.332-2.066-.258-3.873-1.035-2.583 4.913-18.848-5.44 2.325-5.178-1.034-.772-3.878 4.403-2.583 3.366-4.13 3.108-9.811 12.95-9.811 5.44-3.873 10.1-1.807 5.178 5.416z"/>
                    <path fill="#E1C7B8"
                          d="M217.097 180.455v16.226l4.957 17.343-2.879 20.6-6.334 12.349-.749 2.534-15.34-4.485-23.335-2.319-3.323-.336-25.549 2.657-19.227 4.782-.298-3.283-6.799-11.894-2.772-21.699 2.753-23.33-2.666-15.34-1.598-4.357-.232-.656-2.914-7.998-3.784-3.454 7.941-3.609 2.079-.956 3.411-1.029 3.377 6.997 9.486 18.599 17.467 9.445 19.846.301 20.575-.301 11.55-11.957 6.218-16.385 6.452-7.521 4.937 1.859 7.979 3.632-6.183 7.049-5.046 16.536zM232.149 355.523h-18.422l-18.76 4.318-14.288 7.602-8.339 11.545c-.801.238-1.635.351-2.471.336-.407 0-.78-.053-1.172-.072-.391.019-.78.072-1.172.072-.792.019-1.583-.083-2.345-.301l-7.896-10.392-15.397-8.783-20.286-5.247-16.055-.56 1.579-22.764 10.313-35.013 3.563-5.924 5.197 6.981 26.349 7.086 38.201-.284 20.433-7.686 5.518-5.506 2.972 3.556 10.604 36.792 1.874 24.244zM184.086 124.183l-9.241 9.035h-11.852l-9.649-9.446.589-13.069 13.415-8.715 1.349.303 1.334-.303 13.43 8.715.625 13.48z"/>
                    <path fill="#FBDFCF"
                          d="M202.205 43.585l-10.997-15.7-19.101-3.791-3.509.236-3.509-.23-19.086 3.826-10.967 15.718-2.367 18.092 1.921 13.327 6.232 25.7 5.579 9.675 15.919 11.91 12.734-.01 15.903-11.938 5.563-9.686 6.183-25.712 1.899-13.33-2.397-18.087z"/>
                    <path fill="#FBDFCF"
                          d="M136.975 73.396l-5.253-1.177-1.908 2.858 3.59 10.469 3.588 7.376 3.348 6.899 4.775.234-8.14-26.66zM200.241 73.34l5.252-1.196L207.405 75l-3.57 10.476-3.576 7.381-3.334 6.906-4.774.242 8.09-26.665z"/>
                    <path fill="#82543A"
                          d="M200.438 26.973l-13.536-9.592-16.092-1.322h-4.912l-16.089 1.35-13.52 9.62-7.625 16.943 2.566 22.869 2.553 6.807 5.113 12.04-1.29-18.681 3.648-15.019 17.345-5.072 8.421 1.05 1.364.174 1.365-.175 8.421-1.062 17.354 5.049 3.674 15.01-1.256 18.685 5.088-12.053 2.54-6.81 2.527-22.873-7.659-16.938z"/>
                    <path fill="#E1C7B8" d="M170.308 301.401l-2.911-3.483 2.523-4.004 2.743 3.255-2.355 4.232z"/>
                    <path fill="#82543A"
                          d="M138.088 58.187c-.147.593.893 10.37.893 10.37s40.717-.592 40.864-1.185c.148-.593 5.497-13.334 5.497-13.334l3.569 13.778s10.846 3.41 10.702 2.667c-.144-.741-.891-20.888-.891-20.888s-22.142-6.666-22.587-6.822c-.446-.156-20.654-1.335-21.405-1.186-.751.149-19.912 8.594-19.912 8.594l3.27 8.006z"/>
                    <path fill="#2E2E2E"
                          d="M221.624 143.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.094.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.13.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404zM107.624 143.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.094.028-.168.084-.224.056-.066.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.18.854 2.114 0 .737-.182 1.334-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .074-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.066-.135-.164-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.164 1.806-.49.401-.336.602-.836.602-1.498 0-.663-.201-1.158-.602-1.484-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M169.5 132.43L169.5 344.675M169.5 132.43L169.5 344.675"/>
                    </g>
                    <g opacity=".5">
                        <path stroke="#fff" d="M75 217.5L251 217.5M75 217.5L251 217.5"/>
                    </g>
                    <g opacity=".5">
                        <path stroke="#fff" d="M93 156.5L233 156.5M93 156.5L233 156.5"/>
                    </g>
                    <path fill={B_color}
                          d="M175 288c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M183.401 293c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM187 298.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM187 277.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={A_color}
                          d="M139 288c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M146.409 293c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM151 298.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM151 277.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H299.433V350H0z" transform="translate(17 11)"/>
                    </clipPath>
                    <filter id="filter0_d" width="339.433" height="390" x="0" y="-5" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );
        }else {
            return (

                <svg viewBox={"0 0 340 418"}
                     width={width}
                     height={height}
                     style={style}
                     xmlns={xmlns}
                     name={name}
                     className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                <path d="m135.247 55.112 10.204 10.347.628 12.365-7.112 14.518-12.74 10.914-10.148 5.927-.933 9.984 4.814 3.909 5.492-1.013-5.327 4.809-10.306-2.897-5.698-11.214 7.097-17.325 11.349-13.13-2.168-9.12 1.489-11.675 13.359-6.399z" fill="#82543A"/>
                <path d="m205.259 192.264-.141 5.869c-4.937-12.55-8.099-26.052-4.897-39.407a122.122 122.122 0 0 1 3.909-13.08 65.817 65.817 0 0 1 2.795-7.462l14.949 6.809 10.428 17.163 5.632 26.979 8.587 42.577 1.71 10.236 8.582 12.293 7.988 25.872 4.63 16.162 5.064 17.359-.053.161 5.365 12.876-7.107 8.556-10.022-5.172-20.412-39.568-11.334-19.225L228 254.93l-14.713-42.221-8.028-20.445z" fill="#FBDFCF"/>
                <path d="m262.303 322.019-1.295 5.947 3.625 17.815 4.403 9.811 12.689 18.074 4.142.516 4.404 3.098 6.474 1.289 2.337-2.076.258-3.873 1.037-2.583-4.913-18.847 5.439 2.324 5.18-1.034.777-3.872-4.404-2.582-3.367-4.132-3.107-9.811-12.948-9.809-5.439-3.874-10.101-1.807-5.191 5.426zm-47.476 3.333-11.792-40.166-.05-.124-4.26-22.564-2.067-31.711 7.516-12.151-.065-6.647a116.056 116.056 0 0 1-6.432-41.853c.211-10.983 1.579-23.16 6.825-33.152l-25.896-7.871-8.936-7.871-.586-13.056-.625-13.484-13.43-8.715-1.334.301-1.349-.3-13.415 8.714-.587 13.075-.622 13.468-8.937 7.872-26.228 7.957c.526 1.133.947 2.231 1.274 3.02a115.976 115.976 0 0 1 5.414 16.551c3.925 15.781-.235 31.354-6.407 45.891l.384 16.093 7.514 12.151-2.059 30.819-4.266 23.454-.052.124-11.792 40.175-2.28 23.028-.036 17.396 1.775 20.267 15.465 72.297 10.104 37.925 30.686-17.332 1.246-36.049 2.168-34.507-.124-29.246-1.658-18.087c2.426.123 4.858.123 7.284 0l-1.363 18.087-.123 29.246 2.134 34.507 4.912 37.975 30.909 10.67 6.539-34.963 14.861-70.518 2.065-20.267-.072-17.784-2.232-22.645zm-172.755-42.65.963-3.035.034-.113-.997 3.148z" fill="#FBDFCF"/>
                <path d="m102.579 191.816.139 5.871c4.937-12.551 8.1-26.053 4.898-39.406a122.687 122.687 0 0 0-3.909-13.082 65.96 65.96 0 0 0-2.795-7.462l-14.945 6.813-10.432 17.16-5.632 26.979-8.586 42.576-1.71 10.235-8.58 12.293-7.99 25.87-4.622 16.164-5.065 17.361.054.159-5.365 12.877 7.108 8.557 10.02-5.172 20.413-39.568 11.332-19.225 2.932-16.33 14.712-42.224 8.023-20.446z" fill="#FBDFCF"/>
                <path d="m45.54 321.567 1.293 5.947-3.626 17.812-4.402 9.811-12.689 18.072-4.144.525-4.402 3.098-6.474 1.291-2.332-2.066-.258-3.873-1.035-2.583 4.913-18.848-5.44 2.325-5.178-1.034-.772-3.878 4.403-2.583 3.366-4.13 3.108-9.811 12.95-9.811 5.44-3.873 10.1-1.807 5.178 5.416z" fill="#FBDFCF"/>
                <path d="M202.096 164.454v16.226l4.957 17.343-2.879 20.6-6.334 12.349-.749 2.534-15.34-4.485-23.335-2.319-3.323-.336-25.549 2.657-19.227 4.782-.298-3.283-6.799-11.894-2.772-21.699 2.753-23.33-2.666-15.34-1.598-4.357-.232-.656-2.914-7.998-3.784-3.454 7.94-3.609 2.08-.956 3.411-1.029 3.377 6.997 9.486 18.599 17.467 9.445 19.846.301 20.575-.301 11.55-11.957 6.218-16.385 6.452-7.521 4.937 1.859 7.979 3.632-6.183 7.049-5.046 16.536zm15.052 175.068h-18.422l-18.76 4.318-14.288 7.602-8.339 11.545a8.164 8.164 0 0 1-2.471.336c-.407 0-.78-.053-1.172-.072-.391.019-.78.072-1.172.072a7.844 7.844 0 0 1-2.345-.301l-7.896-10.392-15.397-8.783L106.6 338.6l-16.055-.56 1.579-22.764 10.313-35.013 3.563-5.924 5.197 6.981 26.349 7.086 38.201-.284 20.433-7.686 5.518-5.506 2.972 3.556 10.604 36.792 1.874 24.244zm-48.063-231.34-9.241 9.035h-11.852l-9.649-9.446.589-13.069 13.415-8.715 1.349.303 1.334-.303 13.43 8.715.625 13.48z" fill="#E1C7B8"/>
                <path d="m187.204 27.585-10.997-15.7-19.101-3.791-3.509.236-3.509-.23-19.086 3.826-10.967 15.718-2.367 18.092 1.921 13.327 6.232 25.7 5.579 9.675 15.919 11.91 12.734-.01L175.956 94.4l5.563-9.686 6.183-25.712 1.899-13.33-2.397-18.087z" fill="#FBDFCF"/>
                <path d="m121.974 57.396-5.254-1.177-1.908 2.858 3.59 10.469 3.589 7.376 3.348 6.899 4.774.234-8.139-26.66zm63.266-.056 5.252-1.196L192.404 59l-3.571 10.476-3.575 7.38-3.335 6.907-4.773.242 8.09-26.665z" fill="#FBDFCF"/>
                <path d="M185.438 10.973 171.902 1.38 155.81.059h-4.912l-16.089 1.35-13.52 9.62-7.625 16.943 2.566 22.869 2.553 6.807 5.113 12.04-1.29-18.681 3.648-15.02 17.345-5.071 8.421 1.05 1.364.174 1.365-.175 8.421-1.062 17.354 5.049 3.674 15.01-1.256 18.685 5.088-12.053 2.54-6.81 2.527-22.873-7.659-16.938z" fill="#82543A"/>
                <path d="m155.307 285.401-2.911-3.483 2.523-4.004 2.743 3.255-2.355 4.232z" fill="#E1C7B8"/>
                <path d="M123.086 42.186c-.147.593.893 10.37.893 10.37s40.717-.592 40.864-1.185c.148-.593 5.497-13.334 5.497-13.334l3.569 13.778s10.846 3.41 10.702 2.667c-.144-.74-.891-20.888-.891-20.888s-22.142-6.666-22.587-6.822c-.446-.156-20.654-1.335-21.405-1.186-.751.15-19.912 8.594-19.912 8.594l3.27 8.006z" fill="#82543A"/>
                <path d="M206.622 127a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.302.302 0 0 1-.224.084h-.266zM92.623 127a.303.303 0 0 1-.225-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.029-.182.085-.238a.303.303 0 0 1 .224-.084h2.981c.99 0 1.764.145 2.325.434.56.29.956.719 1.19 1.288.242.56.368 1.246.377 2.058.01.42.014.794.014 1.12 0 .318-.004.686-.014 1.106-.018.859-.144 1.568-.377 2.128a2.42 2.42 0 0 1-1.177 1.26c-.55.271-1.306.406-2.267.406h-3.052zm.573-.84h2.409c.746 0 1.334-.098 1.763-.294.44-.205.752-.527.939-.966.195-.448.298-1.036.308-1.764.009-.28.014-.522.014-.728v-.63c0-.205-.005-.448-.014-.728-.02-1.026-.257-1.782-.715-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                <g opacity=".5">
                    <path d="M154.5 116.43v212.245V116.43zm0 0v212.245z" fill="#000"/>
                    <path d="M154.5 116.43v212.245m0-212.245v212.245V116.43z" stroke="#fff"/>
                </g>
                <g opacity=".5">
                    <path d="M60 201.5h176H60zm0 0h176z" fill="#000"/>
                    <path d="M60 201.5h176m-176 0h176H60z" stroke="#fff"/>
                </g>
                <g opacity=".5">
                    <path d="M78 140.5h140H78zm0 0h140z" fill="#000"/>
                    <path d="M78 140.5h140m-140 0h140H78z" stroke="#fff"/>
                </g>
                <path fill={B_color} d="M119 167c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                <path d="M126.409 172a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zM131 177.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM131 156.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" fill="#fff"/>
                <path fill={A_color} d="M165 167c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path d="M173.401 172a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM177 177.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM177 156.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" fill="#fff"/>
                </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0H299.433V350H0z" transform="translate(0 0)"/>
                        </clipPath>
                        <filter id="filter0_d" width="339.433" height="390" x="0" y="-5" color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="10"/>
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                    </defs>
                </svg>)
        }
    }

}

export default FC_Abdomen

