import React, {useEffect, useRef, useState} from "react";
import logo from "../../../assets/logo.svg"
import avatar from "../../../assets/p-avatar.png"
import "./patientStyle/headerPatient.css"
import useMediaQuery from "../../../utils/useMediaQuery";
import SVGraphics from "../../../assets/SVGraphics";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {authenticationService} from "../../../services/auth/AuthenticationService";



export function PatientHeader(props) {

    // const menu = [{label: 'Latest Exam', name: 'latestExam'}, {label: 'Past Exams', name: 'pastExams'}]

    const patientForm = [{label: 'image', name: 'image'}, {label: 'Name', name: 'fullName'}, {label: 'ID', name: 'idNumber'}, {label: 'Health System', name: 'tenantName'}, {label: 'Age', name: 'age'}, {label: 'DOB', name: 'birthday'}, {label: 'Height', name: 'height'}, {label: 'Weight', name: 'weight'}, {label: 'Sex', name: 'gender'}]
    const tabletForm = [{label: 'ID', name: 'idNumber', width: '9rem'}, {label: 'Health System', name: 'tenantName', width: '10.5rem'}, {label: 'Age', name: 'age', width: '7rem'}, {label: 'Height', name: 'height', width: '7rem'}, {label: 'Weight', name: 'weight', width: '7rem'}, {label: 'Sex', name: 'gender', width: '5.5rem'}, {label: 'DOB', name: 'birthday', width: '9.5rem'}]
    const patientFormMobileLine1 = [{label: 'ID', name: 'idNumber', width: '7.1rem'}, {label: 'Health System', name: 'tenantName', width: '9rem'}, {label: 'Age', name: 'age', width: '6rem'}]
    const patientFormMobileLine2 = [{label: 'Height', name: 'height', width: '5.25rem'}, {label: 'Weight', name: 'weight', width: '5.25rem'}, {label: 'Sex', name: 'gender', width: '3.75rem'}, {label: 'DOB', name: 'birthday', width: '9.5rem'}]
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [userData, setUserData] = useState("");

    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const target = useRef(null);
    const menu = [{label: 'Latest Exam', name: 'latestExam'}, {label: 'Past Exams', name: 'pastExams'}, {label: 'Patient Profile', name: 'patientProfile'},isUserLoggedIn ? {label: 'Log out', name: 'logOut'} : {label: 'Login as physician', name: 'login'}]

    useEffect(() => {
            setIsUserLoggedIn(props.isUserLoggedIn)
        if(props.isUserLoggedIn){
            setUserData(authenticationService.currentUserValue?.profile)
        }
        //    let mobilePatient =[{idNumber:props.patient.idNumber,tenantName:props.patient.tenantName,age:props.patient.age },
        //        {height:props.patient.height,weight:props.patient.weight,gender:props.patient.gender,birthday:props.patient.birthday }
        //    ]
        // setPatientMobile(mobilePatient)
    }, [props.isUserLoggedIn])


    return (
        <>
            {isSmallScreen || isMobilScreen ?
                <div className={'headerContainerSmallScreen'}>

                    <div className={`smallScreenNameCont ${isMobilScreen ? "mobileNameCont" : ""}`}>
                        <div className={'smallScreenNameCont-left-section'}>
                            <div className={'logoBox'}><img style={{padding: '0.9875rem', width: '100%'}} src={logo}
                                                            alt={'logo'}/></div>
                            <div className={'smallNameImage'}>
                                <div className={'piiInfoDivSmall'}>
                                    <div className={'piiInfoDivImageSmall'}><img src={avatar} className={'patientImage'}
                                                                                 alt={'patient'}
                                                                                 style={{marginRight: '1rem'}}/></div>
                                    <div className={'piiInfoValue piiInfoValueSmall'}>{props.patient['fullName']}</div>
                                </div>

                                { props.isUserLoggedIn &&


                                <div className={'call-patient-button-container '}  style={{height:'100%', paddingRight:'5px',marginTop:'0'}} >
                                    <button className={'call-patient-button call-patient-button-mobile '} style={{backgroundColor:props.isOnlineCall?'#E53724':'#68BE0B'}}
                                            onClick={()=>{props.isOnlineCall?props.endCallFromMobile():props.callButtonCLicked()}}
                                    >
                                        <SVGraphics svgname={'phone'} width={32} heigth={32} />
                                    </button>
                                </div>

                                }
                            </div>

                        </div>
                        {!props.sharedByPatient &&
                        <div style={{display: 'flex'}}>
                            <SVGraphics onClick={() => props.openMenu()} className={'ellipsis-svg-mobile'}
                                        svgname={'ellipsis'} width={'2.25rem'} />
                        </div>
                        }

                    </div>
                    {/*<div className={`headerContainerSmallLine2 ${isMobilScreen?'headerContainerMobileLine2':''}`} >*/}
                    {isMobilScreen ?
                        <>
                            <div className={"headerContainerSmallLine2"} style={{
                                paddingLeft: '1rem',
                                marginBottom: '1rem',
                                marginLeft:'-0.5rem',
                                paddingTop: '5.5rem',
                                justifyContent: 'space-evenly'
                            }}>
                                {patientFormMobileLine1.map(pii => {
                                    let _age = props.patient['age']?.split(',')
                                    let age =""
                                    if(_age && _age.length>0){
                                        age = <div>{`${_age[0]},`} <span style={{fontSize:'0.75rem',position:'relative',bottom:'0.2rem',zIndex:-1}}>{` ${_age[1]}`}</span></div>
                                    }
                                    return <div key={pii.name} className={'piiInfoDiv _piiInfoDivSmall'} style={{minWidth: pii.width, paddingLeft: pii.name !== 'idNumber' && '1rem', paddingRight: '0.5rem'}}>
                                        <div className={'piiInfoLabel'}>{(pii.label)}</div>
                                        <div className={'piiInfoValue'}>{pii.name==='age'?age:props.patient[pii.name]}</div>
                                    </div>
                                })
                                }
                            </div>
                            <div className={"headerContainerSmallLine2"}>
                                {patientFormMobileLine2.map(pii => {
                                    return <div key={pii.name} className={'piiInfoDiv _piiInfoDivSmall'} style={{
                                        minWidth: pii.width,
                                        paddingLeft: '0.75rem',
                                        paddingRight: '1rem',

                                    }}>
                                        <div className={'piiInfoLabel'}>{(pii.label)}</div>
                                        <div className={'piiInfoValue'}>{pii.name==='gender'?props.patient[pii.name]?.charAt(0):props.patient[pii.name]}</div>
                                    </div>
                                })
                                }
                            </div>

                        </>
                        :
                        <div className={"headerContainerSmallLine2"}
                             style={{justifyContent: 'space-around', marginTop: '0.5rem'}}>
                            {
                                tabletForm.map(pii => {
                                    let _age = props.patient['age']?.split(',')
                                    let age =""
                                    if(_age && _age.length>0){
                                        age = <div>{`${_age[0]},`} <span style={{fontSize:'1rem',position:'relative',bottom:'0.2rem'}}>{` ${_age[1]}`}</span></div>
                                    }
                                    if (pii.name !== 'image' && pii.name !== 'fullName') {
                                        return <div key={pii.name} className={' _piiInfoDivSmall-tablet'}
                                                    style={{width: pii.width}}
                                        >
                                            <div className={'piiInfoLabel'}>{(pii.label)}</div>
                                            <div className={'piiInfoValue'}>{pii.name==='gender'?props.patient[pii.name]?.charAt(0):pii.name==='age'?age:props.patient[pii.name]}</div>
                                        </div>
                                    }
                                })
                            }
                        </div>
                    }

                </div> :
                <div className={'headerContainer'}>
                    <div className={'header-pii'}>
                        <div className={'logoBox'}><img style={{padding: '0.9875rem'}} src={logo} alt={'logo'}/></div>

                        {patientForm.map(pii => {
                            if (pii.name === 'image') {
                                return <div key={pii.name} className={'piiInfoDivImage'}>
                                    <img src={props.profileImage ? props.profileImage : avatar}
                                         className={'patientImage'} alt={'patient'}/>
                                </div>
                            } else {
                                return <div key={pii.name} className={'piiInfoDiv'}>
                                    <div className={'piiInfoLabel'}>{(pii.label)}</div>
                                    <div className={'piiInfoValue'}>{props.patient ? props.patient[pii.name] : ''}</div>
                                </div>
                            }
                        })
                        }
                    </div>
                    {!props.sharedByPatient &&
                    <OverlayTrigger trigger="click" placement={'bottom-end'} show={props.isMenuOpen} overlay={
                        <div className={'header-menu'} style={{bottom:'2rem'}}>
                        {menu.map((m, index) => {
                            return <div className={'header-menu-label'}
                                        key={m.name}
                                        onClick={() => props.headerMenuOnClick(m.name)}
                                        style={{borderBottom: index !== menu.length - 1 && '1px solid #F0EFF2', color:m.name==='logOut'?'#A51414':'#2E2E2E'}}
                            >
                                <div style={{paddingLeft:'2rem'}}>  {m.label}</div>
                            </div>
                        })
                        }
                            {isUserLoggedIn &&
                            <div style={{paddingLeft:'2rem',marginBottom:'1rem'}} className={'header-menu-physician'}>{`${userData?.firstName?userData?.firstName:""} ${userData?.lastName?userData?.lastName+',':""} ${userData?.jobTitle?userData?.jobTitle:""}`}</div>
                            }
                    </div>}>

                        <div className={'header-ellipsis'}>
                            <SVGraphics onClick={() => props.openMenu()} svgname={'ellipsis-circle'} width={'2.25rem'}
                                        height={'2.25rem'}/>
                        </div>

                    </OverlayTrigger>
                        }


                    </div>



            }

        </>
    )
}