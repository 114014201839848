import React, {Component} from "react";
import VideoPlayer from "../../../../../media/VideoPlayer";
import MakeVideo from "../../../../../modal/VideoModal";
import VideoPlayerThroat from "../../../../../media/VideoPlayerOtoscope";

class ThroatExam extends Component {

    constructor(props) {
        super(props);
        this.mouthRef = React.createRef()
        this.ThroatRef = React.createRef()

    }

    async openFullScreen(url) {
        await MakeVideo({url})
    }

    render() {
        let {files,t} = this.props
        let exams = ['Mouth', 'Throat']
        let language= localStorage.getItem('language')

        return (
            <div className={'exam-result-container throat-exam-container'}>
                <div className={'throat-exam-results-container  h-centered'}>
                    {
                        files.map((file, i) => {
                            if (exams.includes(file['type']) && file['downloadUrl'] != null)
                                return <VideoPlayerThroat fileId={file['fileID']} fileId={file['fileID']} openFullScreen={() => this.openFullScreen(file['downloadUrl'])}
                                                    className={'throat-video'} title={language === 'en' ?file['type'] :file['type'] ==='Mouth'?'Boca' :'Garganta' }
                                                    url={file['downloadUrl']}/>
                        })
                    }
                    {
                        files.length === 0 &&
                        <div className={'Error-Files h-centered'}>{t('physician.patient.exams.fileErr1')}</div>
                    }
                </div>
            </div>
        );
    }

}

export default ThroatExam
