import SVGraphics from "../../../../assets/SVGraphics";
import React from "react";
import utils from "../../../../utils/utils";

export function getExamValue (type, exam,screen) {
    let unit = localStorage.getItem('unit')
    let value = exam?.Observations?.find(x => x.type === type)
    let returnValue
    switch (type) {
        case 'Body_Temperature':
            let tempType = unit === 'c' ? 'c' : 'f'
            let tempValue =  unit === 'f'?parseFloat(value?.value)*9/5+32:parseFloat(value?.value)

            returnValue =
                screen==='copy'?
                   ` <div>${tempValue.toFixed(2)} <span>&#176;${tempType}</span></div>`
                    :
                <div>{tempValue.toFixed(2)} <span style={{fontSize: '0.75rem'}}>&#176;{tempType}</span></div>
            break;
        case 'BloodPressure':
            if(screen ==="notes"){
                returnValue = <div className={'notes-bloodPressure-main-text'} style={{flexDirection:'column'}}>
                    <div>{value?.value}<span className={'sidebar-bloodPressure-text'}>SYS mmHg </span></div>
                    <div>{value?.secondValue}<span className={'sidebar-bloodPressure-text'}>DIA mmHg</span>
                    </div>
                </div>
            }else if(screen ==="main"){
                returnValue =
                    <div className={'sidebar-bloodPressure-main-text sidebar-bloodPressure-main-text-main'} style={{width:'100%'}}>{value?.value}<span className={'sidebar-bloodPressure-text-main'}> SYS mmHg </span>&nbsp;&nbsp;&nbsp;{value?.secondValue}
                        <div className={'sidebar-bloodPressure-text-main'}>&nbsp;DIA mmHg</div></div>
            }else if (screen ==="copy") {
                returnValue=<div style="font-size: 16px; font-weight: bold;">{`${value?.value} SYS mmHg ${value?.secondValue} DIA mmHg`} </div>
            }else
            {
                returnValue =
                    <div className={'sidebar-bloodPressure-main-text'}>{value?.value}<div className={'sidebar-bloodPressure-text'}> SYS mmHg </div>&nbsp;&nbsp;&nbsp;&nbsp;   {value?.secondValue}
                        <div className={'sidebar-bloodPressure-text'}>&nbsp;DIA mmHg</div></div>
        }
            break;
        case 'Saturation':
            if(screen ==="notes"){
                returnValue =
                    <div>{value?.value}<span style={{fontSize: '0.75rem'}}>%</span>&nbsp;|{value?.secondValue}<span style={{fontSize: '0.75rem'}}>bpm</span></div>

            }else if(screen ==="copy"){
                returnValue=`${value?.value} % | ${value?.secondValue} bpm`
            }else{
                returnValue =  <div>{value?.value}<span style={{fontSize: '0.75rem'}}> % </span>&nbsp;   {value?.secondValue} <span
                    style={{fontSize: '0.75rem'}}>bpm</span></div>
            }
            break;
        case 'Heart':
        case 'Abdomen':
            let files = exam?.Files.filter(x => x.type === type)
            let points = files?.length / 4
            if(screen ==="copy"){
                returnValue=`${points}/${type === 'Heart' ? '4' : '2'} points`
            }else{
                returnValue = <div>{`${points}/${type === 'Heart' ? '4' : '2'}`}<span
                    style={{fontSize: '0.75rem'}}> points </span></div>
            }

            break;
        case 'Lungs':
            let LungsFiles = exam?.Files.filter(x => x.type === type)
            let backPoints = LungsFiles?.filter(x => x.subType === "Back")?.length / 4
            let frontPoints = LungsFiles?.filter(x => x.subType === "Front")?.length / 4
            if(screen ==="notes"){
                returnValue = <div className={'sidebar-lungs-container'}>
                    { <div>{`${frontPoints}/2 p | ${backPoints}/4 p`}</div>}
                </div>
            }else if(screen ==="copy"){
                returnValue=`${frontPoints}/2 p | ${backPoints}/4 p`
            }
            else{
                returnValue = <div className={'sidebar-lungs-container'}>
                    <div className={'sidebar-lungs-points'}>
                        <div className={'sidebar-text sidebar-exam-subTitle'}>Front</div>
                        <div>{`${frontPoints}/2`}<span style={{fontSize: '0.75rem'}}> points </span></div>
                    </div>
                    <div className={'sidebar-lungs-points'}>
                        <div className={'sidebar-text sidebar-exam-subTitle'}>Back</div>
                        <div>{`${backPoints}/4`}<span style={{fontSize: '0.75rem'}}> points </span></div>
                    </div>
                </div>
            }
            break;
        case 'Skin':
        case 'Covid19':
        case 'Strep':
            let skinFiles = exam?.Files.filter(x => x.type === type)
            if(screen ==="notes" ){
                returnValue=<div>{skinFiles.length + " images"}</div>
            }else if(screen ==="copy"){
                returnValue=skinFiles.length + " images"
            }else{
                returnValue = <div className={'sidebar-exam-image-container'}>
                    {skinFiles?.map(ex => {
                        return <img key={ex.fileID} alt={type} className={'sidebar-exam-image'} src={ex.downloadUrl}/>
                    })}
                </div>
            }
            break;
        case 'Throat':
            let videoFiles = type === 'Throat' ? exam?.Files.filter(x => x.type === 'Throat' || x.type === 'Mouth') : type === 'Ears' ? exam.Files.filter(x => x.type === type) : exam.Files.filter(x => x.type === type)
            videoFiles = videoFiles?.filter(x=>x.filter==="Filter1"||x.filter==="None")
            if(screen ==="notes"){
                returnValue = <div>{videoFiles.length + " videos"}</div>
            }else if(screen ==="copy"){
                returnValue=videoFiles.length + " videos"
            }else{
                returnValue = <div className={'sidebar-exam-image-container'}>
                    {videoFiles?.map(ex => {
                        return <div>
                                <div
                                    className={'sidebar-text sidebar-exam-subTitle sidebar-exam-subTitle-video '}>{type === 'Throat' ? ex.type : ex.subType}</div>
                                <SVGraphics svgname={'playCircle'} className={'sidebar-exam-video-play'}/>
                                <video className={'sidebar-exam-image sidebar-exam-video'}
                                       src={ex.downloadUrl} type="video/mp4"/>
                        </div>
                    })}
                </div>
            }
            break;
        case 'Ears':
            let earsFiles = exam?.Files.filter(x => x.type === type)
            if(screen ==="notes"){
                returnValue = <div>{earsFiles.length + " videos"}</div>}
            else if(screen ==="copy"){
                    returnValue=earsFiles.length + " videos"
            }else{
                returnValue = <div className={'sidebar-exam-image-container'}>
                    {earsFiles?.map(ex => {
                        return <div>
                            <div
                                className={'sidebar-text sidebar-exam-subTitle sidebar-exam-subTitle-video '}>{ ex.subType}</div>
                            <SVGraphics svgname={'playCircle'} className={'sidebar-exam-video-play'}/>
                            <video className={'sidebar-exam-image sidebar-exam-video'}
                                   src={ex.downloadUrl} type="video/mp4"/>
                        </div>
                    })}
                </div>
            }
            break;
        default :
            returnValue = ""

    }
    return returnValue

}