import React, {Component} from 'react'

class TheadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterData: []
        }

    }

    render() {

        let style = {
            cursor: 'initial',
            ...this.props.style
        }
        let labelStyle = {
            cursor: 'pointer',
            ...this.props.children[0].props.className
        }
        let thClass = this.props.thClass ? this.props.thClass : ""
        let inClassName = this.props.children[0].props.className ? this.props.children[0].props.className : ""
        return (
            <div className={`rt-th ${this.props.className}`} role={this.props.role} tabIndex={this.props.tabIndex}
                 style={style}>
                <div onClick={async e => {
                    await this.props.onSort()
                    await this.props.toggleSort(e);
                }} style={labelStyle}
                     className={`${thClass} ${inClassName}`}>{this.props.children[0].props.children}</div>
            </div>

        )
    }
}

export default TheadComponent;

