import React, {useEffect} from "react";
import "../patientStyle/notes.css"
import utils from "../../../../utils/utils";
import {getExamValue} from "../exams/getExamsValue";

export function Examinations(props) {

    const adjustTextareaHeight = (event) => {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    return <div className={'notes-examination-card-container'}>
        <div className={'notes-examination-card-firstLine'}>
            <div className={'note-examination-card-title'} style={{color:utils.getExamLabelColor(props.exam?.exam)}}>{utils.getExamLabel(props.exam.exam,"notes")}</div>
            <div className={'note-examination-card-value'}>{props.exam ? getExamValue(props.exam?.exam,props.exams,"notes") :""}</div>
        </div>
        {/*<input className={`note-examination-card-input note-input-${props.exam?.exam}`} placeholder={'Add note...'} name={props.exam?.exam} onChange={props.examinationOnChange}/>*/}
        <textarea className={`note-examination-card-input note-input-${props.exam?.exam}`}
                  placeholder={'Add note...'}
                  onChange={(event)=>{props.examinationOnChange(event)
                      adjustTextareaHeight(event);}}
                  rows={"2"}
                  id={props.exam?.exam}
                  name={props.exam?.exam}>{props.value}</textarea>
        {props.isPath &&
        <div className={'notes-examination-path'}></div>
        }
    </div>
}