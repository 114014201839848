import React, {useEffect, useRef, useState} from "react";

import WaveSurfer from "wavesurfer.js";
import SVGraphics from "../../../../assets/SVGraphics";
import useMediaQuery from "../../../../utils/useMediaQuery";



export default function Waveform({url, image,closeAudio,isPlaying,setParentPlay,info,file,isPlayNew}) {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [playing, setPlay] = useState(false);
    const [currentTime, setCurrentTime] = useState('00:00');
    const [duration, setDuration] = useState('00:00');
    const progressBarRef = useRef();
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');

    const formWaveSurferOptions = ref => ({
        container: ref,
        waveColor: "rgba(255, 255, 255, 0.4)",
        progressColor: " #FFFFFF",
        cursorColor: " #FFFFFF",
        barWidth: 2,
        barRadius: 1,
        responsive: true,
        height: isMobilScreen?40:55,
        barGap: 3,
        // If true, normalize by the maximum peak instead of 1.0.
        normalize: true,
        // Use the PeakCache to improve rendering speed of large waveforms.
        partialRender: true
    });
    useEffect(() => {
        if(url){
            setPlay(false);
            const options = formWaveSurferOptions(waveformRef.current);
            wavesurfer.current = WaveSurfer.create(options);
            wavesurfer.current.load(url);
            wavesurfer.current.on('ready', function () {
                setDuration(secondsToTimestamp(wavesurfer.current.getDuration()))
                wavesurfer.current.play(wavesurfer.current.getCurrentTime(),wavesurfer.current?.getDuration())
                setPlay(true)
                wavesurfer.current.on('audioprocess', progressUpdate)

            })
            wavesurfer.current.on('finish', function () {
                // no jump when using MediaElement
                if (wavesurfer.current.getDuration() === wavesurfer.current.getCurrentTime()) {
                    closeAudio()
                }
            })
            // Removes events, elements and disconnects Web Audio nodes.
            // when component unmount
            return () => wavesurfer.current.destroy();
        }
    }, [url]);
    useEffect(() => {
        if(url){

            if(!isPlaying){
                wavesurfer.current.pause()
                setPlay(false)
            }else{
                wavesurfer.current.play(wavesurfer.current.getCurrentTime(),wavesurfer.current.getDuration())
                setPlay(true)
            }

        }
    },[isPlaying])


    function progressUpdate() {

        const percent = (wavesurfer?.current?.getCurrentTime() / wavesurfer.current.getDuration()) * 100
        if(progressBarRef.current){
            progressBarRef.current.style.flexBasis = `${percent}%`
        }
        setCurrentTime(secondsToTimestamp(wavesurfer?.current?.getCurrentTime()))
    }
    const secondsToTimestamp = (seconds) => {
        seconds = Math.floor(seconds);
        let h = Math.floor(seconds / 3600);
        let m = Math.floor((seconds - (h * 3600)) / 60);
        let s = seconds - (h * 3600) - (m * 60);
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        return  m + ':' + s;
    }
    const handlePlayPause = () => {
        setPlay(!playing);
        wavesurfer.current.playPause();
        setParentPlay(!playing)
    };
    const handleBackward = () => {

        wavesurfer.current.skipBackward(0.25);
        setCurrentTime(secondsToTimestamp(wavesurfer?.current?.getCurrentTime()))
    };
    const handleForward = () => {

        wavesurfer.current.skipForward(0.25);
        setCurrentTime(secondsToTimestamp(wavesurfer?.current?.getCurrentTime()))
    };

    const playControls = () => {
        return  <div className={`controls-play-buttons ${isMobilScreen?'controls-play-buttons-mobile':''}`}>
            <SVGraphics svgname={'backward'}
                        className={`audio-exam-play ${isMobilScreen ? 'audio-exam-play-waveform-mobile' : ''}`}
                        onClick={handleBackward}
            />
            <SVGraphics svgname={playing ? 'pause-white' : 'play-white'}
                        className={`audio-exam-play ${isMobilScreen ? 'audio-exam-play-waveform-mobile' : ''}`}
                        style={{marginLeft:isMobilScreen?'1.5rem':'1rem',marginRight:isMobilScreen?'1.2rem':'0.5rem' }}
                        onClick={handlePlayPause}
            />
            <SVGraphics svgname={'forward'}
                        className={`audio-exam-play ${isMobilScreen ? 'audio-exam-play-waveform-mobile' : ''}`}
                        onClick={handleForward}
            />
        </div>
    }
    const audioDetails = () => {
        return <div className={`controls-time ${isMobilScreen ? 'controls-time-mobile' : ''}`}>
            {/*{playControls}*/}
            <div className={`timecode ${isMobilScreen ? 'timecode-mobile' : ''}`}>
                <span id="currentTime">{currentTime}</span>
                <span>/</span>
                <span id="totalDuration">{duration}</span>
            </div>
            <div
                className={`timecode ${isMobilScreen ? 'timecode-mobile' : ''}`}>{`${info.type}, ${info.point} point, ${info.filter}`}</div>
        </div>
    }

    return (
        <>
            {url?
                <div className={`waveform-container ${isMobilScreen?'waveform-container-mobile':''}`}>

                    {/*<SVGraphics avgname={'close'}/>*/}
                    <SVGraphics svgname={'close-white'} className={`waveform-close ${isMobilScreen?'waveform-close-mobile':''}`} onClick={closeAudio}/>
                    <img className={`waveform-image ${isMobilScreen?'waveform-image-mobile':''}`} src={image}/>

                    <div className={`waveform-wrapper ${isMobilScreen?'waveform-wrapper-mobile':''}`}>
                        <div id={file?.fileID} ref={waveformRef} style={{height:isMobilScreen?'40px':'55px',marginTop:isMobilScreen?'5px':'0px'}}/>
                        <div>
                        </div>
                        <div className={'controls'}>
                            <div className="player-progress">
                                <div className="player-progress-filled" ref={progressBarRef}></div>
                            </div>
                            {isMobilScreen ?
                                <div className={'controls-time-container-mobile'}>
                                {audioDetails()}
                                {playControls()}
                                </div>
                                :
                                <div className={'controls-time-container'}>
                                    {playControls()}
                                    {audioDetails()}
                                </div>
                            }
                        </div>

                        {/*</div>*/}
                    </div>
                </div>
                :
                <div>
                    <div>No Audio</div>
                    <SVGraphics svgname={'close-white'} className={`waveform-close ${isMobilScreen?'waveform-close-mobile':''}`} onClick={closeAudio}/>
                </div>}
        </>
    );
}
