import React, {Component} from "react";
import SVGraphics from "../../../../assets/SVGraphics";
import ExamTabs from "./examTabs/ExamTabs";
import Exam from "./exams/Exam";
import Status from "../../../../config/Status";
import {doctorApi} from "../../../../services/ApiService";
import MakeVideo from "../../../modal/VideoModal";
import * as Yup from "yup";
import validationHelper from "../../../../utils/validationHelper";
import utils from "../../../../utils/utils";
import Error from "../../../Error/Error";
import MakeMessage from "../../../modal/MessageModal";
import Globals from "../../../../utils/Globals";
const {suggestion} = Globals
class SessionExams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: null,
            errors: {},
            errorsOnSave: {},
            summaryCont: {
                summary: '',
            },
            notesCont: {
                notes: '',
            },
        }
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            summary: Yup.string().required(t('physician.patient.profile.summaryErr'))
        });
        let errors = await validationHelper.validate(this.state.summaryCont, validationSchema);
        await this.setState({
            validationSchema,
            errors
        })
    };

    async onSummaryChange(event) {
        let value = event.target.value;
        let {validationSchema} = this.state;
        let summaryCont = {summary: value};
        let errors = await validationHelper.validate(summaryCont, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;

        if (utils.get(errors, 'summary')) {
            utils.set(errorsOnSave, 'summary', utils.get(errors, 'summary'))
        } else {
            delete errorsOnSave['summary']
        }
        this.setState({
            summaryCont,
            errors,
            errorsOnSave
        });
        // this.summaryRef = React.createRef()
    }

    async componentDidMount() {
        await this.initValidation();

        let {session} = this.props;
        if (!!session['summary']) {
            await this.onSummaryChange({target: {name: "", value: session['summary']}})
        }
        if (session.status === Status.ended) {
            this.props.setStatusRead(session['sessionID'])
        }
        // let body = {status:Status.ended}
        // let sessionResp = await doctorApi.updateSessionById(this.props.session['sessionID'],body)
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.initValidation();

        let {session} = this.props;
        if (!!session['summary']) {
            await this.onSummaryChange({target: {name: "", value: session['summary']}});
        }

    }

    getConditionDescriptionVideo(files) {
        let videoFile = null;
        files.forEach(file => {
            if (file['type'] === 'PatientVideo') videoFile = file
        });
        return videoFile
    }

    async openFullScreen(url) {
        await MakeVideo({url})
    }

    async saveSummary() {
        let {t} = this.props
        if (!utils.isEmpty(this.state.errors)) {
            this.setState({errorsOnSave: this.state.errors});
            return;
        }
        let {summaryCont,notesCont} = this.state;
        let {summary} = summaryCont;
        let {notes} = notesCont;
        let body = {status: Status.sent, summary,notes};
        let sessionResp = await doctorApi.updateSessionById(this.props.session['sessionID'], body);
        if (!!sessionResp) {
            let {session} = this.props;
            session['status'] = sessionResp['status'];
            session['summary'] = sessionResp['summary'];
            session['notes'] = sessionResp['notes'];
            session['updatedAt'] = sessionResp['updatedAt'];
            await MakeMessage({title: t('physician.patient.exams.sessionChanged'), message: t('physician.patient.exams.summarySaved')});
            this.props.saveSummary(session)
        }
    }

    async setSuggestion(suggestion, sessionID) {
        let ta = document.getElementById('session-notes-id-' + sessionID);
        let {notesCont} = this.state;

        let {notes} = notesCont;
        let suggestionValue = suggestion + ' ';
        if (!!notes) suggestionValue = ' ' + suggestionValue;
        notes = notes + suggestionValue;
        ta.value = notes;
    }

    render() {
        let {session, patient, id,t,tenant} = this.props;

        let language= 'en'
        let {type, errorsOnSave} = this.state;
        let conditionDescriptionVideo = this.getConditionDescriptionVideo(session['Files']);
        let quickSuggestions = !!type ? suggestion[type] : []
        return (
            <div className={'session-exams-container'} id={id}>
                <div className={'condition-description-container'}>

                    <div className={'condition-description-label'}>
                        {t('physician.patient.profile.condition')}
                    </div>
                    <div className={'condition-description-text'}>
                        {session['description']}
                    </div>
                    {!!conditionDescriptionVideo && <div className={'condition-description-video pointer'}
                                                         onClick={() => this.openFullScreen(conditionDescriptionVideo['downloadUrl'])}>{/*4c23b5*/}
                        <SVGraphics svgname={'play-circled'} className={'condition-description-play'}/>
                        <div className={'condition-description-play-text'}> {t('physician.patient.profile.playVideo')}</div>
                    </div>}
                </div>
                {
                    <div style={{display: "flex", justifyContent: 'center',width:'60%'}}>
                        {!!patient.vaccinations && Array.isArray(patient.vaccinations) && patient.vaccinations.length !== 0 && <div style={{display: "flex", background: ' #F6F4FB', marginRight: '10px'}}>Recent
                            vaccination: &nbsp;
                            {<label style={{fontWeight: '700'}}> {patient.vaccinations[0].vaccination}</label>}
                            &nbsp;{utils.getDateFormatWithYear(new Date(patient.vaccinations[0].date))}</div>}

                        {!!patient.travels && Array.isArray(patient.travels) && patient.travels.length !== 0 && <div style={{display: "flex", background: ' #F6F4FB'}}>
                            Recent travel: &nbsp;
                            {<label style={{fontWeight: '700'}}>{patient.travels[0].destination}</label>}
                            &nbsp; {utils.getDateFormatWithYear(new Date(patient.travels[0].date))}</div>}

                </div>}
                <div className={'session-exams-container'}>
                    <ExamTabs sessionID={session['sessionID']} exams={session['exams']} observations={session['Observations']} type={type}
                              t={this.props.t} onChange={type => this.setState({type})} tenant={tenant}/>
                    <Exam t={this.props.t} observations={session['Observations']} type={type} files={session['Files']}
                          patient={patient} tenant={tenant}/>
                </div>
                {quickSuggestions.length > 0&& <div className={'session-suggestions-container'}>
                    <div className={'quick-suggestions-label'} style={{color:tenant === 'fluentsmartexam'?"rgba(0, 45, 116,0.5)":"rgba(76, 35, 181,0.5)"}}>{t('physician.patient.exams.quickSuggestions')}</div>
                    <div className={'quick-suggestions'}>
                        {
                            quickSuggestions.map(suggestion => {
                                return (
                                    <div className={'quick-suggestion inline pointer'}
                                         style={{backgroundColor:tenant === 'fluentsmartexam'?"rgba(0, 45, 116,0.05)":"rgba(76, 35, 181, 0.05)"}}
                                         onClick={() => this.setSuggestion(suggestion, session['sessionID'])}>{t(`physician.patient.exams.${suggestion}`)}</div>
                                );
                            })
                        }
                    </div>
                </div>}

                <div style={{display:'flex' ,fontWeight:'bold',fontSize:'16px',color:"#2e2e2e"}}>
                    <label style={{flex:'1 auto',marginLeft:'35px'}}
                    >{t('physician.patient.exams.summaryForPatients')}</label>
                </div>

                <div className={'session-summary-container'}>

                    <textarea id={'session-summary-id-' + session['sessionID']}
                              className={'session-internalSummary'}

                              style={{color:"rgba(0, 45, 116,0.05)"}}
                              onChange={event => {
                                  this.onSummaryChange(event)
                              }} defaultValue={session['summary']}
                              disabled={true}
                              placeholder={t('physician.patient.exams.typeSummaryHere')}
                    />
                    <Error errorMessage={errorsOnSave['summary']} isShown={errorsOnSave.hasOwnProperty('summary')} isSummary={true}
                    />



                </div>
                <div className={'session-save-container'}>
                    {/*<button className={'session-save-btn btn mhd-btn'} onClick={() => this.saveSummary()}>{t('physician.patient.profile.saveUpdate')}*/}
                    {/*</button>*/}
                </div>
            </div>
        );
    }
}

export default SessionExams
