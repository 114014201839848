import '../patientStyle/exams.css'
import React, {useEffect, useState} from "react";
import {ExamAudio} from "./ExamAudio";
import useMediaQuery from "../../../../utils/useMediaQuery";
// import ExamsBodyImages from "./assetsExams/ExamsBodyImages";


export default function StethoscopeExam(props) {

    const [examPoints, setExamPoints] = useState([]);
    const [isLungs, setIsLungs] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [lungsBackPoints, setLungsBackPoints] = useState([]);
    const [bodyImages, setBodyImages] = useState(null);
    const [playedFile, setPlayedFile] = useState(null);
    const [dotName, setDotName] = useState(null);
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');
    const mdScreen = useMediaQuery('(max-width: 1439px)');

    let filtersFiles = {
        Diaphragm: props.files.filter(file => file['filter'] === 'Filter1'),
        Bell: props.files.filter(file => file['filter'] === 'Filter2'),
        Extended: props.files.filter(file => file['filter'] === 'Filter3'),
        Midband: props.files.filter(file => file['filter'] === 'Filter4')
    }
    useEffect(async() => {

        let heart = ['A', 'B', 'C', 'D']
        let abdomen = ['A', 'B']
        let lungsFront = ['A', 'B']
        let _examPoints
        const loadData = async () => {
            let data
            if (props.type === 'Heart') {
                const HeartImages =  await import("./assetsExams/Heart/HeartBodyImages");
                data = JSON.parse(JSON.stringify(HeartImages))
                _examPoints = heart
            } else if (props.type === 'Abdomen') {
                const AbdomenImages =await import ("./assetsExams/Abdomen/AbdomenBodyImages");
                data = JSON.parse(JSON.stringify(AbdomenImages))
                _examPoints = abdomen
            } else {
                _examPoints = lungsFront
                const LungsImages = await import( "./assetsExams/Lungs/LungsBodyImages");
                data = JSON.parse(JSON.stringify(LungsImages))
                setIsLungs(true)
            }
            setBodyImages(data['default'])
            return data['default']

        }

        let imageType = props.age < 12 ? 'CHILD' : props.gender
        loadData().then(resolve=>{
            if (resolve){
                setExamPoints(setPoints(resolve,_examPoints, imageType, props.type))
            }
        })

    }, [])
    useEffect(()=>{
        if(playedFile){
            setDotName(playedFile.dotName)
        }
    },[playedFile])

    const setPoints = (_bodyImages,pointType, imageType, type) => {
        let _points = []
        if(_bodyImages){
            if (props.type === 'Lungs') {
                pointType.forEach(p => {
                    _points.push({
                        src: _bodyImages[type]['Lungs_front'][imageType][imageType + '_' + p], point: p
                    })
                })
                let backPoints = []
                let lungsBack = ['A', 'B', 'C', 'D']
                lungsBack.forEach(p => {
                    backPoints.push({
                        src: _bodyImages[type]['Lungs_back'][imageType][imageType + '_' + p], point: p
                    })
                })
                setLungsBackPoints(backPoints)
            } else {
                pointType.forEach(p => {


                    _points.push({
                        src: _bodyImages[type][imageType][imageType + '_' + p], point: p
                    })
                })
            }
        }

        return _points
    }
    const setPlay = (file,fileID,type,point) => {
        if(!isPlaying){
            setPlayedFile(file)
            setIsPlaying(true)
            props.setPlayedFileId(fileID)
            props.setPlayedFile(file)
            props.setPlayedType(type)
            props.setPoint(point)
        }
    }
    const filters = ['Diaphragm', 'Bell', 'Extended', 'Midband']
    const getFilter = (point, src,side) => {
        let files = props.files.filter(file => file['dotName'] === point)
        if(side){
            files = files.filter(file => file['subType'] === side)
        }
        return <div className={isMobilScreen || isSmallScreen ? 'exam-filter-Container-mobile' : 'exam-filter-Container'}>
            {
                files.length > 0 ?
                    filters.map(filter => {
                        let _file = props.type === 'Lungs'?filtersFiles[filter].find(x=>x.dotName===point && x.subType===side):filtersFiles[filter].find(x=>x.dotName===point)
                        return <ExamAudio key={_file?.fileID} filter={filter} file={_file}
                                          image={src} point={point} type={props.type}
                                          isPlaying={props.playedFileId===_file?.fileID && props.playedType===props.type}
                                          setParentPlaying={()=>setPlay(_file,_file?.fileID,props.type,point)}
                                          setParentStop={()=>setIsPlaying(false)}
                                          setPlayedFileId={props.setPlayedFileId}
                                          setPoint={props.setPoint}
                                          setPlayedFile={props.setPlayedFile}
                                          setPlayedType={props.setPlayedType}

                        />
                    })
                    : <div className={'noAudioText'}>No Audio files</div>
            }
        </div>
    }

    return (
        <div>
            {!isLungs ?
                <div className={isSmallScreen || mediumScreen ? 'exam-heart-container-tablet' : isMobilScreen ? 'exam-heart-container-mobile' : 'exam-heart-container'}
                     style={{width: props.type === 'Abdomen' && !mdScreen ? '50%' : '100%'}}>
                    {examPoints.map(point => {

                        return <div className={`exam-body-parts-card ${isMobilScreen || isSmallScreen ? 'exam-body-parts-card-mobile' : ''}`}
                                    style={{width: isSmallScreen || mediumScreen || isMobilScreen ? '100%' : '24%',
                                    border: (props.playedType===props.type) && (point.point === props.point)? '2px solid #FD5A56':'1px solid #F0EFF2'
                                    }}
                        >
                            <div
                                className={`exam-body-parts-image-div ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : ''}`}>
                                <img
                                    className={`exam-body-parts-image ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : 'stethoscope-image'}`}
                                    src={point.src}/>
                            </div>
                            <div className={`exam-body-filter ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : ''}`}>
                                {getFilter(point.point, point.src)}
                            </div>
                        </div>
                    })}
                </div> :
                <div>
                    <div className={'stethoscopeExams_subLabel'}>Lungs Front</div>
                    <div className={isSmallScreen || mediumScreen ? 'exam-heart-container-tablet' : isMobilScreen ? 'exam-heart-container-mobile' : 'exam-heart-container'}
                         style={{width: !mdScreen ? '50%' : '100%'}}>
                        {examPoints.map(point => {
                            return <div className={`exam-body-parts-card ${isMobilScreen || isSmallScreen ? 'exam-body-parts-card-mobile' : ''}`}
                                        style={{width: isSmallScreen || mediumScreen || isMobilScreen ? '100%' : '24%',
                                            border: (props.playedType===props.type) && (point.point === props.point) &&(props.playedFile?.subType==='Front')? '2px solid #FD5A56':'1px solid #F0EFF2'
                                        }}>
                                <div
                                    className={`exam-body-parts-image-div ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : ''}`}>
                                    <img
                                        className={`exam-body-parts-image ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : 'stethoscope-image'}`}
                                        src={point.src}/>
                                </div>
                                <div
                                    className={`exam-body-filter ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : ''}`}>
                                    {getFilter(point.point, point.src,'Front')}
                                </div>
                            </div>
                        })}
                    </div>
                    <div className={'stethoscopeExams_subLabel'} style={{borderTop: '1px solid #F0EFF2', marginTop: '1rem'}}>
                        <div style={{marginTop: '0.75rem'}}>Lungs Back</div>
                    </div>
                    <div className={isSmallScreen || mediumScreen ? 'exam-heart-container-tablet' : isMobilScreen ? 'exam-heart-container-mobile' : 'exam-heart-container'}
                         style={{width: props.type === 'Abdomen' && !mdScreen ? '50%' : '100%',


                         }}>
                        {lungsBackPoints.map(point => {
                            return <div
                                className={`exam-body-parts-card ${isMobilScreen || isSmallScreen ? 'exam-body-parts-card-mobile' : ''}`}
                                style={{width: isSmallScreen || mediumScreen || isMobilScreen ? '100%' : '24%',
                                    border:  (props.playedType===props.type) && (point.point === props.point) &&(props.playedFile?.subType==='Back')? '2px solid #FD5A56':'1px solid #F0EFF2'
                                }}>
                                <div
                                    className={`exam-body-parts-image-div ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : ''}`}>
                                    <img
                                        className={`exam-body-parts-image ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : 'stethoscope-image'}`}
                                        src={point.src}/>
                                </div>
                                <div
                                    className={`exam-body-filter ${isMobilScreen || isSmallScreen ? 'exam-body-parts-image-div-mobile' : ''}`}>
                                    {getFilter(point.point, point.src,'Back')}
                                </div>
                            </div>
                        })}
                    </div>

                </div>
            }
        </div>
    )
}