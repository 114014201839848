import React, {useEffect, useRef, useState} from "react";
import "./patientStyle/notes.css"
import SVGraphics from "../../../assets/SVGraphics";
import {TemplateCard} from "./notesComponents/TemplateCard";
import {Examinations} from "./notesComponents/Examinations";
import utils from "../../../utils/utils";
import {getExamValue} from "./exams/getExamsValue";
import * as PropTypes from "prop-types";
import copyHtmlToClipboard from './notesComponents/CopyHtmlToClipboard'
import moment from "moment";
import useMediaQuery from "../../../utils/useMediaQuery";
import sessionsApi from "../../../services/ApiServices/doctorApi/sessionsApi";
import {timeout} from "rxjs";
import {authenticationService} from "../../../services/auth/AuthenticationService";






export function PatientsNotes(props) {

    const [notes, setNotes] = useState({});
    const [description, setDescription] = useState(null);
    const [data, setData] = useState([]);
    const [assessment, setAssessment] = useState('');
    const [treatment, setTreatment] = useState('');
    const [copied, setCopied] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [examDate, setExamDate] = useState('');
    const [notesSent, setNotesSent] = useState(false);
    const textRef = useRef(null);
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mobileTablet = isSmallScreen || isMobilScreen
    let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
    let timer
    const styles = {
        backgroundColor: 'lightblue',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'
    };


    useEffect( () => {
        setDescription(props.exam?.mainComplaint)
        setTreatment(props.exam?.treatment)
        setAssessment(props.exam?.assessment)
        setNotes(props.exam?.SessionExams)
        setExamDate(moment(props.exam['createdAt']).format('MMM DD, YYYY'))
        return () => clearTimeout(timer);
    },[props.exam])
    useEffect( () => {
        let isAuthenticated = Boolean(sessionStorage.getItem('userLoggedIn') === 'true');
        // sessionStorage.setItem('userLoggedIn','true');
        let user = authenticationService.currentUserValue
        if(isAuthenticated){
            setIsUserLoggedIn(true)
        }
    },[sessionStorage.getItem('userLoggedIn')])
    const examinationOnChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        let _notes = JSON.parse(JSON.stringify(notes))
        let selectedExam = _notes.findIndex(x=>x.exam===name)
        _notes[selectedExam]['note'] = value
        setNotes(_notes)
    }

    const copyNotes = () => {

        let html = `<div style="font-family:Tahoma;background-color: #FFFFFF"">` +
            `<div style="color=#2E2E2E; font-size: 26px; font-weight: 700;">Patient Record</div><br/><br/>` +
            `<div><label>Patient Name:</label> <label>${props.patient.fullName}</label></div>` +
            `<div><label>source:</label> <label>${props.patient.tenantName}</label></div>` +
            `<div><label>Session’s Date:</label> <label>${examDate}</label></div><br/>` +
            `<div><div style=" background-color: #FFFFFF"><div style="font-size: 16px; font-weight: bold;">Main Complaints</div>` +
            `<div>${props.exam?.mainComplaint?props.exam.mainComplaint:''}</div>` +
            `</div><br/><hr style="color=#F0EFF2"/><br/>` +
            ` <div style="border-bottom: 1px solid black; color #2E2E2E;font-size: 16px; font-weight: 700;">Examinations</div><br/>`;
        for (const [key, value] of Object.entries(notes)) {
            let examVal = getExamValue(key, props.exam, "copy")
            if (notes.hasOwnProperty(key)) {
                html += `<div style=" background-color: #FFFFFF">
                                           <div style="font-size: 16px; line-height: 24px;">${utils.getExamLabel(key)}</div>
                                          <div style="font-size: 16px; font-weight: bold;line-height: 24px;">${examVal}</div>
                                           <div style="color #2E2E2E;font-size: 16px; line-height: 24px;">${value}</div><br/>
                           </div>`;
            }
        }

        html += `</div><br/><hr style="color=#F0EFF2"/><br/>` +
        `<div><div style=" background-color: #FFFFFF"><div style="font-size: 16px; font-weight: bold;">Assessment</div>` +
        `<div>${assessment}</div>` +
        `</div><br/><hr style="color=#F0EFF2"/><br/>`+
        `<div><div style=" background-color: #FFFFFF"><div style="font-size: 16px; font-weight: bold;">Treatment</div>` +
        `<div>${treatment}</div>` +
        `</div><br/><hr style="color=#F0EFF2"/><br/>`


        html += '</div>';
        copyStyledTextToClipboard(html);
    }
    const copyStyledTextToClipboard = (htmlString) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        document.body.appendChild(tempElement);

        const range = document.createRange();
        range.selectNode(tempElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        try {
            document.execCommand('copy');
            setCopied(true)

        } catch (error) {
            console.error('Error copying styled text to clipboard:', error);
        }

        window.getSelection().removeAllRanges();
        document.body.removeChild(tempElement);
        let timeOut = setTimeout(() =>  setCopied(false), 2000);
        return () => clearTimeout(timeOut);
    };
    const sendVisitSummary = async () => {
        let summary = {
            SessionExams:notes,
            assessment:assessment,
            treatment:treatment,
            mainComplaint:description
        }
        let sendNotes = await sessionsApi.saveNotes(summary,props.exam?.patientID,props.exam?.sessionID)
        if(sendNotes){
            setNotesSent(true)
             timer = setTimeout(() => {
                 setNotesSent(false)
            }, 2000);
            props.sendVisitSummary()
        }



    }

    return (
        <div className={'notes-container'}>

            {/*<textarea ref={textRef} style={{position: 'absolute', top: '-9999px'}}/>*/}
            {
                copied &&
                <div
                    style={{width:isMobilScreen?'90%':'16.5rem',right:mobileTablet?'4%':'11.8%',top:mobileTablet?'10%':'15%',position:mobileTablet?'absolute':'unset'}}
                    className={'notes-copied'} >
                    <SVGraphics width={"1.188rem"} height={"1.188rem"} style={{marginBottom:'3px'}} svgname={'check-circle'}/>
                    <div className={'notes-copied-text'}>Notes copied to clippboart</div>

                </div>
            }
            <div className={'notes-title-section'}>

                <div className={'notes-title'}>
                    {props.isMobile && <div onClick={props.closeNotes} className={'notes-arrow'}>&#8592;</div>}
                    Notes
                </div>
                <button className={'notes-copyNotes-button'} style={{width:isSafari&&mobileTablet?'10.5rem':'8.5'}} onClick={() => copyNotes()}>
                    <SVGraphics width={"0.9688rem"} height={"0.9688rem"} svgname={'copy-purple'}/>
                    <div >Copy Notes</div>
                </button>

            </div>
            <div className={'notes-body'}>
                <div className={'notes-body-section'}>
                    <TemplateCard
                        title={"Main Complaints"}
                        body={<div className={'notes-template-card-container-body'}>
                            <textarea className={'notes-template-card-body-main'}
                                      placeholder={'Main Complaints...'}
                                      value={description?description:""}
                                      onChange={(e) => setDescription(e.target.value)}
                                      name={'mainComplaint'}></textarea>
                        </div>}
                    />
                </div>
                <div className={'notes-body-section'}>
                    <TemplateCard
                        title={"Examinations"}
                        body={
                            props.exam?.SessionExams?.map((ex, index) => {
                                if(ex.exam!=='Heart_Rate'){
                                    return <Examinations key={ex.exam} exam={ex} exams={props.exam}
                                                         isPath={index !== props.exam?.SessionExams.length - 1}
                                                         value={ex.note}
                                                         examinationOnChange={(e) => examinationOnChange(e)}/>
                                }

                            })
                        }
                    />
                </div>
                <div className={'notes-body-section'}>
                    <TemplateCard
                        title={"Assessment"}
                        body={<div className={'notes-template-card-container-body'}>
                            <textarea className={`note-examination-card-input`} onChange={e=>setAssessment(e.target.value)} placeholder={'Add diagnosis...'}
                                      value={assessment?assessment:""}
                            >

                            </textarea>
                            {/*<input className={`note-examination-card-input`} placeholder={'Type tag name here...'}/>*/}
                        </div>}
                    />
                </div>
                <div className={'notes-body-section'}>
                    <TemplateCard
                        title={"Treatment"}
                        body={<div className={'notes-template-card-container-body'}>
                            <textarea className={`note-examination-card-input`} onChange={e=>setTreatment(e.target.value)} placeholder={'Add Treatment...'}
                                      value={treatment?treatment:""}
                            >

                            </textarea>
                        </div>}
                    />
                </div>

                {
                   isUserLoggedIn &&
                   <>
                    <button style={{marginBottom:notesSent?'1rem':'6rem'}} className={'notes-sendNotes-button'} onClick={()=>sendVisitSummary()}>Send Visit Summary</button>
                {notesSent &&
                    <div
                    style={{width:isMobilScreen?'90%':'16.5rem', marginBottom:'5rem',position:isMobilScreen?'relative':'unset', bottom:isMobilScreen && '13rem'}}
                    className={'notes-sent'} >
                    <SVGraphics width={"1.188rem"} height={"1.188rem"} style={{marginBottom:'3px'}} svgname={'check-circle'}/>
                    <div className={'notes-copied-text'}>Visit summary sent</div>

                    </div>
                }
                   </>

                }


                {/*<div></div>*/}
            </div>
        </div>
    );
}