import React, {Component} from "react";
import TempExam from "./TempExam";
import '../../../../../../style/View/physician/patient/Exam.css'
import SaturationExam from "./SaturationExam";
import HeartExam from "./HeartExam";
import AbdomenExam from "./AbdomenExam";
import LungsExam from "./LungsExam";
import EarsExam from "./EarsExam";
import ThroatExam from "./ThroatExam";
import SkinExam from "./SkinExam";

class Exam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exam: <div/>
        }
    }

    getExamContainer(type, observations, files, patient, examRef) {
        let {t} = this.props
        switch (type) {
            case 'Body_Temperature':
                return <TempExam observations={observations}/>
            case 'Saturation':
                return <SaturationExam observations={observations}/>
            case 'Heart':
                return <HeartExam t={this.props.t} examRef={ref => examRef(ref)} files={files.filter(file => file['type'] === 'Heart')} patient={patient}/>
            case 'Abdomen':
                return <AbdomenExam t={this.props.t} examRef={ref => examRef(ref)} files={files.filter(file => file['type'] === 'Abdomen')} patient={patient}/>
            case 'Lungs':
                return <LungsExam t={this.props.t} examRef={ref => examRef(ref)} lbFiles={files.filter(file => file['type'] === 'Lungs' && file['subType'] === 'Back')}
                                  lfFiles={files.filter(file => file['type'] === 'Lungs' && file['subType'] === 'Front')}
                                  patient={patient}/>
            case 'Ears':
                return <EarsExam t={this.props.t} files={files.filter(file => file['type'] === 'Ears' && file['downloadUrl'] != null)}/>
            case 'Throat':
                return <ThroatExam t={this.props.t} files={files}/>
            case 'Skin':
                return <SkinExam t={this.props.t} files={files}/>
            default:
                return <div/>
        }
    }

    render() {
        let {type, observations, files, patient} = this.props
        let examHtml = this.getExamContainer(type, observations, files, patient)
        return (
            <div className={'exam-container'}>
                {examHtml}
            </div>
        );
    }

}

export default Exam
