import React from 'react';
import {Route, Switch} from "react-router-dom";
import NotFound from "./NotFound";
import {RedirectRoutes, RedirectRoutesConfig} from "../../routes/components_access/Redirect";
import utils from "../../utils/utils";
import Middleware from "../../routes/Middleware";
import PatientCardRedirectMetas from "./PatientCardRedirect/PatientCardRedirectMetas";

class Redirect extends React.Component {

    buildRoutes() {

        let that = this
        let routes = [];
        let middlewares = ['userAccessRoute'];
        RedirectRoutesConfig.routes.forEach((obj, index) => {
            if (!utils.isEmpty(RedirectRoutes[obj.component])) {
                const component = Middleware.routeToDisplay(
                    middlewares,
                    <Route
                        key={index}

                        path={obj.url}
                        render={(props) => {
                            if (obj.isAllowPermissionsControl){
                                props['setAllowedPermission'] = (isPermissionsNotificationAllowed) => this.setAllowedPermission(isPermissionsNotificationAllowed);
                            }
                            if (obj.signal) {
                                props['setCardRef'] = (ref) => this.setCardRef(ref);
                            }
                            return RedirectRoutes[obj.component]({props})
                        }}/>
                );
                if (!utils.isEmpty(component)) {
                    routes.push(component);
                }
            }
        });
        routes = routes.filter((x) => {
            return !utils.isEmpty(x);
        });
        return routes;
    };

    render() {

        const url = window.location.origin + this.props.location.pathname
        let image = url.toLowerCase().includes('us.')?"https://myhomedoc-s3-mailing-assets-us-east-1.s3.eu-central-1.amazonaws.com/nonagon_ico.png":"https://myhomedoc-s3-mailing-assets-eu-central-1.s3.eu-central-1.amazonaws.com/nonagon_ico.png"

        return (
            <div style={{height: '100%'}}>
                {
                    url.includes('rdct/pcrd') &&
                        <PatientCardRedirectMetas url={url} image={image}/>
                }

                <Switch>
                    {this.buildRoutes()}
                    <Route exact component={NotFound}/>
                </Switch>
            </div>

        )
    }
}


export default Redirect
