import React, {Component} from "react";
import ExamTab from "./ExamTab";


class ExamTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isTabOpen: false,
            tabs: {
                Body_Temperature: {
                    disabled: true,
                    active: false,
                    color: '#F8A315',
                    value: undefined
                },
                Saturation: {
                    disabled: true,
                    active: false,
                    color: '#05D2CE',
                    value: undefined
                },
                Heart: {
                    disabled: true,
                    active: false,
                    color: '#FD5A56'
                },
                Abdomen: {
                    disabled: true,
                    active: false,
                    color: '#C10096'
                },
                Lungs: {
                    disabled: true,
                    active: false,
                    color: '#0085FF'
                },
                Ears: {
                    disabled: true,
                    active: false,
                    color: '#4C23B5'
                },
                Throat: {
                    disabled: true,
                    active: false,
                    color: '#FF64AE'
                },
                Skin: {
                    disabled: true,
                    active: false,
                    color: '#FF7B4A'
                },
            }
        }
    }

    toggle(type) {
        let {tabs} = this.state
        Object.entries(tabs).forEach(([name, options]) => {
            if (type === name) options.active = true
            else options.active = false
        })
        this.setState({tabs,isTabOpen: true})
        this.props.onChange(type)
    }

    componentDidMount() {
        let {type, exams, observations} = this.props
        let {tabs} = this.state
        let isTabOpen = false
        let allowToggle = true;
        let toggleType;
        Object.entries(tabs).forEach(([name, options]) => {
            if (exams.includes(name)) {
                options.disabled = false;
                if (!isTabOpen){
                    options.active = true
                    isTabOpen = true
                    this.toggle(name)
                }
                if (allowToggle) {
                    toggleType = name;
                    allowToggle = false;
                }
            }
            if (name === 'Body_Temperature') {
                let observation = observations.find(observation => observation.type === name);
                if (observation) options.value = observation.value
            } else {
                if (name === 'Saturation') {
                    // let heartRate = observations.find(observation => observation.type === 'Heart_Rate');
                    let saturation = observations.find(observation => observation.type === 'Saturation');

                    if (/*!!heartRate && */!!saturation) options.value = saturation.value + '% ' + /*heartRate.value*/ saturation.secondValue + 'bpm'
                }
            }

        })
        this.setState({tabs,isTabOpen})

        if (isTabOpen && type) this.toggle(type);
        else if (isTabOpen && toggleType) {
            this.toggle(toggleType);
        }
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        let {type, exams, observations} = this.props
        let {tabs,isTabOpen} = this.state
        let allowToggle = true;
        let toggleType;
        if (!!type && exams.length === 0) this.props.onChange(null)

        Object.entries(tabs).forEach(([name, options]) => {
            if (exams.includes(name)) {
                options.disabled = false;
                if (!isTabOpen){
                    options.active = true
                    isTabOpen = true
                    this.toggle(name)
                }
                if (allowToggle) {
                    toggleType = name;
                    allowToggle = false;
                }
            } else {
                options.disabled = true
            }
            if (name === 'Body_Temperature') {
                let observation = observations.find(observation => observation.type === name);
                if (observation) options.value = observation.value
            } else {
                if (name === 'Saturation') {
                    // let heartRate = observations.find(observation => observation.type === 'Heart_Rate');
                    let saturation = observations.find(observation => observation.type === 'Saturation');

                    if (/*!!heartRate && */!!saturation) options.value = saturation.value + '% ' + /*heartRate.value*/ saturation.secondValue + 'bpm'
                }
            }

        })

        if (tabs !== this.state.tabs) {
            this.setState({tabs})
            if (isTabOpen && type) this.toggle(type);
            else if (isTabOpen && toggleType) {
                this.toggle(toggleType)
            }
        }
    }

    render() {
        let {tabs} = this.state
        let {t} = this.props
        return (
            <div className={'session-tabs-container'}>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Body_Temperature'])['color']}
                         active={(tabs['Body_Temperature'])['active']}
                         title={t('physician.patient.exams.temp')}
                         examType={'Body_Temperature'} disabled={(tabs['Body_Temperature'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Saturation'])['color']}
                         active={(tabs['Saturation'])['active']}
                         title={t('physician.patient.exams.saturation')}
                         examType={'Saturation'} disabled={(tabs['Saturation'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Heart'])['color']}
                         active={(tabs['Heart'])['active']}
                         title={t('physician.patient.exams.heart')} examType={'Heart'}
                         disabled={(tabs['Heart'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Abdomen'])['color']}
                         active={(tabs['Abdomen'])['active']}
                         title={t('physician.patient.exams.abdomen')} examType={'Abdomen'}
                         disabled={(tabs['Abdomen'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Lungs'])['color']}
                         active={(tabs['Lungs'])['active']}
                         title={t('physician.patient.exams.lungs')} examType={'Lungs'}
                         disabled={(tabs['Lungs'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Ears'])['color']}
                         active={(tabs['Ears'])['active']}
                         title={t('physician.patient.exams.ears')} examType={'Ears'}
                         disabled={(tabs['Ears'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Throat'])['color']}
                         active={(tabs['Throat'])['active']}
                         title={t('physician.patient.exams.throat_mouth')} examType={'Throat'}
                         disabled={(tabs['Throat'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Skin'])['color']}
                         active={(tabs['Skin'])['active']}
                         title={t('physician.patient.exams.skin')} examType={'Skin'}
                         disabled={(tabs['Skin'])['disabled']}/>
            </div>
        );
    }

}

export default ExamTabs
