import React, {Component} from "react";

class FA_Abdomen extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'
        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#c10096'

        let A_color = grey
        let B_color = grey

        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){return (

            <svg viewBox={"0 0 340 418"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF"
                          d="M320.184 430.493l-2.567 4.624-5.646 4.622-9.67-3.265-20.703-35.924-21.213-52.096-3.387-18.949-20.866-50.537-8.088-21.885.246 24.928-8.686 14.099-4.742 37.052 12.036 25.917.061.144 16.203 46.609 2.588 26.264 1.006 10.205-.925 10.421-1.725 40.69-14.868 77.911-8.521 35.615-4.539 30.415 1.128 55.467-3.471 31.938-8.87 44.369-5.398 31.766 4.396 10.998-1.377 8.776 2.029 8.591 9.776 26.532 1.192 7.686-11.089 11.015-18.382 4.48-1.375-.779-4.272.987-7.763-4.542-1.292-24.634.821-19.531.944-7.215-1.642-9.227 4.497-11.365-2.486-39.52-2.525-44.676 1.108-39.59 3.78-28.813-.823-28.256-.39-36.458.042-43.179-2.482-40.012.144-33.929 1.398-5.651.351-1.399c-.925.275-1.888.408-2.853.392-.473 0-.904-.061-1.356-.083-.452.022-.904.083-1.356.083-.916.02-1.83-.098-2.711-.352-.04-.019-.102-.019-.142-.04l1.744 7.05.14 33.929-2.51 40.026.061 43.179-.411 36.458-.821 28.256 3.808 28.813 1.11 39.59-2.548 44.676-2.485 39.52 4.498 11.365-1.623 9.228.946 7.214.8 19.531-1.294 24.634-7.737 4.542-4.292-.987-1.355.779-18.401-4.48-11.068-11.015 1.171-7.686 9.765-26.538 2.803-9.448-1.355-8.776 4.432-9.7-6.208-32.2-8.871-44.377-3.476-31.938 1.109-55.467-4.518-30.415-8.537-35.615-14.87-77.911-1.704-40.69-.924-10.421.967-9.76 2.63-26.716 16.204-46.609.062-.144 12.034-25.917-4.727-37.048-8.687-14.1-.123-24.393-7.721 21.351-20.866 50.533-3.388 18.949L58.28 400.55l-20.7 35.924-11.582 6-8.215-9.926 6.202-14.94-.062-.185 5.854-20.133 5.38-18.888 6.961-26.14 7.455-23.552 9.919-14.263 4.334-20.517 9.26-46.65 6.507-31.301 12.054-19.913 18.566-8.487 30.927-9.434 10.33-9.124.717-15.618.679-15.167 15.505-10.111 1.561.352 1.54-.352 15.524 10.111.719 15.64.679 15.145 10.328 9.124 30.911 9.434 18.585 8.487 12.055 19.913 6.506 31.301 9.241 46.65 4.354 20.51 9.918 14.263 7.456 23.552 6.962 26.14 5.381 18.888 5.851 20.14 4.243 13.07z"/>
                    <path fill="#E1C7B8"
                          d="M225.874 219.168v18.828l5.73 20.121-3.326 23.9-8.686 14.099-.287 2.26-16.943-4.294-26.963-2.692-3.84-.39-29.54 3.082-21.226 5.995-.513-3.966-8.686-14.099-3.204-25.175 3.183-27.067-3.088-17.795-1.848-5.055-.267-.76-3.369-9.27-4.362-4.008 9.18-4.191 2.403-1.111 3.942-1.193 3.902 8.117 10.967 21.58 20.186 35.974 22.939 29.913 23.781-29.913 13.349-38.886 7.188-19.004 7.455-8.734 5.709 2.159 9.223 4.214-7.148 8.178-5.841 19.183zM93.252 432.548l22.248.343 23.445 6.08 17.799 10.189 9.124 12.055c.881.254 1.795.373 2.711.352.452 0 .904-.061 1.356-.082.452.021.879.082 1.355.082.966.016 1.928-.117 2.854-.392l9.639-13.383 16.514-8.821 20.278-5.138 25.179-1.737.76-.062-3.341-26.201-10.01-28.778-8.201 6.596-27.591 10.422-51.581.384-35.581-9.602-4.686-4.399-8.818 25.377-3.453 26.715z"/>
                    <path fill="#ECD2C2"
                          d="M187.718 153.876l-10.678 10.48h-13.696l-11.151-10.953.679-15.167 15.505-10.111 1.561.352 1.54-.352 15.526 10.111.714 15.64z"/>
                    <path fill="#82543A"
                          d="M146.913 54.666l-5.899-8.733 2.567-16.698 11.808-10.018 16.942-2.569 20.023 6.687 5.391 20.807-7.188 12.588-43.644-2.064z"/>
                    <path fill="#FBDFCF"
                          d="M206.298 69.304l-11.809-16.955-20.537-4.11-3.764.25-3.766-.25-20.535 4.113-11.807 16.952-2.56 19.531 2.053 14.387 6.682 27.745 5.988 10.446 17.114 16.783h13.692l17.113-16.783 5.991-10.446 6.681-27.745 2.054-14.387-2.59-19.531z"/>
                    <path fill="#FBDFCF"
                          d="M136.133 101.419l-5.648-1.282-2.053 3.083 3.85 11.303 3.851 7.962 3.594 7.45 5.134.257-8.728-28.773zM204.157 101.419l5.648-1.282 2.054 3.083-3.854 11.307-3.851 7.962-3.594 7.45-5.134.257 8.731-28.777z"/>
                    <path fill="#82543A"
                          d="M204.414 57.15l-14.546-9.42-17.3-1.311.014-.403-2.651.2-2.653-.2.015.403-17.299 1.31-14.547 9.421-8.215 16.61 2.738 22.435 2.738 6.687 5.477 11.817-1.368-18.331 3.937-14.728 18.653-4.966 9.057 1.035 1.467.176 1.468-.176 9.057-1.035 18.654 4.966 3.935 14.728-1.37 18.324 5.475-11.817 2.74-6.687 2.737-22.434-8.213-16.604z"/>
                    <path fill="#FBDFCF" d="M171.794 359.483l-3.363-4.047 2.913-4.647 3.165 3.776-2.715 4.918z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M171.5 157L171.5 393M171.5 157L171.5 393"/>
                    </g>
                    <path fill="#2E2E2E"
                          d="M228.624 168c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.093.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.131.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404zM99.624 168c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.093.028-.168.084-.224.056-.065.13-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.181.854 2.114 0 .737-.182 1.335-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .075-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.065-.135-.163-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.13.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.163 1.806-.49.401-.336.602-.835.602-1.498s-.201-1.157-.602-1.484c-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M81 274.5L257 274.5M81 274.5L257 274.5"/>
                    </g>
                    <g opacity=".5">
                        <path stroke="#fff" d="M99 183.5L239 183.5M99 183.5L239 183.5"/>
                    </g>
                    <path fill={B_color}
                          d="M181 363c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M189.401 368c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM193 373.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM193 352.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={A_color}
                          d="M137 363c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M144.409 368c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM149 373.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM149 352.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H300V381H0z" transform="translate(17 13)"/>
                    </clipPath>
                    <filter id="filter0_d" width="340" height="421" x="0" y="-3" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );


        }else{return (

            <svg viewBox={"0 0 340 418"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                <path d="m303.185 414.493-2.568 4.624-5.646 4.622-9.67-3.265-20.703-35.924-21.213-52.096-3.387-18.949-20.866-50.537-8.088-21.885.246 24.928-8.686 14.099-4.742 37.052 12.036 25.917.061.144 16.203 46.609 2.588 26.264 1.006 10.205-.925 10.421-1.725 40.69-14.868 77.911-8.521 35.615-4.539 30.415 1.128 55.467-3.471 31.938-8.87 44.369-5.398 31.766 4.396 10.998-1.377 8.776 2.029 8.591 9.776 26.532 1.192 7.686-11.089 11.015-18.382 4.48-1.375-.779-4.272.987-7.763-4.542-1.292-24.634.821-19.531.944-7.215-1.642-9.227 4.497-11.365-2.486-39.52-2.525-44.676 1.108-39.59 3.78-28.813-.823-28.256-.39-36.458.042-43.179-2.482-40.012.144-33.929 1.398-5.651.351-1.399a9.466 9.466 0 0 1-2.853.392c-.473 0-.904-.061-1.356-.083-.452.022-.904.083-1.356.083a9.07 9.07 0 0 1-2.711-.352c-.04-.019-.102-.019-.142-.04l1.744 7.05.14 33.929-2.51 40.026.061 43.179-.411 36.458-.821 28.256 3.808 28.813 1.11 39.59-2.548 44.676-2.485 39.52 4.498 11.365-1.623 9.228.946 7.214.8 19.531-1.294 24.634-7.737 4.542-4.292-.987-1.355.779-18.401-4.48-11.068-11.015 1.171-7.686 9.765-26.538 2.803-9.448-1.355-8.776 4.432-9.7-6.208-32.2-8.871-44.377-3.476-31.938 1.109-55.467-4.518-30.415-8.537-35.615-14.87-77.911-1.704-40.69-.924-10.421.967-9.76 2.63-26.716 16.204-46.609.062-.144 12.034-25.917-4.727-37.048-8.687-14.1-.123-24.393-7.72 21.351-20.867 50.533-3.388 18.949L41.28 384.55l-20.7 35.924-11.582 6-8.215-9.926 6.202-14.94-.062-.185 5.854-20.133 5.38-18.888 6.961-26.14 7.455-23.552 9.92-14.263 4.333-20.517 9.26-46.65 6.507-31.301 12.054-19.913 18.566-8.487 30.927-9.434 10.33-9.124.717-15.618.679-15.167 15.505-10.111 1.561.352 1.54-.352 15.524 10.111.719 15.64.679 15.145 10.328 9.124 30.911 9.434 18.585 8.487 12.055 19.913 6.506 31.301 9.241 46.65 4.354 20.51 9.918 14.263 7.456 23.552 6.962 26.14 5.381 18.888 5.851 20.14 4.243 13.07z" fill="#FBDFCF"/>
                <path d="M208.874 203.169v18.828l5.73 20.121-3.326 23.9-8.686 14.099-.287 2.26-16.943-4.294-26.963-2.692-3.84-.39-29.54 3.082-21.226 5.995-.513-3.966-8.686-14.099-3.204-25.175 3.183-27.067-3.088-17.795-1.848-5.055-.267-.76-3.369-9.27-4.362-4.008 9.18-4.191 2.403-1.111 3.942-1.193 3.902 8.117 10.967 21.58 20.186 35.974 22.939 29.913 23.781-29.913 13.349-38.886 7.188-19.004 7.455-8.734 5.709 2.159 9.223 4.214-7.148 8.178-5.841 19.183zM76.252 416.549l22.248.343 23.445 6.08 17.799 10.189 9.124 12.055a9.038 9.038 0 0 0 2.711.352c.452 0 .904-.061 1.356-.082.452.021.879.082 1.355.082a9.473 9.473 0 0 0 2.854-.392l9.639-13.383 16.514-8.821 20.278-5.138 25.179-1.737.76-.062-3.341-26.201-10.01-28.778-8.201 6.596-27.591 10.422-51.581.384-35.581-9.602-4.686-4.399-8.818 25.377-3.453 26.715z" fill="#E1C7B8"/>
                <path d="m170.718 137.876-10.678 10.48h-13.696l-11.151-10.953.679-15.167 15.505-10.111 1.561.352 1.54-.352 15.526 10.111.714 15.64z" fill="#ECD2C2"/>
                <path d="m129.913 38.666-5.899-8.733 2.567-16.698 11.808-10.018L155.331.648l20.023 6.687 5.391 20.807-7.188 12.588-43.644-2.064z" fill="#82543A"/>
                <path d="M189.298 53.304 177.489 36.35l-20.537-4.11-3.764.25-3.766-.25-20.535 4.113-11.807 16.952-2.56 19.531 2.053 14.387 6.682 27.745 5.988 10.446 17.114 16.783h13.692l17.113-16.783 5.991-10.446 6.681-27.745 2.054-14.387-2.59-19.53z" fill="#FBDFCF"/>
                <path d="m119.133 85.42-5.648-1.282-2.053 3.083 3.85 11.303 3.851 7.962 3.594 7.45 5.134.257-8.728-28.773zm68.024 0 5.648-1.282 2.054 3.083-3.854 11.307-3.851 7.962-3.594 7.45-5.134.257 8.731-28.777z" fill="#FBDFCF"/>
                <path d="m187.414 41.15-14.546-9.42-17.3-1.31.014-.403-2.651.2-2.653-.2.015.403-17.299 1.31-14.547 9.42-8.215 16.61 2.738 22.436 2.738 6.687 5.477 11.817-1.368-18.331 3.937-14.728 18.653-4.966 9.057 1.035 1.467.176 1.468-.176 9.057-1.035 18.654 4.966 3.935 14.728-1.37 18.324 5.475-11.817 2.74-6.687 2.737-22.434-8.213-16.604z" fill="#82543A"/>
                <path d="m154.795 343.483-3.363-4.047 2.913-4.647 3.165 3.776-2.715 4.918z" fill="#FBDFCF"/>
                <g opacity=".5">
                    <path d="M154.5 141v236-236zm0 0v236z" fill="#000"/>
                    <path d="M154.5 141v236m0-236v236-236z" stroke="#fff"/>
                </g>
                <path d="M211.624 152a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.302.302 0 0 1-.224.084h-.266zM82.624 152a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.37 1.246.378 2.058.01.42.014.794.014 1.12 0 .318-.004.686-.014 1.106-.018.859-.144 1.568-.378 2.128a2.42 2.42 0 0 1-1.176 1.26c-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.44-.205.752-.527.938-.966.196-.448.3-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                <g opacity=".5">
                    <path d="M64 258.5h176H64zm0 0h176z" fill="#000"/>
                    <path d="M64 258.5h176m-176 0h176H64z" stroke="#fff"/>
                </g>
                <g opacity=".5">
                    <path d="M82 167.5h140H82zm0 0h140z" fill="#000"/>
                    <path d="M82 167.5h140m-140 0h140H82z" stroke="#fff"/>
                </g>
                <path fill={B_color} d="M164 347c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path fill="#fff" d="M172.401 352a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM176 357.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM176 336.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                <path fill={A_color} d="M120 347c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path fill="#fff" d="M127.409 352a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zM132 357.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM132 336.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H300V381H0z" transform="translate(0 0)"/>
                    </clipPath>
                    <filter id="filter0_d" width="340" height="421" x="0" y="-3" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );


        }

    }

}

export default FA_Abdomen

