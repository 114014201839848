import React from "react";

class SkinBodyIndicatorFront extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, part, side} = this.props

        let isFace = (part).toLowerCase() === 'face'
        let isNick = (part).toLowerCase() === 'neck'
        let isChest = (part).toLowerCase() === 'chest'
        let isAbdomen = (part).toLowerCase() === 'abdomen'
        let isGenitals = (part).toLowerCase() === 'genitals' || (part).toLowerCase() === 'groin'
        let isRightArm = (part).toLowerCase() === 'arm' && (side).toLowerCase() === 'right'
        let isRightHand = (part).toLowerCase() === 'hand' && (side).toLowerCase() === 'right'
        let isLeftArm = (part).toLowerCase() === 'arm' && (side).toLowerCase() === 'left'
        let isLeftHand = (part).toLowerCase() === 'hand' && (side).toLowerCase() === 'left'
        let isRightLeg = (part).toLowerCase() === 'leg' && (side).toLowerCase() === 'right'
        let isRightFoot = (part).toLowerCase() === 'foot' && (side).toLowerCase() === 'right'
        let isLeftLeg = (part).toLowerCase() === 'leg' && (side).toLowerCase() === 'left'
        let isLeftFoot = (part).toLowerCase() === 'foot' && (side).toLowerCase() === 'left'


        let xmlns = "http://www.w3.org/2000/svg"
        return (
            <svg viewBox={"0 0 167 369"}
                 width={width}
                 height={height}
                 fill="none"
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <svg xmlns="http://www.w3.org/2000/svg" width="167" height="369" fill="none" viewBox="0 0 167 369">
                    <path fill="#FBDFCF"
                          d="M143.645 173.907l-2.768 3.01-5.529-.752-11.571-24.329-4.021-11.29-.249-6.524-5.536-11.039-1.939-5.622-1.079 4.869-2.262 11.29-2.263 7.029.754 10.031.755 4.515 2.263 15.554 4.528 18.183 1.008 25.206-2.767 17.06-1.509 12.288.755 10.535 2.012 9.783-1.134 16.808-3.017 18.813-5.278 21.446 1.38 3.95.566 3.575v3.202l3.017 6.961 3.111 7.196-.905 2.084-8.425 3.261-5.657-.564-2.263-1.693-1.07-2.132-.193-10.162.755-6.019-.566-4.704 1.508-5.267-.565-13.921-1.509-17.684-1.003-6.083-.129-14.424.755-6.961-1.698-8.654-.754-14.297-3.395-16.367-3.205-12.793-1.13-14.924-1.005 14.924-3.206 12.793-3.395 16.367-.753 14.297-1.697 8.654.754 6.961v14.924l-1.131 5.581-1.509 17.684-.566 13.921 1.509 5.268-.566 4.703.754 6.02-.188 10.159-.818 3.136-2.262 1.694-5.657.564-3.143-1.317-4.903-1.69-1.32-2.07 3.268-8.215 3.018-6.96v-3.198l.565-3.574 1.38-3.95-5.528-20.95-3.27-18.559-1.002-17.814 2.006-9.783.755-10.536-1.51-12.287-2.762-17.059.72-24.891 4.81-18.496 2.261-15.557.755-4.515.754-10.031-2.263-7.029-2.262-11.29-1.072-4.869-1.946 5.622-5.526 11.04-.257 6.524-4.02 11.29-11.561 24.328-5.537.752-2.76-3.01 2.262-9.782 3.516-22.072 2.52-11.29 4.268-6.524 2.014-14.049V99.657l1.51-11.536 3.772-6.413 6.363-4.568 6.709-.948 4.782-.36 11.81-6.772.385-8.82.31-7.11 19.8-.189.385 8.158.37 7.961 11.819 6.773 4.775-.072 7.292 1 5.778 4.516 3.772 6.844 1.509 11.536v10.536l2.014 14.049 4.276 6.524 2.515 11.29 3.523 22.071 2.259 9.78z"/>
                    <path fill="#FBDFCF"
                          d="M31.353 174.432l.778 3.106-1.445 8.21-2.449 6.768-4.782 7.989-3.112.662-2.891 1.11-2.001 1.003-1.558-1.109.112-1.998-1.113-2.219 2.113-9.208-3.336 1.775-2.558-1L9 187.745l2.335-.887 1.447-2.996 1-4.327 3.781-3.55 4.673-3.106 3.225.111 5.892 1.442zM135.083 174.23l-.79 3.153 1.467 8.333 2.483 6.866 4.854 8.107 3.161.677 2.935 1.126 2.032 1.014 1.579-1.127-.112-2.027 1.129-2.252-2.146-9.345 3.386 1.802 2.596-1.013.113-1.802-2.371-.901-1.467-3.037-1.016-4.391-3.838-3.603-4.739-3.158-3.273.113-5.983 1.465z"/>
                    <path fill="#E1C7B8"
                          d="M116.237 193.346l-1.761 2.256-5.785 2.137-8.547 1.001-7.293-2.128-6.096-2.072-3.331-1.756-3.143 1.568-6.786 2.38-6.412 1.634-6.54-.249-5.528-1.881-4.403-2.509 1.13-5.27 2.867-18.065 3.247-13.82.614-1.379.973-4.235 1.728 1.131 7.045 3.01 10.68 1.753h10.938l9.557-1.881 6.283-3.01 1.749-1.004 1.114 4.235.834 1.164 3.103 14.898 2.758 17.203 1.005 4.889z"/>
                    <path fill="#ECD2C2"
                          d="M93.74 61.099L88.5 69.413l-4.783 1.023-5.144-1.091-5.325-9.105.309-7.111 19.8-.189.385 8.157z"
                          opacity=".61"/>
                    <path fill="#FBDFCF"
                          d="M100.974 38.267l-1.043 3.417-.467 1.527-.106.339-3.22 10.254-.646 4.09-6.24 7.567-5.537 1.4-5.9-1.468-5.683-7.693-.689-3.713-3.062-9.858-.302-.775-.483-1.58-1.13-3.71.377-12.025 6.078-8.33 10.304-2.656V15l.09.03.091-.03v.053l11.111 2.86 6.089 8.322.368 12.032z"/>
                    <path fill="#FBDFCF"
                          d="M73.291 54.294l-1.094-.308-1.697-.49-1.862-2.777-1.954-3.965-1.117-3.17.37-2.084 1.109.18.55.091.762.128 1.462 3.673 3.471 8.722zM101.781 43.588l-1.116 3.17-1.954 3.965-1.863 2.777-1.086.305-1.705.49 3.07-7.713L98.99 41.9l1.321-.218.671-.113.43-.066.369 2.084z"/>
                    <path fill="#82543A"
                          d="M99.932 41.684l-3.183 5.425 1.258-7.4-.88-6.522-4.526-2.382-4.526.752-4.149 1.003-6.914-2.006-6.788 1-1.132 7.78.728 6.24-.038.595-2.188-4.395-1.13-3.71.377-12.024 6.08-8.331 10.305-2.656V15l.09.03.09-.03v.053l11.112 2.86 6.089 8.322.369 12.033-1.044 3.416z"/>
                    <path fill="#2E2E2E"
                          d="M50.624 67c-.093 0-.168-.028-.224-.084-.056-.056-.084-.13-.084-.224v-9.17c0-.093.028-.168.084-.224.056-.065.13-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.57.476.854 1.18.854 2.114 0 .737-.182 1.335-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .075-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.065-.135-.163-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.13.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.163 1.806-.49.401-.336.602-.835.602-1.498s-.2-1.157-.602-1.484c-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618zM112.624 67c-.093 0-.168-.028-.224-.084-.056-.056-.084-.13-.084-.224v-9.184c0-.093.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.13.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404z"
                          opacity=".5"/>

                    {isFace && <path id="front-face" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                     d="M100.5 37.305c0 7.011-1.881 13.336-4.896 17.893C92.586 59.76 88.474 62.5 84 62.5c-4.473 0-8.586-2.74-11.604-7.302-3.015-4.557-4.896-10.882-4.896-17.893 0-7.01 1.878-11.166 4.798-13.59C75.238 21.277 79.343 20.5 84 20.5s8.761.776 11.702 3.216c2.92 2.423 4.798 6.579 4.798 13.589z"/>}
                    {isNick && <path id="front-nick" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                     d="M95.5 67.232c0 1.96-.969 3.757-2.885 5.081C90.69 73.645 87.797 74.5 84 74.5c-3.797 0-6.689-.855-8.615-2.187-1.916-1.324-2.885-3.12-2.885-5.081 0-.986.243-1.738.687-2.327.448-.594 1.133-1.067 2.09-1.43 1.94-.736 4.854-.975 8.723-.975 3.869 0 6.784.239 8.722.974.959.364 1.643.837 2.09 1.431.445.59.688 1.341.688 2.327z"/>}
                    {isChest && <path id="front-chest" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                      d="M109.109 100.267c0 11.943-2.851 17.651-7.259 20.444-2.225 1.41-4.896 2.113-7.918 2.459-2.897.331-6.075.331-9.448.33h-.9c-3.374.001-6.552.001-9.449-.33-3.022-.346-5.693-1.049-7.918-2.459-4.408-2.793-7.259-8.501-7.259-20.444 0-2.565-.039-4.857-.074-6.913-.047-2.785-.087-5.136-.013-7.142.13-3.486.605-5.74 1.871-7.259 1.257-1.507 3.384-2.395 7.112-2.884 3.72-.49 8.913-.569 16.18-.569 7.266 0 12.459.08 16.179.569 3.728.49 5.855 1.377 7.112 2.884 1.266 1.519 1.742 3.773 1.871 7.26.074 2.005.034 4.356-.013 7.14-.035 2.057-.074 4.35-.074 6.914z"/>}
                    {isAbdomen && <path id="front-abdomen" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                        d="M109.371 141.923c0 3.233-.708 5.594-1.941 7.333-1.233 1.738-3.028 2.908-5.3 3.692-4.499 1.553-10.715 1.552-17.733 1.552h-.794c-7.018 0-13.234.001-17.733-1.552-2.272-.784-4.067-1.954-5.3-3.692-1.233-1.739-1.94-4.1-1.94-7.333 0-1.409-.04-2.676-.075-3.81l-.019-.601c-.038-1.287-.059-2.375.005-3.31.126-1.854.575-2.991 1.784-3.781 1.279-.836 3.474-1.334 7.279-1.607 3.78-.27 9.05-.314 16.396-.314s12.616.044 16.396.314c3.805.273 6 .771 7.279 1.607 1.209.79 1.658 1.927 1.784 3.781.064.935.043 2.023.005 3.31l-.019.602c-.035 1.133-.074 2.4-.074 3.809z"/>}
                    {isGenitals && <path id="front-genitals" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M54.65 175.952c0-4.223.823-7.324 2.268-9.614 1.442-2.284 3.532-3.807 6.155-4.824 2.633-1.022 5.792-1.529 9.343-1.777 3.397-.238 7.12-.237 11.052-.237h1.064c3.931 0 7.655-.001 11.052.237 3.551.248 6.71.755 9.343 1.777 2.623 1.017 4.713 2.54 6.155 4.824 1.445 2.29 2.269 5.391 2.269 9.614 0 1.822.045 3.462.086 4.931l.021.78c.044 1.668.069 3.088-.005 4.311-.148 2.443-.682 3.975-2.116 5.028-1.489 1.093-4.03 1.738-8.417 2.09-4.363.351-10.444.408-18.92.408-8.477 0-14.557-.057-18.92-.408-4.387-.352-6.928-.997-8.417-2.09-1.434-1.053-1.968-2.585-2.116-5.028-.074-1.223-.05-2.643-.005-4.311l.021-.78c.041-1.469.086-3.109.086-4.931z"/>}


                    {isRightArm && <path id="front-right-arm" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M36.096 116.728l-.009.059-.021.055c-5.234 13.226-8.186 26.623-9.13 36.931-.473 5.158-.44 9.519.05 12.687.245 1.587.6 2.845 1.046 3.748.447.906.948 1.388 1.462 1.558 1.188.393 2.523.085 4.01-1.29 1.494-1.383 3.082-3.796 4.718-7.452 3.268-7.304 6.662-19.419 9.91-37.688l.007-.04.013-.038c4.238-12.057 6.215-22.5 6.438-30.138.112-3.823-.216-6.913-.9-9.142-.689-2.246-1.694-3.489-2.853-3.873-1.252-.414-2.515-.261-3.798.646-1.302.921-2.627 2.623-3.926 5.31-2.599 5.375-5.02 14.527-7.017 28.667z"/>}
                    {isRightHand && <path id="front-right-hand" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                          d="M13.23 185.8l-.043.139-.111.094c-1.277 1.083-2.182 1.955-2.798 2.671-.62.72-.919 1.251-1.03 1.652-.104.372-.05.644.097.9.164.285.453.567.867.907.133.11.278.224.43.344.754.594 1.698 1.338 2.392 2.43.826 1.301 1.29 3.059.894 5.628 1.211 1.028 2.517 1.401 3.839 1.301 1.412-.106 2.883-.757 4.297-1.817 2.832-2.122 5.314-5.795 6.463-9.493 1.155-3.716 1.3-7.335.625-10.172-.678-2.849-2.152-4.817-4.159-5.441-2.008-.624-4.337.161-6.511 2.124-2.165 1.954-4.097 5.017-5.252 8.733z"/>}
                    {isLeftArm && <path id="front-left-arm" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                        d="M131.017 116.728l.008.059.022.055c5.233 13.226 8.186 26.623 9.13 36.931.472 5.158.44 9.519-.051 12.687-.245 1.587-.601 2.845-1.046 3.748-.447.906-.948 1.388-1.462 1.558-1.188.393-2.523.085-4.01-1.29-1.493-1.383-3.081-3.796-4.717-7.452-3.269-7.304-6.663-19.419-9.91-37.688l-.007-.04-.014-.038c-4.238-12.057-6.214-22.5-6.438-30.138-.112-3.823.217-6.913.9-9.142.689-2.246 1.694-3.489 2.854-3.873 1.251-.414 2.514-.261 3.797.646 1.302.921 2.627 2.623 3.927 5.31 2.598 5.375 5.019 14.527 7.017 28.667z"/>}
                    {isLeftHand && <path id="front-left-hand" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M153.883 185.8l.044.139.111.094c1.277 1.083 2.181 1.955 2.797 2.671.62.72.919 1.251 1.031 1.652.103.372.049.644-.098.9-.163.285-.452.567-.867.907-.133.11-.277.224-.43.344-.754.594-1.698 1.338-2.391 2.43-.827 1.301-1.29 3.059-.895 5.628-1.211 1.028-2.517 1.401-3.839 1.301-1.412-.106-2.883-.757-4.297-1.817-2.832-2.122-5.313-5.795-6.463-9.493-1.155-3.716-1.3-7.335-.625-10.172.678-2.849 2.152-4.817 4.159-5.441 2.008-.624 4.337.161 6.511 2.124 2.165 1.954 4.097 5.017 5.252 8.733z"/>}
                    {isRightLeg && <path id="front-right-leg" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M53.851 261.24c-.527 17.701.931 33.753 3.245 45.39 1.157 5.822 2.525 10.518 3.955 13.758.716 1.622 1.438 2.855 2.139 3.679.71.835 1.335 1.177 1.855 1.192.498.015 1.041-.269 1.626-1.042.583-.772 1.146-1.955 1.679-3.541 1.062-3.164 1.959-7.794 2.71-13.567 1.5-11.539 2.406-27.538 2.934-45.269.265-8.893 1.24-17.382 2.251-25.102.231-1.763.464-3.484.69-5.161.768-5.676 1.466-10.835 1.79-15.338.42-5.849.2-10.477-1.247-13.63-.716-1.559-1.728-2.747-3.111-3.551-1.388-.806-3.188-1.247-5.51-1.244-7.415.009-11.884 1.776-14.522 4.84-2.653 3.081-3.557 7.589-3.559 13.329 0 4.792.624 10.354 1.319 16.53.136 1.214.275 2.453.413 3.713.836 7.656 1.608 16.11 1.343 25.014z"/>}
                    {isRightFoot && <path id="front-right-foot" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                          d="M58.445 337.315c-.324 1.86-1.035 3.624-1.71 5.22l-.198.467c-.607 1.429-1.144 2.691-1.364 3.819-.238 1.218-.087 2.179.7 2.972.829.838 2.436 1.567 5.335 2.071 2.34.408 4.739-.556 6.734-2.542 1.994-1.984 3.548-4.962 4.158-8.467.61-3.505.154-6.833-1.052-9.375-1.207-2.543-3.14-4.261-5.478-4.668-1.193-.208-2.12-.086-2.868.277-.75.365-1.37.998-1.903 1.91-1.081 1.848-1.726 4.706-2.354 8.316z"/>}
                    {isLeftLeg && <path id="front-left-leg" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                        d="M112.576 261.24c.528 17.701-.93 33.753-3.244 45.39-1.158 5.822-2.525 10.518-3.956 13.758-.716 1.622-1.437 2.855-2.138 3.679-.71.835-1.336 1.177-1.856 1.192-.498.015-1.04-.269-1.625-1.042-.584-.772-1.147-1.955-1.679-3.541-1.062-3.164-1.959-7.794-2.71-13.567-1.5-11.539-2.406-27.538-2.934-45.269-.265-8.893-1.24-17.382-2.251-25.102-.231-1.763-.464-3.484-.69-5.161-.768-5.676-1.466-10.835-1.79-15.338-.421-5.849-.2-10.477 1.247-13.63.716-1.559 1.728-2.747 3.11-3.551 1.39-.806 3.188-1.247 5.51-1.244 7.415.009 11.885 1.776 14.523 4.84 2.652 3.081 3.557 7.589 3.558 13.329.001 4.792-.624 10.354-1.318 16.53-.137 1.214-.276 2.453-.413 3.713-.836 7.656-1.609 16.11-1.344 25.014z"/>}
                    {isLeftFoot && <path id="front-left-foot" fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M107.983 337.315c.324 1.86 1.034 3.624 1.709 5.22l.199.467c.607 1.429 1.144 2.691 1.364 3.819.237 1.218.087 2.179-.7 2.972-.829.838-2.436 1.567-5.336 2.071-2.338.408-4.738-.556-6.733-2.542-1.994-1.984-3.548-4.962-4.159-8.467-.61-3.505-.153-6.833 1.053-9.375 1.206-2.543 3.139-4.261 5.478-4.668 1.193-.208 2.121-.086 2.868.277.749.365 1.37.998 1.903 1.91 1.081 1.848 1.726 4.706 2.354 8.316z"/>}
                </svg>

            </svg>
        );
    }
}

export default SkinBodyIndicatorFront;
