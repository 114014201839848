import React from "react";
import set from 'set-value'
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import useMediaQuery from "./useMediaQuery";


class utils {

    get(obj, path) {
        // Get the path as an array
        path = this.stringToPath(path);
        // Cache the current object
        let current = obj;
        // For each item in the path, dig into the object
        for (let i = 0; i < path.length; i++) {
            // If the item isn't found, return the default (or null)
            if (current === null || this.isUndefined(current) || this.isUndefined(current[path[i]]))
                return '';
            // Otherwise, update the current  value
            current = current[path[i]];
        }
        return current;
    };

    flatten = (obj) => {
        let flattened = {}
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(flattened, this.flatten(obj[key]))
            } else {
                flattened[key] = obj[key]
            }
        })

        return flattened
    }

    set(object, path, value) {
        set(object, path, value);
    }

    stringToPath = (path) => {
        // If the path isn't a string, return it
        if (typeof path !== 'string') return path;

        // Create new array
        let output = [];

        // Split to an array with dot notation
        path.split('.').forEach(function (item) {

            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {

                // Push to the new array
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });

        return output;
    };

    isUndefined = (obj) => {
        return typeof obj === 'undefined';
    };

    isEmpty = (value) => {

        let is = this.isEmpty;

        let isEmptyObject = function (a) {
            if (typeof a.length === 'undefined') { // it's an Object, not an Array
                let hasNonempty = Object.keys(a).some(function nonEmpty(element) {
                    return !is(a[element]);
                });
                return hasNonempty ? false : isEmptyObject(Object.keys(a));
            }

            return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
                return !is(element); // at least one element should be non-empty
            });
        };

        let isEmptyString = function (s) {
            if (typeof s === 'string')
                return s.length === 0;
        };

        return (
            // value === false
            // ||
            typeof value === 'undefined'
            || value == null
            || (typeof value === 'object' && isEmptyObject(value))
            || (typeof value === 'string' && isEmptyString(value))
        );
    };


    getDateWithoutYear = function (date) {
        let monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = monthNames[today.getMonth()]
        return dd + '/' + mm;
    };

    getDateStringWithoutYear = function (date,x) {

        let language= 'en'
        let monthNames =language === 'en'? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] : ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = monthNames[today.getMonth()]
        return mm + ' ' + dd;
    };

    getDateStringWithYear = function (date,x) {
        let language= 'en'
        let monthNames =language === 'en'? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] : ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = monthNames[today.getMonth()]
        let yy = today.getFullYear().toString();
        return mm + ' ' + dd + ', ' + yy;
    };

    getFullDateStringWithYearAndDay = function (date,x) {
        // Friday, January 2 2020
        let language= 'en'
        var days =language === 'en'? ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] : ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let monthNames =language === 'en'? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] :['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        var dayName = days[today.getDay()];
        let mm = monthNames[today.getMonth()]
        let yy = today.getFullYear().toString();
        return dayName + ', ' + mm + ' ' + dd + ' ' + yy;
    };

    formatAMPM(date) {

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm.toUpperCase();
        return strTime;
    }

    format24(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes
        return strTime;
    }

    getDateFormatWithYear = function (date, isYY, isDotSeperator) {
        let seperator = isDotSeperator ? '.' : '/'
        if (!date) return "NoDate"
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = today.getMonth() + 1
        if (mm.toString().length === 1) mm = '0' + mm.toString()
        let yyyy = today.getUTCFullYear();
        return dd + seperator + mm + seperator + (isYY ? yyyy.toString().substr(-2) : yyyy);
    };

    getDateAndTime(dateInput) {
        let date = this.getDateStringWithYear(dateInput)
        let time = this.format24(dateInput)
        return date + '   ' + time
    }

    _calculateAge(birthday) { // birthday is a date
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getLastSundayFrom(dat) {
        let datC = new Date(dat.toISOString())
        dat.setDate(dat.getDate() - (dat.getDay()));
        let isSunday = Math.abs(this.daysBetween(dat, datC)) === 7;
        return isSunday ? datC : dat;
    }

    getNextDayOfWeek(date, dayOfWeek) {
        // Code to check that date and dayOfWeek are valid left as an exercise ;)

        var resultDate = new Date(date.getTime());

        resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

        return resultDate;
    }

    getNextDay(selectedDate) {
        let date = new Date(selectedDate.toISOString())
        date.setDate(selectedDate.getDate() + 1);
        return date
    }

    treatAsUTC(date) {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }

    daysBetween(startDate, endDate) {
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay;
    }

    getWeekRange(selectedDay,language) {
        let sunday = this.getDateStringWithoutYear(this.getLastSundayFrom(selectedDay),language)
        let saturday = this.getDateStringWithYear(this.getNextDayOfWeek(selectedDay, 6),language)
        return sunday + ' - ' + saturday
    }

    parseIsoDatetime(dtstr) {
        return new Date(dtstr);
    }

    compareBy(a, b, property) {
        const propertyA = property ? a[property].toUpperCase() : a
        const propertyB = property ? b[property].toUpperCase() : b

        let comparison = 0;
        if (propertyA > propertyB) {
            comparison = 1;
        } else if (propertyA < propertyB) {
            comparison = -1;
        }
        return comparison;
    }

    sortObjectByKeys(unordered) {
        return Object.keys(unordered).sort().reduce(
            (obj, key) => {
                obj[key] = unordered[key];
                return obj;
            },
            {}
        );
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    capitalize0(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    renameKey(object, key, newKey) {
        const clone = (obj) => Object.assign({}, obj);
        const clonedObj = clone(object);
        const targetKey = clonedObj[key];
        delete clonedObj[key];
        clonedObj[newKey] = targetKey;
        return clonedObj;


    }
    exportToCsv(csvData, fileName, myHeader) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let ws;
        if (myHeader) {
            ws = XLSX.utils.json_to_sheet(csvData, { header: myHeader });
            const range = XLSX.utils.decode_range(ws['!ref']);
            range.e['c'] = myHeader.length - 1;
            ws['!ref'] = XLSX.utils.encode_range(range);
        } else {
            ws = XLSX.utils.json_to_sheet(csvData);
        }
        const wb = {
            Workbook: {
                Views: [
                    {LTR: true}
                ],
            },
            Sheets: {'data': ws}, SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    getExamLabel(exam,screen){
        switch(exam){
             case "Body_Temperature":
                return "Body Temperature"
            break;
            case "BloodPressure":
                return "Blood Pressure"
                break;
                case "Saturation":
                return "Heart rate & Oxygen level"
                break;
                case "Covid19":
                    if(screen==='notes'){
                        return "COVID-19"
                }else{
                        return "COVID-19 At Home Test"
                    }

                break;
                case "Strep":
                    if(screen==='notes'){
                        return "Strep Throat"
                    }else{
                        return "Strep Throat At Home Test"
                    }
                break;
                case "Throat":
                return "Mouth & Throat"
                break;

            default:
                return exam
        }
    }
    getExamLabelColor(name){
        switch(name){
            case "Body_Temperature":
                return "#FFC031"
                break;
            case "BloodPressure":
                return "#E53724"
                break;
            case "Saturation":
                return "#80E1E3"
                break;
            case "Covid19":
                return "#7B5EC6"
                break;
            case "Strep":
                return "#7B5EC6"
                break;
            case "Throat":
                return "#E76B82"
                break;
                case "Heart":
                return "#FD5A56"
                break;
                case "Lungs":
                return "#79ADCD"
                break;
                case "Abdomen":
                return "#8B487C"
                break;
                case "Ears":
                return "#8C86C7"
                break;
                case "Skin":
                return "#FF7B4A"
                break;


            default:
                return "#2E2E2E"
        }
    }


}

export default new utils();
