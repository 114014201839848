import React, {Component} from "react";

class MA_Abdomen extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#c10096'

        let A_color = grey
        let B_color = grey

        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){return (
            <svg viewBox={"0 0 395 416"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF"
                          d="M351.652 422.689l-7.071 7.689-14.125-1.923-29.558-62.148-10.27-28.839-.636-16.667-14.143-28.199-4.952-14.359-2.755 12.437-5.78 28.839-5.78 17.955 1.927 25.624 1.927 11.534 5.78 39.734 11.569 46.447 2.574 64.389-7.068 43.578-3.854 31.39 1.927 26.912 5.139 24.99-2.896 42.936-7.707 48.056-13.482 54.784 3.526 10.091 1.446 9.131v8.18l7.707 17.781 7.946 18.383-2.311 5.325-21.523 8.329-14.451-1.44-5.78-4.326-2.735-5.446-.49-25.957 1.927-15.378-1.446-12.015 3.853-13.455-1.444-35.561-3.854-45.174-2.563-15.538-.328-36.846 1.927-17.781-4.336-22.106-1.927-36.522-8.671-41.809-8.189-32.679-2.884-38.124-2.57 38.124-8.189 32.679-8.671 41.809-1.926 36.522-4.334 22.106 1.927 17.781v38.124l-2.891 14.256-3.853 45.174-1.445 35.561 3.853 13.455-1.446 12.015 1.928 15.378-.482 25.951-2.088 8.011-5.78 4.326-14.451 1.441-8.029-3.363-12.523-4.318-3.373-5.287 8.35-20.984 7.707-17.78v-8.169l1.444-9.131 3.526-10.091-14.123-53.514-8.349-47.409-2.563-45.506 5.125-24.991 1.928-26.912-3.858-31.389-7.053-43.577 1.839-63.582 12.284-47.249 5.778-39.74 1.927-11.534 1.928-25.624-5.78-17.955-5.78-28.839-2.737-12.437-4.972 14.359-14.117 28.203-.656 16.666-10.27 28.84-29.532 62.144-14.144 1.923-7.05-7.689 5.78-24.99 8.98-56.381 6.435-28.84 10.906-16.666 5.145-35.888v-26.906l3.854-29.469 9.636-16.381 16.255-11.669 17.137-2.422 12.216-.919 30.172-17.299.982-22.529.79-18.166 50.58-.48.982 20.837.945 20.338 30.193 17.3 12.198-.183 18.627 2.556 14.76 11.533 9.636 17.484 3.853 29.469v26.912l5.144 35.888 10.925 16.667 6.424 28.84 8.999 56.381 5.771 24.983z"/>
                    <path fill="#E1C7B8"
                          d="M281.637 472.349l-4.497 5.761-14.778 5.46-21.832 2.557-18.632-5.436-15.57-5.293-8.511-4.486-8.028 4.006-17.335 6.08-16.378 4.172-16.706-.634-14.123-4.805-11.246-6.409 2.884-13.462 7.325-46.149 8.294-35.302 1.569-3.522 2.487-10.819 4.412 2.889 17.996 7.689 27.284 4.48h27.94l24.414-4.807 16.05-7.689 4.466-2.564 2.847 10.82 2.13 2.971 7.926 38.059 7.046 43.943 2.566 12.49z"/>
                    <path fill="#ECD2C2"
                          d="M224.172 134.521l-13.391 21.24-12.216 2.614-13.141-2.788-13.604-23.257.79-18.166 50.58-.48.982 20.837z"
                          opacity=".61"/>
                    <path fill="#FBDFCF"
                          d="M242.649 76.201l-2.662 8.728-1.194 3.9-.271.866-8.224 26.196-1.652 10.444-15.942 19.331-14.143 3.576-15.068-3.749-14.519-19.652-1.76-9.483-7.823-25.182-.771-1.98-1.233-4.038-2.889-9.476.964-30.717 15.528-21.28 26.32-6.784v-.135l.232.077.231-.077v.135l28.383 7.304 15.553 21.26.94 30.736z"/>
                    <path fill="#FBDFCF"
                          d="M171.935 117.147l-2.795-.788-4.334-1.25-4.759-7.093-4.991-10.131-2.852-8.096.945-5.324 2.832.462 1.406.231 1.946.326 3.733 9.383 8.869 22.28zM244.712 89.793l-2.852 8.096-4.99 10.131-4.759 7.093-2.775.779-4.355 1.25 7.843-19.702 4.759-11.958 3.373-.556 1.716-.289 1.097-.168.943 5.324z"/>
                    <path fill="#82543A"
                          d="M239.986 84.929l-8.129 13.858 3.212-18.903-2.249-16.66-11.562-6.086-11.56 1.923-10.598 2.562-17.663-5.126-17.338 2.557-2.893 19.87 1.86 15.944-.097 1.518-5.589-11.226-2.889-9.477.964-30.717 15.534-21.28L197.31 16.9v-.135l.231.077.232-.077v.135l28.383 7.304 15.553 21.26.943 30.738-2.666 8.726z"/>
                    <path fill="#ECD2C2"
                          d="M111.431 227.098l1.927-1.442 11.239 9.932 8.349 34.922 38.545 4.484-39.828 4.49-6.424-2.564-4.656-39.726-9.152-10.096zM286.293 227.579l-3.373-1.923-11.239 9.932-8.028 35.249-38.858 4.164 39.821 4.486 6.424-2.563 7.386-36.844 7.867-12.501z"
                          opacity=".61"/>
                    <path fill="#2E2E2E"
                          d="M258.624 152c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.093.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.131.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404zM129.624 152c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.093.028-.168.084-.224.056-.065.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.181.854 2.114 0 .737-.182 1.335-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .075-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.065-.135-.163-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.163 1.806-.49.401-.336.602-.835.602-1.498s-.201-1.157-.602-1.484c-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M198.5 156L198.5 392M198.5 156L198.5 392"/>
                    </g>
                    <g opacity=".5">
                        <path stroke="#fff" d="M110 273.5L286 273.5M110 273.5L286 273.5"/>
                    </g>
                    <g opacity=".5">
                        <path stroke="#fff" d="M110 182.5L286 182.5M110 182.5L286 182.5"/>
                    </g>
                    <path fill={B_color}
                          d="M208 352c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M216.401 357c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM220 362.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM220 341.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={A_color}
                          d="M164 352c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M171.409 357c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM176 362.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM176 341.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H355V380H0z" transform="translate(17 12)"/>
                    </clipPath>
                    <filter id="filter0_d" width="395" height="420" x="0" y="-4" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );}
        else {
            return (
                    <svg viewBox={"0 0 395 416"}
                     width={width}
                     height={height}
                     style={style}
                     xmlns={xmlns}
                     name={name}
                     className={className}>
                    <g clip-path="url(#clip0)" filter="url(#filter0_d)">                    <path d="m307.325 406.408-7.071 7.689-14.125-1.923-29.558-62.148-10.27-28.839-.636-16.667-14.143-28.199-4.952-14.359-2.755 12.437-5.78 28.839-5.78 17.955 1.927 25.624 1.927 11.534 5.78 39.734 11.569 46.447 2.574 64.389-7.068 43.578-3.854 31.39 1.927 26.912 5.139 24.99-2.896 42.936-7.707 48.056-13.482 54.784 3.526 10.091 1.446 9.131v8.18l7.707 17.781 7.946 18.383-2.311 5.325-21.523 8.329-14.451-1.44-5.78-4.326-2.735-5.446-.49-25.957 1.927-15.378-1.446-12.015 3.853-13.455-1.444-35.561-3.854-45.174-2.563-15.538-.328-36.846 1.927-17.781-4.336-22.106-1.927-36.522-8.671-41.809-8.189-32.679-2.884-38.124-2.57 38.124-8.189 32.679-8.671 41.809-1.926 36.522-4.334 22.106 1.927 17.781v38.124l-2.891 14.256-3.853 45.174-1.445 35.561 3.853 13.455-1.446 12.015 1.928 15.378-.482 25.951-2.088 8.011-5.78 4.326-14.451 1.441-8.029-3.363-12.523-4.318-3.373-5.287 8.35-20.984 7.707-17.78v-8.169l1.444-9.131 3.526-10.091-14.123-53.514-8.349-47.409-2.563-45.506 5.125-24.991 1.928-26.912-3.858-31.389-7.053-43.577 1.839-63.582 12.284-47.249 5.778-39.74 1.927-11.534 1.928-25.624-5.78-17.955-5.78-28.839-2.737-12.437-4.972 14.359-14.117 28.203-.656 16.666-10.27 28.84-29.532 62.144-14.144 1.923L0 406.408l5.78-24.99 8.98-56.381 6.435-28.84 10.906-16.666 5.145-35.888v-26.906l3.854-29.469 9.636-16.381 16.255-11.669 17.137-2.422 12.216-.919 30.172-17.299.982-22.529.79-18.166 50.58-.48.982 20.837.945 20.338 30.193 17.3 12.198-.183 18.627 2.556 14.76 11.533 9.636 17.484 3.853 29.469v26.912l5.144 35.888 10.925 16.667 6.424 28.84 8.999 56.381 5.771 24.983z" fill="#FBDFCF"/>
                    <path d="m237.31 456.068-4.497 5.761-14.778 5.46-21.832 2.557-18.632-5.436-15.57-5.293-8.511-4.486-8.028 4.006-17.335 6.08-16.378 4.172-16.706-.634-14.123-4.805-11.246-6.409 2.884-13.462 7.325-46.149 8.294-35.302 1.569-3.522 2.487-10.819 4.412 2.889 17.996 7.689 27.284 4.48h27.94l24.414-4.807 16.05-7.689 4.466-2.564 2.847 10.82 2.13 2.971 7.926 38.059 7.046 43.943 2.566 12.49z" fill="#E1C7B8"/>
                    <path opacity=".61" d="m179.844 118.24-13.391 21.24-12.216 2.614-13.141-2.788-13.604-23.257.79-18.166 50.58-.48.982 20.837z" fill="#ECD2C2"/>
                    <path d="m198.323 59.92-2.662 8.728-1.194 3.9-.271.866-8.224 26.196-1.652 10.444-15.942 19.331-14.143 3.576-15.068-3.749-14.519-19.652-1.76-9.483-7.823-25.182-.771-1.98-1.233-4.038-2.889-9.476.964-30.717 15.528-21.28L152.984.62V.485l.232.077.231-.077V.62l28.383 7.304 15.553 21.26.94 30.736z" fill="#FBDFCF"/>
                    <path d="m127.608 100.867-2.795-.788-4.334-1.25-4.759-7.093-4.991-10.131-2.852-8.096.945-5.324 2.832.462 1.406.23 1.946.327 3.733 9.383 8.869 22.28zm72.777-27.354-2.852 8.096-4.99 10.13-4.759 7.094-2.775.779-4.355 1.25 7.843-19.702 4.759-11.958 3.373-.556 1.716-.29 1.097-.167.943 5.324z" fill="#FBDFCF"/>
                    <path d="m195.659 68.648-8.129 13.858 3.212-18.903-2.249-16.66-11.562-6.086-11.56 1.923-10.598 2.562-17.663-5.126-17.338 2.557-2.893 19.87 1.86 15.944-.097 1.518-5.589-11.226-2.889-9.477.964-30.717 15.534-21.28L152.983.62V.484l.231.077.232-.077V.62l28.383 7.304 15.553 21.26.943 30.738-2.666 8.726v.001z" fill="#82543A"/>
                    <path opacity=".61" d="m67.103 210.817 1.928-1.442 11.238 9.932 8.35 34.922 38.545 4.484-39.829 4.49-6.423-2.564-4.657-39.726-9.151-10.096zm174.863.481-3.373-1.923-11.239 9.932-8.028 35.249-38.858 4.164 39.821 4.486 6.424-2.563 7.386-36.844 7.867-12.501z" fill="#ECD2C2"/>
                    <path d="M214.624 136a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.302.302 0 0 1-.224.084h-.266zM85.624 136a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.37 1.246.378 2.058.01.42.014.794.014 1.12 0 .318-.004.686-.014 1.106-.018.859-.144 1.568-.378 2.128a2.42 2.42 0 0 1-1.176 1.26c-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.44-.205.752-.527.938-.966.196-.448.3-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                    <g opacity=".5">
                        <path d="M154.174 139.72v236-236zm0 0v236z" fill="#000"/>
                        <path d="M154.174 139.72v236m0-236v236-236z" stroke="#fff"/>
                    </g>
                    <g opacity=".5">
                        <path d="M65.674 257.22h176-176zm0 0h176z" fill="#000"/>
                        <path d="M65.674 257.22h176m-176 0h176-176z" stroke="#fff"/>
                    </g>
                    <g opacity=".5">
                        <path d="M65.674 166.22h176-176zm0 0h176z" fill="#000"/>
                        <path d="M65.674 166.22h176m-176 0h176-176z" stroke="#fff"/>
                    </g>
                    <path fill={B_color} d="M163.674 335.72c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M172.075 340.72a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zm-.517 7.32c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={A_color} d="M119.674 335.72c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path  fill="#fff" d="M127.083 340.72a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zm-1.303 9.238c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    </g>
                        <defs>
                            <clipPath id="clip0">
                                <path fill="#fff" d="M0 0H355V380H0z" transform="translate(0 0)"/>
                            </clipPath>
                            <filter id="filter0_d" width="395" height="420" x="0" y="-4" color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset dx="3" dy="4"/>
                                <feGaussianBlur stdDeviation="10"/>
                                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>

            )
        }
    }

}

export default MA_Abdomen

