import React, {Component} from "react";
import TempExam from "./TempExam";
import '../../../../../style/View/physician/patient/Exam.css'
import SaturationExam from "./SaturationExam";
import HeartExam from "./HeartExam";
import AbdomenExam from "./AbdomenExam";
import LungsExam from "./LungsExam";
import EarsExam from "./EarsExam";
import ThroatExam from "./ThroatExam";
import SkinExam from "./SkinExam";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class ExamComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exam: <div/>
        }
    }

    getExamContainer(type, observations, files, patient,tenant) {
        switch (type) {
            case 'Body_Temperature':
                return <TempExam t={this.props.t} observations={observations}/>
            case 'Saturation':
                return <SaturationExam t={this.props.t} observations={observations}/>
            case 'Heart':
                return <HeartExam t={this.props.t} files={files.filter(file => file['type'] === 'Heart')} patient={patient}/>
            case 'Abdomen':
                return <AbdomenExam t={this.props.t} files={files.filter(file => file['type'] === 'Abdomen')} patient={patient}/>
            case 'Lungs':
                return <LungsExam t={this.props.t} lbFiles={files.filter(file => file['type'] === 'Lungs' && file['subType'] === 'Back')}
                                  lfFiles={files.filter(file => file['type'] === 'Lungs' && file['subType'] === 'Front')}
                                  patient={patient}/>
            case 'Ears':
                return <EarsExam t={this.props.t} files={files} id={patient?.patientID} onlineCall={this.props.onlineCall} tenant={tenant}/>
            case 'Throat':
                return <ThroatExam t={this.props.t} id={patient?.patientID} files={files} onlineCall={this.props.onlineCall} tenant={tenant}/>
            case 'Skin':
                return <SkinExam t={this.props.t} files={files} tenant={tenant}/>
            default:
                return <div/>
        }
    }

    render() {
        let {type, observations, files, patient,tenant} = this.props
        let examHtml = this.getExamContainer(type, observations, files, patient,tenant)
        return (
            <div className={'exam-container'}>
                {examHtml}
            </div>
        );
    }

}

const Exam = compose(
    withTranslation(),
)(ExamComposed);
export default Exam
