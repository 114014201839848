import React, {Component} from "react";
import SkinBodyIndicatorFront from "../../../../../../assets/skin/SkinBodyIndicatorFront";
import SkinBody from "../../../../../../assets/skin/SkinBody";
import MakeImage from "../../../../../modal/ImageModal";
import VideoPlayer from "../../../../../media/VideoPlayer";

class SkinExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hoveredSkin: undefined
        }
    }

    async openImage(url) {
        await MakeImage({url})
    }

    render() {
        let skinFiles = []
        let {files,t} = this.props
        if (files) {
            skinFiles = files.filter(file => file.type === 'Skin');
        }
        let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

        return (
            <div className={'exam-result-container skin-exam-container'}>
                <div className={'skin-exam-body-container  v-centered'}>
                    {/*<SkinBodyIndicatorFront />*/}
                    <SkinBody className={'skin-exam-body  centered'} hoveredSkin={this.state.hoveredSkin}/>
                </div>
                <div className={'skin-exam-results-container'}>
                    <div className={'skin-exam-images-container'}>
                        {
                            skinFiles.length === 0 &&
                            <div className={'Error-Files h-centered'}>{t('physician.patient.exams.fileErr1')}</div>
                        }
                        {
                            skinFiles.map((file, i) => {
                                let positions = (file['filter']).split('-')
                                let name = file['subType']
                                if (!(positions.includes('None') || positions.includes('none')))
                                    name = positions[0] + ' ' + positions[1] + ' ' + file['subType']
                                if (file['downloadUrl'] != null)
                                    return (
                                        <div className={'skin-image-container'} onMouseEnter={() => this.setState({
                                            hoveredSkin: {
                                                part: file['subType'],
                                                facing: positions[0],
                                                side: positions[1]
                                            }
                                        })} onMouseLeave={() => this.setState({hoveredSkin: undefined})}>
                                            <div className={'skin-image-title'}>{t(`bodyPart.${name}`)}</div>
                                            {/*<div className={'skin-image'}>*/}
                                            <img onClick={() => this.openImage(file['downloadUrl'])}
                                                 src={file['downloadUrl']} className={'skin-image'}/>
                                            {/*</div>*/}
                                            <div className={'skin-image-number'}>{i + 1}</div>
                                        </div>
                                    );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

}

export default SkinExam
