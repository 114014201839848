import React, {Component} from 'react'
import Prototypes from 'prop-types'
import '../../style/component/Error.css'
import SVGraphics from "../../assets/SVGraphics";

class Error extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.tab_option) {
            this.setState({tab_option: this.props.tab_option})
        }
    }


    render() {
        let divStyle = {
            display: 'flex',
            visibility: this.props.isShown ? 'visible' : 'hidden',
            float: this.props.isNonFloat ? 'none' : 'left',
            position: this.props.abs ? 'absolute' : 'relative',
            justifyContent: this.props.isCentered ? 'center' : 'start',
        }
        if (this.props.isPasswordValidationErr) {
            divStyle['height'] = 'max-content'
        }
        if(this.props.isSummary){
            divStyle = {
                display: 'flex',
                visibility: this.props.isShown ? 'visible' : 'hidden',
                float: this.props.isNonFloat ? 'none' : 'left',
                position: this.props.abs ? 'absolute' : 'relative',
                justifyContent: this.props.isCentered ? 'center' : 'center',
                marginLeft: this.props.marginLeft ? this.props.marginLeft :'190px',
                width: this.props.width ? this.props.width : '90%'
            }
        }

        return (
            <div className={'error-message-label'} style={divStyle}>
                <SVGraphics svgname={'error'} height={'15px'} width={'15px'} className={`inline error-svg`}/>
                <div className={`nova-tab-label red inline error-label`}
                     style={{height: 'max-content'}}>{`${this.props.errorMessage}`}</div>
            </div>
            // call: <Error errorMessage={'Email is required'} isShown={false}/>
        )
    }
}

Error.Prototypes = {
    errorMessage: Prototypes.string.isRequired,
    isShown: Prototypes.bool.isRequired
};
export default Error;
