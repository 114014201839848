import React, {Component} from "react";
import FA_Heart from "./female/adult/FA_Heart";
import FC_Heart from "./female/child/FC_Heart";
import FA_Abdomen from "./female/adult/FA_Abdomen";
import FC_Abdomen from "./female/child/FC_Abdomen";
import FA_LungsBack from "./female/adult/FA_LungsBack";
import FC_LungsFront from "./female/child/FC_LungsFront";
import FC_LungsBack from "./female/child/FC_LungsBack";
import FA_LungsFront from "./female/adult/FA_LungsFront";
import MA_Heart from "./male/adult/MA_Heart";
import MC_Heart from "./male/child/MC_Heart";
import MA_Abdomen from "./male/adult/MA_Abdomen";
import MC_Abdomen from "./male/child/MC_Abdomen";
import MA_LungsBack from "./male/adult/MA_LungsBack";
import MC_LungsBack from "./male/child/MC_LungsBack";
import MA_LungsFront from "./male/adult/MA_LungsFront";
import MC_LungsFront from "./male/child/MC_LungsFront";

class Body extends Component {
    render() {
        let {gender, age, part, className, width, height, style, name, files, activeDot, isOnline} = this.props
        let isFemale = String(gender).toLowerCase() === 'female' || gender === '1' || gender === 1
        let isAdult = age > 18
        if (isFemale) {
            if (part === 'heart') {
                if (isAdult) return (
                    <FA_Heart className={className} activeDot={activeDot} isOnline={isOnline}
                               width={width} height={height} style={style}
                              name={name} files={files}/>);
                else return (
                    <FC_Heart className={className} activeDot={activeDot} isOnline={isOnline}
                              width={width} height={height} style={style}
                              name={name} files={files}/>);
            } else if (part === 'abdomen') {
                if (isAdult) return (
                    <FA_Abdomen className={className} activeDot={activeDot} isOnline={isOnline}
                                width={width} height={height} style={style}
                                name={name} files={files}/>);
                else return (
                    <FC_Abdomen className={className} activeDot={activeDot} isOnline={isOnline}
                                width={width} height={height} style={style}
                                name={name} files={files}/>);
            } else if (part === 'lungs-back') {
                if (isAdult) return (
                    <FA_LungsBack className={className} activeDot={activeDot} isOnline={isOnline}
                                  width={width} height={height}
                                  style={style} name={name} files={files}/>);
                else return (
                    <FC_LungsBack className={className} activeDot={activeDot} isOnline={isOnline}
                                  width={width} height={height}
                                  style={style} name={name} files={files}/>);
            } else if (part === 'lungs-front') {
                if (isAdult) return (
                    <FA_LungsFront className={className} activeDot={activeDot} isOnline={isOnline}
                                   width={width} height={height}
                                   style={style} name={name} files={files}/>);
                else return (
                    <FC_LungsFront className={className} activeDot={activeDot} isOnline={isOnline}
                                   width={width} height={height}
                                   style={style} name={name} files={files}/>);
            }
        } else {
            if (part === 'heart') {
                if (isAdult) return (
                    <MA_Heart className={className} activeDot={activeDot} isOnline={isOnline}
                              width={width} height={height} style={style}
                              name={name} files={files}/>);
                else return (
                    <MC_Heart className={className} activeDot={activeDot} isOnline={isOnline}
                              width={width} height={height} style={style}
                              name={name} files={files}/>);
            } else if (part === 'abdomen') {
                if (isAdult) return (
                    <MA_Abdomen className={className} activeDot={activeDot} isOnline={isOnline}
                                width={width} height={height} style={style}
                                name={name} files={files}/>);
                else return (
                    <MC_Abdomen className={className} activeDot={activeDot} isOnline={isOnline}
                                width={width} height={height} style={style}
                                name={name} files={files}/>);
            } else if (part === 'lungs-back') {
                if (isAdult) return (
                    <MA_LungsBack className={className} activeDot={activeDot} isOnline={isOnline}
                                  width={width} height={height}
                                  style={style} name={name} files={files}/>);
                else return (
                    <MC_LungsBack className={className} activeDot={activeDot} isOnline={isOnline}
                                  width={width} height={height}
                                  style={style} name={name} files={files}/>);
            } else if (part === 'lungs-front') {
                if (isAdult) return (
                    <MA_LungsFront className={className} activeDot={activeDot} isOnline={isOnline}
                                   width={width} height={height}
                                   style={style} name={name} files={files}/>);
                else return (
                    <MC_LungsFront className={className} activeDot={activeDot} isOnline={isOnline}
                                   width={width} height={height}
                                   style={style} name={name} files={files}/>);
            }
        }
    }
}

export default Body;
