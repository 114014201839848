import {Skeleton, Stack} from "@mui/material";
import React from "react";

export function LoadingSkelaton(props) {
    return(
        <div style={{width:'100%',height:'100%'}} >
            <Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
                <Skeleton variant="circular" width={80} height={80} />
                <Skeleton variant="rectangular"  height={200} />
                <Skeleton variant="rounded"  height={200} />
            </Stack>

        </div>
    )
}