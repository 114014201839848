import React, {Component} from "react";
import Config from "../../../../../../config/Config";

class TempExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            celsius: 0,
            unit: Config.region === 'eu' ? 'c' : 'f'
        }
    }

    celsiusToFarenheit() {
        let {celsius, unit} = this.state
        if (unit === 'c') return celsius.toFixed(1)
        else if (unit === 'f') return ((celsius * (9 / 5)) + 32).toFixed(2)

    }

    componentDidMount() {
        let {observations} = this.props
        // let observation = observations.findLast(observation => observation.type === 'Body_Temperature');
        let index = observations.map(el => el.type).lastIndexOf('Body_Temperature');
        if(index !== -1){
            let observation = observations[index]
            this.setState({celsius: observation['value']})
        }
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        let {observations} = nextProps
        // let observation = observations.findLast(observation => observation.type === 'Body_Temperature');
        let index = observations.map(el => el.type).lastIndexOf('Body_Temperature');

        if(index !== -1){
            let observation = observations[index]
            this.setState({celsius: observation['value']})
        }
    }

    render() {
        let {unit} = this.state
        let degree = this.celsiusToFarenheit()
        let activeClass = 'exam-temperature-unit exam-temperature-unit-active'
        let inactiveClass = 'exam-temperature-unit pointer'
        return (
            <div className={'exam-result-container temp-exam-container'}>
                <div className={'exam-results temp-results-container centered'}>
                    <div className={'exam-temperature'}>
                        {degree}
                    </div>
                    <div className={'exam-temperature-units-container'}>
                        <div className={unit === 'c' ? activeClass : inactiveClass}
                             onClick={() => this.setState({unit: 'c'})}>
                            C
                        </div>
                        <div className={unit === 'f' ? activeClass : inactiveClass}
                             onClick={() => this.setState({unit: 'f'})}>
                            F
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default TempExam
