import Config from "../../../config/Config";
import restApi from "../RestApi";


const patientsApi = {
    getPatientById: async (patientId,tenant, accessToken, asrAuth) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'tenant': tenant,
                'Authorization': accessToken,
                'x-asrAuth': asrAuth
            },
        };
        return (await restApi.get(
            `${Config.globalUrl}/api/rest/v1.0/sts/patients/${patientId}`,
            requestconfig
        ))
    },
    getPatientCardIframePathDetailsFromTTUID: async (ttuid) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return (await restApi.get(
            `${Config.globalUrl}/api/rest/v1.0/sts/open/patients/iframe/ttuid/${ttuid}`,
            requestconfig
        ))
    },
    renewPatientCardIframePathFromTTUID: async (ttuid) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return (await restApi.get(
            `${Config.globalUrl}/api/rest/v1.0/sts/open/patients/ttuid/${ttuid}/renew`,
            requestconfig
        ))
    },

};

export default patientsApi;
