import React, {useEffect, useState} from "react";
import {Modal} from 'react-bootstrap'
import '../../../style/component/modal/newModal.css'
import useMediaQuery from "../../../utils/useMediaQuery";
import SVGraphics from "../../../assets/SVGraphics";


export default function NewModal (props) {


    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');


    useEffect(async () => {

         }, [])



        return (
            <Modal
                // size="lg"
                show={props.show}
                centered={true}
                onHide={() => props.proceed()}
                dialogClassName={ props.dialogClassName}
                contentClassName={`new-modal-container`}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {/*<div className={'video-modal-title'}><span*/}
                {/*    style={{fontWeight: 'bold'}}>{language === 'en' ? file['type'] : file['type'] === 'Mouth' ? 'Boca' : 'Garganta'} video</span> {time} | {date} </div>*/}
                {/*<div className={'media-close-btn-container modal-close-video pointer'} onClick={() => proceed(this.state.captures)}>*/}
                {/*    <SVGraphics svgname={'close-white'} className={'modal-close-btn modal-close-btn-video'} />*/}
                {/*</div>*/}
                <div className={'media-close-btn-container modal-close-button pointer'} onClick={() => props.closeModal()}>
                <SVGraphics svgname={'close-black'} className={' modal-close-btn-prescription'} />
                </div>
                <div className={`modal-dialog-title`}>
                   {props.title }
                </div>

                {/*<Modal.Header className={'video-media-header media-header'} closeButton/>*/}

                <Modal.Body
                    className={ 'modal-dialog-body'} style={{display:'flex', justifyContent:'center'}}>
                    {props.message}

                </Modal.Body>
            </Modal>
        );

}


