import React, {Component} from "react";
import SVGraphics from "../../../../../assets/SVGraphics";


class ExamTab extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {examType, title, disabled, active, color} = this.props
        let containerClass = 'session-tab-container pointer'
        let svgClass = 'session-tab-icon'
        let svgName = examType
        let textClass = 'session-tab-title'
        let borderBottom = 'none'
        if (disabled) {
            containerClass = containerClass.replace('pointer', 'blocked')
            svgName = svgName + '-disabled'
            textClass = textClass + ' session-tab-title-disabled'
        } else {
            if (active) {
                borderBottom = '3px solid ' + color
                textClass = textClass + ' session-tab-title-active'
            }
        }
        // console.log(examType,disabled,active)
        return (
            <div className={'session-tab session-tab-' + examType}>
                <div className={containerClass} style={{borderBottom}} onClick={() => {
                    disabled ? void (0) : this.props.onClick(examType)
                }}>
                    <SVGraphics svgname={svgName} className={svgClass}/>
                    <div className={textClass}>{title}</div>
                </div>
            </div>
        );
    }

}

export default ExamTab
