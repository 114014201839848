import React, {Component} from "react";
import avatar from "../../../assets/p-avatar.png";
import utils from "../../../utils/utils";
import TableMoreOptions from "../../physician/waitingRoom/TableMoreOptions";

class PatientPersonalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit:false,
            height:0,
            weight:0,
        }
    }

    rowOptions() {
        let {t} =this.props
        let options = [
            {actionName: t('physician.patient.profile.editPatient'), call: (async () => await this.editPatient())}
        ]



        return options
    }
    editPatient(){
        let {isEdit} = this.state
        this.setState({isEdit:true})
    }
    async savePatient(){
        let {patient} = this.props;
        let {height,weight} = this.state
        let heightWeight = {height:height,weight:weight}
        let {isEdit} = this.state
        this.setState({isEdit:false})
    }
    addHeight(name,value){

    this.setState({height:value})
    }

    addWeight(name,value){

    this.setState({weight:value})
    }
    render() {
        let {isEdit} = this.state
        let {patient,tenant,t} = this.props;
        let language= 'en'
        let physician = null;
        let birthday, gender, height, idNumber, phoneNumber, picUrl, weight = "";
        if (!!patient) {
            birthday = patient['birthday'];
            gender = patient['gender'];
            height = patient['height'];
            idNumber = patient['idNumber'];
            phoneNumber = patient['phoneNumber'];
            picUrl = patient['photoUrl'];
            weight = patient['weight'];
        }


        return (
            <div className={'patient-personal-data'}>
                <div className={'patient-personal-image-container'}>
                    <img src={picUrl ? picUrl : avatar} className={'patient-personal-image'}/>
                </div>
                <div className="container patient-personal-data-info">
                    <div className="row">
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col1'}>{t('physician.patient.profile.id')}</div>
                            <div className={'patient-personal-value'}>{idNumber}</div>
                        </div>
                        {/*<div className="col patient-personal-data-holder">*/}
                        {/*    <div className={'patient-personal-label col2'}>{t('physician.patient.profile.physician')}</div>*/}
                        {/*    <div className={'patient-personal-value'}>{physician}</div>*/}
                        {/*</div>*/}
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col3'}>{t('physician.patient.profile.healthSystem')}</div>
                            <div className={'patient-personal-value'} >{tenant}</div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col1'}>{t('physician.patient.profile.gender')}</div>
                            <div className={'patient-personal-value'}>{gender}</div>
                        </div>
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col2'}>{t('physician.patient.profile.Weight')}</div>
                            <div className={'patient-personal-value'}>{!isEdit?weight ? `${weight} kg` : this.state.weight+' kg':
                                <input type={'text'} style={{width:'50px'}} onChange={e=>this.addWeight('weight',e.target.value)}
                                       defaultValue={weight?weight:this.state.weight}/>}</div>
                        </div>
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col3'}>{t('physician.patient.profile.dob')}</div>
                            <div
                                className={'patient-personal-value'}>{utils.getDateStringWithYear(new Date(birthday),language)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col1'}>{t('physician.patient.profile.phone')}</div>
                            <div className={'patient-personal-value'}>{phoneNumber}</div>
                        </div>
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col2'}>{t('physician.patient.profile.age')}</div>
                            <div className={'patient-personal-value'}>{utils._calculateAge(new Date(birthday))}</div>
                        </div>
                        <div className="col patient-personal-data-holder">
                            <div className={'patient-personal-label col3'}>{t('physician.patient.profile.height')}</div>
                            <div className={'patient-personal-value'}>{!isEdit?height ? `${height} cm` : this.state.height+' cm':
                            <input type={'text'} style={{width:'50px'}} onChange={e=>this.addHeight('height',e.target.value)}
                                   defaultValue={height?height:this.state.height}/>} </div>
                        </div>
                        {isEdit&&<div className={'patient-personal-value'}>
                            <button type={"button"} onClick={() => this.savePatient()}
                                    className={"btn save-btn"}>{t('physician.patient.profile.save')}
                            </button>
                      </div>}

                    </div>

                </div>
            </div>
        );
    }
}

export default PatientPersonalInfo
