import React, {Component} from 'react';
import Select, {components, createFilter} from 'react-select';
import '../../style/component/input/TextInput.css'
import SVGraphics from "../../assets/SVGraphics";
import utils from "../../utils/utils";
import '../../style/component/input/PhysicianSelect.css';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>

        </components.DropdownIndicator>
    );
};

class  MultiSelect extends Component {
    constructor() {
        super();
        this.state = {
            menuIsOpen: false,
            selectedValues: [],
            isErr: false
        };

        this.handleMultiChange = this.handleMultiChange.bind(this);
    }

    handleMultiChange(selectedOptions) {
        if (selectedOptions === null || selectedOptions === undefined) selectedOptions = []
        this.setState(state => {
            return {
                selectedValues: selectedOptions
            };
        });
        this.props.onChange(selectedOptions.map(options => options.value), selectedOptions)
    }

    //
    // CustomOption = (props) => {
    //     let {label, values, isSelected, innerProps} = props
    //     return <div className={'physician-select-option-container'}>
    //         <input
    //             type="checkbox"
    //             checked={isSelected}
    //             className={'physician-select-option-checkbox'}
    //             onChange={event => innerProps.onClick()}
    //         />
    //         <div className={'physician-select-option-label'}>{label}</div>
    //     </div>
    // }
    //
    //
    //
    CustomOption = (props) => {
        let {label, values, isSelected, innerProps} = props
        return <div className={'physician-select-option-container'}>
            <input
                type="checkbox"
                checked={isSelected}
                className={'physician-select-option-checkbox'}
                onChange={event => innerProps.onClick()}
            />
            {
                typeof label === 'object' && label !== null ?
                    <div>
                        <div className={'physician-select-option-label'}>{label.name}</div>
                        <div className={'physician-select-option-label'}>{label.practice}</div>
                    </div>
                    :
                    <div className={'physician-select-option-label'}>{label}</div>
            }
        </div>
    }


    filterOption = (option, inputValue) => {
        return (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0
    }

    render() {
        let {isFocused} = this.state;
        let {
            name,
            placeholder,
            label,
            isErr,
            values,
            errMessage,
            disabled,
            className,
            options
        } = this.props
        let isActive = !!values && !isFocused
        let labelClass = 'outlined-custom-input-label outlined-custom-input-label-default'
        let inputClassName = 'custom-input'

        if (!!className) {
            inputClassName = inputClassName + ' outlined-input-' + className
            labelClass = labelClass + ' outlined-input-label-' + className
        }

        if (disabled) {
            labelClass = labelClass + ' outlined-custom-input-label outlined-custom-input-label-disabled'
            inputClassName = 'custom-input-disabled ' + inputClassName
            if (!!values) {
                labelClass = labelClass + ' outlined-custom-input-label-valued-disabled'
            }
        } else {
            if (isFocused) {
                labelClass = 'outlined-custom-input-label outlined-custom-input-label-focused'
                inputClassName = 'custom-input custom-input-focused'
            } else if (isActive) {
                labelClass = 'outlined-custom-input-label outlined-custom-input-label-active'
                inputClassName = 'custom-input custom-input-active'
            }
        }

        if (isErr) {
            labelClass = labelClass + ' outlined-custom-input-label-err'
            inputClassName = inputClassName + ' custom-input-err'
        }
        return (
            <div className={'outlined-custom-input-container'}>
                <div className={labelClass}>{label}</div>
                <Select
                    name={name}
                    placeholder={placeholder}
                    value={values}
                    label={""}
                    // menuIsOpen={true}
                    options={options}
                    filterOption={(option, inputValue) => this.filterOption(option, inputValue)}
                    // onChange={this.handleMultiChange}
                    onChange={ val => {
                        this.handleMultiChange(val)
                    }}
                    isMulti={true}
                    onFocus={() => this.setState({isFocused: true})}
                    onBlur={() => this.setState({isFocused: false})}
                    backspaceToRemoveMessage="remove"
                    hideSelectedOptions={false}
                    components={{Option: this.CustomOption, DropdownIndicator, IndicatorSeparator: () => null}}
                    styles={{
                        indicatorSeparator: (base, state) => ({
                            ...base,
                            display: "none"
                        }),
                        container: (base, state) => ({
                            ...base,
                            zIndex:"10",
                            outline: "unset",
                            border: "unset",
                            marginTop: "3px",
                            height: "58px",
                            width: "100%",

                        }),
                        control: (base, state) => {;return ({
                            ...base,
                            zIndex:"10",
                            border: isErr ? "3px solid #fd5a56" : "3px solid rgba(76, 35, 181, 0.5)",
                            borderRadius: "4px",
                            // backgroundColor: "",
                            width: "100%",
                            height: "58px",
                            color: "rgba(0,0,0,1)",
                            textTransform: "capitalize",
                            fontSize: "17px",
                            outline: "unset",
                            padding: "2px 3px 2px 3px",
                            fontFamily: "Rubik-Regular",
                            overflow:"auto",
                            boxShadow: "inset 2px 2px 1px 1px rgba(199, 197, 197,0.1)"

                        })},
                        valueContainer: (base, state) => ({
                            ...base,
                            zIndex:"10",
                            // color: "rgba(0,0,0,1)",
                            fontFamily: "Rubik-Regular",
                            fontSize: "17px"
                        }),
                        placeholder: (base, state) => ({
                            ...base,
                            zIndex:"1",
                            // color: "rgba(0,0,0,1)",
                            fontFamily: "Rubik-Regular",
                            fontSize: "17px"
                        }),
                        input: (base, state) => ({
                            ...base,
                            zIndex:"1",
                            color: "rgba(0,0,0,1)",
                            fontFamily: "Rubik-Regular",
                            fontSize: "17px"
                        }),
                        menuList: (base, state) => ({
                            ...base,
                            maxHeight:"120px",
                        }),
                        indicatorsContainer: (base, state) => ({
                            ...base,
                            zIndex:"10",
                            display: "none"
                        }),
                        multiValue: (base, state) => ({
                            ...base,
                        backgroundColor: "rgba(76, 35, 181, 0.1) !important",
                        borderRadius: "20px !important",
                        display: "-webkit-box"
                        }),
                    }}
                />
                {
                    isErr &&
                    <div className={'outlined-error'}>{errMessage}</div>
                }
            </div>
        );
    }

}


export default MultiSelect;
