import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../../style/View/NotFound.css'
import PatientsApi from "../../services/ApiServices/doctorApi/patientsApi";
import logo from "../../assets/nonagon-icon.svg"
import fluentLogo from "../../assets/Fluent_logo.png"
import SVGraphics from "../../assets/SVGraphics";

class ExpiredLink extends Component {

    constructor() {
        super();
        this.state = {
            tenant: null
        }
    }

    async componentDidMount() {
        let {tenant} = this.props.match.params
        if (tenant)
            this.setState({tenant})
        else {
            const ttuid = this.props.match.params.ttuid
            const ttuidData = await PatientsApi.getPatientCardIframePathDetailsFromTTUID(ttuid)
            if (!!ttuidData) {
                if (ttuidData.status === 'success') {
                    const params = new URLSearchParams({
                        tenant: ttuidData.tenant,
                        pid: ttuidData.pid,
                        sid:ttuidData.sid,
                        accessToken: ttuidData.accessToken,
                        asrAuth: ttuidData.asrAuth,
                    });
                    this.props.history.push({
                        tenant: ttuidData.tenant,
                        pathname: '/physician/patient/card',
                        search: params.toString()
                    })

                } else if (ttuidData.status === 'expired') {
                    this.setState({tenant: ttuidData.tenant})
                }
            }else this.props.history.push({
                tenant: ttuidData.tenant,
                pathname: `/rdct/404/pcrd`,
            })
        }
    }

    async askLinkRenew() {
        const ttuid = this.props.match.params.ttuid
        await PatientsApi.renewPatientCardIframePathFromTTUID(ttuid)

    }

    render() {

        let {title} = this.props;
        const {tenant} = this.state
        title = title ? title : 'Link Has expired';
        //fixme: fex return to homepage
        return (
            <div className={"expired-link-container"} style={{backgroundColor:tenant ==='fluentsmartexam'&&'#F4EEE9'}}>
                {tenant === 'fluentsmartexam' ?
                    <SVGraphics svgname={'logoFlunet'} width={'386px'} height={"160px"}/> :
                    <img style={{width: '386px'}} src={tenant === 'fluentsmartexam' ? fluentLogo : logo}/>
                }
                <div className={"expired-link-title"}>Link Expired</div>
                <div className={"expired-link-Message"}>The link to your patient’s exams has expired.</div>
                <div className={"expired-link-Message"} style={{textDecoration:'underline'}} onClick={e => this.askLinkRenew()}>Ask your patient for new link</div>
            </div>
        );
    }
}

ExpiredLink.propTypes = {
    title: PropTypes.string
};

export default ExpiredLink;
