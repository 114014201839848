import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from 'react-confirm'
import '../../style/component/modal/modal.css'
import SVGraphics from "../../assets/SVGraphics";

class ErrorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            isErr: false
        }
    }


    render() {
        let {
            title,
            message,
            show,
            proceed
        } = this.props;
        return (
            <Modal
                size="lg"
                show={show}
                centered={true}
                onHide={() => proceed()}
                dialogClassName={'exit-session-dialog'}
                contentClassName={'exit-session-content'}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <div className={'exit-session-btn-container modal-close pointer'} onClick={() => proceed(false)}>
                    <SVGraphics svgname={'close'} className={'modal-close-btn'}/>
                </div>
                <Modal.Body className={'exit-session-media-body'}>
                    <div className={'modal-message-header'}>{title}</div>
                    <div className={'modal-message-body'}>
                        {String(message).split("\n").map((i, key) => {
                            return <div key={key}>{i}</div>;
                        })}
                    </div>
                    <div className={'modal-message-footer'}>
                        <button className={'btn mhd-btn'} onClick={() => proceed(true)}>Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

ErrorModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

export default function MakeError({title,message} = {
                                      title: title = 'Error',
                                      message: message = "Error, please refresh the page"
                                  }) {
    return createConfirmation(confirmable(ErrorModal))({
        title,
        message
    });
}
