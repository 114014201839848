import React from "react";

class SkinBodyIndicatorBack extends React.Component {


    render() {
        let {className, width, height, style, name, part, side} = this.props

        let isHead = (part).toLowerCase() === 'head'
        let isNick = (part).toLowerCase() === 'neck'
        let isBack = (part).toLowerCase() === 'back'
        let isGenitals = (part).toLowerCase() === 'buttocks'
        let isRightArm = (part).toLowerCase() === 'arm' && (side).toLowerCase() === 'right'
        let isRightHand = (part).toLowerCase() === 'hand' && (side).toLowerCase() === 'right'
        let isLeftArm = (part).toLowerCase() === 'arm' && (side).toLowerCase() === 'left'
        let isLeftHand = (part).toLowerCase() === 'hand' && (side).toLowerCase() === 'left'
        let isRightLeg = (part).toLowerCase() === 'leg' && (side).toLowerCase() === 'right'
        let isRightFoot = (part).toLowerCase() === 'foot' && (side).toLowerCase() === 'right'
        let isLeftLeg = (part).toLowerCase() === 'leg' && (side).toLowerCase() === 'left'
        let isLeftFoot = (part).toLowerCase() === 'foot' && (side).toLowerCase() === 'left'


        let xmlns = "http://www.w3.org/2000/svg"
        return (
            <svg viewBox={"0 0 167 369"}
                 width={width}
                 height={height}
                 fill="none"
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <svg xmlns="http://www.w3.org/2000/svg" width="167" height="369" fill="none" viewBox="0 0 167 369">
                    <path fill="#2E2E2E"
                          d="M111.624 67c-.093 0-.168-.028-.224-.084-.056-.056-.084-.13-.084-.224v-9.17c0-.093.028-.168.084-.224.056-.065.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.18.854 2.114 0 .737-.182 1.335-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .075-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.065-.135-.163-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.163 1.806-.49.401-.336.602-.835.602-1.498s-.201-1.157-.602-1.484c-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618zM50.624 67c-.093 0-.168-.028-.224-.084-.056-.056-.084-.13-.084-.224v-9.184c0-.093.028-.168.084-.224.056-.056.13-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.13.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.13.084-.224.084h-5.404z"
                          opacity=".5"/>
                    <path fill="#FBDFCF"
                          d="M144.241 175.245l-2.784 3.032-5.019-.426-12.218-24.86-4.05-11.385-.262-6.584-5.577-11.132-1.955-5.67-1.082 4.912-2.287 11.385-2.279 7.09.759 10.113.067.409.689 4.147 2.287 15.686 4.845 18.648.156 5.47.567 19.632-2.784 17.202-1.518 12.394.759 10.627 2.025 9.859-.759 21.758-2.784 14.668-4.189 21.505-.376 4.172.123 3.667 1.012 3.162 4.537 5.593 6.24 1.306v3.031l-1.334 2.091-6.459 2.778-6.467 2.474-5.7-.575-3.997-2.787.951-5.496.385-8.535.759-5.88-.253-5.696-.567-14.041-1.743-16.898-1.303-5.103.384-16.506.76-7.02-1.709-8.729-.759-14.415-3.421-16.505-3.23-12.9-.392-5.714-.48-7.133-.149-2.204-.166 2.273-.533 7.046-.437 5.731-3.229 12.9-3.421 16.505-.757 14.416-1.71 8.727.758 7.02.376 16.506-.925 4.77-2.113 17.231-.575 14.041.698 5.313.252 4.738.035 10.818.472 4.739-5.709 2.752-6.712-.915-3.989-.949-7.838-2.597-1.318-2.081.277-1.896 8.58-2.178 3.782-5.412 1.388-2.029.637-3.162-.385-5.558-4.937-22.393-2.787-14.666-.76-21.757 2.026-9.86.759-10.626-1.519-12.394-2.792-17.202.767-19.232.245-6.184 4.574-18.335 2.278-15.686.707-4.225v-.008l.053-.323.76-10.112-2.28-7.09-2.278-11.385-1.09-4.912-1.956 5.67-5.577 11.133-.253 6.585-4.05 11.385-11.669 24.525-5.572.758-2.793-3.032 2.278-9.868 3.552-22.254 2.532-11.385 4.31-6.576 2.038-14.173v-10.625l1.518-11.629 3.803-7.342 5.822-4.546 7.352-1.011 4.817.505 11.914-6.826.375-8.03.385-8.223 19.96.191.315 7.169.384 8.893 11.913 6.828 4.819-.504 7.348 1.01 5.831 4.546 3.803 7.343 1.518 11.628v10.623l2.025 14.172 4.311 6.576 2.54 11.385 3.544 22.254 2.28 9.869z"/>
                    <path fill="#FBDFCF"
                          d="M136.585 176.524l-.836 3.337 1.553 8.819 2.627 7.27 5.137 8.58 3.344.715 3.105 1.192 2.149 1.073 1.672-1.192-.119-2.145 1.194-2.384-2.268-9.891 3.582 1.906 2.747-1.072.119-1.907-2.507-.953-1.553-3.218-1.075-4.648-4.06-3.814-5.016-3.337-3.463.12-6.332 1.549zM31.27 176.212l.81 3.234-1.505 8.549-2.547 7.048-4.978 8.317-3.241.694-3.01 1.155-2.083 1.04-1.62-1.155.114-2.079-1.157-2.31 2.202-9.588-3.474 1.848-2.665-1.041L8 190.075l2.431-.922 1.505-3.122 1.042-4.505 3.937-3.697 4.862-3.235 3.357.116 6.135 1.502z"/>
                    <path fill="#E1C7B8"
                          d="M116.738 195.226l-1.204.853-2.854 2.021-7.794 2.848-9.226.079-1.955.017-6.973-1.705-2.924-1.271-.113-.053-.2.122-2.269 1.297-6.764 1.978-.367.104-12.167-.758-9.565-3.632-.507-.644-1.274-1.63 1.012-4.938 2.959-17.311 2.946-14.154.85-2.077 1.31-4.32v-.009l1.57 1.065 6.338 3.04 9.636 1.899h11.023l10.77-1.768 7.104-3.04 1.458-1.106 1.257 4.242.833 1.751 2.837 14.262 3.12 17.529 1.133 5.309z"/>
                    <path fill="#FBDFCF"
                          d="M65.969 38.464l1.05 3.445.47 1.54.108.342 4.007 10.34.745 1.92 6.197 9.835 4.821 1.032 5.186-1.1 5.825-9.962.6-1.54 3.848-9.942.305-.781.486-1.594 1.141-3.741-.38-12.126-6.13-8.4-10.387-2.679V15l-.091.03-.091-.03v.053l-11.2 2.884-6.139 8.393-.371 12.134z"/>
                    <path fill="#FBDFCF"
                          d="M93.875 54.628l1.102-.31 1.711-.494 1.879-2.8 1.969-4 1.125-3.196-.373-2.101-1.118.182-.555.091-.768.129-1.475 3.704-3.497 8.795zM65.535 43.827l1.126 3.195 1.97 4 1.877 2.8 1.095.31 1.719.494-3.095-7.779-1.878-4.72-1.331-.22-.678-.114-.433-.066-.372 2.1z"/>
                    <path fill="#82543A"
                          d="M100.757 38.259l-.38-12.126-6.13-8.4-10.386-2.68V15l-.092.03-.091-.03v.053l-11.2 2.884-6.138 8.393-.373 12.134 1.05 3.445 1.394 2.378 3.086 6.92 4.584 9.358 7.42 1.707 7.51-1.581 7.883-17.24.722-1.45 1.141-3.742z"/>
                    <path fill="#CFC5C0"
                          d="M54.718 118.232l-.689 2.003-1.074-6.175-1.745-7.691-1.17-4.502 2.33 3.633 2.034 8.562.314 4.17zM112.444 118.234l.69 2.003 1.073-6.175 1.746-7.691 1.169-4.504-2.33 3.633-2.033 8.562-.315 4.172z"/>


                    {isHead && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                     d="M100.5 33.915c0 7.68-1.884 12.81-4.847 16.02C92.696 53.138 88.596 54.5 84 54.5s-8.696-1.362-11.653-4.565c-2.963-3.21-4.847-8.34-4.847-16.02 0-7.683 1.885-12.247 4.815-14.904C75.254 16.346 79.35 15.5 84 15.5s8.746.846 11.685 3.511c2.93 2.657 4.815 7.22 4.815 14.904z"/>}
                    {isNick && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                     d="M95.5 61.439c0 2.498-1.006 4.747-2.924 6.38-1.924 1.636-4.8 2.681-8.576 2.681-3.776 0-6.652-1.044-8.576-2.681-1.918-1.633-2.924-3.882-2.924-6.38 0-1.253.253-2.219.714-2.973.46-.751 1.151-1.331 2.098-1.774 1.923-.898 4.82-1.192 8.688-1.192 3.867 0 6.765.294 8.688 1.192.947.443 1.638 1.023 2.098 1.774.461.754.714 1.72.714 2.973z"/>}
                    {isBack && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                     d="M109.445 117.937c0 19.244-2.893 28.567-7.398 33.114-4.41 4.451-10.472 4.45-17.489 4.449h-.729c-7.017.001-13.08.002-17.49-4.449-4.504-4.547-7.397-13.87-7.397-33.114 0-5.88-.14-11.084-.261-15.65-.078-2.896-.148-5.535-.172-7.927-.062-6.211.19-10.79 1.394-14.157 1.185-3.313 3.297-5.465 7.064-6.811C70.78 72.03 76.275 71.5 84.193 71.5c7.047 0 12.11.53 15.758 1.89 3.612 1.346 5.843 3.507 7.242 6.838 1.417 3.37 1.989 7.954 2.198 14.154.142 4.227.115 9.167.084 14.942-.015 2.686-.03 5.553-.03 8.613z"/>}
                    {isGenitals && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M53.566 174.36c0-4.584.848-7.954 2.341-10.445 1.489-2.482 3.644-4.133 6.34-5.235 2.708-1.105 5.956-1.654 9.608-1.923 3.494-.258 7.324-.257 11.367-.257h1.094c4.044 0 7.874-.001 11.368.257 3.652.269 6.9.818 9.607 1.923 2.697 1.102 4.852 2.753 6.34 5.235 1.493 2.491 2.342 5.861 2.342 10.445 0 2.336.111 4.443.232 6.335l.08 1.238c.093 1.395.176 2.657.205 3.809.073 2.98-.233 5.054-1.573 6.569-1.35 1.528-3.85 2.598-8.479 3.267-4.611.667-11.222.922-20.669.922-8.41 0-14.505-.255-18.921-.921-4.426-.668-7.084-1.737-8.705-3.297-1.605-1.545-2.27-3.648-2.514-6.594-.168-2.019-.137-4.37-.1-7.143.018-1.3.037-2.692.037-4.185z"/>}
                    {isRightArm && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M130.548 116.124l.008.059.021.054c5.234 13.366 8.187 26.903 9.131 37.319.472 5.212.44 9.619-.051 12.822-.245 1.604-.601 2.876-1.046 3.789-.448.916-.949 1.402-1.462 1.573-1.186.397-2.521.087-4.009-1.305-1.494-1.397-3.083-3.836-4.719-7.531-3.269-7.381-6.663-19.623-9.91-38.083l-.007-.04-.014-.037c-4.238-12.183-6.215-22.736-6.438-30.455-.112-3.864.216-6.987.9-9.24.69-2.27 1.695-3.526 2.853-3.913 1.249-.418 2.513-.264 3.797.653 1.302.93 2.628 2.651 3.928 5.368 2.599 5.431 5.02 14.68 7.018 28.967z"/>}
                    {isRightHand && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                          d="M154.158 185.939l.044.139.11.094c1.204 1.021 2.055 1.841 2.634 2.514.583.678.862 1.175.965 1.546.096.343.046.592-.088.827-.152.264-.421.526-.811.847-.125.103-.261.21-.404.322-.711.56-1.605 1.265-2.262 2.299-.781 1.23-1.218 2.89-.849 5.309-1.133.955-2.351 1.302-3.585 1.209-1.324-.1-2.706-.711-4.036-1.707-2.664-1.997-4.999-5.453-6.081-8.933-1.087-3.499-1.223-6.904-.589-9.572.638-2.681 2.023-4.524 3.901-5.108 1.879-.584 4.064.149 6.109 1.996 2.036 1.838 3.855 4.72 4.942 8.218z"/>}
                    {isLeftArm && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                        d="M35.626 116.124l-.008.059-.021.054c-5.234 13.366-8.187 26.903-9.131 37.319-.473 5.212-.44 9.619.05 12.822.246 1.604.602 2.876 1.047 3.789.448.916.949 1.402 1.462 1.573 1.186.397 2.52.087 4.008-1.305 1.495-1.397 3.084-3.836 4.72-7.531 3.269-7.381 6.663-19.623 9.91-38.083l.007-.04.013-.037c4.239-12.183 6.215-22.736 6.44-30.455.111-3.864-.217-6.987-.901-9.24-.69-2.27-1.696-3.526-2.853-3.913-1.25-.418-2.513-.264-3.797.653-1.303.93-2.628 2.651-3.928 5.368-2.599 5.431-5.02 14.68-7.018 28.967z"/>}
                    {isLeftHand && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M12.954 185.939l-.043.139-.111.094c-1.203 1.021-2.055 1.841-2.634 2.514-.583.678-.862 1.175-.965 1.546-.096.343-.046.592.089.827.151.264.42.526.81.847.125.103.261.21.404.322.71.56 1.605 1.265 2.262 2.299.781 1.23 1.219 2.89.85 5.309 1.132.955 2.35 1.302 3.585 1.209 1.324-.1 2.705-.711 4.035-1.707 2.664-1.997 5-5.453 6.081-8.933 1.088-3.499 1.224-6.904.589-9.572-.638-2.681-2.023-4.524-3.901-5.108-1.878-.584-4.064.149-6.11 1.996-2.035 1.838-3.854 4.72-4.941 8.218z"/>}
                    {isRightLeg && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M112.576 265.186c.528 18.789-.93 35.827-3.245 48.181-1.158 6.18-2.527 11.167-3.959 14.61-.717 1.724-1.44 3.035-2.143 3.912-.715.892-1.338 1.248-1.846 1.264-.484.015-1.026-.278-1.618-1.108-.586-.824-1.151-2.084-1.684-3.77-1.064-3.363-1.961-8.28-2.712-14.408-1.501-12.247-2.407-29.228-2.936-48.044-.264-9.434-1.238-18.44-2.25-26.631-.23-1.87-.464-3.697-.69-5.476-.768-6.023-1.466-11.501-1.79-16.282-.421-6.208-.201-11.132 1.251-14.49.72-1.663 1.735-2.925 3.117-3.777 1.386-.855 3.18-1.321 5.498-1.318 7.406.01 11.873 1.881 14.514 5.137 2.661 3.28 3.567 8.079 3.568 14.176.002 5.089-.624 10.995-1.318 17.549-.137 1.288-.276 2.602-.414 3.939-.835 8.124-1.608 17.092-1.343 26.536z"/>}
                    {isRightFoot && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                          d="M114.989 341.462c.388 1.221 1.366 2.342 2.228 3.286.09.1.18.197.267.292.344.375.662.722.938 1.058.346.422.582.779.695 1.087.106.287.105.532-.034.771-.15.257-.484.545-1.141.833-1.321.579-3.762 1.085-7.935 1.483-3.553.339-7.021-.221-9.745-1.374-2.733-1.157-4.64-2.871-5.26-4.825-.621-1.953.117-3.92 1.976-5.515 1.853-1.59 4.771-2.759 8.324-3.098 3.59-.343 5.634.191 6.939 1.19 1.327 1.015 2.054 2.627 2.748 4.812z"/>}
                    {isLeftLeg && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                        d="M53.851 265.186c-.527 18.789.931 35.827 3.246 48.181 1.158 6.18 2.526 11.167 3.958 14.61.718 1.724 1.44 3.035 2.144 3.912.714.892 1.338 1.248 1.845 1.264.485.015 1.027-.278 1.618-1.108.587-.824 1.152-2.084 1.685-3.77 1.064-3.363 1.961-8.28 2.712-14.408 1.501-12.247 2.407-29.228 2.935-48.044.265-9.434 1.24-18.44 2.25-26.631.232-1.87.464-3.697.691-5.476.767-6.023 1.465-11.501 1.79-16.282.42-6.208.2-11.132-1.252-14.49-.72-1.663-1.734-2.925-3.116-3.777-1.386-.855-3.18-1.321-5.498-1.318-7.406.01-11.873 1.881-14.514 5.137-2.662 3.28-3.567 8.079-3.569 14.176 0 5.089.625 10.995 1.32 17.549.136 1.288.275 2.602.412 3.939.836 8.124 1.608 17.092 1.343 26.536z"/>}
                    {isLeftFoot && <path fill="#FF7B4A" fillOpacity=".3" stroke="#FF7B4A"
                                         d="M52.501 341.667c-.396 1.247-1.368 2.396-2.23 3.372l-.267.301c-.344.386-.662.745-.939 1.092-.347.436-.588.808-.706 1.132-.111.305-.113.563.022.811.144.264.467.556 1.106.848 1.287.587 3.677 1.096 7.775 1.487 3.484.333 6.898-.256 9.591-1.451 2.699-1.198 4.61-2.97 5.254-4.997.644-2.027-.063-4.049-1.864-5.676-1.798-1.624-4.647-2.81-8.131-3.143-3.52-.336-5.531.22-6.828 1.251-1.327 1.054-2.068 2.723-2.783 4.973z"/>}
                </svg>

            </svg>
        );
    }
}

export default SkinBodyIndicatorBack;
