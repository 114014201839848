import React, {useState} from "react";
import {Modal} from 'react-bootstrap'
import '../../style/component/modal/modal.css'
import useMediaQuery from "../../utils/useMediaQuery";
import SVGraphics from "../../assets/SVGraphics";


export function ShareSessionModal (props) {

    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');

    const [urlLinkStatus, setUrlLinkStatus] = useState('Copy');
    const url = props.url;

    const  copyLinkToClipboard= async() => {
        await setUrlLinkStatus('Copied')
        setTimeout(() => {
            setUrlLinkStatus('Copy')
        },2000)
        if (navigator && navigator.clipboard && navigator.clipboard.writeText)
            return navigator.clipboard.writeText(props.url);
        return Promise.reject('The Clipboard  is not available.');

    };


        return (
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={'backdropClassName-share'}
                contentClassName={'share-records-modal'}
            >
                <div className=" d-flex justify-content-between align-items-center">

                    <span className="Share-Session">Share Records</span>
                    <div onClick = {() => props.closeModal()} className="close-modal close-modal-share"> <SVGraphics svgname={'close-black'} width={'1.5rem'} height={'1.5rem'}/>

                    </div>
                </div>
                <span className="Copy-this-link-to-share-session-with-another-clinician">
                  Copy this link to share session with another clinician
                       </span>
                {isMobilScreen?
                    <div className=" align-items-center">
                        <div className="link-description" style={{width:'17rem',marginLeft:'1rem'}}>
                            <div className={'url-label'} style={{width:'17rem',marginLeft:'1rem'}}>{url}</div>
                        </div>
                        <button style={{float:'left', marginLeft:'1rem',marginBottom:'1rem',marginTop:'1.5rem'}} disabled={urlLinkStatus === 'Copied'} className={'copy-button copy-span'} onClick = {() => copyLinkToClipboard()}>
                            {urlLinkStatus}
                        </button>

                    </div>:
                    <div className=" d-flex justify-content-between align-items-center" style={{marginBottom: '1.813rem'}}>
                        <div className="link-description" style={{width:isSmallScreen && '85%'}}>
                            <div className={'url-label'}>{url}</div>
                        </div>
                        <button disabled={urlLinkStatus === 'Copied'} className={'copy-button copy-span'} onClick = {() => copyLinkToClipboard()}>
                            {urlLinkStatus}
                        </button>

                    </div>
                }

            </Modal>
        );
    }



