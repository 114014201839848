import React, {Component, useEffect, useRef, useState} from "react";
import SVGraphics from "../assets/SVGraphics";
import '../style/View/Auth.css'
import TextInput from "../component/input/TextInput";
import * as Yup from "yup";
import validationHelper from "../utils/validationHelper";
import Config from "../config/Config";
import Reaptcha from 'reaptcha';
import utils from "../utils/utils";
import {authenticationService} from "../services/auth/AuthenticationService";
import Error from "../component/Error/Error";
import {doctorApi} from "../services/ApiService";
import MakeError from "../component/Error/ErrorModal";
import queryString from "query-string";
import Status from "../config/Status";
import allUsersApi from "../services/ApiServices/AllUsersApi";
import {Checkbox, FormControlLabel, IconButton, InputAdornment} from "@material-ui/core";
import {Modal} from 'react-bootstrap'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useMediaQuery from "../utils/useMediaQuery";

export function Login(props) {

    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 550px)');
    const isExtraSmall = useMediaQuery('(min-width:551px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');

    const [auth, setAuth] = useState({});
    const [entry, setEntry] = useState({});
    const [isErr, setIsErr] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [errorsOnSave, setErrorsOnSave] = useState({});
    const [passwordIsShown, setPasswordIsShown] = useState(false);
    const [color, setColor] = useState("#D2C8EC");
    const [checked, setChecked] = useState(false);
    const [validationSchema, setValidationSchema] = useState({});
    let recaptchaRef = useRef();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const initValidation = async () => {
        let _validationSchema = Yup.object().shape({
            email: Yup.string().matches(Config.emailRegExp, "Email is not valid").required("Email is required"),
            password: Yup.string().required("Password is required"),
            // tenant: Yup.string().required('tenant is required')
        });
        let _errors = await validationHelper.validate(auth, validationSchema);
        setValidationSchema(_validationSchema)
        setErrors(_errors)
    };


    useEffect( () => {
        auth['email'] = localStorage.getItem('email')?localStorage.getItem('email'):'';


    }, [])

    const onAuthChange = async (event) => {
        let _auth = JSON.parse(JSON.stringify(auth))
        let _validationSchema = {...validationSchema}
        _auth[event.target.name] = event.target.value.replace(/\s+/g, '');
        let _errors = await validationHelper.validate(_auth, _validationSchema);
        let _errorsOnSave = {...errorsOnSave}
        if (utils.get(_errorsOnSave, event.target.name)) {
            utils.set(_errorsOnSave, event.target.name, utils.get(errors, event.target.name))
        }
        document.getElementById('submitbutton').disabled = false
        setErrors(_errors)
        setErrorsOnSave(_errorsOnSave)
        setIsErr(false)
        setErrMessage('')
        setAuth(_auth)
        setEntry(_auth)

    }

    const onSubmit = async () => {

        let _auth = JSON.parse(JSON.stringify(auth))
        // if (!utils.isEmpty(errors)) {
        //     setErrorsOnSave(errors)
        //     // this.setState({errorsOnSave: this.state.errors});
        //     return;
        // }
        let email = entry["email"]
        let password = entry["password"]

         await authenticationService.login(email, password, props.tenant, 'Physician').then(async userData => {

            //
            localStorage.setItem('email', _auth["email"]);
            localStorage.setItem('tenant', _auth["tenant"]);

            //get profile
            let userProfile = userData['profile'];
            // check if locked
            if (userProfile.enabled === 0) {
                setIsErr(true)
                setErrMessage("User is disabled")
                await authenticationService.logout();
                return;
            }else if(userProfile){
                props.login()
            }
            // let query = queryString.parse(props.history?.location.search);
            // if (query && query.redirectToCard) {
            //     let pid = query.redirectToCard;
            //     let index = pid.indexOf('?')
            //     let patinentID = pid.slice(0, index)
            //     await props.login()
                // let patient = await doctorApi.getPatientById(patinentID)
                // await props.getPatient(patient)
                //
                // let sessions = await doctorApi.getPatientSessionsById(patinentID, true)
                // let ignoredSessions = [Status.missed, Status.denied]
                // props.getPatientSessions(patinentID, sessions.filter(ses => !ignoredSessions.includes(ses.status)))
                //
                // props.history.push({
                //     pathname: '/physician/patient/card',
                //     search: `pid=${patinentID}`
                // })
            // } else {
            //     setIsErr(true)
            //     setErrMessage("Invalid credentials")
            //     await authenticationService.logout();
            // }
        }).catch(async err => {
            // document.getElementById('submitbutton').disabled = false

            console.error(err)
            let errString = "Connection Error";
            if (err && err.message) {
                errString = err.message;
            }
            if (err.error === 'PasswordResetRequiredException') {
                await MakeError({title: "Login failed", message: errString})

            } else {

                setIsErr(true)
                setErrMessage(errString)
            }
        });
    }
    const togglePasswordVisibility = () => {
        setPasswordIsShown(!passwordIsShown)
    }
    // async setLanguage(event){
    //     let type = event.target.getAttribute("data-name")
    //     this.setState({language:event.target.innerHTML, isLanguage:false})
    //     let lang = Translate.getLanguage(type);
    //     let language = await Translate.setLanguage(lang);
    //     const { i18n } = this.props;
    //     await i18n.changeLanguage(language);
    // }

    // forgotPassword(){
    //     let {email, password, tenant, role} = (this.state)['auth'];
    //     localStorage.setItem('role', role);
    //     localStorage.setItem('email', email);
    //     localStorage.setItem('tenant', tenant);
    //
    //     this.props.history.push('/password/forgot')
    //
    // }


    let hybridCare = window.location.origin.slice(0).toLowerCase().includes('hybridcare')
    const ExecuteRecaptcha = async (reCaptchaToken) => {

        // await captchaRef.reset()
        await recaptchaRef.current.reset()
        await recaptchaRef.current.execute()
    }


    return (
        <Modal
            // size="lg"
            show={props.show}
            // centered={true}
            onHide={() => props.closeModal()}
            dialogClassName={`media-dialog-login `}
            contentClassName={`media-dialog-login-content`}
            backdrop={false}
            keyboard={true}
            aria-labelledby="example-modal-sizes-title-sm"
        >

            <Modal.Body
                className={'modal-dialog-body-login'} style={{display: isMobilScreen?'block':'flex', justifyContent: 'center',borderRadius:'15px'}}>
                <div className={'media-close-btn-container modal-close-button pointer'}
                     onClick={() => props.closeModal()}>
                    <SVGraphics svgname={'close-black'} className={' modal-close-btn-prescription'}
                    />
                </div>
                <div className={'auth-page '}>
                    {/*<SVGraphics svgname={'mhd-logo'} className={'auth-mhd-logo'}/>*/}
                    <div className={'auth-page-content centered auth-page-content-modal'}>
                        <div style={{width: isMobilScreen?'5rem':'15rem', height: 'auto'}}>
                            <SVGraphics svgname={hybridCare ? 'logoHc' : 'mhd-logo'}
                                        className={'auth-placeholder-image'}/>
                        </div>
                        <div className={'auth-form-container'}>
                            <div className={'auth-form-label'}>SIGN IN</div>
                            <div className={'auth-form-inputs h-centered'}>

                                <TextInput
                                    type={'text'}
                                    autoComplete={false}
                                    name={'email'}
                                    // ref={emailRef}
                                    isErr={!!errorsOnSave['email']}
                                    defaultValue={auth['email']}
                                    value={auth['email']}
                                    errMessage={errorsOnSave['email']}
                                    onChange={event => onAuthChange(event)}
                                    label={"Email"}
                                    // onSubmit={async () => await recaptchaRef.execute()}
                                    className={'auth-form-input'}
                                    placeholder={"Email"}
                                    required={true}/>
                                {/*<div style={{*/}
                                {/*    display: 'flex',*/}
                                {/*    width: '100%',*/}
                                {/*    justifyContent: 'space-between',*/}
                                {/*    alignItems: 'center'*/}
                                {/*}}>*/}
                                    <TextInput
                                        type={passwordIsShown ? 'text' : 'password'}
                                        // onSubmit={async () => await recaptchaRef.execute()}
                                        autoComplete={false}
                                        name={'password'}
                                        isErr={!!errorsOnSave['password']}
                                        defaultValue={auth['password']}
                                        className={'auth-form-input'}
                                        errMessage={errorsOnSave['password']}
                                        onChange={event => onAuthChange(event)}
                                        label={"Password"}
                                        ref={passwordRef}
                                        placeholder={"Password"}
                                        required={true}
                                        endAdornment={(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => togglePasswordVisibility()}
                                                >
                                                    {passwordIsShown ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )}/>

                                {/*</div>*/}


                                {/*<TextInput*/}
                                {/*    type={'text'}*/}
                                {/*    onSubmit={async () => await recaptchaRef.execute()}*/}
                                {/*    autoComplete={false}*/}
                                {/*    name={'tenant'}*/}
                                {/*    isErr={!!errorsOnSave['tenant']}*/}
                                {/*    defaultValue={auth['tenant']}*/}
                                {/*    value={auth['tenant']}*/}
                                {/*    className={'auth-form-input'}*/}
                                {/*    errMessage={errorsOnSave['tenant']}*/}
                                {/*    onChange={event => onAuthChange(event)}*/}
                                {/*    label={"Tenant"}*/}
                                {/*    placeholder={"Tenant"}*/}
                                {/*    required={true}/>*/}


                            </div>


                            <button className={'mhd-btn auth-btnUpdated h-centered'}
                                    style={{marginTop: '3.5rem'}}
                                    // onClick={async () => {
                                    //     await recaptchaRef.execute()
                                    //     document.getElementById('submitbutton').disabled = true
                                    // }}
                                    // onClick={() => ExecuteRecaptcha()}
                                    onClick={ () => onSubmit()}
                                    id={"submitbutton"}
                            >
                                Login
                            </button>


                            <Error isShown={isErr} errorMessage={errMessage} isCentered={true}/>
                        </div>

                    </div>
                    <Reaptcha
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        size={'invisible'}
                        explicit={false}
                        onVerify={async token => {
                            if (!!token) {
                                await onSubmit()
                            }
                            else {
                                setIsErr(true)
                                setErrMessage("Blocked By ReCAPTCHA")
                            }
                        }}
                        onExpire={() => {
                            setIsErr(true)
                            setErrMessage("ReCAPTCHA Expired, please refresh the page")
                        }}
                        onError={err => {

                            setIsErr(true)
                            setErrMessage(err)
                        }}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );

}




