import React, {Component} from "react";
import VideoPlayerOtoscope from "../../../../media/VideoPlayerOtoscope";
import moment from "moment";
import MakeVideoFilter from "../../../../modal/VideoModalWithFilter";
import SVGraphics from "../../../../../assets/SVGraphics";
import MakeSnap from "../../../../modal/SnapModal";
import sessionsApi from "../../../../../services/ApiServices/doctorApi/sessionsApi";
import {doctorApi} from "../../../../../services/ApiService";
import queryString from "query-string";
import MakeErrorConfirm from "../../../../Error/ErrorModalWithCancel";
import physician from "../../../../../views/physician/Physician";
import utils from "../../../../../utils/utils";
import {history} from "../../../../../utils/history";

class EarsExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            LeftCaptures: [],
            RightCaptures: [],
            delete:false
        }
    }
    async componentDidMount() {
        let search = !history.location.search || history.location.search === {} ? window.location.search : history.location.search
        let query = queryString.parse(search);
        let allow = await this.checkQueryParam(query);
        let tenant = query.tenant;
        let sid = query.sid;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth
        let sessionRes = await doctorApi.getSessionById(sid, tenant, accessToken,asrAuth);
        if(sessionRes){
            let files = sessionRes.Files
            let RightCaptures = files.filter(x => x.type === "RightEarCapture")
            let LeftCaptures = files.filter(x => x.type === "LeftEarCapture")
            this.setState({RightCaptures, LeftCaptures})

        }

    }
    async openFullScreen(url, file, files) {
        let {LeftCaptures, RightCaptures} = this.state
        let {id} = this.props
        let language = 'en'
        let type;
        if(language === 'en'){
            type= file['subType'] === 'Left' ? 'Left Ear':'Right Ear'
        }else{
            type= file['subType'] === 'Left' ? 'Izquierda Oído' : 'Derecha Oído'
        }
        let captures = file['subType'] === "Left" ? LeftCaptures : RightCaptures
        let filters = ['Original','Brighter','Brightest'].map((label,id) => {return{id: String(id+1), label}})
        let newCaptures = await MakeVideoFilter({url, file, type, files, filters, id,captures})
        if (!!newCaptures && newCaptures.length > 0) {
            if (file['subType'] === "Left") {
                this.setState({LeftCaptures: newCaptures})
            } else {
                this.setState({RightCaptures: newCaptures})
            }
        }
    }
    getFileTimestamp(file) {
        let time = moment(file.createdAt).format('HH:mm');
        let date = utils.getDateStringWithYear(new Date(file.createdAt));
        return `${time} | ${date}`
    }
    async openImage(file){
        let {LeftCaptures, RightCaptures} = this.state
        let captures = file['type'] === "RightEarCapture" ? RightCaptures :file['type'] === "LeftEarCapture" && LeftCaptures
        let newCaptures = await MakeSnap({file, captures})
        if (file['type']  === "LeftEarCapture") {
            this.setState({LeftCaptures: newCaptures})
        } else {
            this.setState({RightCaptures: newCaptures})
        }


    }
    async deleteSnapShot(fileID,type){
        let { RightCaptures, LeftCaptures} = this.state
        let query = queryString.parse(history.location.search);
        let allow = await this.checkQueryParam(query);
        let tenant = query.tenant;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth
        let check = await MakeErrorConfirm({title:"Delete Image", message:"Are you sure you want to remove this snapshot?"})
        if(check) {
            if (!this.state.delete) {
                this.setState({delete: true})
                let deleteSnap = await sessionsApi.deleteScreenshot(fileID, tenant, accessToken,asrAuth)
                if (deleteSnap) {
                    if (type === "RightEarCapture") {
                        let newSnaps = RightCaptures.filter(x => x.fileID !== fileID)
                        this.setState({RightCaptures: newSnaps})
                    } else if (type === "LeftEarCapture") {
                        let newSnaps = LeftCaptures.filter(x => x.fileID !== fileID)
                        this.setState({LeftCaptures: newSnaps})
                    }
                    this.setState({delete: false})
                }
            }
        }

    }
    // render() {
    //     let {files, t} = this.props
    //     let language= localStorage.getItem('language')
    //     return (
    //         <div className={'exam-result-container throat-exam-container'}>
    //             {/*<div className={'throat-exam-results-container-throat'}>*/}
    //
    //                     {
    //                         files.map((file, i) => {
    //
    //                             if (file['type'] === 'Ears' && file['downloadUrl'] != null)
    //                                 return (<>
    //                                     <div className={ 'ears-exam-results-container-ears '} style={{justifyContent: 'start'}}>
    //                                         <div className={"throat-grid-item-capture"}>
    //                                             <label className={'throatMouth-title'}>{file['subType']} Ear</label>
    //
    //                                             <div className={'exam_title_Ear'}>{language === 'en' ? file['subType']+" Ear Video" : file['subType'] === 'Mouth' ? 'Boca' : 'Garganta'}</div>
    //
    //                                     <VideoPlayerOtoscope fileId={file['fileID']} openFullScreen={() => this.openFullScreen(file['downloadUrl'], file, files.filter(x => x.type === 'Ears'))}
    //                                                     className={'throat-video'} title={language === 'en' ? file['subType'] +" Ear Video"  :file['subType'] === 'Right' ? 'Derecho' : 'Izquierdo' }
    //                                                     url={file['downloadUrl']}
    //                                                          fullscreenClass={'video-player-screen-control video-fullscreen-ears vp-fsc-throat-video'}/>
    //                                             <div className={'exam_footer_throat'}>{this.getFileTimestamp(file)}</div>
    //
    //                                         </div>
    //                                     </div>
    //                                 </>)
    //                         })
    //                     }
    //                     {
    //                         files.length === 0 &&
    //                         <div className={'Error-Files h-centered'}>{t('physician.patient.exams.fileErr1')}</div>
    //                     }
    //
    //             {/*</div>*/}
    //         </div>
    //     );
    // }
    async checkQueryParam(query) {
        return Object.keys(query).length > 0 && !!query.pid && !!query.tenant
    }

    render() {
        let language ='en'
        let exams = ['Left', 'Right']
        let {files, t,onlineCall,tenant} = this.props
        let {LeftCaptures, RightCaptures} = this.state
        let isCaptures = LeftCaptures.length > 0 || RightCaptures.length > 0
        let gridItem = isCaptures && onlineCall ?'throat-grid-item-capture-online': isCaptures && !onlineCall ? 'throat-grid-item-capture' : 'throat-grid-item'
        let containerStyle=isCaptures?{right:'11.5%',justifyContent: 'start'}:{right:'15.5%',justifyContent: 'start'}

        if (!files || files.length === 0)
            return  <div className={'exam-result-container throat-exam-container'}/>
        else {

            return (
                <>
                    <div className={'exam-result-container throat-exam-container'}>
                        { exams.map(exam => {
                            let video = (files.find(x => {
                                let isExam = x.subType === exam, hasFilters = !!files.filter, isCorrectFilter = x.filter === "Filter1"
                                if (hasFilters)
                                    if (isExam && isCorrectFilter) return exam
                                    else if (isExam) return exam
                            }))
                            let captures = exam === 'Left' ? LeftCaptures : RightCaptures

                            return (
                                <>
                                    {video &&
                                        <label className={'throatMouth-title ears-title'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>{t(`physician.patient.exams.${video['subType'].toLowerCase()}`) } {t('physician.patient.exams.ear')} </label>
                                    }
                                    <div style={containerStyle} className={isCaptures && onlineCall ?'throat-exam-results-container-ears-online': isCaptures && !onlineCall? 'throat-exam-results-container-ears' : 'throat-exam-results-container '} >
                                        { !!video &&
                                            <div className={gridItem}>

                                                <VideoPlayerOtoscope fileId={video['fileID']} openFullScreen={() => this.openFullScreen(video['downloadUrl'], video, files.filter(x => x.type === 'Ears'))}
                                                                     className={'throat-video'} title={language === 'en' ? video['subType'] +" Ear Video"  :video['subType'] === 'Right' ? 'Derecho' : 'Izquierdo' }
                                                                     url={video['downloadUrl']}
                                                                     fullscreenClass={'video-player-screen-control video-fullscreen-throat vp-fsc-throat-video'}/>
                                                <div className={'exam_footer_throat'}>{this.getFileTimestamp(video)}</div>
                                            </div>
                                        }
                                        {
                                            captures.map((capture,index) => {
                                                return (
                                                    <div className={gridItem+"-"+index}>
                                                        <div
                                                            className={'exam_title_throat'}>{t(`physician.patient.exams.${exam.toLowerCase()}`) + " snapshot "}</div>
                                                        <img src={capture['downloadUrl']} alt={'capture'} className={'snapShotImage'} onClick={()=>this.openImage(capture)}/>
                                                        <div className={'exam_footer_throat'}>{this.getFileTimestamp(capture)}</div>
                                                        <SVGraphics svgname={'trashIcon'} className={!onlineCall ?'modal-close-btn image-trash-btn-video':'modal-close-btn image-trash-btn-video-online'} onClick={()=>this.deleteSnapShot(capture.fileID,capture.type)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </>
            )
        }
    }

}

export default EarsExam
