import React, {useEffect, useState} from "react";
import {Modal} from 'react-bootstrap'
import '../../../style/component/modal/Media.css'
import SVGraphics from "../../../assets/SVGraphics";
import moment from "moment";
import useMediaQuery from "../../../utils/useMediaQuery";


export default function NewVVideoModal (props) {

    const [url, setUrl] = useState(props.url);
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

    useEffect(async () => {

         }, [])

        // let videoFiltering = type === 'throat' || type === 'mouth' ? true : false

        let time = moment(props.file.createdAt).format('HH:mm');
        let date = moment(props.file.createdAt).format('MMM DD YYYY');
    const handleSeekChange = (e) => {
        if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {

            this.setState({played: parseFloat(e.target.value)})}
        else {

            let currentSeconds =  Math.floor(document.getElementById(`videoPlayer+${this.props.title}`).currentTime % 60);
            let played = 0+"."+currentSeconds ;


            this.setState({played})
        }
    }
    // const handleLoadedMetadata = (event) => {
    //     const video = event.target;
    //     let element = document.getElementById('videoId')
    //     element.duration=video.duration;
    // };
        return (
            <Modal
                // size="lg"
                show={props.show}
                centered={true}
                onHide={() => props.proceed()}
                dialogClassName={ `media-dialog-video  ${isSmallScreen?'media-dialog-video-small':isMobilScreen?'media-dialog-main-video-mobile':''}`}
                contentClassName={`media-dialog-video-content ${isSmallScreen?'media-dialog-video-small':isMobilScreen?'media-dialog-main-video-mobile-content':''}`}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {/*<div className={'video-modal-title'}><span*/}
                {/*    style={{fontWeight: 'bold'}}>{language === 'en' ? file['type'] : file['type'] === 'Mouth' ? 'Boca' : 'Garganta'} video</span> {time} | {date} </div>*/}
                {/*<div className={'media-close-btn-container modal-close-video pointer'} onClick={() => proceed(this.state.captures)}>*/}
                {/*    <SVGraphics svgname={'close-white'} className={'modal-close-btn modal-close-btn-video'} />*/}
                {/*</div>*/}
                <div className={`media-dialog-snap-title ${isMobilScreen?'media-dialog-snap-title-mobile':''}`}><span
                    style={{fontWeight: 'bold'}}>{props.title + `${props.title!=='Skin'?'  ':''}  `} </span> {time} | {date}

                    <SVGraphics svgname={'close-white'} className={`media-dialog-snap-modal-close-btn ${isMobilScreen?'media-dialog-snap-modal-close-btn-mobile':''}`} onClick={() => props.proceed()}/>
                </div>

                {/*<Modal.Header className={'video-media-header media-header'} closeButton/>*/}

                <Modal.Body
                    className={ 'media-dialog-ear-video'} style={{display:'flex', justifyContent:'center'}}>

                    {/*<VideoPlayerSnap id={"videoId"}*/}
                    {/*                 // videoRef={this.videoRef}*/}
                    {/*                 closeFullScreen={() => props.proceed()}*/}
                    {/*             className={'media-dialog-snap-image'}*/}
                    {/*             url={url}/>*/}
                    {isSafari ? <video id={'videoId'}
                                       // onTimeUpdate={(e)=>this.handleSeekChange(e)}
                                       onEnded={()=>this.onEnded()}
                                       // style={{width: "100%", height: '92%',  padding:'5%'}}
                                       onSeeking={(e) => handleSeekChange(e)}
                                       onSeeked={(e) => handleSeekChange(e)}
                                       style={{width: "93.9%", height: '96%',objectFit:'cover',border:'1px solid'}}
                                       // onPlaying={this.props.onPlaying}
                                       // onPause={this.props.onPause}
                                       // onMouseMove={this.props.onMouseMove}
                                       // onMouseOut={this.props.onMouseOut}
                                       // onLoadedMetadata={handleLoadedMetadata}
                                       playsInline
                                       preload="metadata"
                                       // muted
                                       webkit-playsinline
                                       controls
                                       disablePictureInPicture
                                       controlslist="nodownload noremoteplayback noplaybackrate nofullscreen "

                        >
                            {/*{url === this.props.url && <source src={url} type="video/mp4"/>}*/}
                            {/*{url !== this.props.url && <source src={url} type="video/mp4"/>}*/}
                            <source src={url+'#t=0.001'} type="video/mp4"/>
                        </video>:
                    <video
                        id={'videoId'}
                        // onTimeUpdate={(e)=>this.handleSeekChange(e)}
                        // onEnded={()=>this.onEnded()}
                           style={{width: "93.9%", height: '96%',objectFit:'cover'}}
                        // onSeeking={(e) => this.handleSeekChange(e)}
                        // onSeeked={(e) => this.handleSeekChange(e)}
                           controls
                        // onLoadedMetadata={this.props.onLoadedMetadata}
                        // onPlaying={this.props.onPlaying}
                        // onPause={this.props.onPause}
                        // onLoadedMetadata={handleLoadedMetadata}
                           disablePictureInPicture={true}
                           preload
                           disableDownload={true}
                        webkit-playsinline
                        playsinline
                           controlsList="nodownload noplaybackrate nofullscreen "
                           autoPlay={false}
                        // onMouseMove={this.props.onMouseMove}
                        // onMouseOut={this.props.onMouseOut}
                        // crossOrigin="anonymous"

                    >
                        {url === props.url && <source src={url} type="video/mp4"/>}
                        {url !== props.url && <source src={url} type="video/mp4"/>}
                    </video>}
                </Modal.Body>
            </Modal>
        );

}

// NewVVideoModal.propTypes = {
//     show: PropTypes.bool,
//     url: PropTypes.string,
//     type: PropTypes.string,
//     files: PropTypes.array,
//     filters: PropTypes.array,
//     file: PropTypes.object,
//     // captures: PropTypes.object
// };

// export default function NewVideoModalWithFilter({url: url = '', type: type = '', file: file,files:files,filters:filters=[],id:id}) {
//     return createConfirmation(confirmable(NewModalWithFilter))({
//         url, type, file,files,filters,id
//     });
// }
