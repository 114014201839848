import React, {Component} from "react";
import {
    SingleDatePicker, toMomentObject
} from "react-dates";

import moment from "moment";
import "react-dates/initialize";
import calendar from "../../assets/calendar.svg";
import "../../style/component/input/OutlinedRangeSelect.css"
import 'react-dates/lib/css/_datepicker.css';

class OutlinedDateSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            isErr: false,
            date: moment(),
            focusedInput: null
        };
    }

    handleChange = async selectedDate => {
        await this.props.onSelect(selectedDate)
        await this.setState({isFocused: false})


    };
    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        let years = []
        for (let i = moment().year()+10; i >= moment().year() - 100; i--) {
            years.push(<option value={i} key={`year-${i}`}>{i}</option>)
        }
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '8px',
                width: '96%',
                marginRight: '5px'
            }}>
                <div className={'monthSelect'}>
                    <select className={'OutLinedDateSelect outlined-date-range-select yearMonthSelect'}
                            value={month.month()}
                            onChange={(e) => onMonthSelect(month, e.target.value)}
                    >
                        {moment.months().map((label, value) => (
                            <option value={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <div className={'yearSelect'}>
                    <select className={'OutLinedDateSelect outlined-date-range-select yearMonthSelect'}
                            value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                        {years}
                    </select>
                </div>
            </div>
        )
    }

    render() {
        let {isFocused, date, focusedInput} = this.state;
        let language = localStorage.getItem('language')
        let {
            disabled,
            label,
            isErr,
            errMessage,
            allowFuture,
            allowPast,
            yearJump,
            dateChange,
            isOutsideRange,
            placeholder
        } = this.props
        let selectedDate = this.props.selected
        let isActive = !!selectedDate && !isFocused
        let labelClass = 'outlined-date-select-label outlined-date-select-label-default'
        let selectClass = 'outlined-date-select outlined-date-select-default'
        let className = 'OutLinedDateSelect'
        let inputClassName = 'OutLinedDateSelect-input'

        if (yearJump) {
            className = className + ' years-allowed'
        }

        if (disabled) {
            labelClass = labelClass + ' outlined-date-select-label-focused outlined-date-select-label-disabled'
            selectClass = selectClass + ' outlined-date-select-disabled'
            className = 'OutLinedDateSelect-disabled ' + className
            inputClassName = 'OutLinedDateSelect-input-disabled ' + inputClassName
        } else {
            if (isFocused) {
                labelClass = 'outlined-date-select-label outlined-date-select-label-focused'
                selectClass = 'outlined-date-select outlined-date-select-focused'
                inputClassName = 'OutLinedDateSelect-input OutLinedDateSelect-input-focused'
            } else if (isActive) {
                labelClass = 'outlined-date-select-label outlined-date-select-label-active'
                selectClass = 'outlined-date-select outlined-date-select-active'
                inputClassName = 'OutLinedDateSelect-input OutLinedDateSelect-input-active'
            }
        }

        if (isErr) {
            labelClass = labelClass + ' outlined-date-select-label-err'
            selectClass = selectClass + ' outlined-date-select-err'
            className = 'OutLinedDateSelect-err ' + className
            inputClassName = inputClassName + ' OutLinedDateSelect-input-err'
        }

        const TestCustomCloseIcon=()=>{
            return <span className={'calendarIconContainerSingle'}><img src={calendar} alt={'calendar'}/></span>
        }
        moment.locale(language)
        return (
            <div className={'outlined-date-select-container outlined-select-date'}>
                <div className={labelClass}>{label}</div>
                <div className={selectClass}>
                    <SingleDatePicker
                        isOutsideRange={isOutsideRange}
                        placeholderText={placeholder}
                        date={selectedDate ? moment(selectedDate) : selectedDate}
                        onDateChange={date => this.handleChange(date)}
                        numberOfMonths={1}
                        focused={isFocused}
                        isSearchable={false}
                        renderMonthElement={this.renderMonthElement}
                        customInputIcon={<TestCustomCloseIcon />}
                        displayFormat=" MMM DD, YYYY"
                        onFocusChange={({focused}) => this.setState({isFocused: focused})}
                        id={'date'}
                        readOnly
                        disabled={disabled}
                    />
                </div>
                {
                    isErr &&
                    <div className={'outlined-error'}>{errMessage}</div>
                }
            </div>

        );
    }

}

//// wrapperStyle={{width:'100%'}}
//                         locale={language}
//                         value={selectedDate}
//                         handleChange={(term) => this.handleChange(term)}
//                         className={className}
//                         inputClassName={inputClassName}
//                         placeholder={''}
//                         dateFormat={'ddd, DD MMMM YYYY'}
//                         disable={this.props.disabled}
//                         allowPast={allowPast}
//                         allowFuture={allowFuture}
//                         yearJump={yearJump}

export default OutlinedDateSelect
