import SkinExamNew from "./SkinExamNew";
import EarsExamNew from "./EarsExamNew";
import StethoscopeExam from "./StethoscopeExam";
import {useState} from "react";


export function Exams(props) {





    const examComponent = () => {
        let files = props.exam?.Files.filter(x => x.type === props.examName)
        let earsFiles = props.exam?.Files.filter(x => x.type === props.examName || x.type.includes('EarCapture'))
        let MouthFiles = props.exam?.Files.filter(x => x.type === 'Mouth' || x.type === 'Throat' || x.type.includes('ThroatCapture')|| x.type.includes('MouthCapture'))

        switch (props.examName){
            case "Heart":
            case "Abdomen":
            case "Lungs":
            {
                return <StethoscopeExam
                    playedFileId={props.playedFileId}
                    playedType={props.playedType}
                    playedFile={props.playedFile}
                    setPlayedFile={props.setPlayedFile}
                    setPlayedType={props.setPlayedType}
                    setPlayedFileId={props.setPlayedFileId}
                    setPoint={props.setPoint}
                    point={props.point}
                    exam={props.exam}
                    files={files}
                    type={props.examName}
                    gender={props.gender}
                    age={props.age}/>
                break;
            }
            case "Skin":{
                return <SkinExamNew exam={props.exam} files={files} gender={props.gender} age={props.age}/>
                break;
            }
            case "Ears":
                case "Throat":{
                return <EarsExamNew exam={props.exam} examName={props.examName} files={props.examName==='Ears'?earsFiles:MouthFiles} isUserLoggedIn={props.isUserLoggedIn} addScreenshot={props.addScreenshot}/>
                break;
            }
        }
    }


    return (
        <div>{examComponent()}</div>
    )
}