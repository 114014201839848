import React, {Component} from "react";
import Body from "../../../../../assets/body/Body";
import utils from "../../../../../utils/utils";
import AudioPlayer from "../../../../media/AudioPlayer";

class LungsExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            playingDot: null,
            facingFront: true,
            isA: false,
            isB: false,
            isC: false,
            isD: false
        }
    }

    componentDidMount() {
        let {lbFiles, lfFiles} = this.props
        if (lbFiles.length > 0 && lfFiles.length === 0)
            this.setState({facingFront: false})
    }

    render() {
        let {facingFront} = this.state
        let {lbFiles, lfFiles, patient, t} = this.props
        let body = <div/>

        let files = facingFront ? lfFiles : lbFiles

        let hasFront = lfFiles.length > 0;
        let hasBack = lbFiles.length > 0;


        if (!!files && !!patient)
            if (facingFront && hasFront) body = <Body activeDot={this.state.playingDot} gender={patient.gender}
                                                      age={utils._calculateAge(new Date(patient['birthday']))}
                                                      part={'lungs-front'}
                                                      className={'exam-body'} name={'exam-body'} files={files}/>
            else body = <Body activeDot={this.state.playingDot} gender={patient.gender}
                              age={utils._calculateAge(new Date(patient['birthday']))} part={'lungs-back'}
                              className={'exam-body'} name={'exam-body'} files={files}/>

        let A_files = files.filter(file => file['dotName'] === 'A')
        let B_files = files.filter(file => file['dotName'] === 'B')
        let C_files = files.filter(file => file['dotName'] === 'C')
        let D_files = files.filter(file => file['dotName'] === 'D')

        return (
            <div className={'exam-result-container lungs-exam-container'}>
                <div className={'exam-res-container h-centered'}>
                    {body}
                    <div className={'lungs-face-options'}>
                        {
                            hasBack &&
                            <div
                                className={facingFront ? 'lungs-face-option pointer' : 'lungs-face-option pointer lungs-face-option-active'}
                                onClick={() => this.setState({
                                    facingFront: false,
                                    isA: false,
                                    isB: false,
                                    isC: false,
                                    isD: false,
                                    playingDot: null
                                })}>{t('physician.patient.exams.backLungs')}</div>
                        }
                        {
                            hasFront &&
                            <div
                                className={!facingFront ? 'lungs-face-option pointer' : 'lungs-face-option pointer lungs-face-option-active'}
                                onClick={() => this.setState({
                                    facingFront: true,
                                    isA: false,
                                    isB: false,
                                    isC: false,
                                    isD: false,
                                    playingDot: null
                                })}>{t('physician.patient.exams.frontLungs')}</div>
                        }
                    </div>
                    <div className={'stethoscope-audios'}>
                        {facingFront && A_files.length > 0 && <AudioPlayer playing={this.state.isA && facingFront}
                                                                           play={bool => this.setState({
                                                                               isA: bool,
                                                                               isB: false,
                                                                               isC: false,
                                                                               isD: false,
                                                                               playingDot: bool ? 'A' : null
                                                                           })} title={'A'}
                                                                           className={'lungs-exam-player stethoscope-audio'}
                                                                           files={A_files}/>}
                        {facingFront && B_files.length > 0 && <AudioPlayer playing={this.state.isB && facingFront}
                                                                           play={bool => this.setState({
                                                                               isA: false,
                                                                               isB: bool,
                                                                               isC: false,
                                                                               isD: false,
                                                                               playingDot: bool ? 'B' : null
                                                                           })} title={'B'}
                                                                           className={'lungs-exam-player stethoscope-audio'}
                                                                           files={B_files}/>}
                        {!facingFront && A_files.length > 0 && <AudioPlayer playing={this.state.isA && !facingFront}
                                                                            play={bool => this.setState({
                                                                                isA: bool,
                                                                                isB: false,
                                                                                isC: false,
                                                                                isD: false,
                                                                                playingDot: bool ? 'A' : null
                                                                            })} title={'A'}
                                                                            className={'lungs-exam-player stethoscope-audio'}
                                                                            files={A_files}/>}
                        {!facingFront && B_files.length > 0 && <AudioPlayer playing={this.state.isB && !facingFront}
                                                                            play={bool => this.setState({
                                                                                isA: false,
                                                                                isB: bool,
                                                                                isC: false,
                                                                                isD: false,
                                                                                playingDot: bool ? 'B' : null
                                                                            })} title={'B'}
                                                                            className={'lungs-exam-player stethoscope-audio'}
                                                                            files={B_files}/>}
                        {!facingFront && C_files.length > 0 && <AudioPlayer playing={this.state.isC && !facingFront}
                                                                            play={bool => this.setState({
                                                                                isA: false,
                                                                                isB: false,
                                                                                isC: bool,
                                                                                isD: false,
                                                                                playingDot: bool ? 'C' : null
                                                                            })} title={'C'}
                                                                            className={'lungs-exam-player stethoscope-audio'}
                                                                            files={C_files}/>}
                        {!facingFront && D_files.length > 0 && <AudioPlayer playing={this.state.isD && !facingFront}
                                                                            play={bool => this.setState({
                                                                                isA: false,
                                                                                isB: false,
                                                                                isC: false,
                                                                                isD: bool,
                                                                                playingDot: bool ? 'D' : null
                                                                            })} title={'D'}
                                                                            className={'lungs-exam-player stethoscope-audio'}
                                                                            files={D_files}/>}
                    </div>
                </div>
            </div>
        );
    }

}

export default LungsExam
