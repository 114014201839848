import React, {Component} from "react";
import utils from "../../../../utils/utils";
import SessionExams from "./SessionExams";


class OfflineSession extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: null,
            session: {},
            patient: {}
        }
    }

    componentDidMount() {
        let {session, patient} = this.props
        this.setState({session,patient})
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     let {session, patient} = nextProps
    //     let currentSession = this.state.session
    //     console.log({currentSession,session})
    //     if (currentSession.sessionID !== session.sessionID)
    //         this.forceUpdate()
    // }

    render() {
        let {session, patient, t, tenant} = this.props
//style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}
        return (
            <div className={'patient-card-content-container session-data-container'}>
                <div className={'session-header-container'}>
                    <div className={'session-header'}>
                        <div className={'session-header-text'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>{t('physician.patient.lastSession')}</div>
                        <div className={'session-header-time-container'}>
                            <div className={'session-header-time-container'}>
                                <div className={'session-header-text session-header-date'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>
                                    {t('physician.patient.profile.start')}: {utils.getDateStringWithYear(new Date(session['examStartTime']))}
                                </div>
                                <div
                                    className={'session-header-text session-header-time'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>{utils.format24(new Date(session['examStartTime']))}</div>
                            </div>
                            <div className={'session-header-time-container'}>
                                <div className={'session-header-text session-header-date'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>
                                    {t('physician.patient.profile.end')}: {utils.getDateStringWithYear(new Date(session['examEndTime']))}
                                </div>
                                <div
                                    className={'session-header-text session-header-time'} style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}>{utils.format24(new Date(session['examEndTime']))}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <SessionExams session={session} patient={patient} t={this.props.t}
                              tenant={tenant}
                              setStatusRead={(sessionID) => this.props.setStatusRead(sessionID)}
                              saveSummary={(session) => this.props.saveSummary(session)}/>
            </div>
        );
    }

}

export default OfflineSession
