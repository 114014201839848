import ReactTable from "react-table-v6";
import React from "react";
import "react-table-v6/react-table.css"
import Pagination from "./Pagination";
import '../../style/component/table/MHDTable.css'
import PropTypes from "prop-types";
import TheadComponent from "./TheadComponent";
import utils from "../../utils/utils";
import SVGraphics from "../../assets/SVGraphics";
import Status from "../../config/Status";

class NoDataConst extends React.Component {
    render() {
        let {show,title} = this.props;
        return (
            <div className={'h-centered'}
                 style={{display: show ? "block" : "none", position: 'absolute', top: '130px'}}>
                <SVGraphics style={{display: 'block'}} svgname={'no-search'} height={'150px'}/>
                <div style={{display: 'block', fontSize: '40px', textAlign: "center"}}>{title}</div>
            </div>
        );
    }
}

class LoadingComponent extends React.Component {
    render() {
        return (
            <div className={"loader spin"} style={{display: this.props.loading ? "block" : "none"}}/>
        );
    }
}

class MHDCustomTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selected: {}, selectAll: 0, expanded: []};
        this.reactTable = React.createRef()
    }

    componentDidMount() {
        let {setTableRef} = this.props
        if (!!setTableRef)
            this.props.setTableRef(this)
    }

    getTrProps = (state, rowInfo, instance) => {
        let {original} = rowInfo;
        let {diffParams} = this.props
        let style = {}
        let className = ''
        diffParams.forEach(obj => {
            let diff = false;
            if (original.hasOwnProperty(obj['name'])) {
                if (obj.hasOwnProperty('op')) {
                    if (obj.hasOwnProperty('values') && obj.op(original[obj['name']], obj['values'])) {
                        diff = true;
                    } else if (obj.hasOwnProperty('value') && obj.op(original[obj['name']], obj['value'])) {
                        diff = true;
                    }
                } else {
                    if (obj.hasOwnProperty('value') && original[obj['name']] === obj['value']) {
                        diff = true;
                    } else if (obj.hasOwnProperty('values') && (obj['values']).includes(original[obj['name']])) {
                        diff = true;
                    }
                }
            }

            if (rowInfo) {
                if (obj['styleName'])
                    style[obj['styleName']] = diff ? obj['styleTrueVal'] : obj['styleFalseVal']
                if (diff) {
                    className = obj['className'] + ' '
                }
            }
        })


        if (rowInfo) {
            if (this.props.onRowClick || this.props.SubComponent)
                style['cursor'] = 'pointer';
            if (rowInfo.className) className = className + ' ' + rowInfo.className
            let propsObj = {
                className: className,
                style
            };
            let {allowViewData} = this.props
            if (this.props.onRowClick)
                propsObj['onClick'] = () => allowViewData ? this.props.onRowClick(original, rowInfo) : this.props.onRowClick(original);
            else if (this.props.SubComponent)
                propsObj['onClick'] = () => this.expandRow(rowInfo);
            return propsObj
        }
        return {};
    };

    expandRow(row) {
        let {expanded} = this.state;

        if (expanded[row.viewIndex]) {
            expanded[row.viewIndex] = !expanded[row.viewIndex];
        } else {

            expanded[row.viewIndex] = true;
        }
        this.setState({
            expanded: expanded
        });
    }

    getTheadThProps = (state, column, instance) => {
        let field = instance.id;
        let data = [];
        let filterData = [];
        data.forEach(obj => {
            filterData.push(obj[field])
        });
        let uniqFilterData = [...new Set(filterData)];
        let props = {
            data: uniqFilterData,
            onFilterDataSelected: filter => this.props.onFilterDataSelected(filter),
            onSort: async () => await this.setState({expanded: []})
        };
        if (instance.id) {
            utils.set(props, 'field', instance.id)
        }
        if (instance.makeFilter) {
            utils.set(props, 'makeFilter', instance.makeFilter)
        }
        if (instance.thClass) {
            utils.set(props, 'thClass', instance.thClass)
        }
        if (instance.filterConverter) {
            utils.set(props, 'filterConverter', instance.filterConverter)
        }
        return props
    };

    //editable

    getTableColumns = () => {
        let {columns, edit} = this.props;
        let allowEdit = false;
        if (!!edit) {
            allowEdit = columns.findIndex(col => (col['accessor'] === "edit")) === -1;
            if (allowEdit) {
                columns.push({
                    accessor: "edit",
                    Header: "Edit",
                    makeFilter: false,
                    sortable: false,
                    resizable: false,
                    width: 55,
                    maxWidth: 55,
                    Cell: ({original}) => {
                        return (
                            <SVGraphics onClick={() => edit(original)} svgname={'edit'}
                                        className={'list-table-edit inline pointer v-centered'}/>
                        );
                    },
                })
                // cols = cols.concat(editColumn)
            }
        }
        return columns
    };

    getNoDataProps = () => {
        return {
            show: !this.props.isLoading && this.props.data.length === 0,
            title : this.props.title
        }
    };

    render() {

        return (
            <ReactTable
                className={this.props.className}
                data={[...this.props.data]}
                columns={this.getTableColumns()}
                defaultPageSize={10000000000000000000}
                showPagination={/*this.props.data.length>8*/ false}
                //pageSize={pgSize}
                height
                minRows={0}
                maxRows={10000000000000000000}
                sortable={true}
                multiSort={false}
                expanded={this.state.expanded}

                loading={this.props.isLoading}
                LoadingComponent={LoadingComponent}
                getLoadingProps={this.getLoadingProps}
                getTrProps={this.getTrProps}
                getTheadThProps={this.getTheadThProps}
                ThComponent={TheadComponent}
                //filterable={true}
                showPaginationBottom={false}
                PaginationComponent={Pagination}
                showPageSizeOptions={false}
                getNoDataProps={this.getNoDataProps}
                NoDataComponent={NoDataConst}
                SubComponent={!!this.props.SubComponent ? (v) => this.props.SubComponent(v) : false}
                defaultSorted={this.props.defaultSorted}
            />
        );
    }
}

MHDCustomTable.propTypes = {
    onFilterDataSelected: PropTypes.func,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
};

export default MHDCustomTable

