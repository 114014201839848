const Status = {

    calling: 'Calling',
    missed: 'Missed',
    initiated: 'Initiated',
    denied: 'Denied',
    inProgress: 'InProgress',
    ended: 'Ended',
    read: 'Read',
    sent: 'Sent',
    done: 'Done'

};

Object.freeze(Status);

const bodyParts = {

    face: 'face',
    nick: 'nick',
    chest: 'chest',
    abdomen: 'abdomen',
    genitals: 'genitals',
    arm: 'arm',
    buttocks: 'buttocks',
    hand: 'hand',
    leg: 'leg',
    foot: 'foot',
    head: 'head',
    back: 'back',

};

const bodyPositions = {
    Front: 'Front',
    Back: 'Back',
    Right: 'Right',
    Left: 'Left'
};

const requestTypes = {
    General: 'General',
    Body_Temperature: 'Body_Temperature',
    Saturation: 'Saturation',
    Heart: 'Heart',
    Abdomen: 'Abdomen',
    Lungs: 'Lungs',
    Ears: 'Ears',
    Throat: 'Throat',
    Skin: 'Skin'
}

const actionsReceiver = {
    Select: 'Select',
    Start: 'Start',
    Reset: 'Reset',
    Retake: 'Retake',
    Confirm: 'Confirm',
    Close: 'Close',
    T_Action: 'T_Action',
    Quality: 'Quality',
    Control_Req: 'Control_Req',
    BT_DISC: 'BT_DISC',
    UAction: 'UAction',
    Disconnect: 'Disconnect',
    Incoming_Call: 'Incoming_Call',
    Control_Allow: 'Control_Allow',
    Control_Deny: 'Control_Deny',
    Delete: 'Delete',
    Next: 'Next',
    Confirm_Img: 'Confirm_Img',
    Capture: 'Capture',
    Record: 'Record',
    Hide: 'Hide',
    Bad_Result: 'Bad_Result'
};

const stethoscopePositionsReceiver = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
};
const earsPositionsReceiver = {
    Left: 'Left',
    Right: 'Right'
};
const throatPositionsReceiver = {
    throat: 'Throat',
    mouth: 'Mouth'
};
const stethoscopeTypesReceiver = {
    Heart: 'Heart',
    Lungs: 'Lungs',
    Abdomen: 'Abdomen',
    lungsFront: 'Lungs_Front',
    lungsBack: 'Lungs_Back',
    abdomen: 'Abdomen'
};

Object.freeze(Status);
Object.freeze(bodyParts);
Object.freeze(bodyPositions);
Object.freeze(requestTypes);
Object.freeze(actionsReceiver);
Object.freeze(stethoscopePositionsReceiver);
Object.freeze(earsPositionsReceiver);
Object.freeze(throatPositionsReceiver);
Object.freeze(stethoscopeTypesReceiver);

export {
    Status,
    bodyParts,
    bodyPositions,
    requestTypes,
    actionsReceiver,
    stethoscopePositionsReceiver,
    earsPositionsReceiver,
    throatPositionsReceiver,
    stethoscopeTypesReceiver
};
