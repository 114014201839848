import React, {Component} from "react";
import ExamTab from "./ExamTab";


class ExamTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionID: null,
            tabs: {
                Body_Temperature: {
                    disabled: true,
                    active: false,
                    color: '#F8A315',
                    value: undefined
                },
                Saturation: {
                    disabled: true,
                    active: false,
                    color: '#05D2CE',
                    value: undefined
                },
                Heart: {
                    disabled: true,
                    active: false,
                    color: '#FD5A56'
                },
                Abdomen: {
                    disabled: true,
                    active: false,
                    color: '#C10096'
                },
                Lungs: {
                    disabled: true,
                    active: false,
                    color: '#0085FF'
                },
                Ears: {
                    disabled: true,
                    active: false,
                    color: '#4C23B5'
                },
                Throat: {
                    disabled: true,
                    active: false,
                    color: '#FF64AE'
                },
                Skin: {
                    disabled: true,
                    active: false,
                    color: '#FF7B4A'
                },
            }
        }
    }

    toggle(type) {
        let {tabs} = this.state;
        Object.entries(tabs).forEach(([name, options]) => {
            options.active = type === name;
        });
        this.setState({tabs});
        this.props.onChange(type);
    }

    async componentDidMount() {
        let {exams, observations,sessionID} = this.props;
        let {tabs} = this.state
        let tabsObj = JSON.parse(JSON.stringify(tabs))
        let allowToggle = true;
        let toggleType;
        Object.entries(tabsObj).forEach(([name, options]) => {
            let examExists = exams.includes(name)
            options.disabled = !examExists;

            if (examExists && allowToggle) {
                toggleType = name;
                allowToggle = false;
            }
            if (name === 'Body_Temperature') {
                let observation = observations.find(observation => observation.type === name);
                if (observation) options.value = observation.value
            } else {
                if (name === 'Saturation') {
                    let saturation = observations.find(observation => observation.type === 'Saturation');
                    if (!!saturation) options.value = saturation.value + '% ' + saturation.secondValue + 'bpm'
                }
            }
        })
        await this.setState({tabs: tabsObj, sessionID});
        if (!allowToggle){
            this.toggle(toggleType);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {type, exams, observations, sessionID} = nextProps;
        let {tabs} = this.state
        let currentSessionID = this.state.sessionID
        let tabsObj = JSON.parse(JSON.stringify(tabs))
        let allowToggle = true;
        let toggleType;
        Object.entries(tabsObj).forEach(([name, options]) => {
            let examExists = exams.includes(name)
            options.disabled = !examExists;
            if (!examExists && options.active) {
                options.active = false;
            }
            if (examExists && allowToggle && currentSessionID !== sessionID){
                toggleType = name;
                type = name;
                allowToggle = false;
            }
            if (name === 'Body_Temperature') {
                let observation = observations.find(observation => observation.type === name);
                if (observation) options.value = observation.value
            } else {
                if (name === 'Saturation') {
                    let saturation = observations.find(observation => observation.type === 'Saturation');
                    if (!!saturation) options.value = saturation.value + '% ' + saturation.secondValue + 'bpm'
                }
            }
        })

        if (JSON.stringify(tabs) !== JSON.stringify(tabsObj)){
            this.setState({tabs: tabsObj})
        }
        if (sessionID !== currentSessionID){
            this.setState({sessionID})
            this.toggle(type)
        }
    }

    render() {
        let {tabs} = this.state;
        let {t} = this.props

        return (
            <div className={'session-tabs-container'}>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Body_Temperature'])['color']}
                         active={(tabs['Body_Temperature'])['active']}
                         title={t('physician.patient.exams.temp')}
                         examType={'Body_Temperature'} disabled={(tabs['Body_Temperature'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Saturation'])['color']}
                         active={(tabs['Saturation'])['active']}
                         title={t('physician.patient.exams.saturation')}
                         examType={'Saturation'} disabled={(tabs['Saturation'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Heart'])['color']}
                         active={(tabs['Heart'])['active']}
                         title={t('physician.patient.exams.heart')} examType={'Heart'}
                         disabled={(tabs['Heart'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Abdomen'])['color']}
                         active={(tabs['Abdomen'])['active']}
                         title={t('physician.patient.exams.abdomen')} examType={'Abdomen'}
                         disabled={(tabs['Abdomen'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Lungs'])['color']}
                         active={(tabs['Lungs'])['active']}
                         title={t('physician.patient.exams.lungs')} examType={'Lungs'}
                         disabled={(tabs['Lungs'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Ears'])['color']}
                         active={(tabs['Ears'])['active']}
                         title={t('physician.patient.exams.ears')} examType={'Ears'}
                         disabled={(tabs['Ears'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Throat'])['color']}
                         active={(tabs['Throat'])['active']}
                         title={t('physician.patient.exams.throat')} examType={'Throat'}
                         disabled={(tabs['Throat'])['disabled']}/>
                <ExamTab onClick={type => this.toggle(type)} color={(tabs['Skin'])['color']}
                         active={(tabs['Skin'])['active']}
                         title={t('physician.patient.exams.skin')} examType={'Skin'}
                         disabled={(tabs['Skin'])['disabled']}/>
            </div>
        );
    }

}

export default ExamTabs
