let Globals = {
    suggestion: {
        Body_Temperature: ['Hypothermia','Fever','High Fever','Normal temperature'],
        Saturation: ['Potential hypoxemia','Potential tachycardia','Potential bradycardia',
            'Irregular pulse','Arrhythmia','Normal resting heart rate','Normal heart rate','Normal saturation'],
        Heart: ['No murmurs or additional sounds','Irregular heart sounds',
            'heart murmurs','Pulmonary and aortic murmurs','Pericarditis','Aortic stenosis','Mitral regurgitation'],
        Abdomen: [],
        Lungs:['Crackle','Wheeze','Absent breath sounds','Normal breath sounds',
            'Equal sounds in both lungs','No crackles, rhonchi or wheezes'],
        Ears: ['No redness, swelling or tenderness in the external auditory canal','Ear infection',
            'Discharge','Cerumen','Acute otitis media','Otitis externa','Hemotympanum','Intact tympanic membrane',
            'Normal color tympanic membrane','Semi- transparent tympanic membrane',
            'Bulging of the membrane','Retraction of the membrane'],
        Throat: ['Torus palatinus','Bifid uvula','Atrophic glossitis','Macroglossia','Leukoplakia',
            'Aphthous ulcer','No enlargement of the tonsils','Enlargement of the tonsils','No redness, ulceration, discharge or mass'],
        Skin: ['Plaque psoriasis','Rosacea','Acne','Irritation']

    }
}
export default Globals
