import '../patientStyle/exams.css'
import SVGraphics from "../../../../assets/SVGraphics";
import React, {useEffect, useRef, useState} from "react";
import Waveform from "./Waveform";
import useMediaQuery from "../../../../utils/useMediaQuery";

export function ExamAudio(props) {


    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');
    const [wasPlayed, setWasPlayed] = useState(false);
    const [isPlaying, setIsPlaying] = useState(props.isPlaying);
    const [played, setPlayed] = useState(false);
    const [openAudio, setOpenAudio] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [filter, setFilter] = useState('');
    const [isPlayNew, setIsPlayNew] = useState(0);
    const [playIcon, setPlayIcon] = useState('play-gray');
    const [bellIcon, setBellIcon] = useState('bell-outline');
    const [containerStyle, setContainerStyle] = useState({backgroundColor:'#F8F8F8',color:'#2E2E2E'});

    const waveformRef = useRef();

    const playAudio = async() => {
        // localStorage.setItem('currentAudio',props.file?.fileID )
        // if(!props.isPlaying || props.filter===filter){
            setFilter(props.filter)
            props.setParentPlaying()
            setContainerStyle({
                backgroundColor:'#FD5A56',
                color:'#FFFFFF'
            })


            if(!openAudio ){
                setOpenAudio(true)
            }
            // wavesurfer.load(props.file.downloadUrl);
            //wavesurfer.load("https://myhomedoc-s3-patient-files-dev-eu-central-1.s3.eu-central-1.amazonaws.com/myhomedoc/patients/PatientIDSigal/sessions/7958/Heart/7958HeartNoneAFilter3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT5AQB5NVRI5NHDZP%2F20230327%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230327T100131Z&X-Amz-Expires=3600&X-Amz-Signature=f2a4af931871b0946aca16d0b5c52cfe46de8de2ba449584d3ddad3dfd90a326&X-Amz-SignedHeaders=host");
            if(props.file){


                setFileUrl(props.file.downloadUrl)
                if(isPlaying===true) {
                    setPlayIcon('play-white')
                }
                else{
                    setPlayIcon('pause-white')
                }
                setBellIcon('bell-outline-white')
                setIsPlaying(!isPlaying)
                props.setPlayedFileId(props.file.fileID)
                props.setPlayedFile(props.file)
                props.setPlayedType(props.type)
                props.setPoint(props.point)

            }



        // }

    }
    const closeAudio = () => {
        setPlayed(true)
        props.setParentStop()
        props.setPlayedFileId(null)
        props.setPlayedFile(null)
        props.setPlayedType(null)
        props.setPoint(null)
        setOpenAudio(false)
        setBellIcon('bell-outline-red')
        setPlayIcon('play-red')
        setContainerStyle({
            backgroundColor:'#FFEEEE',
            color:'#FD5A56'
        })

    }

    useEffect(() => {
      if(!props.isPlaying && wasPlayed){
          setPlayed(true)
          props.setParentStop()
          setOpenAudio(false)
          setBellIcon('bell-outline-red')
          setPlayIcon('play-red')
          setContainerStyle({
              backgroundColor:'#FFEEEE',
              color:'#FD5A56'
          })
      } else if(!wasPlayed && props.isPlaying) {
          setWasPlayed(true)
      }
        setIsPlayNew(isPlayNew+1)

    },[props.isPlaying])
    const setBell = () => {
        if(played){
            setBellIcon(bellIcon==='bell-outline-ring'?'bell-outline-red':'bell-outline-ring')
        }
    }

    const handlePlayPause = () =>{
        playAudio().then()
    }

    return (
        <div style={{width:'100%',display:'flex',justifyContent: 'center'}} className={isMobilScreen?'exam-body-parts-image-div-mobile':''}>
            <div style={containerStyle} className={`audio-exam ${isMobilScreen?'audio-exam-mobile':''}`}>
                <div className={'audio-exam-left'} onClick={() => playAudio()}>
                    <SVGraphics svgname={playIcon}
                                className={`audio-exam-play`}
                    />
                    <div className={'audio-exam-filter-label'} >{props.filter}</div>
                </div>
                <SVGraphics svgname={bellIcon}
                            className={`audio-exam-bell`}
                            onClick={() =>setBell() }
                />


            </div>
            {openAudio &&
            <div className={`exam-audio-wave ${isMobilScreen?'waveform-container-mobile':'' }`} style={{width:isSmallScreen || mediumScreen ?'90%':isMobilScreen?'95%':'43%'}} ref={waveformRef}>
                <Waveform url={fileUrl} image={props.image} closeAudio={()=>closeAudio()} file={props.file} isPlaying={isPlaying} isPlayNew={isPlayNew} setParentPlay={(childPlay)=>handlePlayPause(childPlay)}
                          info={{point:props.point, type: props.type, filter: props.filter}}
                />
            </div>
            }

        </div>
    )
}