// general protocol structure
import {
    actionsReceiver,
    earsPositionsReceiver,
    stethoscopePositionsReceiver, requestTypes,
    stethoscopeTypesReceiver, throatPositionsReceiver, bodyPositions
} from "../../config/constants";

function getProtocol(exam, action) {
    return {
        type: exam,
        action: actionsReceiver[action]
    };
}

// general stethoscope protocol structure
function get_stethoscope_protocol(stethoscopeType, action, position, bodyPosition) {
    let protocol = getProtocol(stethoscopeTypesReceiver[stethoscopeType], action);
    if (position) {
        protocol['option'] = {
            position: bodyPosition ?
                bodyPositions[bodyPosition] + '_' + stethoscopePositionsReceiver[position] :
                stethoscopePositionsReceiver[position]
        }
    }
    return protocol
}

//protocols by exam type based on general protocol structure
function get_general_protocol(action) {
    return getProtocol(requestTypes.General, action)
}

function get_body_temp_protocol(action) {
    return getProtocol(requestTypes.Body_Temperature, action)
}

function get_saturation_protocol(action) {
    return getProtocol(requestTypes.Saturation, action)
}

function get_heart_protocol(action, position) {
    return get_stethoscope_protocol(requestTypes.Heart, action, position)
}

function get_lungsFront_protocol(action, position) {
    return get_stethoscope_protocol(requestTypes.Lungs, action, position, bodyPositions.Front)
}

function get_lungsBack_protocol(action, position) {
    return get_stethoscope_protocol(requestTypes.Lungs, action, position, bodyPositions.Back)
}

function get_lungs_protocol(action, position, bodyPosition) {
    return get_stethoscope_protocol(requestTypes.Lungs, action, position, bodyPosition)
}

function get_abdomen_protocol(action, position) {
    return get_stethoscope_protocol(requestTypes.Abdomen, action, position)
}

function get_ears_protocol(action, position) {
    let protocol = getProtocol('Ears', action);
    if (position) {
        protocol['option'] = {
            position: earsPositionsReceiver[position]
        }
    }
    return protocol
}

function get_throat_protocol(action, position) {
    let protocol = getProtocol('Throat', action);
    if (position) {
        protocol['option'] = {
            position: throatPositionsReceiver[position]
        }
    }
    return protocol
}

function get_skin_protocol(action, position, bodyPart) {
    let protocol = getProtocol('Skin', action);
    protocol['options'] = {}
    if (position) {
        (protocol['options'])['position'] = position
    }
    if (bodyPart) {
        (protocol['options'])['bodyPart'] = bodyPart
    }
    return protocol
}

let Protocol = {
    General: (action) => get_general_protocol(action),
    Body_Temperature: (action) => get_body_temp_protocol(action),
    Saturation: (action) => get_saturation_protocol(action),
    Heart: (action, position) => get_heart_protocol(action, position),
    Lungs: (action, position, bodyPosition) => get_lungs_protocol(action, position, bodyPosition),
    LungsFront: (action, position) => get_lungsFront_protocol(action, position),
    LungsBack: (action, position) => get_lungsBack_protocol(action, position),
    Abdomen: (action, position) => get_abdomen_protocol(action, position),
    Ears: (action, position) => get_ears_protocol(action, position),
    Throat: (action, position) => get_throat_protocol(action, position),
    Skin: (action, position, bodyPart) => get_skin_protocol(action, position, bodyPart)
};

Object.freeze(Protocol);
export default Protocol;
