import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from 'react-confirm'
import '../../style/component/modal/Media.css'
import SVGraphics from "../../assets/SVGraphics";
import VideoPlayer from "../media/VideoPlayer";

class VideoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            isErr: false
        }
    }


    render() {
        let {
            url,
            show,
            proceed
        } = this.props;
        return (
            <Modal
                size="lg"
                show={show}
                centered={true}
                onHide={() => proceed()}
                dialogClassName={'video-media-dialog media-dialog'}
                contentClassName={'video-media-content media-content'}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <div className={'media-close-btn-container modal-close pointer'} onClick={() => proceed()}>
                    <SVGraphics svgname={'close'} className={'modal-close-btn'}/>
                </div>
                {/*<Modal.Header className={'video-media-header media-header'} closeButton/>*/}
                <Modal.Body className={'video-media-body media-body'}>
                    <VideoPlayer closeFullScreen={() => proceed()} className={'modal-video-media modal-media'}
                                 url={url}/>
                </Modal.Body>
            </Modal>
        );
    }
}

VideoModal.propTypes = {
    show: PropTypes.bool,
    url: PropTypes.string
};

export default function MakeVideo({url: url = ''}) {
    return createConfirmation(confirmable(VideoModal))({
        url
    });
}
