import React, {createRef, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Modal, OverlayTrigger} from 'react-bootstrap'
import {confirmable, createConfirmation} from 'react-confirm'
import '../../style/component/modal/Media.css'
import SVGraphics from "../../assets/SVGraphics";
import moment from "moment";
import Original from '../../assets/light_01.svg'
import Brightest from '../../assets/light_02.svg'
import Brighter from '../../assets/light_03.svg'
import Original_white from '../../assets/light_01_white.svg'
import Brightest_white from '../../assets/light_02_white.svg'
import Brighter_white from '../../assets/light_03_white.svg'
import Snapshot from '../../assets/Snapshot.svg'
import VideoPlayerSnap from "../media/VideoPlayerSnap";
import sessionsApi from "../../services/ApiServices/doctorApi/sessionsApi";
import MakeError from "../Error/ErrorModal";
import Tooltip from "../../component/toolTip/ToolTip";
import queryString from "query-string";
import {history} from "../../utils/history";


let snapshots = [];
let scaleFactor = 0.25;


class VideoModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            url: this.props.url,
            isErr: false,
            hover: false,
            filter: '1',
            image:null,
            filters:this.props.filters?this.props.filters:[],
            captures:this.props.captures?this.props.captures:[],
            clicked:false,
            toolTip:false,
            disable:false
        }

    }


    async checkQueryParam(query) {
        return Object.keys(query).length > 0 && !!query.pid && !!query.tenant
    }

    async getFrame() {
        let {files, file} = this.props
        let query = queryString.parse(history.location.search);
        let allow = await this.checkQueryParam(query);
        let tenant = query.tenant;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth
        let {url, filter, captures} = this.state
        let type = file.type
        let imageType;
        switch (type) {
            case 'Ears': {
                imageType=file.subType==='Right'? 'RightEarCapture': 'LeftEarCapture'
                break;
            }
            case 'Throat': imageType = 'ThroatCapture'; break;
            case 'Mouth': imageType = 'MouthCapture'; break;
        }
        if (!imageType) {
            await MakeError({message: 'error taking snapshot'})
            return;
        }
        let dotName = null
        if (captures.length === 0) {
            dotName = "1"
        } else {
            for (let i = 1; i <= 5; i++) {
                let pos = `${i}`
                if (!captures.find(capture => capture.dotName === pos)){
                    dotName = pos
                    break;
                }
            }
        }
        if (captures.length < 5) {
            this.setState({disabled:true})
            if(!this.state.clicked){
                this.setState({toolTip:true})
            }
            let _filter = filter === "1" ? "Filter1" : filter === "2" ? "Filter2" : filter === "3" ? "Filter3" : 'None'
            let _file = files.find(x => x.filter === _filter) ? files.find(x => x.filter === _filter) : file
            let data = {}
            let fileName = _file.sessionID + imageType + 'None' + dotName + 'None'
            let video = document.getElementById('videoId')
            let time = video ? video.currentTime : 0
            data['sessionID'] = _file.sessionID
            data['patientID'] = this.props.id
            data['fileName'] = fileName
            data['type'] = imageType
            data['subType'] = type ==="Ears" ? "None" :_file.subType
            data['filter'] = "None"
            data['dotName'] = dotName
            data['videoUrl'] = url
            data['second'] = time
            // let button = document.getElementById('snapButton')
            // button.style.cursor = "wait";
            let capture = await sessionsApi.addScreenshot(data)
            if (capture) {
                // button.style.cursor = "pointer"
                capture['downloadUrl'] = capture.downloadUrl
                captures.push(capture)
                this.setState({captures})
            }
            // this.setState({clicked:true,toolTip:false,disabled:false})


            await setTimeout(()=>{this.setState({clicked:true,toolTip:false,disabled:false})}, 3000)
            if(captures.length===5){
                this.setState({disabled:true})
            }
            // await setTimeout(()=> button.style.cursor = "pointer",3000)
        }


    }

    seekToTimeBack() {
        // try and avoid pauses after seeking
        let video_element = document.getElementById(`videoId`);
        video_element.pause();
        video_element.currentTime =  video_element.currentTime -0.033376 ; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:
        let timer = setInterval(function() {
            if (video_element.paused && video_element.readyState ==4 || !video_element.paused) {
                clearInterval(timer);
            }
        }, 50);
    }

    seekToTime() {
        // try and avoid pauses after seeking
        let video_element = document.getElementById(`videoId`);
        video_element.pause();
        video_element.currentTime =  video_element.currentTime +0.033376 ; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:
        let timer = setInterval(function() {
            if (video_element.paused && video_element.readyState ==4 || !video_element.paused) {
                clearInterval(timer);
            }
        }, 50);
    }
    setFilter(filter){
        let url;
        let type = this.props.file?.type
        let videos = this.props.files
        let NotFound = this.props.files.every(el => el.filter === "undefined" || el.filter === "None" || el.filter === null)
        if(NotFound){
            url=this.props.url
        }else{
            switch(filter) {
                case '1':
                    url=videos.find(x=>x.filter==='Filter1')?.downloadUrl
                    break;
                case '2':
                    url=videos.find(x=>x.filter==='Filter3')?.downloadUrl
                    break;
                case '3':
                    url=videos.find(x=>x.filter==='Filter2')?.downloadUrl
                    break;
                default:
                    url=videos[0].downloadUrl
                    break;
            }
        }

    this.setState({filter:filter,url:url})

    }
    render() {
        let {
            url,
            t,
            show,
            proceed, type,
            file,
            filters
        } = this.props;
        // let videoFiltering = type === 'throat' || type === 'mouth' ? true : false


        let time = moment(file.createdAt).format('HH:mm');
        let date = moment(file.createdAt).format('MMM DD YYYY');

        let language = 'en'
        let isFilter;
        if(this.props.files.length > 1 && this.props.files.every(file => !!file.filter)){
            isFilter = true
        }else{
            isFilter=false
        }
        if(file.type==="Ears"){
            isFilter=false
        }


        return (
            <Modal
                size="lg"
                show={show}
                centered={true}
                onHide={() => proceed()}
                dialogClassName={ 'video-media-dialog-filtering'  + ' media-dialog'}
                contentClassName={'video-media-content media-content media-content-filtering'}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <div className={'video-modal-title'}><span
                    style={{fontWeight: 'bold'}}>{language === 'en' ? file['type'] : file['type'] === 'Mouth' ? 'Boca' : 'Garganta'} video</span> {time} | {date} </div>
                <div className={'media-close-btn-container modal-close-video pointer'} onClick={() => proceed(this.state.captures)}>
                    <SVGraphics svgname={'close-white'} className={'modal-close-btn modal-close-btn-video'} />
                </div>
                {/*<Modal.Header className={'video-media-header media-header'} closeButton/>*/}

                <Modal.Body
                    className={ 'video-media-content-filtering'  + ' media-body'}>


                    <VideoPlayerSnap id={"videoId"} videoRef={this.videoRef} closeFullScreen={() => proceed()}
                                 className={'modal-video-media modal-media modal-video-media-filtering'}
                                 url={this.state.url}/>

                        <>
                        <div className={'brightnessFilterContainer'}>
                            {
                                <div className={isFilter ? 'brightnessFilter' : 'brightnessFilter hidden'}>

                                    {
                                        filters.map(filter => {
                                        let hover = this.state
                                        let black = filter.id === "1" ? Original : filter.id === '2' ? Brighter : Brightest
                                        let white = filter.id === '1' ? Original_white : filter.id === '2' ? Brighter_white : Brightest_white
                                        return <label key={filter.id}
                                            // onMouseOver={() => this.setState({hover: true, filter: filter})}
                                            // onMouseOut={() => this.setState({hover: false, filter: ''})}
                                                      onClick={() => this.setFilter(filter.id)}
                                                      className={'radio-brightness-container'}>
                                            <input type="radio" value={filter.id} className={'brightnessFilter-input'}
                                                   name="brightness"/> <span
                                            className={this.state.filter === filter.id?'brightnessFilterButtonSelected':'brightnessFilterButton'}
                                        >
                                        <img alt={filter.label}
                                             src={ this.state.filter === filter.id ? black : white}/> {filter.label}</span></label>
                                    })
                                    }
                                </div>
                            }


                            <div className={'brightnessFilter frameFilter'}>
                                <div className={'radio-brightness-container'} onClick={()=>this.seekToTimeBack()}>
                                    <div className={'frameFilterWhite'}>
                                            <span style={{width: '100%'}}>
                                                <SVGraphics width={"20%"} height={"20%"} svgname={'frameB'} style={{marginRight: '2px'}} className={'modal-frame'} />
                                                -1 Frame</span>
                                    </div>
                                </div>
                                <div className={'radio-brightness-container'}>
                                    <div className={'frameFilterWhite'} onClick={()=>this.seekToTime()}> <span style={{width: '100%'}}
                                    >
                                        +1 Frame   <SVGraphics svgname={'frameF'} height={"20%"} width={"20%"} style={{marginLeft: '2px'}} className={'modal-frame'}/>

                                    </span></div>
                                </div>
                            </div>
                            <label className={'captures-length'}>{this.state.captures?.length}</label>
                            <Tooltip
                                style={{visibility:this.state.toolTip?'visible':'hidden'}}
                                text={
                                <button  className={'brightnessFilter snapShotContainer'}
                                        onClick={() => this.getFrame()}
                                        disabled={this.state.disabled}
                                >
                                    <div id={'snapButton'} className={'frameFilterWhite snapShotWhite'} >
                                        <div className={'radio-brightness-container'}> <span style={{width: '100%'}}
                                        >
                                        <img alt={'Snapshot'} src={Snapshot} style={{
                                            width: '20px'
                                        }}/>  Snapshot</span></div>
                                    </div>
                                </button>
                            }
                                     placement={'top'}
                                     tooltipText={'Snapshots will appear once the player is minimized'}/>

                        </div>
                            <div id="output"></div>
                        </>

                </Modal.Body>
            </Modal>
        );
    }
}

VideoModal.propTypes = {
    show: PropTypes.bool,
    url: PropTypes.string,
    type: PropTypes.string,
    files: PropTypes.array,
    filters: PropTypes.array,
    file: PropTypes.object,
    captures: PropTypes.object
};

export default function MakeVideo({url: url = '', type: type = '', file: file,files:files,filters:filters=[],id:id,captures:captures}) {
    return createConfirmation(confirmable(VideoModal))({
        url, type, file,files,filters,id,captures
    });
}
