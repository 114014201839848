import axios from "axios";

class RestApi {
    async get(url, config) {
        return (await axios.get(url, config))
    }

    async post(url, data, config) {
        return (await axios.post(url, data, config))
    }

    async put(url, data, config) {
        return (await axios.put(url, data, config))
    }

    async s3Put(url, data, config) {
        return (await axios.put(url, data, config))
    }

    async delete(url, config) {
        return (await axios.delete(url, config))
    }

}

let restApi = new RestApi()
export default restApi;
