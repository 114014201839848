import React from 'react'


class Middleware {
    routeToDisplay(middlewares = [], routeToVisit = '', extra = {}) {
        const mware = {
            userAccessRoute: (routeToVisit) => this.userAccessRoute(routeToVisit, extra),
        };
        let ret = null;
        try {
            for (let i = 0; i < middlewares.length; i++) {
                ret = mware[middlewares[i]](routeToVisit, extra);
                if (ret.status === false) {
                    break
                }
            }
            return ret.routeObject
        } catch (e) {
            return this._getRouteReturn(false, null).routeObject
        }
    }

    linkToDisplay(middlewares = [], linkToVisit = '', extra = {}) {
        const mware = {
            userAccessLink: (linkToVisit) => this.userAccessLink(linkToVisit, extra),
        };
        let ret = null;
        try {
            for (let i = 0; i < middlewares.length; i++) {
                ret = mware[middlewares[i]](linkToVisit, extra);
                if (ret.status === false) {
                    break
                }
            }
            return ret.routeObject
        } catch (e) {
            return this._getRouteReturn(false, null).routeObject
        }
    }

    _getRouteReturn(status, routeObject) {
        return {status, routeObject}
    }

    userAccessRoute(component, extra) {

        return this._getRouteReturn(true, component)
    }

    userAccessLink(component, extra) {

        return this._getRouteReturn(true, component)
    }

}

export default new Middleware();
