import React, {Component} from "react";

class MC_LungsBack extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1
        let isC_Enabled = (files.findIndex(file => file['dotName'] === 'C')) > -1
        let isD_Enabled = (files.findIndex(file => file['dotName'] === 'D')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'
        let isC_Active = activeDot === 'C'
        let isD_Active = activeDot === 'D'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#03b3fe'

        let A_color = grey
        let B_color = grey
        let C_color = grey
        let D_color = grey
        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }
        if (isC_Enabled) {
            if (isC_Active) {
                C_color = active
            } else {
                C_color = sleep
            }
        }
        if (isD_Enabled) {
            if (isD_Active) {
                D_color = active
            } else {
                D_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){  return (
            <svg viewBox={"0 0 339 384"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF" d="M54.833 318.091l.954-3.012.033-.114-.987 3.126z"/>
                    <path fill="#FBDFCF"
                          d="M118.771 202.738l4.591 1.184c4.887-12.454 1.882-24.909-1.288-38.163-1.046-4.395-2.336-8.728-3.863-12.98-.485-2.418-1.188-4.786-2.1-7.076l-15.315 4.81-10.48 18.659-5.576 26.762-8.501 42.251-1.693 10.158-8.492 12.198-7.911 25.673-4.575 16.04-5.017 17.227.053.158-5.303 12.779 7.034 8.491 9.92-5.132 20.203-39.266 11.22-19.066 5.469-21.114 15.402-35.524 6.222-18.069z"/>
                    <path fill="#FBDFCF"
                          d="M60.618 327.8l1.281 5.893-3.59 17.679-4.358 9.735-12.561 17.935-4.103.512-4.358 3.075-6.41 1.281-2.306-2.05-.256-3.849-1.025-2.56 4.87-18.699-5.383 2.305-5.128-1.025-.77-3.848 4.358-2.562 3.325-4.1 3.086-9.731 12.819-9.736 5.381-3.848 10-1.793 5.128 5.386zM229.702 169.953c-3.884 15.66-3.555 9.547-9.101 39.076l-5.703 26.866-2.193 31.948 4.222 23.276.053.125 5.425 26.62c-4.743.77-9.488 1.484-14.247 1.875-15.459 1.037-30.973.97-46.423-.201-14.657-.874-31.445-2.664-47.792-2.001l5.354-26.293.052-.125 4.217-22.389-1.971-31.941s-7.735-32.39-7.997-46.179c-.207-10.899 4.755-33.996-.436-43.904l30.32-10.32 8.847-7.805.581-12.954.616-13.378 13.302-8.647 1.32.299 1.337-.3 13.281 8.648.581 12.974.614 13.358 8.848 7.805 31.912 11.611c-.525 1.127 5.175 5.879 4.852 6.661-2.222 5.286 1.515 9.715.129 15.295zM277.743 318.091l-.956-3.012-.033-.114.989 3.126z"/>
                    <path fill="#FBDFCF"
                          d="M217.112 202.44l-2.072-.886c-4.885-12.455-4.465-22.985-1.293-36.238 1.049-4.394 2.341-8.727 3.869-12.979.738-2.017 1.584-3.993 2.535-5.919l15.029 5.261 10.327 17.034 5.578 26.771 8.499 42.251 1.692 10.156 8.494 12.2 7.909 25.673 4.576 16.04 5.014 17.227-.054.158 5.312 12.779-7.034 8.491-9.921-5.134-20.212-39.257-11.219-19.076-4.813-20.672-15.699-34.933-6.517-18.947z"/>
                    <path fill="#FBDFCF"
                          d="M275.208 327.361l-1.281 5.893 3.59 17.679 4.358 9.736 12.561 17.934 4.103.512 4.358 3.076 6.409 1.28 2.307-2.05.256-3.848 1.025-2.563-4.871-18.704 5.384 2.306 5.128-1.025.769-3.848-4.359-2.561-3.325-4.1-3.077-9.736-12.819-9.736-5.382-3.848-9.999-1.793-5.135 5.396z"/>
                    <path fill="#E1C7B8"
                          d="M229.298 356.325l-1.575-21.891-7.206-31.34-1.778-8.289-6.929 5.931-26.989 3.985-37.322.444-23.03-5.307-7.036-5.053-1.183 8.585-7.71 31.044-1.645 20.514-3.975 34.983 18.707 6.773 18.774 4.548 18.903.558 6.415-.582s1.984-14.65 2.075-14.653c.021 0 1.332 14.504 1.332 14.504l6.301.801 18.903-.614 18.762-4.609 18.683-6.822-2.477-33.51z"/>
                    <path fill="#FBDFCF"
                          d="M135.348 67.982l-5.251-.556-1.658 2.66 4.311 8.785 4.075 6.092 3.803 5.7 4.703-.229-9.983-22.452zM199.613 67.928l5.251-.55 1.654 2.661-4.318 8.781-4.08 6.09-3.81 5.696-4.706-.232 10.009-22.446z"/>
                    <path fill="#82543A"
                          d="M201.919 36.76l-11.277-16.494-19.591-3.985-3.589.249-3.59-.235-19.582 4.013-11.247 16.519-2.433 19.012 1.971 14.005 10.447 25.538 7.316 10.976 10.973 7.551 13.104-.622 10.211-7.161 7.314-11.21 10.501-25.143 1.947-14.008-2.475-19.005z"/>
                    <path fill="#E1C7B8"
                          d="M114.395 215.434l-5.333-26.75 2.674 2.879 4.409 18.828-1.75 5.043zM220.142 210.389l4.407-18.828 2.665-2.877-5.331 26.75-1.741-5.045z"/>
                    <path fill="#2E2E2E"
                          d="M226.624 142.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.094.028-.168.084-.224.056-.066.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.18.854 2.114 0 .737-.182 1.334-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .074-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.066-.135-.164-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.164 1.806-.49.401-.336.602-.836.602-1.498 0-.663-.201-1.158-.602-1.484-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618zM100.624 142.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.094.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.13.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M168.5 129L168.5 341.245M168.5 129L168.5 341.245"/>
                    </g>
                    <path fill="none" stroke="#fff"
                          d="M155.554 172.203c-1.422 9.484-4.356 21.212-7.447 30.567-1.546 4.678-3.127 8.747-4.571 11.639-.723 1.448-1.402 2.581-2.015 3.346-.306.382-.584.657-.829.832-.248.178-.428.231-.548.231-.127 0-.32-.054-.59-.226-.267-.17-.577-.435-.924-.802-.696-.734-1.497-1.825-2.366-3.225-1.737-2.794-3.707-6.745-5.632-11.342-3.851-9.196-7.496-20.904-8.739-30.967-.267-2.155.555-4.456 2.123-6.773 1.564-2.309 3.831-4.578 6.351-6.651 5.04-4.147 11.006-7.442 14.11-8.661 3.058-1.201 6.573-1.148 8.946 1.739 2.424 2.948 3.822 9.017 2.131 20.293zM180.257 172.203c1.422 9.484 4.355 21.212 7.447 30.567 1.546 4.678 3.126 8.747 4.57 11.639.723 1.448 1.403 2.581 2.015 3.346.307.382.584.657.829.832.248.178.429.231.548.231.127 0 .32-.054.591-.226.267-.17.576-.435.924-.802.696-.734 1.496-1.825 2.366-3.225 1.736-2.794 3.706-6.745 5.631-11.342 3.852-9.196 7.496-20.904 8.74-30.967.266-2.155-.556-4.456-2.124-6.773-1.563-2.309-3.831-4.578-6.35-6.651-5.041-4.147-11.007-7.442-14.11-8.661-3.059-1.201-6.574-1.148-8.946 1.739-2.424 2.948-3.823 9.017-2.131 20.293z"/>
                    <path fill={A_color}
                          d="M137 188c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M144.409 193c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM149 198.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM149 177.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={B_color}
                          d="M175 188c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M183.401 193c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM187 198.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM187 177.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={C_color}
                          d="M137 238c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M149.083 243.14c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828-.009-.252-.014-.663-.014-1.232 0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196-.056.047-.121.07-.196.07h-1.89c-.121 0-.215-.023-.28-.07-.065-.056-.121-.154-.168-.294-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084.065.056.093.126.084.21-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zM149 248.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM149 227.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={D_color}
                          d="M175 238c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M183.319 243c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zM187 248.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM187 227.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H298.707V350H0z" transform="translate(17 10)"/>
                    </clipPath>
                    <filter id="filter0_d" width="338.707" height="390" x="0" y="-6" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );}
        else {
           return (

               <svg viewBox={"0 0 339 384"}
                    width={width}
                    height={height}
                    style={style}
                    xmlns={xmlns}
                    name={name}
                    className={className}>
                   <g clip-path="url(#clip0)" filter="url(#filter0_d)">                <path d="m38.508 301.81.954-3.012.033-.114-.987 3.126z" fill="#FBDFCF"/>
                <path d="m102.447 186.457 4.591 1.184c4.887-12.454 1.882-24.909-1.288-38.163a121.923 121.923 0 0 0-3.863-12.98 40.788 40.788 0 0 0-2.1-7.076l-15.315 4.81-10.48 18.659-5.576 26.762-8.501 42.251-1.693 10.158-8.492 12.198-7.911 25.673-4.575 16.04-5.017 17.227.053.158-5.303 12.779 7.034 8.491 9.92-5.132 20.203-39.266 11.22-19.066 5.469-21.114 15.402-35.524 6.222-18.069z" fill="#FBDFCF"/>
                <path d="m44.292 311.519 1.281 5.893-3.59 17.679-4.358 9.735-12.56 17.935-4.104.512-4.358 3.075-6.41 1.281-2.306-2.05-.256-3.849-1.025-2.56 4.87-18.699-5.383 2.305-5.128-1.025-.77-3.848 4.358-2.562 3.325-4.1 3.086-9.731 12.82-9.736 5.38-3.848 10-1.793 5.128 5.386zm169.084-157.847c-3.884 15.66-3.555 9.547-9.101 39.076l-5.703 26.866-2.193 31.948 4.222 23.276.053.125 5.425 26.62c-4.743.77-9.488 1.484-14.247 1.875a325.764 325.764 0 0 1-46.423-.201c-14.657-.874-31.445-2.664-47.792-2.001l5.354-26.293.052-.125 4.217-22.389-1.971-31.941s-7.735-32.39-7.997-46.179c-.207-10.899 4.755-33.996-.436-43.904l30.32-10.32 8.847-7.805.581-12.954.616-13.378 13.302-8.648 1.32.3 1.337-.3 13.281 8.648.581 12.974.614 13.358 8.848 7.805 31.912 11.611c-.525 1.127 5.175 5.879 4.852 6.661-2.222 5.286 1.515 9.715.129 15.295zm48.041 148.138-.956-3.012-.033-.114.989 3.126z" fill="#FBDFCF"/>
                <path d="m200.788 186.159-2.072-.886c-4.885-12.455-4.465-22.985-1.293-36.238a122.473 122.473 0 0 1 3.869-12.979 59.71 59.71 0 0 1 2.535-5.919l15.029 5.261 10.327 17.034 5.578 26.771 8.499 42.251 1.692 10.156 8.494 12.2 7.909 25.673 4.576 16.04 5.014 17.227-.054.158 5.312 12.779-7.034 8.491-9.921-5.134-20.212-39.257-11.219-19.076-4.813-20.672-15.699-34.933-6.517-18.947z" fill="#FBDFCF"/>
                <path d="m258.883 311.08-1.281 5.893 3.59 17.679 4.358 9.736 12.561 17.934 4.103.512 4.358 3.076 6.409 1.28 2.307-2.05.256-3.848 1.025-2.563-4.871-18.704 5.384 2.306 5.128-1.025.769-3.848-4.359-2.561-3.325-4.1-3.077-9.736-12.819-9.736-5.382-3.848-9.999-1.793-5.135 5.396z" fill="#FBDFCF"/>
                <path d="m212.972 340.044-1.575-21.891-7.206-31.34-1.778-8.289-6.929 5.931-26.989 3.985-37.322.444-23.03-5.307-7.036-5.053-1.183 8.585-7.71 31.044-1.645 20.514-3.975 34.983 18.707 6.773 18.774 4.548 18.903.558 6.415-.582s1.984-14.65 2.075-14.653c.021 0 1.332 14.504 1.332 14.504l6.301.801 18.903-.614 18.762-4.609 18.683-6.822-2.477-33.51z" fill="#E1C7B8"/>
                <path d="m119.022 51.7-5.251-.555-1.658 2.66 4.311 8.785 4.075 6.092 3.803 5.7 4.703-.23-9.983-22.451zm64.265-.053 5.251-.55 1.654 2.66-4.318 8.782-4.08 6.09-3.81 5.696-4.706-.232 10.009-22.446z" fill="#FBDFCF"/>
                <path d="M185.594 20.479 174.317 3.985 154.726 0l-3.589.249-3.59-.235-19.582 4.013-11.247 16.519-2.433 19.012 1.971 14.005 10.447 25.538 7.316 10.976 10.973 7.551 13.104-.622 10.211-7.161 7.314-11.21 10.501-25.143 1.947-14.008-2.475-19.005z" fill="#82543A"/>
                <path d="m98.067 199.153-5.333-26.75 2.674 2.879 4.41 18.828-1.75 5.043zm105.747-5.045 4.407-18.828 2.665-2.877-5.331 26.75-1.741-5.045z" fill="#E1C7B8"/>
                <path d="M84.624 126a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.303.303 0 0 1-.224.084h-.266zM210.624 126a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.369 1.246.378 2.058a47.205 47.205 0 0 1 0 2.226c-.018.859-.144 1.568-.378 2.128-.233.56-.625.98-1.176 1.26-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.439-.205.752-.527.938-.966.196-.448.299-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                <g opacity=".5">
                    <path d="M152.176 112.719v212.245-212.245zm0 0v212.245z" fill="#000"/>
                    <path d="M152.176 112.719v212.245m0-212.245v212.245-212.245z" stroke="#fff"/>
                </g>
                <path fill="none" clip-rule="evenodd" d="M139.226 155.922c-1.422 9.484-4.356 21.212-7.447 30.567-1.546 4.678-3.127 8.747-4.571 11.639-.723 1.448-1.402 2.581-2.015 3.346-.306.382-.584.657-.829.832-.248.178-.428.231-.548.231-.127 0-.32-.054-.59-.226-.267-.17-.577-.435-.924-.802-.696-.734-1.497-1.825-2.366-3.225-1.737-2.794-3.707-6.745-5.632-11.342-3.851-9.196-7.496-20.904-8.739-30.967-.267-2.155.555-4.456 2.123-6.773 1.564-2.309 3.831-4.578 6.351-6.651 5.04-4.147 11.006-7.442 14.11-8.661 3.058-1.201 6.573-1.148 8.946 1.739 2.424 2.948 3.822 9.017 2.131 20.293zm24.703 0c1.422 9.484 4.355 21.212 7.447 30.567 1.546 4.678 3.126 8.747 4.57 11.639.723 1.448 1.403 2.581 2.015 3.346.307.382.584.657.829.832.248.178.429.231.548.231.127 0 .32-.054.591-.226.267-.17.576-.435.924-.802.696-.734 1.496-1.825 2.366-3.225 1.736-2.794 3.706-6.745 5.631-11.342 3.852-9.196 7.496-20.904 8.74-30.967.266-2.155-.556-4.456-2.124-6.773-1.563-2.309-3.831-4.578-6.35-6.651-5.041-4.147-11.007-7.442-14.11-8.661-3.059-1.201-6.574-1.148-8.946 1.739-2.424 2.948-3.823 9.017-2.131 20.293z" stroke="#fff"/>
                <path fill={A_color} d="M120.676 171.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path fill="#fff" d="M128.085 176.719a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zm-1.303 9.238c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                <path fill={B_color} d="M158.676 171.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path fill="#fff" d="M167.077 176.719a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zm-.517 7.32c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                <path fill={C_color} d="M120.676 221.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path fill="#fff" d="M132.759 226.859c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828a38.663 38.663 0 0 1-.014-1.232c0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196a.295.295 0 0 1-.196.07h-1.89a.475.475 0 0 1-.28-.07.665.665 0 0 1-.168-.294c-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084a.23.23 0 0 1 .084.21c-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zm-.083 5.36c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                <path fill={D_color} d="M158.676 221.719c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                <path  fill="#fff" d="M166.995 226.719a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zm.041 7.516c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zm-10.5-10.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" fill="#fff"/>
                   </g>
                   <defs>
                       <clipPath id="clip0">
                           <path fill="#fff" d="M0 0H298.707V350H0z" transform="translate(0 0)"/>
                       </clipPath>
                       <filter id="filter0_d" width="338.707" height="390" x="0" y="-6" color-interpolation-filters="sRGB"
                               filterUnits="userSpaceOnUse">
                           <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                           <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                           <feOffset dx="3" dy="4"/>
                           <feGaussianBlur stdDeviation="10"/>
                           <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                           <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                           <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                       </filter>
                   </defs>
            </svg>)
        }
    }

}

export default MC_LungsBack
