import React, {Component} from "react";

class FC_LungsBack extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {className, width, height, style, name, files, activeDot} = this.props
        let language= 'en'

        let isA_Enabled = (files.findIndex(file => file['dotName'] === 'A')) > -1
        let isB_Enabled = (files.findIndex(file => file['dotName'] === 'B')) > -1
        let isC_Enabled = (files.findIndex(file => file['dotName'] === 'C')) > -1
        let isD_Enabled = (files.findIndex(file => file['dotName'] === 'D')) > -1

        let isA_Active = activeDot === 'A'
        let isB_Active = activeDot === 'B'
        let isC_Active = activeDot === 'C'
        let isD_Active = activeDot === 'D'

        const grey = '#ececec'
        const sleep = '#4c23b5'
        const active = '#03b3fe'

        let A_color = grey
        let B_color = grey
        let C_color = grey
        let D_color = grey
        if (isA_Enabled) {
            if (isA_Active) {
                A_color = active
            } else {
                A_color = sleep
            }
        }
        if (isB_Enabled) {
            if (isB_Active) {
                B_color = active
            } else {
                B_color = sleep
            }
        }
        if (isC_Enabled) {
            if (isC_Active) {
                C_color = active
            } else {
                C_color = sleep
            }
        }
        if (isD_Enabled) {
            if (isD_Active) {
                D_color = active
            } else {
                D_color = sleep
            }
        }


        let xmlns = "http://www.w3.org/2000/svg"
        if (language ==="en"){
        return (
            <svg viewBox={"0 0 340 379"}
                 width={width}
                 height={height}
                 style={style}
                 xmlns={xmlns}
                 name={name}
                 className={className}>
                <g clip-path="url(#clip0)" filter="url(#filter0_d)">
                    <path fill="#FBDFCF"
                          d="M218.828 201.394l-.141 5.902c-4.946-12.622-8.114-26.201-4.906-39.632 1.06-4.455 2.367-8.846 3.915-13.156.783-2.555 1.719-5.061 2.802-7.504l14.976 6.848 10.449 17.261 5.642 27.134 8.603 42.819 1.714 10.295 8.597 12.363 8.004 26.02 4.639 16.255 5.073 17.458-.053.162 5.375 12.949-7.12 8.606-10.039-5.202-20.453-39.794-11.355-19.335-2.937-16.425-14.742-42.463-8.043-20.561z"/>
                    <path fill="#FBDFCF"
                          d="M275.978 331.891l-1.296 5.981 3.632 17.917 4.41 9.867 12.714 18.177 4.151.519 4.412 3.116 6.484 1.296 2.344-2.088.258-3.895 1.039-2.598-4.922-18.955 5.449 2.338 5.189-1.04.777-3.894-4.41-2.597-3.373-4.155-3.114-9.867-12.972-9.866-5.449-3.896-10.12-1.817-5.203 5.457zM230.656 358.01l-2.243-22.769-11.812-40.404-.053-.125-4.268-22.694-2.069-31.883.209-11.916.893-5.139c-4.888-13.28-.351-29.964-.081-43.942.21-11.046 1.582-23.293 6.838-33.341l-25.945-7.917-8.953-7.916-.589-13.13.146-18.021-14.226-4.301-1.336.302-1.351-.302-14.13 5.643.1 16.271-.622 13.545-8.954 7.916-26.278 7.999c.528 1.14.949 2.245 1.276 3.038 2.222 5.405 4.036 10.969 5.425 16.646 3.932 15.871-.234 31.533-6.42 46.153l4.659 16.044 3.255 12.361-2.064 30.995-4.275 23.589-.052.125-11.813 40.404-2.285 23.159-.037 17.495 1.779 20.384 15.495 72.71 5.857 22.067-.626 31.288 3.108 31.113 7.691 38.462 4.537 34.687-2.226 8.604 1.336 7.287-12.855 9.513-1.021 6.664 9.596 9.547 12.352 3.367 11.145-.213 5.554-4.241-2.461-20.041.463-5.344 1.104-8.463-2.468-12.291 2.157-34.258 2.109-42.161-.962-34.303-3.292-24.98-.051-.903 1.065-30.942 2.173-34.705-.127-29.413-1.452-18.295c2.36.169 4.726.206 7.09.112l-1.364 18.19-.125 29.413 2.143 34.695 3.55 27.502.153 5.236-3.276 24.98-.96 34.304 1.84 40.812 2.154 34.26-2.333 11.846 1.638 9.871-.302 5.502-1.478 18.623 5.25 4.758 10.02 1.105 9.346-4.222 13.351-9.941-1.477-6.234-10.093-9.656 1.192-8.462-2.11-8.854 2.05-32.877.197-1.339 7.527-37.646 3.355-29.774-2.153-29.144 5.012-26.895 14.888-70.921 2.068-20.384-.078-17.885zM55.331 292.35l.967-3.052.033-.114-1 3.166z"/>
                    <path fill="#FBDFCF"
                          d="M115.953 200.947l.141 5.903c4.945-12.622 8.114-26.201 4.906-39.63-1.062-4.455-2.369-8.847-3.916-13.157-.782-2.555-1.718-5.061-2.801-7.504l-14.975 6.852-10.45 17.257-5.642 27.133-8.603 42.82-1.712 10.293-8.598 12.363-8.005 26.018-4.632 16.257-5.073 17.46.055.16-5.378 12.951 7.121 8.605 10.041-5.201 20.452-39.794 11.356-19.335 2.935-16.424 14.74-42.464 8.038-20.563z"/>
                    <path fill="#FBDFCF"
                          d="M58.805 331.439l1.295 5.981-3.632 17.913-4.41 9.867-12.714 18.176-4.15.528-4.413 3.115-6.484 1.299-2.336-2.078-.26-3.895-1.038-2.598 4.922-18.955-5.449 2.338-5.189-1.04-.772-3.9 4.41-2.598 3.374-4.153 3.115-9.868 12.974-9.867 5.45-3.895 10.12-1.817 5.187 5.447z"/>
                    <path fill="#E1C7B8"
                          d="M231.338 357.987l-16.524-3.128-18.759.744-14.739 3.724-9.827 7.004-.671 6.755c-.803.239-1.638.353-2.475.338-.408 0-.782-.053-1.174-.072-.391.019-.784.072-1.176.072-.794.019-1.586-.083-2.349-.303l-.492-6.493-9.678-7.001-14.293-3.726-20.391-1.638-15.782 2.382 2.467-31.542 10.33-35.213 3.574-5.96 7.145 2.98 18.899 2.668 38.273-.285 23.521-2.98 8.042-1.789 2.976 3.577 10.624 37.002 2.479 32.884z"/>
                    <path fill="#FBDFCF"
                          d="M135.699 65.653l-5.124-.528-1.617 2.544 4.208 8.409 3.981 5.83 3.716 5.453 4.593-.22-9.757-21.488zM198.486 65.602l5.124-.528 1.616 2.547-4.219 8.402-3.987 5.828-3.721 5.454-4.593-.224 9.78-21.479z"/>
                    <path fill="#E1C7B8"
                          d="M214.864 175.07v16.318l7.615 18.948-6.012 17.618-4.707 13.449-.67 5.365-17.065-3.856-25.812-2.227-25.365 2.443-19.748 2.963-.893-5.14-4.28-12.072-5.314-18.096 3.164-26.521-2.67-15.428-4.746-13.078-5.064-4.871 10.469-4.208 4.666-2.208 2.97 9.222 9.505 18.707 17.499 9.499 19.886.303 20.611-.308 11.57-12.026 6.231-16.478 7.481-10.013 6.483 2.846 6.731 3.25-7.474 8.961-5.061 16.638z"/>
                    <path fill="#E1C7B8"
                          d="M117.407 147.449l18.838 7.276 29.966 3.259 32.637-2.814 14.537-8.611-8.311 31.175s-37.531 13.511-38.57 13.214c-1.039-.298-43.168-14.844-43.168-14.844l-5.929-28.655z"/>
                    <path fill="#82543A"
                          d="M200.738 35.774L189.72 19.986l-19.137-3.814-3.516.237-3.516-.23-19.121 3.847-10.988 15.808-2.372 18.195 1.925 13.403 10.208 24.453 7.144 10.509 10.722 7.225 12.804-.597 9.976-6.86 7.145-10.731 10.254-24.064 1.902-13.407-2.412-18.186z"/>
                    <path fill="#D7A283"
                          d="M156.601 64.905l-5.14 9.834 3.127 11.176 10.723 5.805 15.857-4.688 4.69-8.268-3.8-12.962-10.496-4.697-12.506.673-2.455 3.127z"/>
                    <path fill="#74472E"
                          d="M175.294 70.418l7.644 12.427-2.16 12.263-10.195 12.634-14.903 7.844-11.25 3.54-3.154 9.577 3.825 4.911 5.592.239-6.286 3.519-9.408-5.144-3.05-12.272 10.82-15.394 14.029-10.328-.064-9.433 4.073-11.11 14.487-3.273z"/>
                    <path fill="#2E2E2E"
                          d="M219.624 135.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.17c0-.094.028-.168.084-.224.056-.066.131-.098.224-.098h3.262c1.008 0 1.797.238 2.366.714.569.476.854 1.18.854 2.114 0 .737-.182 1.334-.546 1.792-.364.448-.887.751-1.568.91l2.254 3.836c.028.056.042.107.042.154 0 .074-.028.14-.084.196-.056.056-.121.084-.196.084h-.168c-.14 0-.247-.028-.322-.084-.065-.066-.135-.164-.21-.294l-2.226-3.766h-2.884v3.836c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-.252zm3.192-4.984c.812 0 1.414-.164 1.806-.49.401-.336.602-.836.602-1.498 0-.663-.201-1.158-.602-1.484-.392-.336-.994-.504-1.806-.504h-2.618v3.976h2.618zM104.624 135.391c-.093 0-.168-.028-.224-.084-.056-.056-.084-.131-.084-.224v-9.184c0-.094.028-.168.084-.224.056-.056.131-.084.224-.084h.266c.093 0 .168.028.224.084.056.056.084.13.084.224v8.652h4.83c.103 0 .182.028.238.084.056.056.084.135.084.238v.21c0 .093-.033.168-.098.224-.056.056-.131.084-.224.084h-5.404z"/>
                    <g opacity=".5">
                        <path stroke="#fff" d="M167.5 131L167.5 343.245M167.5 131L167.5 343.245"/>
                    </g>
                    <path fill="none" stroke="#fff"
                          d="M155.553 173.024c-1.422 8.736-4.354 19.541-7.444 28.16-1.546 4.311-3.125 8.058-4.567 10.719-.722 1.333-1.399 2.373-2.008 3.074-.304.35-.58.601-.823.762-.244.162-.426.213-.554.213-.136 0-.336-.053-.608-.213-.269-.158-.579-.403-.927-.741-.695-.676-1.495-1.681-2.364-2.97-1.735-2.573-3.704-6.214-5.629-10.45-3.85-8.473-7.492-19.259-8.735-28.526-.262-1.96.547-4.062 2.112-6.193 1.56-2.124 3.824-4.213 6.343-6.123 5.039-3.82 11.003-6.858 14.108-7.981 3.061-1.108 6.594-1.061 8.973 1.607 2.414 2.707 3.812 8.278 2.123 18.662zM180.258 173.024c1.421 8.736 4.353 19.541 7.443 28.16 1.546 4.311 3.125 8.058 4.567 10.719.722 1.333 1.399 2.373 2.008 3.074.305.35.58.601.823.762.244.162.426.213.554.213.137 0 .336-.053.608-.213.269-.158.579-.403.927-.741.695-.676 1.495-1.681 2.364-2.97 1.735-2.573 3.704-6.214 5.629-10.45 3.85-8.473 7.492-19.259 8.735-28.526.262-1.96-.547-4.062-2.112-6.193-1.56-2.124-3.824-4.213-6.343-6.123-5.038-3.82-11.003-6.858-14.108-7.981-3.061-1.108-6.594-1.061-8.973 1.607-2.414 2.707-3.812 8.278-2.122 18.662z"/>
                    <path fill={A_color}
                          d="M136 181c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M143.409 186c-.074 0-.144-.028-.21-.084-.056-.065-.084-.135-.084-.21 0-.056.005-.098.014-.126l3.29-8.988c.028-.112.089-.205.182-.28.094-.075.215-.112.364-.112h2.072c.15 0 .271.037.364.112.094.075.154.168.182.28l3.276 8.988.028.126c0 .075-.032.145-.098.21-.056.056-.126.084-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738l-1.302-3.696-1.302 3.696h2.604zM148 191.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM148 170.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={B_color}
                          d="M174 181c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M182.401 186c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19-.233.317-.499.551-.798.7.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336.215-.224.322-.495.322-.812 0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM186 191.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM186 170.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={C_color}
                          d="M136 231c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M148.083 236.14c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828-.009-.252-.014-.663-.014-1.232 0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196-.056.047-.121.07-.196.07h-1.89c-.121 0-.215-.023-.28-.07-.065-.056-.121-.154-.168-.294-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084.065.056.093.126.084.21-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zM148 241.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM148 220.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={D_color}
                          d="M174 231c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff"
                          d="M182.319 236c-.093 0-.177-.033-.252-.098-.065-.075-.098-.159-.098-.252v-9.1c0-.103.033-.187.098-.252.075-.065.159-.098.252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zM186 241.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM186 220.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H300V352H0z" transform="translate(17 3)"/>
                    </clipPath>
                    <filter id="filter0_d" width="340" height="392" x="0" y="-13" color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="10"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>
        );}
        else{
            return (
                <svg viewBox={"0 0 340 379"}
                     width={width}
                     height={height}
                     style={style}
                     xmlns={xmlns}
                     name={name}
                     className={className}>
                    <g clip-path="url(#clip0)" filter="url(#filter0_d)">

                    <path d="m204.829 185.394-.141 5.902c-4.946-12.622-8.114-26.201-4.906-39.632a123.459 123.459 0 0 1 3.915-13.156 66.848 66.848 0 0 1 2.802-7.504l14.976 6.848 10.449 17.261 5.642 27.134 8.603 42.819 1.714 10.295 8.597 12.363 8.004 26.02 4.639 16.255 5.073 17.458-.053.162 5.375 12.949-7.12 8.606-10.039-5.202-20.453-39.794-11.355-19.335-2.937-16.425-14.742-42.463-8.043-20.561z" fill="#FBDFCF"/>
                    <path d="m261.979 315.891-1.296 5.981 3.632 17.917 4.41 9.867 12.714 18.177 4.151.519 4.412 3.116 6.484 1.296 2.344-2.088.258-3.895 1.039-2.598-4.922-18.955 5.449 2.338 5.189-1.04.777-3.894-4.41-2.597-3.373-4.155-3.114-9.867-12.972-9.866-5.449-3.896-10.12-1.817-5.203 5.457zm-45.322 26.119-2.243-22.769-11.812-40.404-.053-.125-4.268-22.694-2.069-31.883.209-11.916.893-5.139c-4.888-13.28-.351-29.964-.081-43.942.21-11.046 1.582-23.293 6.838-33.341l-25.945-7.917-8.953-7.916-.589-13.13.146-18.021-14.226-4.301-1.336.302-1.351-.302-14.13 5.643.1 16.271-.622 13.545-8.954 7.916-26.278 7.999c.528 1.14.949 2.245 1.276 3.038a116.763 116.763 0 0 1 5.425 16.646c3.932 15.871-.234 31.533-6.42 46.153l4.659 16.044 3.255 12.361-2.064 30.995-4.275 23.589-.052.125-11.813 40.404-2.285 23.159-.037 17.495 1.779 20.384 15.495 72.71 5.857 22.067-.626 31.288 3.108 31.113 7.691 38.462 4.537 34.687-2.226 8.604 1.336 7.287-12.855 9.513-1.021 6.664 9.596 9.547 12.352 3.367 11.145-.213 5.554-4.241-2.461-20.041.463-5.344 1.104-8.463-2.468-12.291 2.157-34.258 2.109-42.161-.962-34.303-3.292-24.98-.051-.903 1.065-30.942 2.173-34.705-.127-29.413-1.452-18.295c2.36.169 4.726.206 7.09.112l-1.364 18.19-.125 29.413 2.143 34.695 3.55 27.502.153 5.236-3.276 24.98-.96 34.304 1.84 40.812 2.154 34.26-2.333 11.846 1.638 9.871-.302 5.502-1.478 18.623 5.25 4.758 10.02 1.105 9.346-4.222 13.351-9.941-1.477-6.234-10.093-9.656 1.192-8.462-2.11-8.854 2.05-32.877.197-1.339 7.527-37.646 3.355-29.774-2.153-29.144 5.012-26.895 14.888-70.921 2.068-20.384-.078-17.885zM41.332 276.35l.967-3.052.033-.114-1 3.166z" fill="#FBDFCF"/>
                    <path d="m101.954 184.947.141 5.903c4.945-12.622 8.114-26.201 4.906-39.63a124.181 124.181 0 0 0-3.916-13.157 66.647 66.647 0 0 0-2.801-7.504l-14.975 6.852-10.45 17.257-5.641 27.133-8.604 42.82-1.711 10.293-8.599 12.363-8.005 26.018-4.632 16.257-5.073 17.46.056.16-5.378 12.951 7.12 8.605 10.041-5.201 20.452-39.794 11.356-19.335 2.936-16.424 14.74-42.464 8.037-20.563z" fill="#FBDFCF"/>
                    <path d="m44.806 315.439 1.295 5.981-3.632 17.913-4.41 9.867-12.714 18.176-4.15.528-4.413 3.115-6.484 1.299-2.336-2.078-.26-3.895-1.038-2.598 4.922-18.955-5.45 2.338-5.188-1.04-.772-3.9 4.41-2.598 3.374-4.153 3.115-9.868 12.974-9.867 5.45-3.895 10.12-1.817 5.187 5.447z" fill="#FBDFCF"/>
                    <path d="m217.338 341.987-16.524-3.128-18.759.744-14.739 3.724-9.827 7.004-.671 6.755a8.164 8.164 0 0 1-2.475.338c-.408 0-.782-.053-1.174-.072-.391.019-.784.072-1.176.072a7.804 7.804 0 0 1-2.349-.303l-.492-6.493-9.678-7.001-14.293-3.726-20.391-1.638-15.782 2.382 2.467-31.542 10.33-35.213 3.574-5.96 7.145 2.98 18.899 2.668 38.273-.285 23.521-2.98 8.042-1.789 2.976 3.577 10.624 37.002 2.479 32.884z" fill="#E1C7B8"/>
                    <path d="m121.7 49.653-5.124-.528-1.617 2.544 4.208 8.41 3.981 5.83 3.716 5.452 4.593-.22-9.757-21.488zm62.787-.05 5.124-.529 1.616 2.547-4.219 8.402-3.987 5.828-3.721 5.454-4.593-.224 9.78-21.479z" fill="#FBDFCF"/>
                    <path d="M200.864 159.07v16.318l7.615 18.948-6.012 17.618-4.707 13.449-.67 5.365-17.065-3.856-25.812-2.227-25.365 2.443-19.748 2.963-.893-5.14-4.28-12.072-5.314-18.096 3.164-26.521-2.67-15.428-4.746-13.078-5.064-4.871 10.469-4.208 4.666-2.208 2.97 9.222 9.505 18.707 17.499 9.499 19.886.303 20.611-.308 11.57-12.026 6.231-16.478 7.481-10.013 6.483 2.846 6.731 3.25-7.474 8.961-5.061 16.638z" fill="#E1C7B8"/>
                    <path d="m103.408 131.449 18.838 7.276 29.966 3.259 32.637-2.814 14.537-8.611-8.311 31.175s-37.531 13.511-38.57 13.214c-1.039-.298-43.168-14.844-43.168-14.844l-5.929-28.655z" fill="#E1C7B8"/>
                    <path d="M186.738 19.774 175.72 3.986 156.583.172l-3.516.237-3.516-.23-19.121 3.847-10.988 15.808-2.372 18.195 1.925 13.403 10.208 24.453 7.144 10.509 10.722 7.225 12.804-.597 9.976-6.86 7.145-10.731 10.254-24.064 1.902-13.407-2.412-18.186z" fill="#82543A"/>
                    <path d="m142.601 48.904-5.14 9.834 3.127 11.177 10.723 5.804 15.857-4.688 4.69-8.268-3.8-12.962-10.496-4.697-12.506.674-2.455 3.127z" fill="#D7A283"/>
                    <path d="m161.294 54.418 7.644 12.427-2.16 12.263-10.195 12.634-14.903 7.844-11.25 3.54-3.154 9.577 3.825 4.911 5.592.239-6.286 3.519-9.408-5.144-3.05-12.272 10.82-15.394 14.029-10.328-.064-9.433 4.073-11.11 14.487-3.273z" fill="#74472E"/>
                    <path d="M90.624 119.999a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.184c0-.093.028-.168.084-.224a.303.303 0 0 1 .224-.084h.266c.094 0 .168.028.224.084a.303.303 0 0 1 .084.224v9.184a.302.302 0 0 1-.084.224.303.303 0 0 1-.224.084h-.266zM205.624 119.999a.303.303 0 0 1-.224-.084.302.302 0 0 1-.084-.224v-9.17c0-.102.028-.182.084-.238a.303.303 0 0 1 .224-.084h2.982c.99 0 1.764.145 2.324.434.56.29.957.719 1.19 1.288.243.56.369 1.246.378 2.058a47.205 47.205 0 0 1 0 2.226c-.018.859-.144 1.568-.378 2.128-.233.56-.625.98-1.176 1.26-.55.271-1.306.406-2.268.406h-3.052zm.574-.84h2.408c.747 0 1.335-.098 1.764-.294.439-.205.752-.527.938-.966.196-.448.299-1.036.308-1.764.01-.28.014-.522.014-.728v-.63c0-.205-.004-.448-.014-.728-.018-1.026-.256-1.782-.714-2.268-.457-.494-1.246-.742-2.366-.742h-2.338v8.12z" fill="#000"/>
                    <g opacity=".5">
                        <path d="M153.5 115v212.245V115zm0 0v212.245z" fill="#000"/>
                        <path d="M153.5 115v212.245m0-212.245v212.245V115z" stroke="#fff"/>
                    </g>
                    <path fill={'none'} clip-rule="evenodd" d="M141.553 157.024c-1.422 8.736-4.354 19.541-7.444 28.16-1.546 4.311-3.125 8.058-4.567 10.719-.722 1.333-1.399 2.373-2.008 3.074-.304.35-.58.601-.823.762-.244.162-.426.213-.554.213-.136 0-.336-.053-.608-.213-.269-.158-.579-.403-.927-.741-.695-.676-1.495-1.681-2.364-2.97-1.735-2.573-3.704-6.214-5.629-10.45-3.85-8.473-7.492-19.259-8.735-28.526-.262-1.96.547-4.062 2.112-6.193 1.56-2.124 3.824-4.213 6.343-6.123 5.039-3.82 11.003-6.858 14.108-7.981 3.061-1.108 6.594-1.061 8.973 1.607 2.414 2.707 3.812 8.278 2.123 18.662zm24.705 0c1.421 8.736 4.353 19.541 7.443 28.16 1.546 4.311 3.125 8.058 4.567 10.719.722 1.333 1.399 2.373 2.008 3.074.305.35.58.601.823.762.244.162.426.213.554.213.137 0 .336-.053.608-.213.269-.158.579-.403.927-.741.695-.676 1.495-1.681 2.364-2.97 1.735-2.573 3.704-6.214 5.629-10.45 3.85-8.473 7.492-19.259 8.735-28.526.262-1.96-.547-4.062-2.112-6.193-1.56-2.124-3.824-4.213-6.343-6.123-5.038-3.82-11.003-6.858-14.108-7.981-3.061-1.108-6.594-1.061-8.973 1.607-2.414 2.707-3.812 8.278-2.122 18.662z" stroke="#fff"/>
                    <path fill={A_color} d="M122 165c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M129.409 170a.32.32 0 0 1-.21-.084.316.316 0 0 1-.084-.21c0-.056.005-.098.014-.126l3.29-8.988a.512.512 0 0 1 .182-.28.565.565 0 0 1 .364-.112h2.072c.15 0 .271.037.364.112a.506.506 0 0 1 .182.28l3.276 8.988.028.126a.29.29 0 0 1-.098.21.285.285 0 0 1-.21.084h-1.722c-.224 0-.373-.098-.448-.294l-.546-1.428h-3.738l-.532 1.428c-.074.196-.228.294-.462.294h-1.722zm5.894-3.738-1.302-3.696-1.302 3.696h2.604zM134 175.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM134 154.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z"/>
                    <path fill={B_color} d="M160 165c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M168.401 170a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h4.27c1.176 0 2.049.243 2.618.728.579.476.868 1.157.868 2.044 0 .467-.121.863-.364 1.19a2.24 2.24 0 0 1-.798.7c.383.177.705.462.966.854.262.392.392.831.392 1.316 0 .933-.308 1.661-.924 2.184-.616.523-1.493.784-2.632.784h-4.396zm3.99-5.908c.374 0 .658-.093.854-.28.196-.196.294-.453.294-.77 0-.308-.098-.555-.294-.742-.196-.187-.48-.28-.854-.28h-1.82v2.072h1.82zm.126 4.088c.383 0 .682-.112.896-.336a1.13 1.13 0 0 0 .322-.812c0-.336-.107-.611-.322-.826-.214-.224-.513-.336-.896-.336h-1.946v2.31h1.946zM172 175.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM172 154.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={C_color} d="M122 215c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
                    <path fill="#fff" d="M134.083 220.14c-1.335 0-2.38-.322-3.136-.966-.747-.653-1.143-1.596-1.19-2.828a38.663 38.663 0 0 1-.014-1.232c0-.579.005-.999.014-1.26.047-1.213.453-2.147 1.218-2.8.765-.663 1.801-.994 3.108-.994.821 0 1.559.14 2.212.42.653.271 1.167.663 1.54 1.176.383.504.579 1.097.588 1.778v.028c0 .075-.033.14-.098.196a.295.295 0 0 1-.196.07h-1.89a.475.475 0 0 1-.28-.07.665.665 0 0 1-.168-.294c-.131-.476-.336-.807-.616-.994-.28-.196-.649-.294-1.106-.294-1.101 0-1.671.616-1.708 1.848-.009.252-.014.639-.014 1.162 0 .523.005.919.014 1.19.037 1.232.607 1.848 1.708 1.848.457 0 .831-.098 1.12-.294.289-.205.49-.537.602-.994.037-.14.089-.233.154-.28.065-.056.163-.084.294-.084h1.89c.084 0 .154.028.21.084a.23.23 0 0 1 .084.21c-.009.681-.205 1.279-.588 1.792-.373.504-.887.896-1.54 1.176-.653.271-1.391.406-2.212.406zM134 225.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM134 204.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" />
                    <path fill={D_color} d="M160 215c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" />
                    <path fill="#fff" d="M168.319 220a.376.376 0 0 1-.252-.098.376.376 0 0 1-.098-.252v-9.1c0-.103.033-.187.098-.252a.376.376 0 0 1 .252-.098h3.64c1.372 0 2.436.327 3.192.98.756.653 1.153 1.601 1.19 2.842.01.271.014.63.014 1.078 0 .448-.004.803-.014 1.064-.046 1.297-.434 2.263-1.162 2.898-.718.625-1.768.938-3.15.938h-3.71zm3.64-2.016c.616 0 1.069-.145 1.358-.434.29-.299.444-.77.462-1.414.019-.271.028-.621.028-1.05 0-.429-.009-.775-.028-1.036-.018-.625-.186-1.087-.504-1.386-.308-.299-.77-.448-1.386-.448h-1.4v5.768h1.47zM172 225.5c-5.799 0-10.5-4.701-10.5-10.5h-3c0 7.456 6.044 13.5 13.5 13.5v-3zm10.5-10.5c0 5.799-4.701 10.5-10.5 10.5v3c7.456 0 13.5-6.044 13.5-13.5h-3zM172 204.5c5.799 0 10.5 4.701 10.5 10.5h3c0-7.456-6.044-13.5-13.5-13.5v3zm0-3c-7.456 0-13.5 6.044-13.5 13.5h3c0-5.799 4.701-10.5 10.5-10.5v-3z" fill="#fff"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0H300V352H0z" transform="translate(0 0)"/>
                        </clipPath>
                        <filter id="filter0_d" width="340" height="392" x="0" y="-13" color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="10"/>
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                    </defs>

                    </svg>


            )
        }
    }

}

export default FC_LungsBack
