import React, { useState, useEffect } from 'react';
import {history} from "./utils/history";
import './App.css';
import NotFound from "./views/NotFound";
import Physician from "./views/physician/Physician";
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import Redirect from "./views/redirect/Redirect";
import {PatientNew} from "./views/physician/patient_new/Patient";
import ExpiredLinkPatient from "./views/physician/patient_new/ExpiredLinkPatient";
import {Login} from "./views/Login";


 function App() {

    const [zoom, setZoom] = useState(window.innerHeight / 890);
    const [zoomEr, setZoomEr] = useState(1);


    return (
        <BrowserRouter history={history}>

            <div className={'main-app'}
                 // style={{zoom}}
            >
                <ReactNotification/>
                <Switch>
                    <Route path="/rdct" component={Redirect}/>
                    <Route path="/physician" component={Physician}/>
                    {/*<Route path="/physician/PatientNew" component={PatientNew}/>*/}
                    <Route path="/physician/patient/card" component={PatientNew}/>
                    <Route path="/expiredLink" component={ExpiredLinkPatient}/>
                    <Route exact component={NotFound}/>
                </Switch>

                <div className={'footer'}>
                    {/*<div className={'zoomer'}>*/}
                    {/*    <SVGraphics svgname={'zoomin'}*/}
                    {/*                name={'zoomin'}*/}
                    {/*                className={`zoom-tool ${zoom >= 1 ? 'blocked' : 'zoomer-in'}`}*/}
                    {/*                onClick={() => setZoom(zoom >= 1 ? 1 : zoom + 0.1)}/>*/}
                    {/*    <SVGraphics svgname={'zoomout'}*/}
                    {/*                name={'zoomout'}*/}
                    {/*                className={`zoom-tool ${zoom < 0.2 ? 'blocked' : 'zoomer-out'}`}*/}
                    {/*                onClick={() => setZoom(zoom < 0.2 ? zoom : zoom - 0.1)}/>*/}
                    {/*</div>*/}
                    {/*<OverlayTrigger*/}
                    {/*    placement="bottom"*/}
                    {/*    delay={{show: 250}}*/}
                    {/*    overlay={<Tooltip >*/}
                    {/*        {localStorage.getItem('beVersion')}*/}
                    {/*    </Tooltip>}>*/}
                    {/*    <div >{Config.version} ({Config.MHDVersion})</div>*/}
                    {/*</OverlayTrigger>*/}
                    {/*<div >{Config.version} ({Config.MHDVersion})</div>*/}
                </div>
            </div>

        </BrowserRouter>
    );
}

export default App;
