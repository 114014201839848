import React, {Component} from "react";
import PropTypes from 'prop-types'
import '../../style/component/input/TextInput.css'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import utils from "../../utils/utils";
import DatePicker from "react-datepicker";

class OutlinedInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            isErr: false
        };
        this.inputRef = null
    }

    componentDidMount() {
        // this.inputRef.click()
    }

    render() {
        let {isFocused} = this.state;
        let {
            type,
            name,
            onChange,
            defaultValue,
            placeholder,
            required,
            label,
            isErr,
            errMessage,
            disabled,
            className,
            value
        } = this.props

        let isActive = !!value && !isFocused
        let labelClass = 'outlined-custom-input-label outlined-custom-input-label-default'
        let inputClassName = 'custom-input'

        if (!!className) {
            inputClassName = inputClassName + ' outlined-input-' + className
            labelClass = labelClass + ' outlined-input-label-' + className
        }

        if (disabled) {
            labelClass = labelClass + ' outlined-custom-input-label outlined-custom-input-label-disabled'
            inputClassName = 'custom-input-disabled ' + inputClassName
            if (!!value) {
                labelClass = labelClass + ' outlined-custom-input-label-valued-disabled'
            }
        } else {
            if (isFocused) {
                labelClass = 'outlined-custom-input-label outlined-custom-input-label-focused'
                inputClassName = 'custom-input custom-input-focused'
            } else if (isActive) {
                labelClass = 'outlined-custom-input-label outlined-custom-input-label-active'
                inputClassName = 'custom-input custom-input-active'
            }
        }

        if (isErr) {
            labelClass = labelClass + ' outlined-custom-input-label-err'
            inputClassName = inputClassName + ' custom-input-err'
        }

        if (!isActive && !isFocused){
            placeholder = ''
        }
        return (
            <div className={'outlined-custom-input-container'}>
                <div className={labelClass}>{label}</div>
                {/*<div className={selectClass} onBlur={() => this.setState({isFocused: false})}*/}
                {/*     onClick={() => this.setState({isFocused: true})}>*/}
                {/*</div>*/}
                <input onClick={() => this.setState({isFocused: true})} onBlur={() => this.setState({isFocused: false})}
                       ref={ref => this.inputRef = ref} defaultValue={defaultValue} type={type}
                       className={inputClassName} placeholder={placeholder} name={name} disabled={disabled}
                       id={'mhd-outlined-input-container'} required={required}
                       onChange={event => onChange(event.target)}/>
                {
                    isErr &&
                    <div className={'outlined-error'}>{errMessage}</div>
                }
            </div>

        );
    }
}

OutlinedInput.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    isErr: PropTypes.bool,
    errMessage: PropTypes.string,
    defaultValue: PropTypes.string,
};

export default OutlinedInput
