import React, {Component} from "react";
import VideoPlayer from "../../../../../media/VideoPlayer";
import MakeVideo from "../../../../../modal/VideoModal";

class EarsExam extends Component {

    constructor(props) {
        super(props);
    }

    async openFullScreen(url) {
        await MakeVideo({url})
    }

    render() {
        let {files,t} = this.props
        let language= localStorage.getItem('language')
        let lFile = files.find(file => String(file['subType']).toLowerCase() === 'left')
        let rFile = files.find(file => String(file['subType']).toLowerCase() === 'right')
        return (
            <div className={'exam-result-container ears-exam-container'}>
                <div className={'ears-exam-results-container'}>

                    <div className={'ears-exam-images-container h-centered'}>
                        {
                            !!lFile &&
                            <VideoPlayer fileId={lFile['fileID']} openFullScreen={() => this.openFullScreen(lFile['downloadUrl'])}
                                         className={'ears-video'} title={language === 'en'? lFile['subType'] :'Izquierdo'}
                                         url={lFile['downloadUrl']}/>
                        }
                        {
                            !!rFile &&
                            <VideoPlayer fileId={rFile['fileID']} openFullScreen={() => this.openFullScreen(rFile['downloadUrl'])}
                                         className={'ears-video'} title={language === 'en'? rFile['subType'] :'Derecho'}
                                         url={rFile['downloadUrl']}/>
                        }
                        {
                            files.length === 0 &&
                            <div className={'Error-Files h-centered'}>{t('physician.patient.exams.fileErr1')}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}

export default EarsExam
