import utils from "../../../utils/utils";
import './patientStyle/main.css'
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../../utils/useMediaQuery";
import SVGraphics from "../../../assets/SVGraphics";
import {getExamValue} from "./exams/getExamsValue";
import {Exams} from "./exams/Exams";
import NewVVideoModal from "./NewVideoModal";
import toTop from "../../../assets/btn_back_to_top.png"
import Config from "../../../config/Config";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {TechInfoModal} from "./TechInfoModal";
import {Login} from "../../Login";


export function PatientNewMain(props) {

    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 550px)');
    const isExtraSmall = useMediaQuery('(min-width:551px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mediumScreen = useMediaQuery('(min-width:1126px) and (max-width: 1439px)');
    const largeScreen = useMediaQuery('(min-width:1440px) and (max-width: 1600px)');
    const extraLargeScreen = useMediaQuery('(min-width:1601px) and (max-width: 1918px)');
    const [isNotes, setIsNotes] = useState(false);
    // const [isDescriptionCard, setIsDescriptionCard] = useState(false);
    const [video, setVideo] = useState(null);
    const [isVideo, setIsVideo] = useState(false);
    const [enlargePatientVideo, setEnlargePatientVideo] = useState(false);
    const [isPrescriptionRequired, setIsPrescriptionRequired] = useState(false);
    const [prescription, setPrescription] = useState(null);
    const [playedFileId, setPlayedFileId] = useState(null);
    const [point, setPoint] = useState(null);
    const [playedFile, setPlayedFile] = useState(null);
    const [playedType, setPlayedType] = useState(null);
    const [isTechInfo, setIsTechInfo] = useState(false);

    const [techInfo, setTechInfo] = useState(null);


        const [duration, setDuration] = useState(0);

        const handleLoadedMetadata = (event) => {
            const { duration } = event.target;
            setDuration(duration);

        };
    const mobileTablet = isSmallScreen || isMobilScreen

    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

    function isObjEmpty (obj) {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        setTechInfo(props.exam.SessionTechInfo)
        setVideo(props.patientVideo)
        setIsVideo(props.patientVideo?true:false)
        // setIsDescriptionCard(!isVideo && !props.exam.description?false:true)
        if(props.exam.oximRxRequired){
            setIsPrescriptionRequired(true)
        }
        setPrescription(props.exam['UserOximPrescription'])
        // let videoElement = document.getElementById('mainComplainVideo')
        // if(videoElement){
        //     videoElement.load()
        // }
    },[props.patientVideo])
    useEffect(() => {
        if(props.scrollElement){
            const element = document.getElementById(props.scrollElement);
            element?.scrollIntoView({  behavior: "smooth", block: 'start' });
            // window.scrollBy(0, -10);
        }
        props.setScrollNone()

    },[props.scrollElement])

    // const handleSeekChange = (e) => {
    //     if(!(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)) {
    //
    //         this.setState({played: parseFloat(e.target.value)})}
    //     else {
    //
    //         let currentSeconds =  Math.floor(document.getElementById(`videoPlayer+${this.props.title}`).currentTime % 60);
    //         let played = 0+"."+currentSeconds ;
    //
    //
    //         this.setState({played})
    //     }
    // }
    const scrollToTop = () => {
        const scrollableElement = document.getElementById('scrollable-element');
        // console.log()
        // scrollableElement?.scrollIntoView({ behavior: "smooth", block: 'start' });
        // scrollableElement?.scrollIntoView({ behavior: "smooth", block: 'start' });
        scrollableElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const openTechInfo = () => {
        if(props.exam.exams.length>0){
            if(!mobileTablet){
                setIsTechInfo(!isTechInfo)
            }else{
                props.openTechInfo()
            }

        }
    }
    const closeTechInfo = () => {
        setIsTechInfo(false)
    }

    const getPatientDescription = () => {
        return <div className={`main-con-description-text ${mediumScreen || isMobilScreen || isExtraSmall ? 'main-con-description-text-medium' : ''}`}>
            <div className={'description-title'}>Summary</div>
            <div className={'description-value'}>{props.exam.description}</div>
            <div className={'description-title'}>Details </div>
            <div className={'description-value'}>{props.exam.conditionDetails?props.exam.conditionDetails:'-'}</div>
            <div className={'description-title'}>Severity on a scale from 1 (low) to 10 (high) </div>
            <div className={'description-value'}>{props.exam.severity?props.exam.severity:'-'}</div>
        </div>
    }

    return (
        <div id={'scrollable-element'} className={`main-container ${isExtraSmall ? 'main-con-extraSmall' : isSmallScreen || mobileTablet ? 'main-con-tablet' : 'main-con'}`}>
          {/*${extraLargeScreen?'main-con-extraLarge':largeScreen? 'main-con-large'  :*/}
            <div className={'stickMain'} style={{position:mobileTablet?'sticky':'fixed'}}></div>
            <div className={'main-container-sub'}>
                {props.exam && props.exam['examStartTime'] &&
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%',alignItems:'center',paddingBottom:'2rem'}}>
                    <div style={{display: 'flex' ,alignItems:'center'}}>
                    <div className={'main-date'}>{utils.getDateStringWithYear(new Date(props.exam.examStartTime))}</div>
                    <SVGraphics style={{marginTop:'0.75rem',marginLeft:'0.75rem',opacity:props.exam && props.exam.exams.length===0?'0.4':'1'}} svgname={isTechInfo?'purple-settings':'black-settings'} width={'32px'} onClick={()=>openTechInfo()} />
                    </div>

                    {isTechInfo && !mobileTablet &&
                                <div className={'modal-background'}>
                                    <TechInfoModal
                                        exam={props.exam}
                                        closeTechInfo={() => closeTechInfo()}
                                    />
                                </div>
                    }

                    {/*<button className={'notes-copyNotes-button '} onClick={() => props.pastExams()}>*/}
                    {/*    <div >Past Exams</div>*/}
                    {/*</button>*/}
                    {/*{!props.isUserLoggedIn &&*/}
                    {/*    <div onClick={()=>setIsOpenLogin(true)}>Login as physician</div>*/}
                    {/*}*/}

                    <div className={'navigationButtons'}>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{show: 250, hide: 300}}
                            overlay={<Tooltip id="prev" {...props}>
                               Previous Exam
                            </Tooltip>}>
                            <span><SVGraphics className={'navigation-svg'} svgname={'prev-circle'} onClick={()=>props.navigate('prev')} style={{opacity:props.examPosition==='first'?'0.5':'1'}}/></span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{show: 250, hide: 300}}
                            overlay={<Tooltip id="next" {...props}>
                                Next Exam
                            </Tooltip>}>
                        <span><SVGraphics className={'navigation-svg'} svgname={'next-circle'} onClick={()=>props.navigate('next')} style={{opacity:props.examPosition==='last'?'0.5':'1'}}/></span>
                        </OverlayTrigger>
                        </div>
                </div>
                }
                {!isObjEmpty(props.exam) &&
                <div
                    className={`main-card main-con-description ${mediumScreen || mobileTablet ? 'main-con-description-medium' : ''}`}>
                    <div className={'main-con-title-row'}>
                        <div className={'main-con-title'}>Condition Description:</div>

                        <div
                            className={'main-con-date-time'}>{`${utils.getDateStringWithYear(new Date(props.exam.examStartTime))} | ${utils.formatAMPM(new Date(props.exam.examStartTime))}`}</div>

                    </div>

                    <div
                        className={`main-con-description-body ${mediumScreen || isMobilScreen || isExtraSmall ? 'main-con-description-body-medium' : ''}`}
                        style={{gridTemplateColumns:props.isUserLoggedIn?'auto auto':'40% 60%'}}
                    >
                        {isVideo &&
                        <div className={'main-con-video-div'}>
                            {isSafari ?
                                <video className={'main-con-video'}
                                       id={'mainComplainVideo'}
                                    // src={video?.downloadUrl}
                                    // type="video/mp4"
                                    // onSeeking={(e) => handleSeekChange(e)}
                                    // onSeeked={(e) => handleSeekChange(e)}
                                       playsInline
                                       preload="metadata"
                                    // muted
                                       webkit-playsinline
                                       controls
                                       disablePictureInPicture
                                       controlslist="nodownload noremoteplayback noplaybackrate nofullscreen "

                                >
                                    <source src={props.patientVideo?.downloadUrl + '#t=0.001'} type="video/mp4"/>
                                    {/*{video?.downloadUrl? <source src={video?.downloadUrl+'#t=0.001'} type="video/mp4"/>:*/}
                                    {/*    <source src={props.patientVideo.downloadUrl+'#t=0.001'} type="video/mp4"/>*/}
                                    {/*}*/}

                                </video>
                                :
                                <video className={'main-con-video'}

                                    // type="video/mp4"
                                       id={'mainComplainVideo'}
                                       controls
                                       playsInline
                                       webkit-playsinline
                                       onLoadedMetadata={handleLoadedMetadata}
                                       preload="auto"
                                       disablePictureInPicture
                                       controlslist="nodownload noremoteplayback noplaybackrate nofullscreen "
                                >
                                    <source  src={video?.downloadUrl + '#t=0.001'} />
                                </video>
                                    }
                            <SVGraphics svgname={'fullscreen-square-white'}
                                        className={'main-video-enlarge-button'}
                                        onClick={() => setEnlargePatientVideo(true)}

                                // onClick={this.handleClickFullscreen}
                                // style={fullScreenStyle}
                                // className={fullscreenClass}
                            />
                        </div>
                        }
                        {
                            props.exam.description &&
                            getPatientDescription()

                        }
                        {!isVideo && !props.exam.description &&
                        <div className={'main-con-no-description'}>No Description</div>

                        }
                    </div>


                </div>
                }

                <div
                    className={`main-top-exams ${mediumScreen || isMobilScreen || isExtraSmall? 'main-top-exams-mobile' : isSmallScreen ? 'main-top-exams-small' : ''} `}>
                    {
                        props.exam?.SessionExams?.map(sessionExam => {
                            if (sessionExam.exam === 'Body_Temperature' || sessionExam.exam === 'Saturation' || sessionExam.exam === 'BloodPressure') {
                                return <div key={sessionExam.exam} id={sessionExam.exam} className={'main-card main-top-exams-exam-card'}>
                                    <div className={'main-top-exams-exam-first-row'}>
                                        <div className={'main-top-exams-title-container'} style={{width:sessionExam.exam==="Saturation"?'auto':'75%'}} >
                                            <div className={`main-top-exams-icon `} style={{width:"1.5rem", height:"1.5rem"}}>
                                                <SVGraphics svgname={sessionExam.exam} width={"1.5rem"} height={"1.5rem"}/>
                                            </div>
                                            <div className={'main-top-exams-examTitle'}
                                                 style={{color: "#2E2E2E",width:'100%'}}>{
                                                     // sessionExam.exam === 'Saturation'?<span >Heart rate & <br/>Oxygen level</span> :
                                                         utils.getExamLabel(sessionExam.exam)}</div>
                                        </div>
                                    </div>
                                    <div
                                        className={'main-top-exams-exam-value'}>{props.exam && getExamValue(sessionExam.exam, props.exam, "main")}</div>
                                    <div className={`main-top-exams-exam-date ${sessionExam.exam === 'Saturation' && isPrescriptionRequired  &&'main-top-exams-exam-date-prescription'}`}>{`${utils.getDateStringWithoutYear(new Date(sessionExam.examTime))}, ${utils.formatAMPM(new Date(sessionExam.examTime))}`}
                                        { sessionExam.exam === 'Saturation' && isPrescriptionRequired  &&
                                        <div className="row">
                                            <div className="col patient-personal-data-holder"
                                                 style={{width: '40%', maxWidth: 'fit-content'}}>
                                                {/*<div*/}
                                                {/*    className={'patient-personal-label col1'}>''</div>*/}
                                                <div className={'patient-personal-value'}>{prescription ?
                                                    <span className={'prescription-required'}> <a style={{textDecoration: 'none',color:'#7B5EC6'}}
                                                                                                  href={prescription['fileUrl']} target="_blank" rel="noopener noreferrer">  <SVGraphics
                                                        width={"0.9688rem"} height={"0.9688rem"}
                                                        svgname={'eye-purple'}/> Prescription</a></span>
                                                    : <span className={'prescription-required'}>
                                                <SVGraphics
                                                    width={"1.5rem"} height={"1.5rem"}
                                                    svgname={'error-purple'}/>
                                                Prescription Required</span>}</div>
                                            </div>

                                        </div>
                                        }
                                    </div>
                                </div>
                            }
                        })
                    }

                </div>
                <div className={'main-bottom-exams'}>
                    {
                        props.exam?.SessionExams?.map(sessionExam => {
                            if (sessionExam.exam !== 'Body_Temperature' && sessionExam.exam !== 'Saturation' && sessionExam.exam !== 'BloodPressure'&& sessionExam.exam !== 'Heart_Rate') {
                                return <div key={sessionExam.exam} id={sessionExam.exam} className={'main-card main-bottom-exams-exam-card'}>
                                    <div className={'main-bottom-exams-exam-first-row'}>
                                        <div className={'main-bottom-exams-title-container '}>
                                            <div className={`main-top-exams-icon `}>
                                                <SVGraphics svgname={sessionExam.exam} width={'100%'}/>
                                            </div>
                                            <div className={'main-top-exams-examTitle '}
                                                 style={{color: "#2E2E2E" ,width:'82%', fontSize:'1.025rem'}}>{utils.getExamLabel(sessionExam.exam)}</div>
                                        </div>
                                        {(sessionExam.exam==='Heart' || sessionExam.exam==='Abdomen' || sessionExam.exam==='Lungs') && !mobileTablet ?
                                        <div className={'useHeadPhones'}><SVGraphics svgname={'headphone'} width={18} height={18}/> Use headphones to listen to the recording</div>:""}
                                        <div className={'main-top-exams-exam-date main-bottom-exams-exam-date'}>{`${utils.getDateStringWithoutYear(new Date(sessionExam.examTime))}, ${utils.formatAMPM(new Date(sessionExam.examTime))}`}</div>
                                    </div>
                                    {(sessionExam.exam==='Heart' || sessionExam.exam==='Abdomen' || sessionExam.exam==='Lungs') && mobileTablet ?
                                    <div className={'useHeadPhones'} style={{padding:'4px 2px 4px 8px',gap:'2px',marginTop:'0.5rem',marginBottom:'0.5rem'}}><SVGraphics svgname={'headphone'} width={18} height={18}/> Use headphones to listen to the recording</div>:""}
                                    <div >
                                        <Exams
                                            setPlayedFileId={setPlayedFileId}
                                            setPoint={setPoint}
                                            setPlayedFile={setPlayedFile}
                                            setPlayedType={setPlayedType}
                                          playedFileId={playedFileId}
                                          playedFile={playedFile}
                                            playedType={playedType}
                                            isUserLoggedIn={props.isUserLoggedIn}
                                            point={point}
                                            addScreenshot={props.addScreenshot}

                                            age={props.age} examName={sessionExam.exam} exam={props.exam} gender={props.gender}/>
                                    </div>
                                </div>
                            }
                        })
                    }
                </div>
                <div >Beta {Config.version} ({Config.MHDVersion})</div>
            </div>
            {enlargePatientVideo &&
                <NewVVideoModal url={video?.downloadUrl}
                                title={'Main Complaints'}
                                show={enlargePatientVideo}
                                file={video}
                                proceed={()=>setEnlargePatientVideo(false)}

                />
            }
            {!mobileTablet &&
                <img alt={'back to top'} src={toTop} className={'back-to-top-button'} onClick={()=>scrollToTop()}/>
            }


        </div>
    )
}
