import React, {Component} from "react";
import '../../style/component/forms_builder/settings_Builder.css'
import SVGraphics from "../../assets/SVGraphics";
import OutlinedSelect from "../../component/input/OutlinedSelect";
import OutlinedInput from "../../component/input/OutlinedInput";
import avatar from '../../assets/p-avatar.png'
import PropTypes from "prop-types";
import OutlinedDateSelect from "../input/OutlinedDateSelect";
import MultiSelect from "../input/MultiSelect";
import 'react-phone-number-input/style.css'
import { Country, State, City }  from 'country-state-city';
import OutlinedPhoneInput from "../input/OutlinedPhoneInput";
import Switch from "../input/Switch";
import moment from "moment";
// import CurrencyTextField from '@unicef/material-ui-currency-textfield'


class Setting_Builder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
            fields: this.props.fields,
            errors: this.props.errors,
            profileImg: avatar,
        }
    }

    buildForm = () => {
        let inputClassName = 'form-input-container'


        let columns = []
        let matrix = this.props.matrix;
        let rows = []
        let index = 0

        matrix.forEach(column_count => {
            if (this.props.fields[index].type === 'img' || this.props.fields[index].type === 'edit-img' || this.props.fields[index].type === 'add-img') {
                let imgColumn = (
                    <div className={'form-column image-column'}>
                        {this.getInput(this.props.fields[index])}
                    </div>
                )
                index += 1
                columns.push(imgColumn);
            } else {
                for (let i = 0; i < column_count; i++) {
                    if (this.props.fields[index]) {
                        rows.push(
                            <div className={this.props.fields[index].className}>
                                {this.getInput(this.props.fields[index])}
                            </div>
                        )
                        index += 1
                    }
                }
                let column = (
                    <div className={'form-column'}>
                        {rows}
                    </div>
                )
                rows = []
                columns.push(column);
            }

        })
        return columns
    }
    imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({profileImg: reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }


    getInput(input_data) {
        let {profileImg} = this.state;
        let type = input_data.type
        let placeholder = input_data.placeholder
        let i_name = input_data.name
        let dis = input_data.disabled
        let isSymbol =input_data.isSymbol
        switch (type) {
            case 'text':
                return (
                    <OutlinedInput
                        disabled={input_data.disabled}
                        defaultValue={this.props.entry[i_name]}
                        value={this.props.entry[i_name]}
                        type={"text"}
                        name={i_name}
                        isErr={!!this.props.errors[i_name]}
                        errMessage={this.props.errors[i_name]}
                        className={input_data.className}
                        label={input_data.label}
                        placeholder={input_data.placeholder}
                        onChange={(event) => this.props.onChange(event)}
                    />
                )
            case 'number':
                return (
                    <OutlinedInput
                        disabled={input_data.disabled}
                        // defaultValue={this.props.entry[i_name]}
                        value={this.props.entry[i_name]}
                        type={"number"}
                        min={input_data.min}
                        step={1}
                        max={input_data.max}
                        name={i_name}
                        isErr={!!this.props.errors[i_name]}
                        errMessage={this.props.errors[i_name]}
                        className={input_data.className}
                        label={input_data.label}
                        placeholder={input_data.placeholder}
                        onChange={(event) => this.props.onChange(event)}
                    />
                )
            // case 'currency':
            //     let value = this.props.entry[i_name]
            //
            //     return (
            //         <CurrencyTextField
            //             disabled={input_data.disabled}
            //             label={input_data.label}
            //             variant="outlined"
            //             value={this.props.entry[i_name]}
            //             currencySymbol="$"
            //             name={i_name}
            //             textAlign={'left'}
            //             maximumValue={10000}
            //             minimumValue={input_data.minimumValue}
            //             outputFormat={'string'}
            //             className={input_data.className}
            //             placeholder={input_data.placeholder}
            //             onChange={(e,value) => this.props.onChange2(i_name,value)}
            //         />
            //     )
            case 'simple-text':
                return (
                    <div className={input_data.className}>{input_data.label}: {this.props.entry[i_name]}</div>
                )

            case 'select':
                return (
                    <OutlinedSelect label={input_data.label}
                                    name={i_name}
                                    type={"select"}
                                    isErr={!!this.props.errors[i_name]}
                                    errMessage={this.props.errors[i_name]}
                                    disabled={input_data.disabled}
                                    options={input_data.options}
                                    selected={!!this.props.entry[i_name]&&this.props.entry[i_name].toString()}
                                    defaultValue={this.props.entry[i_name]}
                                    onSelect={val => this.props.onChange({name: i_name, value: val})}/>
                )
            case 'country':
                return (
                    <OutlinedSelect label={input_data.label}
                                    name={i_name}
                                    type={"select"}
                                    isErr={!!this.props.errors[i_name]}
                                    errMessage={this.props.errors[i_name]}
                                    disabled={input_data.disabled}
                                    options={(Country.getAllCountries()).map(country => { return {label: country.name, value: country.isoCode} })}
                                    selected={this.props.entry[i_name]}
                                    defaultValue={this.props.entry[i_name]}
                                    onSelect={val => this.props.onChange({name: i_name, value: val})}/>
                )
            case 'state':
                return (
                    <OutlinedSelect label={input_data.label}
                                    name={i_name}
                                    type={"select"}
                                    isErr={!!this.props.errors[i_name]}
                                    errMessage={this.props.errors[i_name]}
                                    disabled={State.getStatesOfCountry(this.props.entry['country']).length === 0}
                                    options={State.getStatesOfCountry(this.props.entry['country']).map(state => { return {label: state.name, value: state.isoCode} })}
                                    selected={this.props.entry[i_name] ? this.props.entry[i_name] : null}
                                    defaultValue={this.props.entry[i_name] ? this.props.entry[i_name] : null}
                                    onSelect={val => this.props.onChange({name: i_name, value: val})}/>
                )
            case 'img':
                return (
                    <div className={input_data.className}>
                        <input type={"file"} id={"file"} className={"input-imgEdit"}
                               onChange={(event) => this.props.imageHandler(event)}/>
                        <img src={this.props.profileImg} alt={""} id={"img"} className={"form-profile-logo"}/>
                    </div>

                )
            case 'edit-img':
                return (
                    <div className={input_data.className}>
                        <label htmlFor={"file"}>
                            <SVGraphics svgname={'edit-image'}
                                        className={'admin-profilePic'}/>
                        </label>
                        <input type={"file"} id={"file"} className={"input-imgEdit"}
                               onChange={(event) => this.props.imageHandler(event)}/>
                        <img src={this.props.profileImg} alt={""} id={"img"} className={"form-profile-img"}/>
                    </div>

                )
            case 'add-img':
                return (
                    <div className={input_data.className}>
                        <label htmlFor={"file"}>
                            <SVGraphics svgname={'add-img'}
                                        className={'admin-profilePic'}/>
                        </label>
                        <input type={"file"} id={"file"} className={"input-imgEdit"}
                               onChange={(event) => this.props.imageHandler(event)}/>
                        <img src={this.props.profileImg} alt={""} id={"img"} className={"form-profile-img"}/>
                    </div>

                )
            case 'date':
                return (
                    <OutlinedDateSelect label={input_data.label}
                                        name={i_name}
                                        type={"date"}
                                        allowPast={true}
                                        allowFuture={false}
                                        yearJump={true}
                                        selected = {this.props.entry[i_name]}
                                        errMessage={this.props.errors[i_name]}
                                        disabled={input_data.disabled}
                                        isErr={!!this.props.errors[i_name]}
                                        isOutsideRange ={day => day.isAfter(moment())}
                                        onSelect={val => this.props.onChange({name: i_name, value: val})}/>

                )
            case 'multiSelect':
                return (
                    <MultiSelect values={this.props.selected}
                                 options={input_data.options}
                                 type = {"multiSelect"}
                                 name={i_name}
                                 limitDrop={this.props.limitDrop}
                                 dropDownDisabled={input_data.disabled}
                                 selected = {this.props.entry[i_name]}
                                 className={input_data.className}
                                 label={input_data.label}
                                 placeholder={input_data.placeholder}
                                 errMessage={this.props.errors[i_name]}
                                 isErr={!!this.props.errors[i_name]}
                                 onChange={this.props.onChangeMulti}

                    />

                )
            case 'multiSelectPhysician':
                return (
                    <MultiSelect values={this.props.selectedPhysicians}
                                 options={input_data.options}
                                 type = {"multiSelect"}
                                 name={i_name}
                                 selected = {this.props.entry[i_name]}
                                 className={input_data.className}
                                 label={input_data.label}
                                 placeholder={input_data.placeholder}
                                 errMessage={this.props.errors[i_name]}
                                 isErr={!!this.props.errors[i_name]}
                                 onChange={this.props.onChangeMultiPhysician}


                    />

                )
            case 'label':
                return (
                    <div onClick={()=>this.props.onClick()} className={this.props.className}>
                        {input_data.label}
                    </div>
                )
            case 'switch':
                return (
                    <div  className={this.props.className} style={{display:'flex',alignItems:'center',justifyContent:'space-between',color:' rgba(76, 35, 181, 0.5)'}}>
                        {input_data.label}
                        <Switch offLabel={'off'} onLabel={'on'} onChange={(e) => this.props.oneToMany(e)} name={i_name} checked={this.props.entry[i_name]}/>
                    </div>
                )
            case 'phoneNumber':
                return (
                    <OutlinedPhoneInput
                        country={"US"}
                        defaultCountry ={"US"}
                        autoFormat={true}
                        values={this.props.selected}
                        type={'phoneNumber'}
                        label={input_data.label}
                        // dropdownClass={input_data.className}
                        disabled={input_data.disabled}
                        defaultValue={this.props.entry[i_name]}
                        value={this.props.entry[i_name]}
                        name={i_name}
                        international={false}
                        withCountryCallingCode={true}
                        isErr={!!this.props.errors[i_name]}
                        errMessage={this.props.errors[i_name]}
                        // containerClass={input_data.className}
                        // inputClass={input_data.className}
                        tabIndex={input_data.label}
                        placeholder={input_data.placeholder}
                        onChange={val => this.props.onChange({name: i_name, value: val})}

                    />
                )

            default:
                return null
        }
    }

    render() {

        return (
            this.props.fields ?
                <div className={`form-container ${this.props.formContainerClass}`}>
                    {this.buildForm()}
                </div>
                : <div/>

        );
    }

}

Setting_Builder.propTypes = {
    entry: PropTypes.object.isRequired,
    matrix: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    imageHandler: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export default Setting_Builder;
