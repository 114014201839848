import React from "react";
import utils from "./utils";

class validationHelper {

    /**
     * If the path is a string, convert it to an array
     * @param  {Object} currentSettings
     * @param  {Object} parrentSettings
     * @param  {Array} changedFields
     * @return {Object}
     */





    validate = async (profile, validationSchema) => {
        let errors = {};

        if (utils.isEmpty(validationSchema))
            return errors;
        await validationSchema.validate(profile, {abortEarly: false}).catch(function (err) {
            err.inner.forEach((error) => {
                utils.set(errors, error.path, error.errors)
            });
        });

        return errors;
    };

}

export default new validationHelper();
