import React, {Component} from "react";
import Select, {components} from 'react-select';
import '../../style/component/input/outlinedSelect.css'
import SVGraphics from "../../assets/SVGraphics";
import PhoneInput from "react-phone-number-input";

// const options = [
//     {label: "fsgtest", value: "fsgtest"},
//     {label: "445test2", value: "445test2"},
//     {label: "t34est3", value: "t34est3"},
//     {label: "test43", value: "test43"},
//     {label: "3test2", value: "3test2"},
//     {label: "fsgtest", value: "fsgtest"},
//     {label: "445test2", value: "445test2"},
//     {label: "t34est3", value: "t34est3"},
//     {label: "test43", value: "test43"},
//     {label: "3test2", value: "3test2"},
//     {label: "dtest3", value: "dtest3"}
// ]
//
// const options2 = [
//     {label: {name:"test",id:"123"}, value: "test"},
//     {label: {name:"test2",id:"456"}, value: "test2"},
//     {label: {name:"test2",id:"789"}, value: "test2"},
// ]

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SVGraphics svgname={'glass'}
                        className={'SearchInput-DropdownIndicator v-centered'}/>
        </components.DropdownIndicator>
    );
};

class OutlinedPhoneInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            text: null,
            options: [],
            isFocused: false,
            isErr: false
        };
    }

    handleInputChange = text => {
        this.setState({text})
        // this.setState({selectedOption: null})
    };

    handleChange = async event => {

        this.props.onChange(event)
        // this.setState({selectedOption/*,isFocused: false*/})
    };

    filterOption = (option, inputValue) => {
        let names = String(option.data.label).toLowerCase().match(inputValue ? inputValue.toLowerCase() : "")
        return (names || []).length > 0
    }

    CustomOption = (props) => {
        let {label, value, isSelected, innerProps} = props
        return <div className={'select-option'}>
            <div className={'select-option-container pointer'} key={value} onClick={() => innerProps.onClick()}>
                <div className={'select-option-label-name'}>{label.name}</div>
                <div className={'select-option-label-id'}>{label.id}</div>
            </div>
        </div>
    }

    render() {
        let {text, isFocused,} = this.state;
        let {options, disabled, label, isErr, errMessage,selected,className,defaultValue,value} = this.props

        let isActive = !!value && !isFocused
        let labelClass = 'outlined-select-label outlined-select-labelPhone'
        let selectClass = 'outlined-PhoneInput outlined-select-default'
        let selectInputClass = 'OutLinedSelect'
        if (!!className) {
            selectClass = selectClass + 'outlined-PhoneInput outlined-select-default' + className
            labelClass = labelClass + 'outlined-select-label outlined-select-label-default' + className
            selectInputClass = selectInputClass + 'OutLinedSelect' + className
        }
        if (disabled) {
            labelClass = 'outlined-custom-input-labelPhone outlined-select-labelPhone-focused'
            selectClass = selectClass
            selectInputClass =  selectInputClass
        } else {
            if (isFocused) {
                labelClass = 'outlined-custom-input-labelPhone outlined-select-labelPhone-focused'
                selectClass = 'outlined-PhoneInput outlined-select-focusedPhone'

            }
            else if (isActive) {
                labelClass = 'outlined-custom-input-labelPhone outlined-custom-input-labelPhone-active'
                selectClass = 'outlined-PhoneInput custom-input-active'
            }
        }

        if (isErr) {
            labelClass = labelClass + ' outlined-select-label-err'
            selectClass = selectClass + ' outlined-select-err'
            selectInputClass = 'OutLinedSelect-err ' + selectInputClass
        }


        return (
            <div className={'outlined-select-container'}>
                <div className={labelClass}>{label}</div>
                <div className={selectClass}>
                    <PhoneInput
                        country={"US"}
                        defaultCountry ={"US"}
                        autoFormat={true}
                        values={value}
                        type={'phoneNumber'}
                        ref={ref => this.inputRef = ref}
                        // dropdownClass={input_data.className}
                        isDisabled={this.props.disabled}
                        disabled={this.props.disabled}
                        defaultValue={defaultValue}
                        value={value}
                        international={false}
                        onChange={event => this.props.onChange(event)}
                        onFocus={(event) => {
                            this.setState({isFocused: true})
                        }}

                        onBlur={(event) => {
                            this.setState({isFocused: false})
                        }}
                        withCountryCallingCode={true}
                        // containerClass={input_data.className}
                        onClick={() => this.setState({isFocused: true})}
                        inputClass={selectInputClass}
                        tabIndex={this.props.tabIndex}
                        placeholder={this.props.placeholder}

                    />
                </div>
                {
                    isErr &&
                    <div className={'outlined-error'}>{errMessage}</div>
                }
            </div>

        );
    }

}

export default OutlinedPhoneInput
