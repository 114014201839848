//PastExams
import React, {useEffect, useState} from "react";
import MHDCustomTable from "../../../component/table/MHDCustomTable";
import utils from "../../../utils/utils";
import SVGraphics from "../../../assets/SVGraphics";
import {history} from "../../../utils/history";
import queryString from "query-string";
import doctorApi from "../../../services/ApiServices/doctorApi/doctorApi";
import moment from "moment";
import './patientStyle/pastExams.css'
import useMediaQuery from "../../../utils/useMediaQuery";
import {Skeleton, Stack} from "@mui/material";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export function PastExams(props) {
    let diffParams = []
    const exmasArr = ["Body_Temperature", "Saturation", "Heart_Rate", "Heart", "Abdomen", "Lungs", "Ears", "Throat", "Skin"]
    const isMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 550px)');
    const isExtraSmall = useMediaQuery('(min-width:551px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const mobileTablet = isSmallScreen || isMobilScreen
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // let Exams = await doctorApi.getPatientSessionsById(pid,tenant,true, accessToken, asrAuth)
    useEffect( () => {
        //    let mobilePatient =[{idNumber:props.patient.idNumber,tenantName:props.patient.tenantName,age:props.patient.age },
        //        {height:props.patient.height,weight:props.patient.weight,gender:props.patient.gender,birthday:props.patient.birthday }
        //    ]
        // setPatientMobile(mobilePatient)
        let search = !history.location.search || history.location.search === {} ? window.location.search : history.location.search
        let query = queryString.parse(search);
        let pid = query.pid;
        let tenant = query.tenant;
        let accessToken = (query.accessToken).split(" ").join("+")
        let asrAuth = query.asrAuth

        const getData = async() =>{
            let exams = await doctorApi.getPatientSessionsById(pid,tenant,true, accessToken, asrAuth)
            if(exams){
                setSessions(exams)
                setIsLoading(false)
            }
        }
        getData().then()


    },[])

    const getColumns = () => {

        return [
            {
                accessor: 'examTime', Header: 'Date Taken', resizable: false, Cell: ({original}) => {
                    return (
                        // utils.getDateAndTime(new Date(original['examTime']))
                        moment(new Date(original['examTime'])).format('DD.MM.yyyy')
                    );
                }/*, width:150*/
            },
            {accessor: '', Header: 'Time Duration', resizable: false/*, width:150*/,Cell: ({original}) => {
                let startTime =  moment(new Date(original['examStartTime'])).format('hh:mm')
                let endTime =  moment(new Date(original['examEndTime'])).format('hh:mm A')
                    let time =`${startTime}-${endTime}`
                    return (
                        // utils.getDateAndTime(new Date(original['examTime']))
                        time
                    );
                }/*, width:150*/},
            {accessor: 'videoSessionStatus', Header: 'Session Type', resizable: false,Cell: ({original}) => {
                    return (
                        `${original.videoSessionStatus==='Offline'?'A-sync':'Live Session'}`
                    )
                }},
            {accessor: 'Physician.physicianID', Header: 'Assigned To', width:185 ,resizable: false,Cell: ({original}) => {
                return (<OverlayTrigger
                        placement="bottom"
                        delay={{show: 250}}
                        overlay={<Tooltip >
                            {`${original.Physician.PII?.firstName} ${original.Physician.PII?.lastName} ${original.Physician?.jobTitle?original.Physician?.jobTitle:''} `}
                        </Tooltip>}><span>{`${original.Physician.PII?.firstName} ${original.Physician.PII?.lastName}, ${original.Physician?.jobTitle?original.Physician?.jobTitle:''} `} </span>
                    </OverlayTrigger>

                )
            }
            },
            {accessor: 'reviewTime', Header: 'Reviewed At', resizable: false,Cell: ({original}) => {
                return (
                    <div>{ original.reviewTime? moment(new Date(original.reviewTime)).format('DD.MM.yyyy'):''}</div>)
            }
            },
            {accessor: 'Assessment', Header: 'Assessment', resizable: false,Cell: ({original}) => {
                return (
                    <div className={`past-exams-summary ${!original['Summary']?'past-exams-summary-missing':''}`}>
                        {`${original['Assessment']?original['Assessment']:'Missing Summary'}`}
                    </div>
                )
            }
            },
            {
                accessor: 'examsLength', Header:'Exams', resizable: false, sortable: false,Cell: ({original}) => {
                    let exams = original.exams
                    exams = exams.sort(function (a, b) {
                        return exmasArr.indexOf(a) - exmasArr.indexOf(b);
                    });
                    return <div className={'session-history-exam-icons'}>
                        {
                            exams.map(exam => {
                                if (exam !== 'Heart_Rate') {
                                    return <SVGraphics key={exam} svgname={exam} className={'session-history-exam-icon'}/>
                                }
                            })
                        }
                    </div>
                }, minWidth: 150
            },
            // {
            //     accessor: 'hasSummery', Header: '', resizable: false, width:200,Cell: ({original}) => {
            //         if (original.hasSummery === 1) {
            //             return <div className={'mhd-color'}>{t('physician.inbox.updated')}</div>
            //         } else if (original.hasSummery === 0) {
            //             return <div className={'red'}>{t('physician.inbox.notSent')}</div>
            //         } else if (original.hasSummery === -1) {
            //             return <div className={'red'}>{t('physician.inbox.missing')}</div>
            //         }
            //     }
            // },

            // {accessor: 'status', Header: '', resizable: false,width:150, Cell: ({original}) => {
            //         return(
            //             <button className={'mhd-btn btn physician-dashboard-add-meeting-btn h-centered'} onClick={(e)=>this.AssignPhysician(e,original.sessionID)}>{t('physician.inbox.assignToMe')}</button>
            //         )
            //     }},


        ]
    }
    return(
        <div className={'past-exams-container'}
             style={{
                 // overflowY:mobileTablet?'auto':'hidden',
                 height:mobileTablet?'100%':'95vh'
            ,marginTop:isMobilScreen?'2rem':'3rem',
                 position:props.isOnLineCall&&'relative',
                 left:props.isOnLineCall&&!mobileTablet&&'3rem',
                 width:props.isOnLineCall&&'95%'
        }}
             onClick={()=>props.closeHeaderMenu()}
        >
            {isLoading?
                <div style={{width:'100%',height:'100%'}} >
                    <Stack spacing={1}>
                        <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
                        <Skeleton variant="circular" width={80} height={80} />
                        <Skeleton variant="rectangular"  height={200} />
                        <Skeleton variant="rounded"  height={200} />
                    </Stack>

                </div>:
                <div className={'past-exams-sub-container'}
                style={{width:mobileTablet&&'90%'}}
                >
                    <div className={'title-container-past-exam'}>
                        <SVGraphics svgname={'back-circle'} className={'back-circle-icon'} onClick={props.backToSession}/>
                        <div className={'past-exam-label'}>Past Exams</div>
                    </div>

                    {sessions.length>0?
                        mobileTablet ?
                        sessions.map(session => {
                            return <div className={'past-exam-session-card'}
                                        onClick={async () => await props.onRowClick(session)}>
                                <div
                                    className={'past-exam-session-card-date'}>{moment(new Date(session['examTime'])).format('DD.MM.yyyy')}</div>
                                <div className={'past-exam-icons-container'}>
                                    {
                                        session.exams.map(exam => {
                                            if (exam !== 'Heart_Rate') {
                                                return <SVGraphics svgname={exam}

                                                                   className={'past-exam-icon'}
                                                />
                                            }
                                        })
                                    }
                                </div>
                                <div className={`past-exams-summary ${!session['Summary']?'past-exams-summary-missing':''}`}>
                                    {`${session['Summary']?session['Summary']:'Missing Summary'}`}
                                </div>
                            </div>
                        })

                        : <div className={'past-exams-table-container'}>
                            <MHDCustomTable data={sessions} allowViewData={true} columns={getColumns()}
                                            diffParams={diffParams}
                                // title={ t('table.noAvailableData')}
                                            className={'past-exams-table'}
                                            onRowClick={async (original, rowInfo) => await props.onRowClick(original, rowInfo)}
                            />

                        </div>
                        :<div>No Sessions</div>}
                </div>
            }

        </div>
    )
}