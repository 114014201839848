/**
 * @author Subhi Samara
 * @Company: Medix Software (ITD Group)
 */
import patientsApi from "./patientsApi";
import sessionsApi from "./sessionsApi";


const doctorApi = {
    ...patientsApi,
    ...sessionsApi,
};

export default doctorApi;
