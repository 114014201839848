import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import '../../../style/component/modal/Media.css'
import SVGraphics from "../../../assets/SVGraphics";
import moment from "moment";
import Original from '../../../assets/light_01.svg'
import Brightest from '../../../assets/light_02.svg'
import Brighter from '../../../assets/light_03.svg'
import Original_white from '../../../assets/light_01_white.svg'
import Brightest_white from '../../../assets/light_02_white.svg'
import Brighter_white from '../../../assets/light_03_white.svg'
import VideoPlayerSnap from "../../../component/media/VideoPlayerSnap";
// import sessionsApi from "../../services/ApiServices/doctorApi/sessionsApi";
// import MakeError from "../Error/ErrorModal";
// import queryString from "query-string";
// import {history} from "../../../utils/history";
import Snapshot from '../../../assets/Snapshot.svg'
import useMediaQuery from "../../../utils/useMediaQuery";
import Tooltip from "../../../component/toolTip/ToolTip";
import MakeError from "../../../component/Error/ErrorModal";
import sessionsApi from "../../../services/ApiServices/doctorApi/sessionsApi";
import {authenticationService} from "../../../services/auth/AuthenticationService";





export default function NewModalWithFilter (props) {



    const [clicked, setClicked] = useState(false);
    const [captures, setCaptures] = useState(props.propsCaptures?props.propsCaptures:[]);
    const [disabled, setDisable] = useState(props.propsCaptures&&props.propsCaptures.length===5?true:false);
    const [url, setUrl] = useState(props.url);
    const [filter, setFilter] = useState('1');
    const [isFilter, setIsFilter] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [toolTip, setToolTip] = useState(false);

    const filters= [{label:'Original',id:"1"},{label:'Brighter',id:"2"}];

    const isSmallMobilScreen = useMediaQuery('(min-width:375px) and (max-width: 450px)');
    const isMobilScreen  = useMediaQuery('(min-width:451px) and (max-width: 767px)');
    const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1125px)');
    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
    const isMobile = isSmallMobilScreen || isMobilScreen

    useEffect(async () => {
        let isAuthenticated = Boolean(sessionStorage.getItem('userLoggedIn') === 'true');
        // sessionStorage.setItem('userLoggedIn','true');
        if(isAuthenticated){
            setIsUserLoggedIn(true)
        }

        // let sessionRes = await doctorApi.getSessionById(sid, tenant, accessToken,asrAuth);
        // if(sessionRes){
        let files = props.files
        let language = 'en'
        if(props.files.length > 1 && props.files.every(file => !!file.filter)){
            setIsFilter(true)
        }else{
            setIsFilter(false)
        }
        if(props.file.type==="Ears"){
            setIsFilter(false)
        }



    }, [])
     const checkQueryParam =async(query) => {
        return Object.keys(query).length > 0 && !!query.pid && !!query.tenant
    }


    const  getFrame = async () =>{
        let type = props.file.type
        let imageType;
        switch (type) {
            case 'Ears': {
                imageType=props.file.subType==='Right'? 'RightEarCapture': 'LeftEarCapture'
                break;
            }
            case 'Throat': imageType = 'ThroatCapture'; break;
            case 'Mouth': imageType = 'MouthCapture'; break;
        }
        if (!imageType) {
            await MakeError({message: 'error taking snapshot'})
            return;
        }
        let dotName = null
        if (captures.length === 0) {
            dotName = "1"
        } else {
            for (let i = 1; i <= 5; i++) {
                let pos = `${i}`
                if (!captures.find(capture => capture.dotName === pos)){
                    dotName = pos
                    break;
                }
            }
        }
        if (captures.length < 5) {
            setDisable(true)
            if(!clicked){
                setToolTip(true)
            }
            let _filter = String(filter) === "1" ? "Filter1" : String(filter) === "2" ? "Filter3" : 'None'
            let _file = props.files.find(x => x.filter === _filter) ? props.files.find(x => x.filter === _filter) : props.file
            let data = {}
            let fileName = _file.sessionID + imageType + 'None' + dotName + 'None'
            let video = document.getElementById('videoId')
            let duration = parseInt(video.duration)
            let time = video ? video.currentTime :0
            if(time>duration){
                time=duration
            }
            data['sessionID'] = _file.sessionID
            data['patientID'] = props.exam.patientID
            data['fileName'] = fileName
            data['type'] = imageType
            data['subType'] = type ==="Ears" ? "None" :_file.subType
            data['filter'] = "None"
            data['dotName'] = dotName
            data['videoUrl'] = url
            data['second'] = time
            // let button = document.getElementById('snapButton')
            // button.style.cursor = "wait";

            let capture = await sessionsApi.addScreenshot( data,props.exam.patientID)
            if (capture) {
                // button.style.cursor = "pointer"
                capture['downloadUrl'] = capture.downloadUrl
                captures.push(capture)
                setCaptures(captures)
                props.addScreenshot()

            }
            setTimeout(()=>{
                setClicked(true)
                setToolTip(false)
                setDisable(false)
                if(captures.length===5){
                    setDisable(true)
                }
            }, 3000)

            // await setTimeout(()=> button.style.cursor = "pointer",3000)
        }
    }
    const seekToTimeBack = () => {
        // try and avoid pauses after seeking
        let video_element = document.getElementById(`videoId`);
        video_element.pause();
        video_element.currentTime =  video_element.currentTime -0.033376 ; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:
        let timer = setInterval(function() {
            if (video_element.paused && video_element.readyState ==4 || !video_element.paused) {
                clearInterval(timer);
            }
        }, 50);
    }

    const seekToTime = () => {
        // try and avoid pauses after seeking
        let video_element = document.getElementById(`videoId`);
        video_element.pause();
        video_element.currentTime =  video_element.currentTime +0.033376 ; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:
        let timer = setInterval(function() {
            if (video_element.paused && video_element.readyState ==4 || !video_element.paused) {
                clearInterval(timer);
            }
        }, 50);
    }
    const changeFilter = (filter) => {
        let url;
        let type = props.file?.type
        let videos = props.files
        let NotFound = props.files.every(el => el.filter === "undefined" || el.filter === "None" || el.filter === null)
        if(NotFound){
            url=props.url
        }else{
            switch(filter) {
                case '1':
                    setUrl(videos.find(x=>x.filter==='Filter1')?.downloadUrl)
                    break;
                case '2':
                    setUrl(videos.find(x=>x.filter==='Filter3')?.downloadUrl)
                    break;
                case '3':
                    setUrl(videos.find(x=>x.filter==='Filter2')?.downloadUrl)
                    break;
                default:
                    setUrl(videos[0].downloadUrl)
                    break;
            }
        }

    setFilter(filter)

    }


        // let videoFiltering = type === 'throat' || type === 'mouth' ? true : false

        let time = moment(props.file.createdAt).format('HH:mm');
        let date = moment(props.file.createdAt).format('MMM DD YYYY');

        return (
            <Modal
                // size="lg"
                show={props.show}
                centered={true}
                onHide={() => props.proceed()}
                dialogClassName={ `media-dialog-snap ${isSmallScreen?'media-dialog-video-small':isMobile?'media-dialog-video-mobile':''}`}
                contentClassName={`media-dialog-snap-content ${isSmallScreen ?'media-dialog-video-small':isMobile?'media-dialog-video-mobile':''}`}
                backdrop={false}
                keyboard={true}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {/*<div className={'video-modal-title'}><span*/}
                {/*    style={{fontWeight: 'bold'}}>{language === 'en' ? file['type'] : file['type'] === 'Mouth' ? 'Boca' : 'Garganta'} video</span> {time} | {date} </div>*/}
                {/*<div className={'media-close-btn-container modal-close-video pointer'} onClick={() => proceed(this.state.captures)}>*/}
                {/*    <SVGraphics svgname={'close-white'} className={'modal-close-btn modal-close-btn-video'} />*/}
                {/*</div>*/}
                <div className={`media-dialog-snap-title ${isMobile?'media-dialog-snap-title-mobile':''}`}><span
                    style={{fontWeight: 'bold'}}>{props.title} </span> {time} | {date}

                    <SVGraphics svgname={'close-white'} className={`media-dialog-snap-modal-close-btn ${isMobile?'media-dialog-snap-modal-close-btn-mobile':''}`} onClick={() => props.proceed()}/>
                </div>

                {/*<Modal.Header className={'video-media-header media-header'} closeButton/>*/}

                <Modal.Body
                    className={ 'media-dialog-ear-video'}>


                    {/*<VideoPlayerSnap id={"videoId"}*/}
                    {/*                 // videoRef={this.videoRef}*/}
                    {/*                 closeFullScreen={() => props.proceed()}*/}
                    {/*             className={'media-dialog-snap-image'}*/}
                    {/*             url={url}/>*/}
                    {isSafari ? <video id={'videoId'}
                            // onTimeUpdate={(e)=>this.handleSeekChange(e)}
                            //            onEnded={()=>this.onEnded()}
                                       style={{width: "99.9%", height: isMobile?'80%':'90%'}}
                            //            onSeeking={(e) => handleSeekChange(e)}
                            //            onSeeked={(e) => handleSeekChange(e)}
                            //            style={{width: "93.9%", height: '96%',objectFit:'cover',border:'1px solid'}}
                                       // onPlaying={this.props.onPlaying}
                                       // onPause={this.props.onPause}
                                       // onMouseMove={this.props.onMouseMove}
                                       // onMouseOut={this.props.onMouseOut}
                                       // onLoadedMetadata={handleLoadedMetadata}
                                       playsInline
                                       preload="metadata"
                            // muted
                                       webkit-playsinline
                                       controls
                                       disablePictureInPicture
                                       controlslist="nodownload noremoteplayback noplaybackrate nofullscreen "
                        >
                            {/*{url === this.props.url && <source src={url} type="video/mp4"/>}*/}
                            {/*{url !== this.props.url && <source src={url} type="video/mp4"/>}*/}
                            <source src={url+'#t=0.001'} type="video/mp4"/>
                        </video>:
                    <video
                        id={'videoId'}
                        // onTimeUpdate={(e)=>this.handleSeekChange(e)}
                        // onEnded={()=>this.onEnded()}
                           style={{width: "99.9%", height: isMobile?'80%':'90%'}}
                        // onSeeking={(e) => this.handleSeekChange(e)}
                        // onSeeked={(e) => this.handleSeekChange(e)}
                           controls
                        // onLoadedMetadata={this.props.onLoadedMetadata}
                        // onPlaying={this.props.onPlaying}
                        // onPause={this.props.onPause}
                           disablePictureInPicture={true}
                           preload
                           playsInline
                           webkit-playsinline
                        controls
                        playsInline
                        disablePictureInPicture
                        controlslist="nodownload noremoteplayback noplaybackrate nofullscreen "
                        src={url?url+'#t=0.001':this.props.url+'#t=0.001'}
                        // onMouseMove={this.props.onMouseMove}
                        // onMouseOut={this.props.onMouseOut}
                        // crossOrigin="anonymous"

                    />}
                    {/*    {url === props.url && <source src={url+'#t=0.001'} type="video/mp4"/>}*/}
                    {/*    {url !== props.url && <source src={url+'#t=0.001'} type="video/mp4"/>}*/}
                    {/*</video>*/}


                        <>

                            <div className={'brightnessFilterContainerNew'}>
                                {isFilter &&
                                    <div className={isFilter ? 'brightnessFilterNew' : 'brightnessFilterNew hidden'}
                                         style={{width:isSmallMobilScreen?'9rem':isMobilScreen?'14rem':'15.063rem'}}
                                    >

                                        {
                                            filters.map(_filter => {
                                                let black = _filter.id === "1" ? Original : _filter.id === '2' ? Brighter : Brightest
                                                let white = _filter.id === '1' ? Original_white : _filter.id === '2' ? Brighter_white : Brightest_white
                                                return <label key={_filter.id}
                                                    // onMouseOver={() => this.setState({hover: true, filter: filter})}
                                                    // onMouseOut={() => this.setState({hover: false, filter: ''})}
                                                              onClick={() => changeFilter(_filter.id)}
                                                              className={'radio-brightness-container'}>
                                                    <input type="radio" value={_filter.id}
                                                           className={'brightnessFilter-input'}
                                                           name="brightness"/> <span
                                                    className={filter === _filter.id ? 'brightnessFilterButtonSelected' : 'brightnessFilterButton'}
                                                    style={{width:isSmallMobilScreen?'3.5rem':isMobilScreen?'6rem':'100%'}}
                                                >
                                        <img alt={_filter.label}
                                             src={filter === _filter.id ? black : white}/> {!isSmallMobilScreen?_filter.label:''}</span></label>
                                            })
                                        }
                                    </div>
                                }


                                <div className={'brightnessFilterNew frameFilterNew'}
                                style={{width:isMobile?'6rem':'16rem'}}
                                >
                                    <div className={'radio-brightness-container'} onClick={() => seekToTimeBack()}>
                                        <div className={' frameFilterWhiteNew'}>
                                            <span style={{width: '100%'}}>
                                                <SVGraphics width={isSmallMobilScreen?"50%":isMobilScreen?'30%':'20%'} height={isSmallMobilScreen?"50%":isMobilScreen?'30%':'20%'} svgname={'frameB'}
                                                            style={{marginRight: '2px'}} className={'modal-frame'}/>
                                                {isSmallMobilScreen?'':isMobilScreen?'-1':'-1 Frame'}</span>
                                        </div>
                                    </div>
                                    <div className={'radio-brightness-container'}>
                                        <div className={' frameFilterWhiteNew'} onClick={() => seekToTime()}> <span
                                            style={{width: '100%'}}
                                        >
                                         {isSmallMobilScreen?'':isMobilScreen?'+1':'+1 Frame'}   <SVGraphics svgname={'frameF'} height={isSmallMobilScreen?"50%":isMobilScreen?'30%':'20%'} width={isSmallMobilScreen?"50%":isMobilScreen?'30%':'20%'}
                                                               style={{marginLeft: '2px'}} className={'modal-frame'}/>

                                    </span></div>
                                    </div>
                                </div>
                                {isUserLoggedIn &&
                                    <>
                                <label className={'captures-length'}>{captures?.length}</label>
                                    <Tooltip
                                    style={{visibility:toolTip?'visible':'hidden'}}
                                    text={
                                    <button  className={`brightnessFilter ${isMobile?'snapShotContainer-mobile':'snapShotContainer'}`}
                                    onClick={() => getFrame()}
                                    disabled={disabled}
                                    style={{cursor:disabled?'default':'pointer'}}
                                    >
                                    <div id={'snapButton'} className={`frameFilterWhite ${isMobile?'':'snapShotWhite'} ${disabled?'getFrameDisabled':''}`} >
                                    <div className={'radio-brightness-container'}> <span style={{width: '100%'}}
                                    >
                                    <img alt={'Snapshot'} src={Snapshot} style={{
                                    width: '20px'
                                }}/>  {isMobile?'':'Snapshot'} </span></div>
                                    </div>
                                    </button>
                                }
                                    placement={'top'}
                                    tooltipText={'Snapshots will appear once the player is minimized'}/>
                                    </>
                                }

                            </div>

                            <div id="output"></div>
                        </>

                </Modal.Body>
            </Modal>
        );

}

NewModalWithFilter.propTypes = {
    show: PropTypes.bool,
    url: PropTypes.string,
    type: PropTypes.string,
    files: PropTypes.array,
    filters: PropTypes.array,
    file: PropTypes.object,
    // captures: PropTypes.object
};

// export default function NewVideoModalWithFilter({url: url = '', type: type = '', file: file,files:files,filters:filters=[],id:id}) {
//     return createConfirmation(confirmable(NewModalWithFilter))({
//         url, type, file,files,filters,id
//     });
// }
