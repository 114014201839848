import React, {Component} from "react";
import utils from "../../../utils/utils";
import MHDTable from "../../table/MHDTable";
import Status from "../../../config/Status";
import SessionExams from "../session/offline/SessionExams";
import SVGraphics from "../../../assets/SVGraphics";

const exmasArr = ["Body_Temperature", "Saturation", "Heart_Rate", "Heart", "Abdomen", "Lungs", "Ears", "Throat", "Skin"]

class SessionHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            sessions: []
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        let {sessions, setRef,t} = this.props
        setRef(this)
        sessions.forEach(session => {
            session['duration'] = new Date(session['examStartTime']) - new Date(session['examEndTime'])
            session['durationLabel'] = utils.format24(new Date(session['examStartTime'])) + ' - ' + utils.format24(new Date(session['examEndTime']))
            let updated;
            if (session['status'] === Status.ended || session['status'] === Status.read) {
                updated = t('physician.patient.exams.missingSummary')
                if (!!session['summary'] && (session['summary']).length > 0) {
                    updated = 'Summary not sent'
                }
            } else if (session['status'] === Status.sent || session['status'] === Status.done) updated = utils.getDateAndTime(new Date(session['updatedAt']))
            session['updated'] = updated

            session['physician'] = (session['Physician'])['firstName'] + ' ' + (session['Physician'])['lastName']
            session['examsLength'] = (session['exams']).includes('Saturation') ? (session['exams']).length - 1 : (session['exams']).length
        })
        this.setState({isLoading: false, sessions})
    }

    async expandRowBySid(sid) {
        await this.tableRef.expandRowBySid(sid)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {sessions,t} = nextProps
        let sessionsClone = Array.from(sessions)
        sessionsClone.forEach(session => {
            session['duration'] = new Date(session['examStartTime']) - new Date(session['examEndTime'])
            session['durationLabel'] = utils.format24(new Date(session['examStartTime'])) + ' - ' + utils.format24(new Date(session['examEndTime']))
            let updated;
            if (session['status'] === Status.ended || session['status'] === Status.read) {
                updated = t('physician.patient.exams.missingSummary')
                if (!!session['summary'] && (session['summary']).length > 0) {
                    updated = 'Summary not sent'
                }
            } else if (session['status'] === Status.sent || session['status'] === Status.done) updated = utils.getDateAndTime(new Date(session['updatedAt']))
            session['updated'] = updated
            session['physician'] = (session['Physician'])['firstName'] + ' ' + (session['Physician'])['lastName']
            session['examsLength'] = (session['exams']).includes('Saturation') ? (session['exams']).length - 1 : (session['exams']).length
        })
        this.setState({isLoading: false, sessions: sessionsClone})
    }

    saveSummary(newSession) {
        newSession['duration'] = new Date(newSession['examStartTime']) - new Date(newSession['examEndTime'])
        newSession['durationLabel'] = utils.format24(new Date(newSession['examStartTime'])) + ' - ' + utils.format24(new Date(newSession['examEndTime']))
        let updated = utils.getDateAndTime(new Date(newSession['updatedAt']))
        newSession['updated'] = updated
        newSession['examsLength'] = (newSession['exams']).includes('Saturation') ? (newSession['exams']).length - 1 : (newSession['exams']).length
        let {sessions} = this.state
        let index = sessions.findIndex(session => session['sessionID'] === newSession['sessionID']);
        if (index > -1) sessions[index] = newSession;
        this.setState({sessions})
    }

    getColumns = () => {
        let {t , tenant} = this.props
        let language= 'en'
        return [
            {
                accessor: 'examTime', Header: () => {return <div style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}> {t('physician.patient.profile.dateTaken')}</div>}, resizable: false, Cell: ({original}) => {
                    return (
                        utils.getDateStringWithYear(new Date(original['examTime']),language)
                    );
                },


            },
            {
                accessor: 'duration',  Header: () => {return <div style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}> {t('physician.patient.profile.duration')}</div>}, resizable: false, Cell: ({original}) => {
                    return original.durationLabel
                },
            },
            {
                accessor: 'updated',  Header: () => {return <div style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}> { t('physician.patient.profile.updated')}</div>}, resizable: false, Cell: ({original}) => {
                    if (original.updated === t('physician.patient.exams.missingSummary') || original.updated === 'Summary not sent')
                        return (
                            <div className={'red'}>
                                {original.updated}
                            </div>
                        );
                    else return original.updated
                },
            },
            {accessor: 'videoSessionStatus',Header: () => {return <div style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}> { t('physician.patient.profile.type')}</div>}, resizable: false, width: 150,Cell: ({original}) => {
                if(language === 'en')
                return original.videoSessionStatus
                    else if(original.videoSessionStatus === 'Online')
                        return "En línea"
                else if(original.videoSessionStatus === 'Offline')
                        return "Sin conexión"
                }
            },
            {accessor: 'physician', Header: () => {return <div style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}> { t('physician.patient.profile.assignedTo')}</div>}, resizable: false,Cell: ({original}) => {
                    let label = original.Physician?original.Physician["PII"]?.firstName+" "+original.Physician["PII"]?.lastName:""

                    if(original.onCall)
                        return label+" "+t('physician.patient.profile.onCall')
                    return label
            },width:240
    },
            // {accessor: 'physician', Header: 'By', resizable: false, maxWidth: 350},
            {
                accessor: 'examsLength', Header: () => {return <div style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}> { t('physician.patient.profile.exams')}</div>}, resizable: false, Cell: ({original}) => {
                    let exams = original.exams
                    exams = exams.sort(function (a, b) {
                        return exmasArr.indexOf(a) - exmasArr.indexOf(b);
                    });

                    return <div className={'session-history-exam-icons'}>
                        {
                            exams.map(exam => {
                                if (exam !== 'Heart_Rate') {
                                    return <SVGraphics svgname={exam} className={'session-history-exam-icon'}/>
                                }
                            })
                        }
                    </div>
                }, minWidth: 150
            }
            // {accessor: 'pending', Header: '', resizable: false}
        ]
    }

    render() {
        let {isLoading, sessions} = this.state
        let {patient,tenant} = this.props
        let {session, t} = this.props

        //style={{color:tenant === 'fluentsmartexam'?"#002D74":"#4C23B5"}}

        return (
            <div className={'patient-card-content-container session-history-container'}>
                <div className={'session-history-header'}
                     style={{color:tenant === 'fluentsmartexam'?"rgb(0, 45, 116)":"rgb(76, 35, 181)"}}
                >{t('physician.patient.profile.assignedTo')}</div>
                <MHDTable setTableRef={tableRef => this.tableRef = tableRef} isLoading={isLoading} data={sessions}
                          title={ t('table.noAvailableData')}
                          tenant={tenant}
                          columns={this.getColumns()} session={session}
                          SubComponent={(v) => {
                              return <SessionExams id={v.original.sessionID} viewIndex={v.viewIndex}
                                                   session={v.original} patient={patient}
                                                   t={this.props.t}
                                                   tenant={tenant}
                                                   setStatusRead={(sessionID) => this.props.setStatusRead(sessionID)}
                                                   saveSummary={session => this.saveSummary(session)}/>
                          }}/>
            </div>
        );
    }

}

export default SessionHistory
