const Status = {

    calling: 'Calling',
    missed: 'Missed',
    initiated: 'Initiated',
    denied: 'Denied',
    inProgress: 'InProgress',
    ended: 'Ended',
    read: 'Read',
    updated: 'Updated',
    sent: 'Sent',
    done: 'Done',

    update: 'update' //fixme: delete this status

};

Object.freeze(Status);
export default Status;
